<h1 mat-dialog-title>{{NewAd}}</h1>
<div mat-dialog-content>
  <form [formGroup]="newAdForm" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="fill" [class.disabled]="disabled">
      <mat-select [value]="selectedAdType"
                  formControlName="adTypeCtrl">
        <mat-option *ngFor="let ad of adTypes" [value]="ad">
          {{ad.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Titel</mat-label>
      <input matInput
             type="text"
             formControlName="titleCtrl"
             id="title"
             placeholder="Bsp.: Adventkalender">
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true">{{Cancel}}</button>
  <button mat-button
          [mat-dialog-close]
          [disabled]="!newAdForm.valid"
          (click)="onSubmit()"
          type="submit">{{Create}}</button>
</div>
