<ng-container *ngIf="adModel !== undefined">
  <div id="ad-reflection-wrapper" class="row justify-content-center">
    <div
      id="view-container"
      class="col h-100 d-flex justify-content-end"
      appAdGenerateHtml
      [adModel]="adModel"
    >
      <div id="view-container-wrapper">
        <ng-container [ngSwitch]="adTypeService.getAdTypeName(adModel) | async">
          <ng-container *ngSwitchCase="StorySlug">
            <app-story-ad
              [adModel]="adModel"
              appGeneratePreviewThumb
            ></app-story-ad>
          </ng-container>
          <ng-container *ngSwitchCase="PickerSlug">
            <app-picker-ad
              [adModel]="adModel"
              appGeneratePreviewThumb
            ></app-picker-ad>
          </ng-container>
          <ng-container *ngSwitchDefault>No template found!</ng-container>
        </ng-container>

        <div id="file-size-info" class="d-flex justify-content-between align-items-center mt-3"
             *ngIf="fileSize !== null">
          <span>{{ AdFileSize }}</span>
          <div id="file-size-chip"
               class="d-flex"
               [ngClass]="FileSizeInfo[fileSizeStatus]"
               (click)="openFileSizeInfoDialog()">
            <span>{{ fileSize | fileSize }}</span>
            <ng-container [ngSwitch]="fileSizeStatus">
              <ng-container *ngSwitchCase="FileSizeInfo.OkForAll">
                <span class="icon material-icons-outlined">done</span>
              </ng-container>
              <ng-container *ngSwitchCase="FileSizeInfo.OkForSome">
                <span class="icon material-icons-outlined">warning</span>
              </ng-container>
              <ng-container *ngSwitchCase="FileSizeInfo.OkForAny">
                <span class="icon material-icons-outlined">error</span>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div id="view-container-shadow" class="position-absolute"></div>
    <!-- Container for the preview thumb -->

    <div
      id="view-container-controls"
      data-html2canvas-ignore="true"
      class="ps-1 col-3 d-flex flex-column align-items-start"
      *ngIf="!hideControls"
    >
      <div id="control-wrapper" class="d-flex flex-column">
        <button
          id="refresh"
          mat-button
          (click)="refresh()"
          class="primary white icon-btn mat-elevation-z0 my-2"
        >
          <span class="d-flex align-items-center">
            <span class="material-icons-outlined">refresh</span>
            <span class="icon-text">{{Reload}}</span>
          </span>
        </button>
        <a
          id="open-preview"
          mat-button
          [routerLink]="[
            UrlSegmentDelimiter,
            AdsRoute,
            AdPreviewRoute,
            adModel.id
          ]"
          [queryParams]="{ v: 'live' }"
          target="_blank"
          class="primary white icon-btn mat-elevation-z0 text-decoration-none"
        >
          <span class="d-flex align-items-center">
            <span class="material-icons-outlined">open_in_new</span>
            <span class="icon-text">Öffnen</span>
          </span>
        </a>
      </div>
    </div>
  </div>
</ng-container>
