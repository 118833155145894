<div
  class="w-100 d-flex flex-column align-items-start justify-content-center gap-1"
  [formGroup]="currentFormGroup"
>
  <div
    class="py-2 w-100 px-4 d-flex gap-2"
    *ngIf="rootFormGroup.get('image_file')?.value"
  >
    <button class="transparent-btn" (click)="deleteImageFile()">
      <i class="material-icons">delete</i>
    </button>
    <img
      alt=""
      [src]="
        sanitizer.bypassSecurityTrustResourceUrl(
          '/api/image/' + rootFormGroup.get('image_thumb_file')?.value
        )
      "
    />
  </div>
  <input
    type="file"
    class="d-none"
    accept="image/png, image/gif, image/jpeg"
    #imgInput
    (change)="handleImageChange($event)"
  />
  <button
    (click)="imgInput.click()"
    class="mb-1 w-100 border-1 rounded-2 py-2 gap-1 d-flex justify-content-center align-items-center bg-white my-2"
    style="color: #6e72eb; border-color: #6e72eb"
  >
    <i class="material-icons">upload</i>
    <span
      class="text-uppercase"
      style="
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.5px;
      "
      >bILD HOCHLADEN</span
    >
  </button>
  <label style="font-size: 14px; color: #332e45" class="my-2"
    >Ausrichtung</label
  >
  <div class="row w-100">
    <div class="col-12 col-md-6">
      <div
        class="col gap-1 d-flex flex-row justify-content-between align-items-center"
      >
        <button
          class="form-control"
          (click)="
            newPromotionService.toggleStyle(rootFormGroup?.get('alignSelf'), {
              activeValue: 'left',
              defaultValue: 'auto'
            })
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            [ngClass]="{
              'text-style-icon-selected':
                rootFormGroup?.get('alignSelf')?.value === 'left'
            }"
            fill="currentColor"
          >
            <path
              d="M3.83317 18.8334H2.1665V2.16675H3.83317V18.8334ZM18.8332 6.33342H5.49984V8.83342H18.8332V6.33342ZM13.8332 12.1667H5.49984V14.6667H13.8332V12.1667Z"
            />
          </svg>
        </button>
        <button
          class="form-control bg-white"
          (click)="
            newPromotionService.toggleStyle(rootFormGroup?.get('alignSelf'), {
              activeValue: 'center',
              defaultValue: 'auto'
            })
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="currentColor"
            [ngClass]="{
              'text-style-icon-selected':
                rootFormGroup?.get('alignSelf')?.value === 'center'
            }"
          >
            <path
              d="M9.66667 2.16675H11.3333V6.33342H18V8.83342H11.3333V12.1667H15.5V14.6667H11.3333V18.8334H9.66667V14.6667H5.5V12.1667H9.66667V8.83342H3V6.33342H9.66667V2.16675Z"
            />
          </svg>
        </button>
        <button
          class="form-control bg-white"
          (click)="
            newPromotionService.toggleStyle(rootFormGroup?.get('alignSelf'), {
              activeValue: 'right',
              defaultValue: 'auto'
            })
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="currentColor"
            [ngClass]="{
              'text-style-icon-selected':
                rootFormGroup?.get('alignSelf')?.value === 'right'
            }"
          >
            <path
              d="M17.1665 2.16675H18.8332V18.8334H17.1665V2.16675ZM2.1665 8.83342H15.4998V6.33342H2.1665V8.83342ZM7.1665 14.6667H15.4998V12.1667H7.1665V14.6667Z"
            />
          </svg>
        </button>
      </div>
    </div>
    <div class="col-12 col-md-6" formGroupName="style_image">
      <div
        class="d-flex w-100 flex-row justify-content-between align-items-center"
      >
        <label for="breite2" style="font-size: 14px; color: #332e45"
          >Breite</label
        >
        <label for="breite2" style="font-size: 14px; color: #332e45"
          >{{ rootFormGroup?.get("width")?.value }}%</label
        >
      </div>
      <input
        type="range"
        class="form-range"
        id="breite2"
        formControlName="width"
      />
    </div>
  </div>

  <input
    type="text"
    class="form-control my-2"
    formControlName="image_title"
    placeholder="Text"
    aria-label="text"
  />
</div>
