import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { EditAdNavigationService } from '../edit-ad-navigation.service';
import { SubSink } from 'subsink';
import { HtmlGeneratorService } from '../../../shared/services/html-generator.service';
import { HttpClient } from '@angular/common/http';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import {
  AdBaseModel,
  PickerAdModel,
  StoryAdModel,
} from '../../../shared/models/ad.model';
import { AdsService } from '../../ads.service';
import { combineLatest, of } from 'rxjs';
import { AdTypeService } from '../../ad-type.service';
import { CommonAdTemplateService } from '../../ad-template-types/common-ad-templates/common-ad-template.service';
import { environment } from '../../../../environments/environment';
import {
  FontSource,
  FontsService,
} from '../../../shared/services/fonts.service';
import { FontModel } from '../../../shared/models/font.model';
import {
  PickerSlug,
  StorySlug,
} from '../../../shared/data-repository/api-slugs';
import { AdAsset } from '../../../shared/interfaces/ad-asset';
import { SlideModel } from '../../../shared/models/slide.model';
import { OptionModel } from '../../../shared/models/option.model';

@Directive({
  selector: '[appAdGenerateHtml]',
})
export class AdGenerateHtmlDirective implements OnInit, OnDestroy {
  @Input() public adModel!: AdBaseModel;
  private subs = new SubSink();
  private fontModels = new Array<FontModel>();

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private httpClient: HttpClient,
    private htmlGeneratorService: HtmlGeneratorService,
    private adsService: AdsService,
    private fontsService: FontsService,
    private adTypesService: AdTypeService,
    private commonAdTemplateService: CommonAdTemplateService,
    private editAdNavigationService: EditAdNavigationService
  ) {}

  ngOnInit(): void {
    this.loadFontModels();
    this.initEvents();
    this.updateAd();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private initEvents(): void | never {
    this.subs.sink = this.commonAdTemplateService.updatePreviewAndFileSize
      .pipe(
        withLatestFrom(this.adTypesService.getAdTypeName(this.adModel)),
        switchMap(([previewRendered, adTypeName]) => {
          const downloadZip = previewRendered[0];
          const adAssets = previewRendered[1];

          let [jsFileName, adCSS] =
            this.htmlGeneratorService.getAssets(adTypeName);
          return combineLatest([
            this.httpClient.get(`assets/js/${jsFileName}`, {
              responseType: 'text',
            }),
            of(adCSS),
            of(adTypeName),
            of(downloadZip),
            of(adAssets),
          ]);
        })
      )
      .subscribe(([js, adCSS, adTypeName, downloadZip, adAssets]) => {
        if (!this.elementRef.nativeElement.querySelector('.ad-container')) {
          throw new Error('Element with class "ad-container" not found');
        }
        const html =
          this.elementRef.nativeElement.querySelector('#renderable')!.outerHTML;

        let selectedFontNames = this.getSelectedFonts();

        let adHtml = this.htmlGeneratorService.buildHTML(
          this.adModel.title!,
          adTypeName,
          html,
          selectedFontNames,
          downloadZip
        );

        if (environment.compress) {
          adHtml = adHtml
            .replace(/(\r\n|\n|\r)/gm, '')
            .replace(/<\!--.*?-->/g, '')
            .replace(/\>\s*\</g, '><');
        }
        const composite = this.generateHTMLComposite(
          adHtml,
          adCSS,
          js,
          selectedFontNames,
          adTypeName,
          adAssets
        );

        this.editAdNavigationService.adHtmlGenerated$.next([
          composite,
          downloadZip,
        ]);
      });
  }

  private getSelectedFonts(): Array<string> {
    let fonts = new Array<string>();
    if (this.adModel.baseable instanceof StoryAdModel) {
      fonts.push(this.adModel.baseable.headlineFontType);
      fonts.push(this.adModel.baseable.textFontType);
    } else if (this.adModel.baseable instanceof PickerAdModel) {
      fonts.push(this.adModel.baseable.headlineFontType);
      fonts.push(this.adModel.baseable.infoTextFontType);
      fonts.push(this.adModel.baseable.continuousTextFontType);
    } else {
      throw new Error('Ad type is not supported.');
    }

    // CTA button font type
    if (this.adModel.ctaButtonTextFontType) {
      fonts.push(this.adModel.ctaButtonTextFontType);
    }

    fonts = fonts.filter(function (item, pos) {
      return fonts.indexOf(item) == pos;
    });

    return fonts;
  }

  private updateAd(): void {
    this.subs.sink = this.editAdNavigationService.adModelChanged$.subscribe(
      (adModel) => {
        this.adModel = adModel;
      }
    );
  }

  private loadFontModels(): void {
    this.fontsService.selectFonts().subscribe((fonts) => {
      this.fontModels = [...fonts];
    });
  }

  private generateHTMLComposite(
    adHtml: string,
    adCSS: string,
    js: string,
    selectedFontNames: string[],
    adTypeName: string,
    adAssets: Array<AdAsset> | null
  ): HTMLAdComposite {
    let adAssetsForHTTP: AssetCapsule[] = [];
    if (adAssets) {
      adAssetsForHTTP = this.generateAdAssets(adTypeName, adAssets);
    }

    let customFonts = this.generateFontAssets(selectedFontNames);

    let assetType = '';
    switch (adTypeName) {
      case StorySlug:
        assetType = 'slides';
        break;
      case PickerSlug:
        assetType = 'options';
        break;
      default:
        throw new Error('Ad type is not supported.');
    }

    const composite: HTMLAdComposite = {
      html: adHtml,
      css: adCSS,
      js,
      customFonts: customFonts,
      [assetType]: adAssetsForHTTP,
    };

    if (this.adModel.logo && this.adModel.logo !== '') {
      composite.logo = {
        content: this.adModel.logo ?? '',
        filename: this.adModel.logoImageName ?? '',
      };
    }

    return composite;
  }

  private generateAdAssets(
    adTypeName: string,
    adAssets: Array<AdAsset>
  ): AssetCapsule[] | never {
    if (adTypeName === StorySlug) {
      return this.mapSlideAssets(adAssets);
    } else if (adTypeName === PickerSlug) {
      return this.mapOptionAssets(adAssets);
    } else {
      throw new Error('Ad type is not supported.');
    }
  }

  private mapSlideAssets(adAssets: Array<AdAsset>): AssetCapsule[] {
    return adAssets
      .filter((asset) => {
        const slide = asset as SlideModel;
        return slide.imageCropped && slide.imageCropped !== '';
      })
      .map((asset) => {
        const slide = asset as SlideModel;
        return {
          content: slide.imageCropped ?? '',
          filename: slide.imageName,
        };
      });
  }

  private mapOptionAssets(adAssets: Array<AdAsset>): AssetCapsule[] {
    const optionAssets = new Array<AssetCapsule>();

    const thumbImages = adAssets
      .filter((asset) => {
        const option = asset as OptionModel;
        return option.thumbImageCropped && option.thumbImageCropped !== '';
      })
      .map((asset) => {
        const option = asset as OptionModel;
        let adAsset: AssetCapsule;
        adAsset = {
          content: option.thumbImageCropped ?? '',
          filename: option.thumbImageName ?? '',
        };
        return adAsset;
      });

    const infoImages = adAssets
      .filter((asset) => {
        const option = asset as OptionModel;
        return (
          option.infoBackgroundImageCropped &&
          option.infoBackgroundImageCropped !== ''
        );
      })
      .map((asset) => {
        const option = asset as OptionModel;
        return {
          content: option.infoBackgroundImageCropped ?? '',
          filename: option.infoBackgroundImageName ?? '',
        };
      });

    optionAssets.push(...thumbImages);
    optionAssets.push(...infoImages);
    return optionAssets;
  }

  private generateFontAssets(selectedFontNames: string[]): AssetCapsule[] {
    const selectedFontModels = this.fontsService.fonts.filter((font) =>
      selectedFontNames.includes(font.title)
    );
    return selectedFontModels
      .filter((fontModel) => fontModel.source === FontSource[FontSource.Custom])
      .map((fontModel) => {
        return {
          filename: fontModel.title + '.' + fontModel.type,
          content: fontModel.content,
        };
      });
  }
}

/**
 * Encapsulates all data that is needed to generate the zip and calculate the file size, respectively.
 */
export type HTMLAdComposite = {
  css: string;
  html: string;
  js: string;
  logo?: AssetCapsule;
  customFonts: AssetCapsule[];
  [asset: string]: any; // Slides, options for file size calculation
};

export type AssetCapsule = {
  content: string;
  filename: string;
};
