import {FontModel} from '../../shared/models/font.model';
import {createReducer, on} from '@ngrx/store';
import {setFontList} from './fonts.actions';

export const initialState: ReadonlyArray<FontModel> = [];

export const fontsReducer = createReducer(
  initialState,
  on(setFontList, (state, {payload}): ReadonlyArray<FontModel> => {
    return [...payload];
  })
);
