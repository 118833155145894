<form class="form configuration-items-wrapper"
      [formGroup]="commonEditAdDownloadService.downloadTemplateForm"
      [ngClass]="{ 'displayErroneousInputs': commonEditAdDownloadService.displayErroneousInputs }">

  <!-- ############ Vorschau -->
  <app-ad-configuration-item [item]="commonEditAdDownloadService.previewConfigurationItem">
    <div class="col-12 align-items-center">
      <div class="row mt-3 align-items-center">
        <div class="col-11"><a id="prev-link"
                               class="text-decoration-none">{{commonEditAdDownloadService.openPreviewLink}}</a></div>
        <div class="col-1 ps-0 justify-content-start">
          <a class="material-icons text-decoration-none copy-btn"
             (click)="commonEditAdDownloadService.copyToClipboard()">content_copy</a>
        </div>
      </div>
    </div>
  </app-ad-configuration-item>

  <!-- ############ Download -->
  <app-ad-configuration-item [item]="commonEditAdDownloadService.downloadConfigurationItem">
    <div class="col-12 mt-3">
      <span class="fst-italic">{{commonEditAdDownloadService.Remark}}</span>
    </div>

    <div class="col-12 mt-2">
      <mat-form-field appearance="fill">
        <mat-label>{{commonEditAdDownloadService.Publisher}}</mat-label>
        <mat-select
          (selectionChange)="commonEditAdDownloadService.publisherChanged($event)"
          formControlName="publisher">
          <mat-option
            [value]="option.key"
            *ngFor="let option of commonEditAdDownloadService.PublisherOptions">
            {{option.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-12 mb-2">
      <mat-form-field appearance="fill" class="mt-0" [hidden]="true"> <!-- [hidden]="true" => remove -->
        <mat-label>{{commonEditAdDownloadService.TargetLink}}</mat-label>
        <input matInput formControlName="targetLink">
      </mat-form-field>
    </div>

    <div class="col-12 mt-1">
      <mat-slide-toggle [hidden]="commonEditAdDownloadService.trackingInvisible"
                        style="display: none !important;">{{commonEditAdDownloadService.ActivateTracking}}</mat-slide-toggle>
      <!-- style="display: none !important;" => remove -->
    </div>
  </app-ad-configuration-item>

  <!-- ############ Infobox -->
  <div class="card-wrapper col-12" style="margin-top: 71px">
    <app-info-box [infoBox]="commonEditAdDownloadService.downloadAdInfoBox"></app-info-box>
  </div>
</form>

<app-edit-ad-navigation></app-edit-ad-navigation>

