<div id="ad-container" class="container-fluid p-0">
  <div class="row">

    <div class="card-wrapper col-xxl-4 col-xl-6 col-sm-6 mb-xl-0 mb-0"
         *ngFor="let ad of ads;">
      <div class="card hoverable-card" [ngClass]="ad.status!">
        <div class="hoverable-icons">
          <a class="icon material-icons me-2 text-decoration-none mat-elevation-z3"
             [routerLink]="[UrlSegmentDelimiter, AdsRoute, AdPreviewRoute, ad.id]"
             target="_blank">remove_red_eye</a>
          <a class="icon material-icons ms-2 text-decoration-none mat-elevation-z3"
             *ngIf="ad.status !== AdStatus.AdStatusDone"
             [routerLink]="[UrlSegmentDelimiter, AdsRoute, EditRoute, adTypeService.getAdTypeName(ad) | async, ad.id, EditDesignRoute]"
             [state]="{accessible: true}">edit</a>
        </div>
        <div class="more position-relative">
          <a class="material-icons context text-decoration-none"
             (click)="contextMenu.onContextMenu($event, contextMenuItems, ad)">more_horiz</a>
        </div>
        <div class="card-body d-flex text-start">
          <div class="ad-preview-img" *ngIf="ad.thumbnail">
            <img [src]="sanitizer.bypassSecurityTrustResourceUrl(ad.thumbnail)">
          </div>
          <div class="ad-data d-flex flex-column">
            <h2 class="ad-title">{{ad.title}}</h2>
            <h4 class="mb-0">{{(adTypeService.getAdType(ad.adTypeId!) | async)?.title}}</h4>
            <h4 class="">{{ad.created | date:'dd.MM.yyyy'}}</h4>
            <div class="state mt-auto">
              <button mat-button class="pe-none">{{ad.status! | adStatus}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<app-context-menu #contextMenu="context-menu"></app-context-menu>
