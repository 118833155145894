import {Component, HostBinding, Input} from '@angular/core';
import { NewPromotionService } from '../../../../new-promotion.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadService } from '../../../../../../backend-services/upload.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-image-styles-view',
  templateUrl: './image-styles-view.component.html',
  styleUrls: ['./image-styles-view.component.scss'],
})
export class ImageStylesViewComponent {
  @HostBinding('class.w-100')
  @Input() rootFormGroup!: any;
  constructor(
    public newPromotionService: NewPromotionService,
    public sanitizer: DomSanitizer,
    private uploadService: UploadService
  ) {}

  get currentFormGroup(): FormGroup {
    return this.rootFormGroup as FormGroup;
  }

  handleImageChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.uploadService.uploadImage(file).subscribe({
        next: (res) => {
          if (res.success && res.data?.file) {
            this.currentFormGroup.get('image_file')?.setValue(res.data.file);
            this.currentFormGroup
              .get('image_thumb_file')
              ?.setValue(res.data.thumb);
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
    }
  }

  deleteImageFile() {
    const file = this.currentFormGroup.get('image_file')?.value;
    if (file) {
      this.uploadService.deleteImage(file).subscribe({ error: console.log });
    }

    this.currentFormGroup.get('image_file')?.setValue('');
    this.currentFormGroup.get('image_thumb_file')?.setValue('');
  }
}
