import {Pipe, PipeTransform} from '@angular/core';
import {AdStatusDone, AdStatusInProgress, AdStatusNew} from '../../data-repository/global-text-snippets';
import {FilterBaseableType, FilterStatus} from '../../data-repository/global-constants';
import {AdTypeModel} from '../../models/ad-type.model';

@Pipe({
  name: 'filterLabel'
})
export class FilterLabelPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string | never {
    if (!args[0]) {
      return '';
    }

    if (args[0] === FilterStatus) {

      if (value === 'AdStatusNew') {
        return AdStatusNew;
      } else if (value === 'AdStatusInProgress') {
        return AdStatusInProgress;
      } else {
        return AdStatusDone;
      }
    } else if (args[0] === FilterBaseableType) {
      if (!args[1]) {
        throw new Error('Ad types are undefined.')
      }
      const adTypes = args[1] as Array<AdTypeModel>;
      let adType = adTypes.find(ad => ad.name === value);
      return adType!.title;
    } else {
      return value.split(',')[1];
    }
  }

}
