import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReplacePipe } from './pipes/replace.pipe';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { SnackBarService } from './snack-bar/snack-bar.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ColorPickerComponent } from './controls/color-picker/color-picker.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatColorPickerModule } from '@angular-material-components/color-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { ImageAlteringDialogComponent } from './dialogs/image-altering-dialog/image-altering-dialog.component';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LySliderModule } from '@alyle/ui/slider';
import { LY_THEME, LY_THEME_NAME, LyTheme2 } from '@alyle/ui';
import { MinimaLight } from '@alyle/ui/themes/minima';
import { LyDialogModule } from '@alyle/ui/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { InfoDialogComponent } from './dialogs/info-dialog/info-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { MatSliderModule } from '@angular/material/slider';
import { AdSizeInfoDialogComponent } from './dialogs/ad-size-info-dialog/ad-size-info-dialog.component';
import { FileSizePipe } from './pipes/file-size.pipe';

@NgModule({
  declarations: [
    ReplacePipe,
    SafeHtmlPipe,
    InfoBoxComponent,
    ColorPickerComponent,
    ImageUploadComponent,
    ImageAlteringDialogComponent,
    InfoDialogComponent,
    EnumToArrayPipe,
    AdSizeInfoDialogComponent,
    FileSizePipe,
  ],
  exports: [
    ReplacePipe,
    SafeHtmlPipe,
    InfoBoxComponent,
    ColorPickerComponent,
    ImageUploadComponent,
    EnumToArrayPipe,
    FileSizePipe,
  ],
  providers: [
    SnackBarService,
    LyTheme2,
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatFormFieldModule,
    NgxMatColorPickerModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    LyImageCropperModule,
    LySliderModule,
    LyDialogModule,
    FormsModule,
    MatDialogModule,
    MatSliderModule,
  ],
})
export class SharedModule {}
