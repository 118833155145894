<div
  class="w-100 h-100 page page-set-user-password d-flex flex-column justify-content-center"
>
  <!-- Content -->
  <div class="main">
    <div class="container">
      <div class="row">
        <div class="col-xxl-4 col-xl-5 col-lg-6 col-md-9 col-sm-10 mx-auto">
          <div class="card box-shadow p-lg-4 p-md-3 p-2 border-0">
            <div class="text-center mb-4">
              <a href="#" class="link__logo d-inline-block text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 72 72"
                  fill="none"
                >
                  <path
                    d="M60 6H12C9 6 6 8.7 6 12V21.03C6 23.19 7.29 25.05 9 26.1V60C9 63.3 12.3 66 15 66H57C59.7 66 63 63.3 63 60V26.1C64.71 25.05 66 23.19 66 21.03V12C66 8.7 63 6 60 6ZM57 60H15V27H57V60ZM60 21H12V12H60V21Z"
                    fill="#332E45"
                  />
                  <path d="M45 36H27V42H45V36Z" fill="#332E45" />
                </svg>
                <strong class="d-block">AdBox</strong>
                <span>Ad & Promotionbuilder</span>
              </a>
            </div>
            <!-- end top block -->
            <h3 class="form-name mb-1">Passwort setzen</h3>
            <div
              class="alert alert-danger"
              role="alert"
              *ngIf="serverErrorMessage"
            >
              {{ serverErrorMessage }}
            </div>
            <div class="alert alert-success" role="alert" *ngIf="success">
              Password Changed
            </div>
            <form
              action="#"
              class="needs-validation"
              novalidate
              [formGroup]="formGroup"
              (ngSubmit)="onSubmit()"
            >
              <div
                class="form-group mb-2 d-flex flex-column"
                [ngClass]="{
                  'was-validated': passwordControl.touched
                }"
              >
                <input
                  class="form-control order-1"
                  type="password"
                  name="password"
                  id="password"
                  #passwordElement
                  required
                  formControlName="password"
                />
                <label class="order-0" for="password"
                  ><span>Passwort</span></label
                >
                <div class="invalid-feedback order-2">Ungültiges Passwort</div>
              </div>
              <div class="text-form mb-2">
                <i
                  >Hinweis: Das Passwort muss mindestens 8 Zeichen lang sein,
                  einen Großbuchstaben und ein Sonderzeichen enthalten.</i
                >
              </div>
              <div class="form-group">
                <button class="btn btn-indigo w-100" type="submit" [disabled]="formIsSubmitting">
                  SPEICHERN & ANMELDEN
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
