import { Component, Input } from '@angular/core';
import { LayoutCard } from '../../new-promotion.service';
import { FormArray } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-quiz-block-preview',
  templateUrl: './quiz-block-preview.component.html',
  styleUrls: ['./quiz-block-preview.component.scss'],
})
export class QuizBlockPreviewComponent {
  @Input() card!: LayoutCard;

  currentNavPage: number = 1;
  currentQuestionNumber: number = 1;

  constructor(public sanitizer: DomSanitizer) {}

  get cardValues() {
    return this.card.data.value;
  }

  get formFields() {
    return (this.card!.data.get('form_fields') as FormArray).controls;
  }

  get questions() {
    return this.card!.data.get('questions') as FormArray;
  }

  changeNavPage(page: number) {
    this.currentNavPage = page;
    this.currentQuestionNumber = 1;
  }

  get currentQuestionValues() {
    return this.questions.at(this.currentQuestionNumber - 1)?.value;
  }

  previousScreen() {
    switch (this.currentNavPage) {
      case 1:
        this.changeNavPage(5);
        break;
      case 3:
      case 4:
      case 5:
        this.changeNavPage(this.currentNavPage - 1);
        break;
      case 2:
        if (this.currentQuestionNumber > 1) {
          this.currentQuestionNumber--;
        } else {
          this.changeNavPage(1);
        }
    }
  }

  nextScreen() {
    switch (this.currentNavPage) {
      case 1:
      case 3:
      case 4:
        this.changeNavPage(this.currentNavPage + 1);
        break;
      case 5:
        this.changeNavPage(1);
        break;
      case 2:
        if (this.currentQuestionNumber < this.questions.length) {
          this.currentQuestionNumber++;
        } else {
          this.changeNavPage(3);
        }
    }
  }
}
