<div
  class="base-container"
  [ngStyle]="{
    background:
      cardValues.background_type === 'color'
        ? cardValues.background_color
        : 'url(\'' + cardValues.background_image_file + '\')',
    width: cardValues.width + '%',
    'padding-left': cardValues.paddingLeft + cardValues.marginUnit,
    'padding-top': cardValues.paddingTop + cardValues.marginUnit,
    'padding-right': cardValues.paddingRight + cardValues.marginUnit,
    'padding-bottom': cardValues.paddingBottom + cardValues.marginUnit
  }"
>
  <div class="nav-bar">
    <button
      [ngClass]="{ 'nav-bar-selected': currentNavPage === 1 }"
      (click)="changeNavPage(1)"
    >
      Startseite
    </button>
    <button
      [ngClass]="{ 'nav-bar-selected': currentNavPage === 2 }"
      (click)="changeNavPage(2)"
    >
      Frage
    </button>
    <button
      [ngClass]="{ 'nav-bar-selected': currentNavPage === 3 }"
      (click)="changeNavPage(3)"
    >
      Formular
    </button>
    <button
      [ngClass]="{ 'nav-bar-selected': currentNavPage === 4 }"
      (click)="changeNavPage(4)"
    >
      Danke
    </button>
    <button
      [ngClass]="{ 'nav-bar-selected': currentNavPage === 5 }"
      (click)="changeNavPage(5)"
    >
      Ende
    </button>
  </div>
  <div *ngIf="currentNavPage === 1">
    <h1
      [ngStyle]="{
        'font-family': cardValues.style_headline_1.fontFamily,
        'font-size': cardValues.style_headline_1.fontSize + 'px',
        'line-height': cardValues.style_headline_1.lineHeight
          ? cardValues.style_headline_1.lineHeight
          : 'normal',
        color: cardValues.style_headline_1.color,
        'font-weight': cardValues.style_headline_1.fontWeight,
        'font-style': cardValues.style_headline_1.fontStyle,
        'text-decoration': cardValues.style_headline_1.textDecoration,
        'text-transform': cardValues.style_headline_1.textTransform,
        'text-align': cardValues.style_headline_1.textAlign
      }"
    >
      {{ cardValues.start_headline_1 }}
    </h1>
    <h2
      class="my-4"
      [ngStyle]="{
        'font-family': cardValues.style_headline_2.fontFamily,
        'font-size': cardValues.style_headline_2.fontSize + 'px',
        'line-height': cardValues.style_headline_2.lineHeight
          ? cardValues.style_headline_2.lineHeight
          : 'normal',
        color: cardValues.style_headline_2.color,
        'font-weight': cardValues.style_headline_2.fontWeight,
        'font-style': cardValues.style_headline_2.fontStyle,
        'text-decoration': cardValues.style_headline_2.textDecoration,
        'text-transform': cardValues.style_headline_2.textTransform,
        'text-align': cardValues.style_headline_2.textAlign
      }"
    >
      {{ cardValues.start_headline_2 }}
    </h2>
    <p
      [ngStyle]="{
        'font-family': cardValues.style_text.fontFamily,
        'font-size': cardValues.style_text.fontSize + 'px',
        'line-height': cardValues.style_text.lineHeight
          ? cardValues.style_text.lineHeight
          : 'normal',
        color: cardValues.style_text.color
      }"
      [innerHTML]="sanitizer.bypassSecurityTrustHtml(cardValues.start_text)"
    >
    </p>
    <div class="w-100 mt-5 d-flex flex-column">
      <button
        class="quiz-primary-btn"
        #primaryBtn
        (mouseenter)="primaryBtn.setAttribute('data-hovered', 'true')"
        (mouseleave)="primaryBtn.setAttribute('data-hovered', 'false')"
        [ngStyle]="{
          'align-self': cardValues.style_button_primary.alignSelf,
          color:
            primaryBtn.getAttribute('data-hovered') === 'true'
              ? cardValues.style_button_primary.hover_color
              : cardValues.style_button_primary.color,
          background:
            primaryBtn.getAttribute('data-hovered') === 'true'
              ? cardValues.style_button_primary.hover_backgroundColor
              : cardValues.style_button_primary.backgroundColor,
          'font-family': cardValues.style_button_primary.fontFamily,
          'font-size': cardValues.style_button_primary.fontSize + 'px',
          'font-weight': cardValues.style_button_primary.fontWeight,
          'font-style': cardValues.style_button_primary.fontStyle,
          'text-decoration': cardValues.style_button_primary.textDecoration,
          'text-transform': cardValues.style_button_primary.textTransform,
          'text-align': cardValues.style_button_primary.textAlign,
          'padding-left':
            cardValues.style_button_primary.paddingLeft +
            cardValues.style_button_primary.marginUnit,
          'padding-top':
            cardValues.style_button_primary.paddingTop +
            cardValues.style_button_primary.marginUnit,
          'padding-right':
            cardValues.style_button_primary.paddingRight +
            cardValues.style_button_primary.marginUnit,
          'padding-bottom':
            cardValues.style_button_primary.paddingBottom +
            cardValues.style_button_primary.marginUnit,

          'border-style': cardValues.style_button_primary.borderStyle,
          'border-radius': cardValues.style_button_primary.borderRadius + 'px',
          'border-color':
            primaryBtn.getAttribute('data-hovered') === 'true'
              ? cardValues.style_button_primary.hover_borderColor
              : cardValues.style_button_primary.borderColor
        }"
        [ngClass]="{ shadow: cardValues.style_button_primary.hasShadow }"
        (click)="nextScreen()"
      >
        {{ cardValues.start_button_text }}
      </button>
    </div>
  </div>
  <div *ngIf="currentNavPage === 2">
    <div
      class="text-center"
      [ngStyle]="{
        'font-family': cardValues.style_text.fontFamily,
        'font-size': cardValues.style_text.fontSize + 'px',
        'line-height': cardValues.style_text.lineHeight
          ? cardValues.style_text.lineHeight
          : 'normal',
        color: cardValues.style_text.color
      }"
    >
      Frage {{ currentQuestionNumber }} / {{ questions.length }}
    </div>
    <ng-container *ngIf="currentQuestionValues">
      <h1
        class="my-3"
        [ngStyle]="{
          'font-family': cardValues.style_headline_1.fontFamily,
          'font-size': cardValues.style_headline_1.fontSize + 'px',
          'line-height': cardValues.style_headline_1.lineHeight
            ? cardValues.style_headline_1.lineHeight
            : 'normal',
          color: cardValues.style_headline_1.color,
          'font-weight': cardValues.style_headline_1.fontWeight,
          'font-style': cardValues.style_headline_1.fontStyle,
          'text-decoration': cardValues.style_headline_1.textDecoration,
          'text-transform': cardValues.style_headline_1.textTransform,
          'text-align': cardValues.style_headline_1.textAlign
        }"
      >
        {{ currentQuestionValues.question }}
      </h1>
      <div class="d-flex flex-wrap justify-content-center my-5 gap-5">
        <div
          class="d-flex flex-column justify-content-between"
          *ngFor="let answer of currentQuestionValues.answers"
        >
          <label for="ans-1" *ngIf="cardValues.show_answer_images">
            <img
              class="answer-img"
              *ngIf="!answer.image_file"
              alt=""
              src="/assets/imgs/quiz/placeholder.jpg"
            />
            <img
              class="answer-img"
              *ngIf="answer.image_file"
              alt=""
              [src]="
                sanitizer.bypassSecurityTrustResourceUrl(
                  '/api/image/' + answer.image_file
                )
              "
            />
          </label>
          <div class="d-flex gap-2 align-items-center py-2">
            <input
              class="form-check-input radio-border"
              type="radio"
              value="px"
              id="ans-1"
            />
            <label
              class="form-check-label"
              for="ans-1"
              [ngStyle]="{
                'font-family': cardValues.style_text.fontFamily,
                'font-size': cardValues.style_text.fontSize + 'px',
                'line-height': cardValues.style_text.lineHeight
                  ? cardValues.style_text.lineHeight
                  : 'normal',
                color: cardValues.style_text.color
              }"
            >
              {{ answer.answer }}
            </label>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="my-3 d-flex justify-content-center gap-4">
      <button
        class="quiz-primary-btn"
        #secondaryButton
        (mouseenter)="secondaryButton.setAttribute('data-hovered', 'true')"
        (mouseleave)="secondaryButton.setAttribute('data-hovered', 'false')"
        [ngStyle]="{
          'align-self': cardValues.style_button_secondary.alignSelf,
          color:
            secondaryButton.getAttribute('data-hovered') === 'true'
              ? cardValues.style_button_secondary.hover_color
              : cardValues.style_button_secondary.color,
          background:
            secondaryButton.getAttribute('data-hovered') === 'true'
              ? cardValues.style_button_secondary.hover_backgroundColor
              : cardValues.style_button_secondary.backgroundColor,
          'font-family': cardValues.style_button_secondary.fontFamily,
          'font-size': cardValues.style_button_secondary.fontSize + 'px',
          'font-weight': cardValues.style_button_secondary.fontWeight,
          'font-style': cardValues.style_button_secondary.fontStyle,
          'text-decoration': cardValues.style_button_secondary.textDecoration,
          'text-transform': cardValues.style_button_secondary.textTransform,
          'text-align': cardValues.style_button_secondary.textAlign,
          'padding-left':
            cardValues.style_button_secondary.paddingLeft +
            cardValues.style_button_secondary.marginUnit,
          'padding-top':
            cardValues.style_button_secondary.paddingTop +
            cardValues.style_button_secondary.marginUnit,
          'padding-right':
            cardValues.style_button_secondary.paddingRight +
            cardValues.style_button_secondary.marginUnit,
          'padding-bottom':
            cardValues.style_button_secondary.paddingBottom +
            cardValues.style_button_secondary.marginUnit,

          'border-style': cardValues.style_button_secondary.borderStyle,
          'border-radius':
            cardValues.style_button_secondary.borderRadius + 'px',
          'border-color':
            secondaryButton.getAttribute('data-hovered') === 'true'
              ? cardValues.style_button_secondary.hover_borderColor
              : cardValues.style_button_secondary.borderColor
        }"
        [ngClass]="{ shadow: cardValues.style_button_primary.hasShadow }"
        (click)="previousScreen()"
      >
        Zurück
      </button>
      <button
        class="quiz-primary-btn"
        #primaryBtn
        (mouseenter)="primaryBtn.setAttribute('data-hovered', 'true')"
        (mouseleave)="primaryBtn.setAttribute('data-hovered', 'false')"
        [ngStyle]="{
          'align-self': cardValues.style_button_primary.alignSelf,
          color:
            primaryBtn.getAttribute('data-hovered') === 'true'
              ? cardValues.style_button_primary.hover_color
              : cardValues.style_button_primary.color,
          background:
            primaryBtn.getAttribute('data-hovered') === 'true'
              ? cardValues.style_button_primary.hover_backgroundColor
              : cardValues.style_button_primary.backgroundColor,
          'font-family': cardValues.style_button_primary.fontFamily,
          'font-size': cardValues.style_button_primary.fontSize + 'px',
          'font-weight': cardValues.style_button_primary.fontWeight,
          'font-style': cardValues.style_button_primary.fontStyle,
          'text-decoration': cardValues.style_button_primary.textDecoration,
          'text-transform': cardValues.style_button_primary.textTransform,
          'text-align': cardValues.style_button_primary.textAlign,
          'padding-left':
            cardValues.style_button_primary.paddingLeft +
            cardValues.style_button_primary.marginUnit,
          'padding-top':
            cardValues.style_button_primary.paddingTop +
            cardValues.style_button_primary.marginUnit,
          'padding-right':
            cardValues.style_button_primary.paddingRight +
            cardValues.style_button_primary.marginUnit,
          'padding-bottom':
            cardValues.style_button_primary.paddingBottom +
            cardValues.style_button_primary.marginUnit,

          'border-style': cardValues.style_button_primary.borderStyle,
          'border-radius': cardValues.style_button_primary.borderRadius + 'px',
          'border-color':
            primaryBtn.getAttribute('data-hovered') === 'true'
              ? cardValues.style_button_primary.hover_borderColor
              : cardValues.style_button_primary.borderColor
        }"
        [ngClass]="{ shadow: cardValues.style_button_primary.hasShadow }"
        (click)="nextScreen()"
      >
        Weiter
      </button>
    </div>
  </div>
  <div *ngIf="currentNavPage === 3">
    <h1
      class="my-3"
      [ngStyle]="{
        'font-family': cardValues.style_headline_1.fontFamily,
        'font-size': cardValues.style_headline_1.fontSize + 'px',
        'line-height': cardValues.style_headline_1.lineHeight
          ? cardValues.style_headline_1.lineHeight
          : 'normal',
        color: cardValues.style_headline_1.color,
        'font-weight': cardValues.style_headline_1.fontWeight,
        'font-style': cardValues.style_headline_1.fontStyle,
        'text-decoration': cardValues.style_headline_1.textDecoration,
        'text-transform': cardValues.style_headline_1.textTransform,
        'text-align': cardValues.style_headline_1.textAlign
      }"
    >
      {{ cardValues.form_headline }}
    </h1>
    <p
      class="my-3"
      [ngStyle]="{
        'font-family': cardValues.style_text.fontFamily,
        'font-size': cardValues.style_text.fontSize + 'px',
        'line-height': cardValues.style_text.lineHeight
          ? cardValues.style_text.lineHeight
          : 'normal',
        color: cardValues.style_text.color
      }"
      [innerHTML]="sanitizer.bypassSecurityTrustHtml(cardValues.form_text)"
    >
    </p>
    <div class="row">
      <div
        class="col-6 p-3"
        *ngFor="let formField of formFields; let i = index"
      >
        <div class="form-group">
          <input
            type="text"
            class="form-control text-center py-4 quiz-form-input"
            [placeholder]="formField.get('label')?.value"
          />
        </div>
      </div>
    </div>
    <div class="d-flex flex-column my-4">
      <div class="d-flex gap-3">
        <input type="checkbox" />
        <label
          [ngStyle]="{
            'font-family': cardValues.style_text.fontFamily,
            'font-size': cardValues.style_text.fontSize + 'px',
            'line-height': cardValues.style_text.lineHeight
              ? cardValues.style_text.lineHeight
              : 'normal',
            color: cardValues.style_text.color
          }"
          >Ja, ich habe die Teilnahmebedingungen und Datenschutzbestimmungen
          gelesen und akzeptiere diese.*</label
        >
      </div>
      <div class="d-flex gap-3 mt-2">
        <input type="checkbox" />
        <label
          [ngStyle]="{
            'font-family': cardValues.style_text.fontFamily,
            'font-size': cardValues.style_text.fontSize + 'px',
            'line-height': cardValues.style_text.lineHeight
              ? cardValues.style_text.lineHeight
              : 'normal',
            color: cardValues.style_text.color
          }"
          >Ja, ich möchte den Newsletter erhalten.</label
        >
      </div>
    </div>
    <div class="w-100 mt-5 d-flex flex-column">
      <button
        class="quiz-primary-btn"
        #primaryBtn
        (mouseenter)="primaryBtn.setAttribute('data-hovered', 'true')"
        (mouseleave)="primaryBtn.setAttribute('data-hovered', 'false')"
        [ngStyle]="{
          'align-self': cardValues.style_button_primary.alignSelf,
          color:
            primaryBtn.getAttribute('data-hovered') === 'true'
              ? cardValues.style_button_primary.hover_color
              : cardValues.style_button_primary.color,
          background:
            primaryBtn.getAttribute('data-hovered') === 'true'
              ? cardValues.style_button_primary.hover_backgroundColor
              : cardValues.style_button_primary.backgroundColor,
          'font-family': cardValues.style_button_primary.fontFamily,
          'font-size': cardValues.style_button_primary.fontSize + 'px',
          'font-weight': cardValues.style_button_primary.fontWeight,
          'font-style': cardValues.style_button_primary.fontStyle,
          'text-decoration': cardValues.style_button_primary.textDecoration,
          'text-transform': cardValues.style_button_primary.textTransform,
          'text-align': cardValues.style_button_primary.textAlign,
          'padding-left':
            cardValues.style_button_primary.paddingLeft +
            cardValues.style_button_primary.marginUnit,
          'padding-top':
            cardValues.style_button_primary.paddingTop +
            cardValues.style_button_primary.marginUnit,
          'padding-right':
            cardValues.style_button_primary.paddingRight +
            cardValues.style_button_primary.marginUnit,
          'padding-bottom':
            cardValues.style_button_primary.paddingBottom +
            cardValues.style_button_primary.marginUnit,

          'border-style': cardValues.style_button_primary.borderStyle,
          'border-radius': cardValues.style_button_primary.borderRadius + 'px',
          'border-color':
            primaryBtn.getAttribute('data-hovered') === 'true'
              ? cardValues.style_button_primary.hover_borderColor
              : cardValues.style_button_primary.borderColor
        }"
        [ngClass]="{ shadow: cardValues.style_button_primary.hasShadow }"
        (click)="nextScreen()"
      >
        {{ cardValues.form_button_text }}
      </button>
    </div>
  </div>
  <div *ngIf="currentNavPage === 4" class="w-100 d-flex flex-column">
    <h1
      [ngStyle]="{
        'font-family': cardValues.style_headline_1.fontFamily,
        'font-size': cardValues.style_headline_1.fontSize + 'px',
        'line-height': cardValues.style_headline_1.lineHeight
          ? cardValues.style_headline_1.lineHeight
          : 'normal',
        color: cardValues.style_headline_1.color,
        'font-weight': cardValues.style_headline_1.fontWeight,
        'font-style': cardValues.style_headline_1.fontStyle,
        'text-decoration': cardValues.style_headline_1.textDecoration,
        'text-transform': cardValues.style_headline_1.textTransform,
        'text-align': cardValues.style_headline_1.textAlign
      }"
    >
      {{ cardValues.thanks_headline }}
    </h1>
    <p
      [ngStyle]="{
        'font-family': cardValues.style_text.fontFamily,
        'font-size': cardValues.style_text.fontSize + 'px',
        'line-height': cardValues.style_text.lineHeight
          ? cardValues.style_text.lineHeight
          : 'normal',
        color: cardValues.style_text.color
      }"
      [innerHTML]="sanitizer.bypassSecurityTrustHtml(cardValues.thanks_text)"
    >
    </p>
    <button
      class="quiz-primary-btn mt-4"
      #primaryBtn
      (mouseenter)="primaryBtn.setAttribute('data-hovered', 'true')"
      (mouseleave)="primaryBtn.setAttribute('data-hovered', 'false')"
      [ngStyle]="{
        'align-self': cardValues.style_button_primary.alignSelf,
        color:
          primaryBtn.getAttribute('data-hovered') === 'true'
            ? cardValues.style_button_primary.hover_color
            : cardValues.style_button_primary.color,
        background:
          primaryBtn.getAttribute('data-hovered') === 'true'
            ? cardValues.style_button_primary.hover_backgroundColor
            : cardValues.style_button_primary.backgroundColor,
        'font-family': cardValues.style_button_primary.fontFamily,
        'font-size': cardValues.style_button_primary.fontSize + 'px',
        'font-weight': cardValues.style_button_primary.fontWeight,
        'font-style': cardValues.style_button_primary.fontStyle,
        'text-decoration': cardValues.style_button_primary.textDecoration,
        'text-transform': cardValues.style_button_primary.textTransform,
        'text-align': cardValues.style_button_primary.textAlign,
        'padding-left':
          cardValues.style_button_primary.paddingLeft +
          cardValues.style_button_primary.marginUnit,
        'padding-top':
          cardValues.style_button_primary.paddingTop +
          cardValues.style_button_primary.marginUnit,
        'padding-right':
          cardValues.style_button_primary.paddingRight +
          cardValues.style_button_primary.marginUnit,
        'padding-bottom':
          cardValues.style_button_primary.paddingBottom +
          cardValues.style_button_primary.marginUnit,

        'border-style': cardValues.style_button_primary.borderStyle,
        'border-radius': cardValues.style_button_primary.borderRadius + 'px',
        'border-color':
          primaryBtn.getAttribute('data-hovered') === 'true'
            ? cardValues.style_button_primary.hover_borderColor
            : cardValues.style_button_primary.borderColor
      }"
      [ngClass]="{ shadow: cardValues.style_button_primary.hasShadow }"
      (click)="nextScreen()"
    >
      {{ cardValues.thanks_button_text }}
    </button>
  </div>
  <div *ngIf="currentNavPage === 5" class="w-100 d-flex flex-column">
    <h1
      [ngStyle]="{
        'font-family': cardValues.style_headline_1.fontFamily,
        'font-size': cardValues.style_headline_1.fontSize + 'px',
        'line-height': cardValues.style_headline_1.lineHeight
          ? cardValues.style_headline_1.lineHeight
          : 'normal',
        color: cardValues.style_headline_1.color,
        'font-weight': cardValues.style_headline_1.fontWeight,
        'font-style': cardValues.style_headline_1.fontStyle,
        'text-decoration': cardValues.style_headline_1.textDecoration,
        'text-transform': cardValues.style_headline_1.textTransform,
        'text-align': cardValues.style_headline_1.textAlign
      }"
    >
      {{ cardValues.end_headline }}
    </h1>
    <p
      [ngStyle]="{
        'font-family': cardValues.style_text.fontFamily,
        'font-size': cardValues.style_text.fontSize + 'px',
        'line-height': cardValues.style_text.lineHeight
          ? cardValues.style_text.lineHeight
          : 'normal',
        color: cardValues.style_text.color
      }"
      [innerHTML]="sanitizer.bypassSecurityTrustHtml(cardValues.end_text)"
    >
    </p>
  </div>
</div>
