import { Component, Input } from '@angular/core';
import { LayoutCard } from '../../new-promotion.service';

@Component({
  selector: 'app-footer-block-preview',
  templateUrl: './footer-block-preview.component.html',
  styleUrls: ['./footer-block-preview.component.scss'],
})
export class FooterBlockPreviewComponent {
  @Input() card!: LayoutCard;
  constructor() {}

  get cardValues() {
    return this.card.data.value;
  }
}
