import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { AdBaseModel, PickerAdModel } from '../../../shared/models/ad.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { OptionModel } from '../../../shared/models/option.model';
import {
  Background,
  Logo,
} from 'src/app/shared/data-repository/global-text-snippets';
import { StorageService } from '../../../shared/services/storage.service';
import { ActivatedRoute } from '@angular/router';
import { FontsService } from '../../../shared/services/fonts.service';
import {
  AdStorageKey,
  OptionsStorageKey,
} from '../../../shared/data-repository/global-constants';
import { SubSink } from 'subsink';
import { EditAdNavigationService } from '../../edit-ad/edit-ad-navigation.service';
import { debounceTime } from 'rxjs/operators';
import { OptionsService } from './options.service';
import PickerAd from '../../../../assets/js/picker-ad.js';
import { map, merge, take } from 'rxjs';
import { CommonEditAdDesignService } from '../../edit-ad/common-services/common-edit-ad-design.service';
import { CommonAdTemplateService } from '../common-ad-templates/common-ad-template.service';

@Component({
  selector: 'app-picker-ad',
  templateUrl: './picker-ad.component.html',
  styleUrls: ['./style.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickerAdComponent implements OnInit, OnDestroy {
  @Input() public adModel!: AdBaseModel | null;
  public pickerAdModel!: PickerAdModel;
  public options!: ReadonlyArray<OptionModel>;
  public sanitizedLogo!: SafeResourceUrl;

  // Constants
  public Background = Background;
  public Logo = Logo;

  private AdStorageKey = AdStorageKey;
  private OptionsStorageKey = OptionsStorageKey;
  private initialized = false;
  private pickerAdObj = new PickerAd();
  private subs = new SubSink();

  constructor(
    public sanitizer: DomSanitizer,
    public commonAdTemplateService: CommonAdTemplateService,
    private editAdNavigationService: EditAdNavigationService,
    private cd: ChangeDetectorRef,
    private commonEditAdDesignService: CommonEditAdDesignService,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private fontsService: FontsService,
    private optionsService: OptionsService
  ) {}

  ngOnInit(): void {
    this.subs.sink = this.activatedRoute.queryParams.subscribe((params) => {
      if (params && params.v) {
        // Show preview
        this.initLivePreview();
      } else {
        if (!this.adModel) {
          throw new Error('Ad model not found.');
        }
        this.storageService.setData(AdStorageKey, this.adModel);
        this.pickerAdModel = this.adModel.baseable as PickerAdModel;
        this.initEvents();
      }
      this.sanitizeLogo();
      this.fontsService.fetchFonts();
    });

    this.initializeDownload();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private initEvents(): void {
    this.subs.sink = this.editAdNavigationService.adModelChanged$.subscribe(
      (adModel) => {
        this.adModel = adModel;
        this.pickerAdModel = adModel.baseable as PickerAdModel;
        this.sanitizeLogo();
        this.save(this.AdStorageKey, this.adModel);
      }
    );

    const optionsChangedObs$ = this.optionsService.optionModelsChanged$.pipe(
      debounceTime(200),
      map((values) => [values, true])
    );
    const fetchOptionsObs$ = this.optionsService
      .fetchOptions(this.adModel!.baseable!.id!)
      .pipe(
        take(1),
        map((values) => [values, true])
      );

    this.subs.sink = merge(optionsChangedObs$, fetchOptionsObs$)
      .pipe(debounceTime(600))
      .subscribe(([value, calcFileSize]) => {
        if (Array.isArray(value)) {
          this.options = value;
        }
        if (value instanceof Map) {
          this.options = Array.from(value, ([_, value]) => value);
        }

        const optionsForStorage = this.options.map((option) => {
          const updatedOption = new OptionModel();
          Object.assign(updatedOption, option);
          if (typeof option.thumbImageOriginal !== 'undefined') {
            delete updatedOption.thumbImageOriginal;
          }

          if (typeof option.infoBackgroundImageOriginal !== 'undefined') {
            delete updatedOption.infoBackgroundImageOriginal;
          }
          return updatedOption;
        });

        this.save(this.OptionsStorageKey, optionsForStorage);
        this.cd.detectChanges();
        this.pickerAdObj.resetAndStart();
        if (calcFileSize as boolean) {
          this.commonAdTemplateService.updatePreviewAndFileSize.next([
            false,
            optionsForStorage,
          ]);
        }
      });

    this.subs.sink = this.commonAdTemplateService
      .renderPreview()
      .subscribe(() => {
        if (this.initialized) {
          this.rerender();
        }
        this.initialized = true;
      });

    this.subs.sink = this.editAdNavigationService.adModelSaved$.subscribe(
      ([ad, generateThumbnail]) => {
        if (generateThumbnail) {
          this.editAdNavigationService.thumbnailGenerationInitiated$.next(ad);
        } else {
          this.editAdNavigationService.thumbnailGenerated$.next(true);
        }
      }
    );
  }

  private save(
    key: string,
    values: ReadonlyArray<OptionModel> | AdBaseModel
  ): void {
    this.storageService.setData(key, values);
  }

  /**
   * Make new ad model object to force re-rendering;
   */
  private rerender(): void {
    let copy = new AdBaseModel();
    Object.assign(copy, this.adModel);
    this.adModel = null;
    this.cd.detectChanges();
    this.adModel = copy;
    this.cd.detectChanges();
    this.pickerAdObj.isInitialized = false;
    this.pickerAdObj.selectedOptionOffset = 0;
    this.pickerAdObj.selectedOptionIndex = 0;
    this.pickerAdObj.resetAndStart();
    this.commonAdTemplateService.updatePreviewAndFileSize.next([
      false,
      [...this.options],
    ]);
  }

  private initLivePreview(): void {
    const storedAdModel = this.storageService.getData(this.AdStorageKey);
    const options = this.storageService.getData(this.OptionsStorageKey);

    if (storedAdModel === null || options === null) {
      throw new Error('No ad data in local storage found.');
    }

    this.adModel = storedAdModel;
    this.options = options;
    this.sanitizeLogo();

    this.pickerAdModel = this.adModel!.baseable as PickerAdModel;
    this.cd.detectChanges();
    this.pickerAdObj.resetAndStart();
  }

  private sanitizeLogo() {
    if (this.adModel!.logo) {
      this.sanitizedLogo = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.adModel!.logo!
      );
    }
  }

  private initializeDownload() {
    this.subs.sink = this.editAdNavigationService.downloadInitiated$.subscribe(
      () => {
        this.commonAdTemplateService.updatePreviewAndFileSize.next([
          true,
          [...this.options],
        ]);
      }
    );
  }
}
