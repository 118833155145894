import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenusModule } from '../shared/menus/menus.module';
import { ComponentsModule } from '../layouts/admin-layout/components/components.module';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { NgxMatColorPickerModule } from '@angular-material-components/color-picker';
import { MyAdsTableViewComponent } from './my-ads/my-ads-table-view/my-ads-table-view.component';
import { AdStatusPipe } from '../shared/pipes/ad-status.pipe';
import { MyAdsCardsViewComponent } from './my-ads/my-ads-cards-view/my-ads-cards-view.component';
import { MyAdsComponent } from './my-ads/my-ads.component';
import { AppRoutingModule } from '../app-routing.module';
import { CreateAdDialogComponent } from '../shared/dialogs/create-ad-dialog/create-ad-dialog.component';
import { AdPreviewComponent } from './ad-preview/ad-preview.component';
import { StoryAdDesignComponent } from './edit-ad/story-ad-form/step-1-ad-design/story-ad-design.component';
import { EditAdComponent } from './edit-ad/edit-ad.component';
import { StoryAdContentComponent } from './edit-ad/story-ad-form/step-2-ad-content/story-ad-content.component';
import { StoryAdAnimationComponent } from './edit-ad/story-ad-form/step-3-ad-animation/story-ad-animation.component';
import { StoryAdDownloadComponent } from './edit-ad/story-ad-form/step-4-ad-download/story-ad-download.component';
import { AdConfigurationItemComponent } from './edit-ad/ad-configuration-item/ad-configuration-item.component';
import { EditAdNavigationComponent } from './edit-ad/edit-ad-navigation/edit-ad-navigation.component';
import { EditAdDirective } from './edit-ad/edit-ad.directive';
import { AdReflectionComponent } from './edit-ad/ad-reflection/ad-reflection.component';
import { StoryAdComponent } from './ad-template-types/story-ad/story-ad.component';
import { SharedModule } from '../shared/shared.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AdTemplateGalleryComponent } from './ad-template-gallery/ad-template-gallery.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AdGenerateHtmlDirective } from './edit-ad/ad-reflection/ad-generate-html.directive';
import { GeneratePreviewThumbDirective } from './edit-ad/ad-reflection/generate-preview-thumb.directive';
import { MatSortModule } from '@angular/material/sort';
import { PickerAdComponent } from './ad-template-types/picker-ad/picker-ad.component';
import { PickerAdContentComponent } from './edit-ad/picker-ad-form/step-2-ad-content/picker-ad-content.component';
import { PickerAdDownloadComponent } from './edit-ad/picker-ad-form/step-4-ad-download/picker-ad-download.component';
import { PickerAdDesignComponent } from './edit-ad/picker-ad-form/step-1-ad-design/picker-ad-design.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PickerAdAnimationComponent } from './edit-ad/picker-ad-form/step-3-ad-animation/picker-ad-animation.component';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
  declarations: [
    MyAdsComponent,
    MyAdsCardsViewComponent,
    MyAdsTableViewComponent,
    AdStatusPipe,
    CreateAdDialogComponent,
    AdPreviewComponent,
    StoryAdDesignComponent,
    EditAdComponent,
    StoryAdContentComponent,
    StoryAdAnimationComponent,
    StoryAdDownloadComponent,
    AdConfigurationItemComponent,
    EditAdNavigationComponent,
    EditAdDirective,
    AdReflectionComponent,
    StoryAdComponent,
    AdTemplateGalleryComponent,
    AdGenerateHtmlDirective,
    GeneratePreviewThumbDirective,
    PickerAdDesignComponent,
    PickerAdComponent,
    PickerAdContentComponent,
    PickerAdDownloadComponent,
    PickerAdAnimationComponent,
  ],
  imports: [
    CommonModule,
    MenusModule,
    ComponentsModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatDialogModule,
    MatTableModule,
    NgxMatColorPickerModule,
    AppRoutingModule,
    SharedModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSortModule,
    MatPaginatorModule,
    OverlayModule,
    MatProgressSpinnerModule,
    MatChipsModule,
  ],
})
export class AdsModule {}
