import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { PaginationData } from '../utils/shared-types';

export type User = {
  id: number;
  customer_id?: number;
  email: string;
  email_verified_at?: string;
  enabled: boolean;
  first_name: string;
  last_name: string;
  role_id?: number;
  created_at: string;
  updated_at: string;
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userCacheMap = new Map<number, BehaviorSubject<User | undefined>>();

  constructor(private http: HttpClient) {}

  getUserById(id: number) {
    if (this.userCacheMap.get(id)) {
      return this.userCacheMap.get(id) as BehaviorSubject<User | undefined>;
    } else {
      return this.http
        .get<{ success: boolean; data: User }>(`/api/users/${id}`)
        .pipe((res) => {
          // create map item
          const ob = new BehaviorSubject<User | undefined>(undefined);
          this.userCacheMap.set(id, ob);

          res.subscribe({
            next: (res) => {
              if (res.success) {
                ob.next(res.data);
              }
            },
            error: (err) => {
              console.log(err);
              ob.error('Error Occurred');
            },
          });

          return ob;
        });
    }
  }

  getUsers(params?: any) {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((k) => {
      if (k === 'sortBy' && params[k] !== undefined) {
        searchParams.set('order[column]', params[k]);
      } else if (k === 'sortDir'&& params[k] !== undefined) {
        searchParams.set('order[direction]', params[k]);
      } else if (params[k] !== undefined && params[k] !== 'management_type') {
        searchParams.set(k, params[k]);
      }
    });

    return this.http.get<{
      success: boolean;
      data: User[];
      meta: PaginationData;
    }>(`/api/users?${searchParams.toString()}`);
  }

  createUser(data: any) {
    // add return url if password is not present
    if (!data.password) {
      const url = new URL(
        `${window.location.protocol}//${window.location.host}/change-password`
      );
      url.searchParams.set('email', data.email);
      data.url = url.toString();
    }

    return this.http.post<{ success: boolean; data: User }>('/api/users', data);
  }

  updateUser(id: number, data: any) {
    // delete the fetch by id cache
    this.userCacheMap.delete(id);

    return this.http.put<{ success: boolean; data: User }>(
      `/api/users/${id}`,
      data
    );
  }
}
