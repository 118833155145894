import { Component, OnInit } from '@angular/core';
import { NewPromotionService } from '../new-promotion.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-new-promotion-settings-tab',
  templateUrl: './new-promotion-settings-tab.component.html',
  styleUrls: ['./new-promotion-settings-tab.component.scss'],
})
export class NewPromotionSettingsTabComponent implements OnInit {
  metaImgPreviewUrl?: string;

  constructor(
    public newPromotionService: NewPromotionService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {}

  handleFileChange(e: any) {
    const newFile = e.target.files[0];
    this.metaImgPreviewUrl = URL.createObjectURL(newFile);
  }
}
