import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  DownloadAd,
  NavigateBackwards,
  NavigateForward,
  Save,
} from 'src/app/shared/data-repository/global-text-snippets';
import { AdsService } from '../../ads.service';
import { EditAdNavigationService } from '../edit-ad-navigation.service';
import { Router } from '@angular/router';
import { RoutingHelperService } from '../../../shared/routing/routing-helper.service';
import { AppBarService } from '../../../layouts/admin-layout/components/app-bar/app-bar.service';
import { BreadcrumbState } from '../../../layouts/admin-layout/components/app-bar/breadcrumb-bar/breadcrumb-bar.component';
import { UrlSegmentDelimiter } from '../../../shared/data-repository/global-constants';
import { SubSink } from 'subsink';
import { CommonAdTemplateService } from '../../ad-template-types/common-ad-templates/common-ad-template.service';

@Component({
  selector: 'app-edit-ad-navigation',
  templateUrl: './edit-ad-navigation.component.html',
  styleUrls: ['./edit-ad-navigation.component.scss'],
})
export class EditAdNavigationComponent implements OnInit, OnDestroy {
  public NavigateForward = NavigateForward;
  public NavigateBackwards = NavigateBackwards;
  public DownloadAd = DownloadAd;
  public Save = Save;
  public width = 0;
  public showControls = false;
  public isFirstPage = true;
  public isLastPage = false;
  public downloadBtnDisabled = true;

  private subs = new SubSink();

  constructor(
    public editAdNavigationService: EditAdNavigationService,
    private router: Router,
    private commonAdTemplateService: CommonAdTemplateService,
    private appBarService: AppBarService,
    private routingHelperService: RoutingHelperService,
    private adsService: AdsService
  ) {}

  ngOnInit(): void {
    this.initEvents();
    this.isLastPage = this.editAdNavigationService.isLastPage();
    this.isFirstPage = this.editAdNavigationService.isFirstPage();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public save() {
    const curIndex = this.editAdNavigationService.getPageIndex();
    this.editAdNavigationService.editAdPagesSaved[curIndex] = true;
    this.editAdNavigationService.savedAdTriggered$.next(null);
  }

  public saveAndContinue(): void {
    this.save();

    const curIndex = this.editAdNavigationService.getPageIndex();
    const [nextPage, nextIndex] = this.editAdNavigationService.nextPage();

    if (nextPage) {
      this.appBarService.updateBreadcrumbState(curIndex, BreadcrumbState.Saved);
      this.appBarService.updateBreadcrumbState(
        nextIndex,
        BreadcrumbState.Current
      );

      const parentURL = this.routingHelperService.parentURL(this.router.url);
      this.router.navigate([parentURL + UrlSegmentDelimiter + nextPage.route], {
        state: { accessible: true },
      });
    }
  }

  public prev(): void {
    const curIndex = this.editAdNavigationService.getPageIndex();
    const [prevPage, prevIndex] = this.editAdNavigationService.prevPage();
    if (prevPage) {
      this.appBarService.updateBreadcrumbState(
        prevIndex,
        BreadcrumbState.Current
      );
      const saveStatus = this.editAdNavigationService.editAdPagesSaved[curIndex]
        ? BreadcrumbState.Saved
        : BreadcrumbState.Edited;
      this.appBarService.updateBreadcrumbState(curIndex, saveStatus);

      const parentURL = this.routingHelperService.parentURL(this.router.url);
      this.save();
      this.router.navigate([parentURL + UrlSegmentDelimiter + prevPage.route], {
        state: { accessible: true },
      });
    }
  }

  public download(): void {
    this.editAdNavigationService.downloadInitiated$.next(null);
  }

  private initEvents(): void {
    this.subs.sink = this.adsService.configurationItemWidthUpdated.subscribe(
      (width) => {
        window.setTimeout(() => {
          this.width = width;
          this.showControls = true; // To prevent unpleasant flickering of the control bar
          this.adsService.navBarInitialized.next(null);
        }, 0);
      }
    );

    this.subs.sink =
      this.editAdNavigationService.saveOrDownloadBtnDisabled$.subscribe(
        (saveOrDownloadBtnDisabled) => {
          this.downloadBtnDisabled = saveOrDownloadBtnDisabled;
        }
      );
  }
}
