import {Directive, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';
import {RoutingHelperService} from '../../shared/routing/routing-helper.service';
import {SidebarService} from './components/sidebar/sidebar.service';
import {AdminLayoutService} from './admin-layout.service';

@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective implements OnInit {
  @Input() public aside!: HTMLElement;
  @Input() public main!: HTMLElement;
  private lastScrollTop = 0;

  constructor(private elRef: ElementRef,
              private sidebarService: SidebarService,
              private routingHelperService: RoutingHelperService,
              private adminLayoutService: AdminLayoutService,
              private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.sidebarService.menuCollapsed.subscribe(() => {
      this.scrollSidebar(true);
    })
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(_: Event) {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > this.lastScrollTop) {
      this.scrollSidebar(true);
    } else {
      this.scrollSidebar(false);
    }
    this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    this.adminLayoutService.windowScrolled.next([this.elRef.nativeElement.scrollHeight, st]);
  }

  @HostListener("wheel", ["$event"])
  public wheel(event: WheelEvent): void {
    if (event.deltaY < 0) {
      this.scrollSidebar(false);
    } else {
      this.scrollSidebar(true);
    }
    this.adminLayoutService.mouseWheelTriggered.next(null);
  }

  public scrollSidebar(scrollDirectionDown: boolean): void {
    const topSection = (this.aside.querySelector('section.top') as HTMLElement);
    const bottomSection = (this.aside.querySelector('section.bottom') as HTMLElement);
    const fluidNav = (this.aside.querySelector('section.nav .sidebar-wrapper') as HTMLElement);

    const clientHeight = this.elRef.nativeElement.clientHeight;
    const topSectionHeight = topSection.offsetHeight;
    const bottomSectionHeight = bottomSection.offsetHeight;
    const navSectionHeight = clientHeight - topSectionHeight - bottomSectionHeight;
    const navHeight = fluidNav.offsetHeight;

    if (navHeight > navSectionHeight) {
      // Check if the total sidebar width including the dynamic sidebar height exceeds content height.
      // If not, add space to sidebar since the last menu item would be hidden otherwise.
      const dynamicSidebarHeight = navHeight + topSectionHeight + bottomSectionHeight;
      if (dynamicSidebarHeight > this.main.offsetHeight) {
        this.renderer.setStyle(fluidNav, 'padding-bottom', bottomSectionHeight + 'px');
        this.renderer.setStyle(fluidNav, 'position', 'absolute');
        this.renderer.removeStyle(fluidNav, 'top');
        this.renderer.removeStyle(fluidNav, 'bottom');
        return;
      } else {
        this.renderer.setStyle(fluidNav, 'padding-bottom', '0');
      }

      if (Math.round(clientHeight - fluidNav.getBoundingClientRect().bottom - bottomSectionHeight) >= 0) { // Reached bottom
        this.reachedBottom(scrollDirectionDown, fluidNav, topSectionHeight, bottomSectionHeight);
      } else if (Math.round(fluidNav.getBoundingClientRect().top - topSectionHeight) >= 0) { // Reached top
        this.reachedTop(scrollDirectionDown, fluidNav, topSectionHeight);
      } else { // In between
        this.renderer.setStyle(fluidNav, 'position', 'absolute');
      }
    } else {
      this.renderer.setStyle(fluidNav, 'position', 'fixed');
      this.renderer.removeStyle(fluidNav, 'top');
      this.renderer.removeStyle(fluidNav, 'bottom');
    }
  }

  private deltaY = 0;

  /**
   * Triggered as soon as the sidebar navigation overlaps the user detail navigation
   */
  private reachedBottom(scrollDirectionDown: boolean,
                        fluidNav: HTMLElement,
                        topSectionHeight: number,
                        bottomSectionHeight: number): void {
    if (scrollDirectionDown) {
      this.renderer.setStyle(fluidNav, 'position', 'fixed');
      this.renderer.setStyle(fluidNav, 'bottom', '0' + bottomSectionHeight + 'px');
      this.renderer.setStyle(fluidNav, 'top', 'auto');
      this.deltaY = fluidNav.getBoundingClientRect().top - this.main.getBoundingClientRect().top;
    } else {
      this.renderer.setStyle(fluidNav, 'position', 'absolute');
      this.renderer.setStyle(fluidNav, 'bottom', 'auto');
      if (this.deltaY > 0) {
        this.renderer.setStyle(fluidNav, 'top', (this.deltaY - topSectionHeight) + 'px');
      }
    }
  }

  /**
   * Triggered as soon as the sidebar navigation overlaps the logo box of the sidebar.
   */
  private reachedTop(scrollDirectionDown: boolean, fluidNav: HTMLElement, topSectionHeight: number): void {
    if (scrollDirectionDown) {
      this.renderer.setStyle(fluidNav, 'position', 'absolute');
      if (this.deltaY > 0) {
        this.renderer.setStyle(fluidNav, 'top', (this.deltaY - topSectionHeight) + 'px');
      }
    } else {
      this.renderer.setStyle(fluidNav, 'position', 'fixed');
      this.renderer.setStyle(fluidNav, 'top', topSectionHeight + 'px');
      this.deltaY = fluidNav.getBoundingClientRect().top - this.main.getBoundingClientRect().top;
    }
  }
}
