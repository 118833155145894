import { Routes } from '@angular/router';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { CreateAdComponent } from '../../ads/create-ad/create-ad.component';
import { MyAdsComponent } from '../../ads/my-ads/my-ads.component';
import { PageNotFoundComponent } from '../../page-not-found/page-not-found.component';
import { PageKey } from '../../shared/data-structures/navigation-data';
import { AdPreviewComponent } from '../../ads/ad-preview/ad-preview.component';
import { pageMap } from '../../shared/data-repository/page-mapping';
import { AdboxRoutes } from '../../shared/data-repository/routes';
import { EditAdComponent } from '../../ads/edit-ad/edit-ad.component';
import { AdTemplateGalleryComponent } from '../../ads/ad-template-gallery/ad-template-gallery.component';
import { EditAdGuardService } from '../../ads/edit-ad/edit-ad-guard.service';
import { UrlSegmentDelimiter } from '../../shared/data-repository/global-constants';
import { AdsSlug } from '../../shared/data-repository/api-slugs';
import { PickerAdRoutes, StoryAdRoutes } from './ad-routes';
import { ProfileComponent } from '../../account/profile/profile.component';
import { UsersListViewPageComponent } from '../../user-management/users-list-view-page/users-list-view-page.component';
import { ManageSingleUserComponent } from '../../user-management/user/manage-single-user.component';
import { ManageSingleCustomerComponent } from '../../user-management/customer/manage-single-customer.component';
import {
  NewPromotionTypePickerComponent
} from "../../promotions/new-promotion/new-promotion-type-picker/new-promotion-type-picker.component";

export const AdminLayoutRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: pageMap.getRoute(PageKey.Dashboard),
        component: DashboardComponent,
      },
    ],
  },
  {
    path: AdboxRoutes.Promotions,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: pageMap.getRoute(PageKey.AllPromotions),
      },
      {
        path: pageMap.getRoute(PageKey.AllPromotions),
        component: MyAdsComponent,
      },
      {
        path: pageMap.getRoute(PageKey.NewPromotion),
        component: NewPromotionTypePickerComponent,
      },
    ],
  },
  {
    path: AdboxRoutes.Ads,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: pageMap.getRoute(PageKey.NewAd),
      },
      { path: pageMap.getRoute(PageKey.NewAd), component: CreateAdComponent },
      {
        path: pageMap.getRoute(PageKey.AdPreview) + '/:id',
        component: AdPreviewComponent,
      },
      { path: pageMap.getRoute(PageKey.MyAds), component: MyAdsComponent },
      {
        path: pageMap.getRoute(PageKey.Edit),
        component: EditAdComponent,

        canActivateChild: [EditAdGuardService],
        children: [
          ...StoryAdRoutes,
          ...PickerAdRoutes,
          {
            path: '**',
            redirectTo:
              +UrlSegmentDelimiter +
              AdsSlug +
              UrlSegmentDelimiter +
              pageMap.getRoute(PageKey.MyAds),
          },
        ],
      },
      {
        path: pageMap.getRoute(PageKey.TemplateGallery),
        component: AdTemplateGalleryComponent,
      },
    ],
  },
  {
    path: AdboxRoutes.Account,
    children: [{ path: AdboxRoutes.MyProfile, component: ProfileComponent }],
  },
  {
    path: AdboxRoutes.UserManagement,
    children: [
      { path: '', component: UsersListViewPageComponent },
      { path: 'user', component: ManageSingleUserComponent },
      { path: 'user/:id', component: ManageSingleUserComponent },
      { path: 'customer', component: ManageSingleCustomerComponent },
      { path: 'customer/:id', component: ManageSingleCustomerComponent },
    ],
  },
  { path: '**', component: PageNotFoundComponent },
];

export declare interface RouteInfo {
  path: string;
  title?: string;
  class: string;
  iconName?: string;
  iconOutlined?: boolean;
  subRoutes?: Array<RouteInfo>;
}
