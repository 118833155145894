<section
  class="top position-fixed bg-white"
  (transitionend)="transitionEnd($event)"
>
  <div id="menu-toggle" class="d-flex justify-content-end" #menuToggle>
    <a
      (click)="toggleSidebar()"
      class="icon material-icons-outlined d-inline-block text-decoration-none user-select-none"
    >
      menu_open
    </a>
  </div>
  <a routerLink="/dashboard" id="brand" class="d-flex text-decoration-none">
    <div class="logo material-icons-outlined me-2">inventory_2</div>
    <div class="product-name">
      <div class="product-title">{{ productTitle }}</div>
      <div class="product-sub-title">{{ productSubTitle }}</div>
    </div>
  </a>
</section>
<section class="nav">
  <nav
    class="sidebar-wrapper mat-display-1 w-100 mb-0"
    style="position: absolute"
    #sidebarWrapper
  >
    <ul class="navbar-nav nav">
      <li
        #parentListItem
        routerLinkActive="active"
        *ngFor="let menuItem of menuItems"
        [attr.data-item]="menuItem.class"
        [ngClass]="[
          menuItem.class,
          'nav-item',
          menuItem.subRoutes ? 'has-submenu' : ''
        ]"
      >
        <a
          (click)="
            menuItem.subRoutes
              ? toggleMenu(parentListItem)
              : goTo(menuItem.path, parentListItem)
          "
          [ngClass]="'d-flex text-decoration-none align-items-center'"
        >
          <div
            [ngClass]="
              menuItem.iconOutlined
                ? 'material-icons-outlined'
                : 'material-icons'
            "
            class="icon me-4 user-select-none"
          >
            {{ menuItem.iconName }}
          </div>
          <div
            class="d-flex align-items-center justify-content-start text-uppercase title"
          >
            {{ menuItem.title }}
          </div>
        </a>

        <ng-container *ngIf="menuItem.subRoutes">
          <ul
            class="nav subnav navbar-nav"
            [style.height.px]="
              menuItem.subRoutes.length * subNavItemHeight +
              subNavFirstItemPadding
            "
            [style.max-height.px]="
              menuItem.subRoutes.length * subNavItemHeight +
              subNavFirstItemPadding
            "
          >
            <li
              *ngFor="let subItem of menuItem.subRoutes"
              routerLinkActive="active"
            >
              <a
                class="d-flex text-decoration-none align-items-center"
                [routerLink]="[menuItem.path, subItem.path]"
                (click)="removeActiveClass()"
                [ngClass]="{
                  active: subItem.path === 'my-ads' && isEditAdsRoute
                }"
                routerLinkActive="active"
              >
                <div
                  [ngClass]="
                    subItem.iconOutlined
                      ? 'material-icons-outlined'
                      : 'material-icons'
                  "
                  class="icon me-4"
                >
                  {{ subItem.iconName }}
                </div>
                <div
                  class="d-flex align-items-center justify-content-start title"
                >
                  {{ subItem.title }}
                </div>
              </a>
            </li>
          </ul>
        </ng-container>
      </li>
    </ul>
  </nav>
</section>
<section class="bottom mt-auto position-fixed bg-white">
  <nav
    id="account-navbar"
    class="nav position-relative align-items-center mt-0 flex-row"
    #profileNavBar
  >
    <a
      id="profile"
      class="nav-link text-decoration-none pb-0 user-select-none"
      [routerLink]="[AccountRoute, MyProfileRoute]"
    >
      <app-profile-picture></app-profile-picture>
    </a>
    <a
      id="user-management"
      class="nav-link material-icons-outlined  text-decoration-none pb-0 user-select-none"
      [routerLink]="[UserManagementRoute]"
    >
      people
    </a>
    <a
      id="accounts"
      class="nav-link material-icons-outlined text-decoration-none user-select-none"
      routerLink="/account/settings"
      >settings</a
    >
    <a
      id="notifications"
      class="nav-link material-icons-outlined text-decoration-none user-select-none"
      routerLink="/account/notifications"
      >notifications</a
    >
    <a
      id="ellipsis"
      class="d-none nav-link material-icons-outlined text-decoration-none user-select-none"
      (click)="contextMenu.onContextMenu($event, contextMenuItems, null)"
      >more_horiz</a
    >
    <a
      id="logout"
      href="#"
      class="material-icons-outlined nav-link ms-auto user-select-none"
      (click)="logout()"
      >logout</a
    >
  </nav>
</section>

<app-context-menu #contextMenu="context-menu"></app-context-menu>
