<div
  class="text-container"
  [ngStyle]="{
    background:
      cardValues.background_type === 'color'
        ? cardValues.background_color
        : 'url(\'' + cardValues.background_image_file + '\')',
    width: cardValues.width + '%',
    'padding-left': cardValues.paddingLeft + cardValues.marginUnit,
    'padding-top': cardValues.paddingTop + cardValues.marginUnit,
    'padding-right': cardValues.paddingRight + cardValues.marginUnit,
    'padding-bottom': cardValues.paddingBottom + cardValues.marginUnit
  }"
>
  <div class="d-flex justify-content-center align-items-center w-100">
    <ul class="d-flex gap-3">
      <li *ngIf="cardValues.show_conditions">
        <a
          #link1
          (mouseenter)="link1.setAttribute('data-hovered', 'true')"
          (mouseleave)="link1.setAttribute('data-hovered', 'false')"
          [ngStyle]="{
            'font-family': cardValues.styles_links.fontFamily,
            'font-size': cardValues.styles_links.fontSize + 'px',
            'line-height': cardValues.styles_links.lineHeight
              ? cardValues.styles_links.lineHeight
              : 'normal',
            'font-weight': cardValues.styles_links.fontWeight,
            'font-style': cardValues.styles_links.fontStyle,
            'text-decoration': cardValues.styles_links.textDecoration,
            'text-transform': cardValues.styles_links.textTransform,
            'text-align': cardValues.styles_links.textAlign,
            color:
              link1.getAttribute('data-hovered') === 'true'
                ? cardValues.styles_links.hover_color
                : cardValues.styles_links.color
          }"
          href="#"
        >
          Teilnahmebedingungen</a
        >
      </li>
      <li *ngIf="cardValues.show_data_protection">
        <a
          #link2
          (mouseenter)="link2.setAttribute('data-hovered', 'true')"
          (mouseleave)="link2.setAttribute('data-hovered', 'false')"
          [ngStyle]="{
            'font-family': cardValues.styles_links.fontFamily,
            'font-size': cardValues.styles_links.fontSize + 'px',
            'line-height': cardValues.styles_links.lineHeight
              ? cardValues.styles_links.lineHeight
              : 'normal',
            'font-weight': cardValues.styles_links.fontWeight,
            'font-style': cardValues.styles_links.fontStyle,
            'text-decoration': cardValues.styles_links.textDecoration,
            'text-transform': cardValues.styles_links.textTransform,
            'text-align': cardValues.styles_links.textAlign,
            color:
              link2.getAttribute('data-hovered') === 'true'
                ? cardValues.styles_links.hover_color
                : cardValues.styles_links.color
          }"
          href="#"
        >
          Datenschutz</a
        >
      </li>
      <li *ngIf="cardValues.show_imprint">
        <a
          #link3
          (mouseenter)="link3.setAttribute('data-hovered', 'true')"
          (mouseleave)="link3.setAttribute('data-hovered', 'false')"
          [ngStyle]="{
            'font-family': cardValues.styles_links.fontFamily,
            'font-size': cardValues.styles_links.fontSize + 'px',
            'line-height': cardValues.styles_links.lineHeight
              ? cardValues.styles_links.lineHeight
              : 'normal',
            'font-weight': cardValues.styles_links.fontWeight,
            'font-style': cardValues.styles_links.fontStyle,
            'text-decoration': cardValues.styles_links.textDecoration,
            'text-transform': cardValues.styles_links.textTransform,
            'text-align': cardValues.styles_links.textAlign,
            color:
              link3.getAttribute('data-hovered') === 'true'
                ? cardValues.styles_links.hover_color
                : cardValues.styles_links.color
          }"
          href="#"
        >
          Impressum</a
        >
      </li>
    </ul>
  </div>
</div>
