import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { AdConfigurationItemModel } from '../../../../shared/models/ad-configuration-item.model';
import {
  Background,
  BackgroundColor,
  Color,
  ContinuousText,
  CtaButton,
  Headline,
  InfoArea,
  InfoText,
  NewOption,
  Options,
  OptionText,
  ReallyDeleteOption,
  Round,
  Shape,
  Square,
  Text,
  TextColor,
  Texts,
  Thumbnail,
} from '../../../../shared/data-repository/global-text-snippets';
import { AdBaseModel, PickerAdModel } from '../../../../shared/models/ad.model';
import { SubSink } from 'subsink';
import { map, take } from 'rxjs';
import { OptionModel } from '../../../../shared/models/option.model';
import { OptionsService } from '../../../ad-template-types/picker-ad/options.service';
import {
  MaxOptionCount,
  PickerAdTypeDesignator,
} from '../../../../shared/data-repository/global-constants';
import { EditAdNavigationService } from '../../edit-ad-navigation.service';
import { InfoDialogComponent } from '../../../../shared/dialogs/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import Sortable, { SortableEvent } from 'sortablejs';
import { ImageModel } from '../../../../shared/models/image.model';
import { PickerSlug } from '../../../../shared/data-repository/api-slugs';
import { ImageService } from '../../../../shared/services/image.service';
import { CommonEditAdContentService } from '../../common-services/common-edit-ad-content.service';
import { AdContentFormControls } from '../../common-services/common-edit-ad-design.service';
import { EditAdService, OriginalOptionImageType } from '../../edit-ad.service';
import { Actions, ofType } from '@ngrx/effects';
import * as fromOptionActions from '../../../../state/options/options.actions';
import { AdsService } from '../../../ads.service';
import { Content } from '../../../../shared/data-repository/page-mapping';
import {
  ImageCropperData,
  ImageOrigin,
} from '../../../../shared/data-structures/types';
import { CommonEditAdBasicService } from '../../common-services/common-edit-ad-basic.service';
import { ImageComposite } from '../../../../shared/data-structures/image';
import { CropperArea } from '../../../../shared/models/cropper-area';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  templateUrl: './picker-ad-content.component.html',
  styleUrls: ['./picker-ad-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickerAdContentComponent implements OnInit, OnDestroy {
  @ViewChild('optionList') optionListElRef!: ElementRef<HTMLElement>;
  public options: Map<number, OptionModel> = new Map();
  // Constants
  public Background = Background;
  public BackgroundColor = BackgroundColor;
  public Headline = Headline;
  public Text = Text;
  public ContinuousText = ContinuousText;
  public InfoText = InfoText;
  public Thumbnail = Thumbnail;
  public InfoArea = InfoArea;
  public Shape = Shape;
  public Round = Round;
  public Square = Square;
  public NewOption = NewOption;
  public TextColor = TextColor;

  public MaxOptionCount = MaxOptionCount;
  public PickerSlug = PickerSlug;
  public displayErroneousInputs = false;
  public contentTemplateForm!: FormGroup;
  public Color = Color;
  public color: ThemePalette = 'primary';
  public textConfigurationItem = new AdConfigurationItemModel();
  public optionWrapperConfigurationItem = new AdConfigurationItemModel();
  public optionConfigurationItems: AdConfigurationItemModel[] = [];
  public ctaBtnConfigurationItem: AdConfigurationItemModel =
    new AdConfigurationItemModel();
  public adContentComponent = this;
  public AdContentFormControls = AdContentFormControls;
  public OriginalOptionImageType = OriginalOptionImageType;
  public thumbImageCropperData: ImageCropperData = {
    inputWidth: 200,
    inputHeight: 200,
    isRound: true,
    outputWidth: 56,
    outputHeight: 56,
  };

  public backgroundImageCropperData: ImageCropperData = {
    inputWidth: 300,
    inputHeight: 251,
    isRound: false,
    outputWidth: 300,
    outputHeight: 251,
  };

  private readonly OptionIdPrefix = 'option-';
  private subs = new SubSink();

  constructor(
    public commonEditAdBasicService: CommonEditAdBasicService,
    private commonEditAdContentService: CommonEditAdContentService,
    private editAdNavigationService: EditAdNavigationService,
    private editAdService: EditAdService,
    private optionsService: OptionsService,
    private dialog: MatDialog,
    private imageService: ImageService,
    private actions$: Actions,
    private cd: ChangeDetectorRef,
    private adsService: AdsService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initConfigurationItems();
    this.saveAdClicked();
    this.initEvents();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public addOption(index: number, optionModel?: OptionModel): void {
    if (this.options.size >= MaxOptionCount) {
      return;
    }

    if (index < 0) {
      throw new Error('Invalid index');
    }

    const newOptionConfigurationItem = new AdConfigurationItemModel();
    newOptionConfigurationItem.title = OptionText + ' ' + (index + 1);

    if (!optionModel) {
      optionModel = this.createOptionModel();
    } else {
      const updatedOptionModel = new OptionModel();
      Object.assign(updatedOptionModel, optionModel);
      updatedOptionModel.orderNumber = index;
      optionModel = updatedOptionModel;
    }
    newOptionConfigurationItem.sortable = true;
    newOptionConfigurationItem.cloneable = true;
    newOptionConfigurationItem.removable = true;

    const optionFormArr = this.contentTemplateForm.controls
      .optionsFormArr as FormArray;
    optionFormArr.push(this.createOptionControl(index, optionModel));
    this.options.set(index, optionModel);
    this.optionConfigurationItems.push(newOptionConfigurationItem);
    this.updateConfigurationItemIds();
    this.optionsService.optionModelsChanged$.next(this.options);
  }

  public copyOption(orderNumber: number): void | never {
    const index = this.options.size;
    const option = this.options.get(orderNumber);

    if (!option) {
      throw new Error('Option is not defined.');
    }

    if (this.commonEditAdContentService.imageFetching) {
      return;
    }

    const newOption = new OptionModel();
    Object.assign(newOption, option);
    newOption.infoBackgroundImageCropped = option.infoBackgroundImageCropped;
    newOption.thumbImageCropped = option.thumbImageCropped;

    let originalId = -1;
    if (option.id && option.id >= 0) {
      originalId = option.id;
    } else if (option.imageOrigin.size > 0) {
      // If an option has not valid id, the id of the origin is used.
      [originalId] = option.imageOrigin.keys();
    } else {
      let newOption = this.copy(
        undefined,
        option,
        OriginalOptionImageType.Thumb
      );
      newOption = this.copy(
        undefined,
        newOption,
        OriginalOptionImageType.Background
      );
      delete newOption.id;
      this.addOption(index, newOption);
      return;
    }

    this.commonEditAdContentService.imageFetching = true;

    // Original images are available.
    if (
      (option.imageOrigin.size > 0 &&
        option.imageOrigin.get(originalId)!.size === 2) ||
      (typeof option.thumbImageOriginal !== 'undefined' &&
        typeof option.infoBackgroundImageOriginal !== 'undefined')
    ) {
      const imagesOrigin = option.imageOrigin.get(originalId);
      const thumbImage = option.thumbImageOriginal
        ? option.thumbImageOriginal
        : imagesOrigin!.get(OriginalOptionImageType.Thumb);
      const backgroundImage = option.thumbImageOriginal
        ? option.infoBackgroundImageOriginal
        : imagesOrigin!.get(OriginalOptionImageType.Background);

      const thumbImageOrigin: ImageOrigin = [originalId, thumbImage];
      const backgroundImageOrigin: ImageOrigin = [originalId, backgroundImage];

      let newOption = this.copy(
        thumbImageOrigin,
        option,
        OriginalOptionImageType.Thumb
      );
      newOption = this.copy(
        backgroundImageOrigin,
        newOption,
        OriginalOptionImageType.Background
      );
      this.addOption(index, newOption);
      this.commonEditAdContentService.imageFetching = false;
    } else {
      // At least one original image is not available.
      const imagesOrigin = option.imageOrigin.get(originalId);
      let imageTypes = new Array<OriginalOptionImageType>();
      if (!imagesOrigin || !imagesOrigin!.has(OriginalOptionImageType.Thumb)) {
        imageTypes.push(OriginalOptionImageType.Thumb);
      }

      if (
        !imagesOrigin ||
        !imagesOrigin!.has(OriginalOptionImageType.Background)
      ) {
        imageTypes.push(OriginalOptionImageType.Background);
      }

      this.subs.sink = this.imageService
        .selectOriginalImage(option.id!, PickerSlug, imageTypes)
        .pipe(take(1))
        .subscribe((originalImage) => {
          const backgroundImageOrigin: ImageOrigin = [
            originalId,
            originalImage.backgroundImageOriginal,
          ];
          const thumbImageOrigin: ImageOrigin = [
            originalId,
            originalImage.thumbImageOriginal,
          ];

          let newOption = this.copy(
            thumbImageOrigin,
            option,
            OriginalOptionImageType.Thumb
          );
          newOption = this.copy(
            backgroundImageOrigin,
            newOption,
            OriginalOptionImageType.Background
          );
          this.addOption(index, newOption);
          this.commonEditAdContentService.imageFetching = false;
        });
    }
  }

  public removeOption(orderNumber: number): void {
    this.subs.sink = this.dialog
      .open(InfoDialogComponent, {
        data: {
          content: ReallyDeleteOption,
        },
        scrollStrategy: new NoopScrollStrategy(),
      })
      .afterClosed()
      .subscribe((cancelled) => {
        if (!cancelled) {
          this.deleteOption(orderNumber);
        }
      });
  }

  public imageChanged(
    [imgComposite, cropperArea]: [ImageComposite | null, CropperArea],
    i: number,
    type: OriginalOptionImageType
  ): void | never {
    const option = this.options.get(i);
    if (!option) {
      throw new Error('Option could not be found.');
    }

    const updatedOption = new OptionModel();
    Object.assign(updatedOption, option);
    if (type === OriginalOptionImageType.Thumb) {
      updatedOption.thumbImageOriginal = imgComposite
        ? imgComposite[0]
        : undefined;
      updatedOption.thumbImageCropped = imgComposite ? imgComposite[1] : null;
      updatedOption.thumbImageCropperArea = cropperArea;
      updatedOption.thumbImageName =
        imgComposite && imgComposite[2]
          ? imgComposite[2]
          : updatedOption.thumbImageName;
    } else {
      // Background
      updatedOption.infoBackgroundImageOriginal = imgComposite
        ? imgComposite[0]
        : undefined;
      updatedOption.infoBackgroundImageCropped = imgComposite
        ? imgComposite[1]
        : null;
      updatedOption.infoBackgroundImageCropperArea = cropperArea;
      updatedOption.infoBackgroundImageName =
        imgComposite && imgComposite[2]
          ? imgComposite[2]
          : updatedOption.infoBackgroundImageName;
    }

    this.options.set(i, updatedOption);

    // Update ngrx store
    if (option.id && option.id >= 0) {
      const updatedOriginalImage = new ImageModel();
      updatedOriginalImage.imageOriginal = undefined;
      updatedOriginalImage.backgroundImageOriginal =
        updatedOption.infoBackgroundImageOriginal;
      updatedOriginalImage.thumbImageOriginal =
        updatedOption.thumbImageOriginal;
      updatedOriginalImage.id = option.id;
      this.imageService.updateOriginalImage(updatedOriginalImage);
    }

    this.editAdService.adModelUserChange = true;
    this.optionsService.optionModelsChanged$.next(this.options);
  }

  public getOption(orderNumber: number): OptionModel | never {
    const option = this.options.get(orderNumber);
    if (!option) {
      throw new Error('Option not found.');
    }
    return option;
  }

  public addNewOption(): void {
    this.addOption(this.options.size);
    this.editAdService.adModelUserChange = true;
  }

  private initForm(adModel: AdBaseModel): void {
    const baseable = adModel.baseable as PickerAdModel;
    this.contentTemplateForm = new FormGroup({
      shapeCtrl: new FormControl('circle'),
      headlineText: new FormControl(baseable.headlineContent),
      continuousTextCtrl: new FormControl(baseable.continuousTextContent),
      optionsFormArr: new FormArray([]),
      ctaBtnText: new FormControl(adModel.ctaButtonText),
    });
    this.commonEditAdContentService.initCTACtrlChanges(
      this.contentTemplateForm
    );
    this.initFormChanges();

    window.setTimeout(() => {
      Sortable.create(this.optionListElRef.nativeElement, {
        handle: '.drag-handle',
        onEnd: (evt) => this.optionOrderChanged(evt),
      });
    });
  }

  private optionOrderChanged(evt: SortableEvent): void {
    const elements = evt.to.querySelectorAll('.orderNumber');

    const updatedOptions = new Map<number, OptionModel>();
    const updatedConfigurationItems = new Array<AdConfigurationItemModel>();

    elements.forEach((el: Element, index: number) => {
      // Update options
      const html = el as HTMLInputElement;
      const orderNumber = +html.value;
      const option = this.options.get(orderNumber);
      if (!option) {
        throw new Error('Option is not defined.');
      }
      let updatedOption = new OptionModel();
      Object.assign(updatedOption, option);
      updatedOption.orderNumber = index;
      updatedOptions.set(index, updatedOption);

      // Update configuration items
      const optionConfigurationItem =
        this.optionConfigurationItems[orderNumber];
      if (!optionConfigurationItem) {
        throw new Error('Option configuration item is not defined.');
      }
      updatedConfigurationItems.push(optionConfigurationItem);
    });
    this.options = updatedOptions;
    this.optionConfigurationItems = updatedConfigurationItems;

    this.updateConfigurationItemIds();
    this.optionsService.optionModelsChanged$.next(this.options);
    this.editAdService.adModelUserChange = true;
  }

  private initConfigurationItems(): void {
    this.textConfigurationItem.id = 'texts';
    this.textConfigurationItem.title = Texts;
    this.textConfigurationItem.collapsed = false;

    this.optionWrapperConfigurationItem.id = 'options';
    this.optionWrapperConfigurationItem.title = Options;
    this.optionWrapperConfigurationItem.hasSubItems = true;

    this.ctaBtnConfigurationItem.id = 'cta-btn';
    this.ctaBtnConfigurationItem.title = CtaButton;
  }

  private loadOptions(optionalbleId: number): void {
    this.subs.sink = this.optionsService
      .fetchOptions(optionalbleId)
      .pipe(take(1))
      .subscribe((options) => {
        this.options = new Map<number, OptionModel>();
        options.forEach((option, index) => {
          this.addOption(index, option);
        });
      });
  }

  private createOptionControl(orderNr: number, option: OptionModel): FormGroup {
    const shape = option.shape || 'circle';
    const thumbnailBackgroundColor = option.thumbBackgroundColor || '';
    const infoText = option.infoText || '';
    const infoTextColor = option.infoTextColor || '';
    const infoBackgroundColor = option.infoBackgroundColor || '';

    const formCtrl = this.formBuilder.group({
      orderNumber: new FormControl(orderNr),
      shapeCtrl: new FormControl(shape),
      thumbnailBackgroundPickerCtrl: new FormControl(thumbnailBackgroundColor),
      infoTextCtrl: new FormControl(infoText),
      infoTextColorCtrl: new FormControl(infoTextColor),
      infoBackgroundColorCtrl: new FormControl(infoBackgroundColor),
    });

    formCtrl.valueChanges.subscribe((values) => {
      this.handleOptionFormValueChange(values);
      this.editAdService.adModelUserChange = true;
    });
    formCtrl.controls.shapeCtrl.valueChanges.subscribe((value) => {
      this.thumbImageCropperData.isRound = value === 'circle';
    });

    return formCtrl;
  }

  private createOptionModel(): OptionModel {
    const newOptionModel = new OptionModel();
    newOptionModel.shape = 'circle';
    newOptionModel.thumbBackgroundColor = 'AABBCC';
    newOptionModel.infoBackgroundColor = 'AABBCC';
    newOptionModel.infoText = this.Text;
    newOptionModel.infoTextColor = 'FFFFFF';
    newOptionModel.orderNumber = this.options.size;
    newOptionModel.optionableType = PickerAdTypeDesignator;
    newOptionModel.optionableId = (
      this.commonEditAdBasicService.AdModel.baseable as PickerAdModel
    ).id;
    delete newOptionModel.id;
    return newOptionModel;
  }

  private handleOptionFormValueChange(values: any): void {
    const orderNr = values.orderNumber;
    const optionModel = this.options.get(orderNr);
    if (!optionModel) {
      throw new Error('Option could not be found.');
    }

    const updatedOptionModel = new OptionModel();
    Object.assign(updatedOptionModel, optionModel);
    updatedOptionModel.shape = values.shapeCtrl;
    updatedOptionModel.thumbBackgroundColor =
      values.thumbnailBackgroundPickerCtrl.hex;
    updatedOptionModel.thumbImageCropped = optionModel.thumbImageCropped;
    updatedOptionModel.infoText = values.infoTextCtrl;
    updatedOptionModel.infoTextColor = values.infoTextColorCtrl.hex;
    updatedOptionModel.infoBackgroundColor = values.infoBackgroundColorCtrl.hex;
    updatedOptionModel.infoBackgroundImageCropped =
      optionModel.infoBackgroundImageCropped;
    updatedOptionModel.orderNumber = optionModel.orderNumber;
    if (updatedOptionModel.id! < 0) {
      this.optionsService.deleteIds(updatedOptionModel);
    }

    const key = optionModel.orderNumber;
    this.options.set(key, updatedOptionModel);
    this.optionsService.optionModelsChanged$.next(this.options);
  }

  private saveAdClicked() {
    this.subs.sink = this.editAdNavigationService.savedAdTriggered$.subscribe(
      () => {
        if (!this.editAdService.adModelUserChange) {
          return;
        }

        const currentAdModel = this.commonEditAdBasicService.AdModel;
        const optionsArr = Array.from(this.options, ([_, optionModel]) => {
          let newOption = new OptionModel();
          Object.assign(newOption, optionModel);
          if (newOption.id! < 0) {
            this.optionsService.deleteIds(newOption);
          }
          return newOption;
        });

        const adId = currentAdModel.id!;
        const optionableId = (currentAdModel.baseable as PickerAdModel).id;
        this.optionsService.addOptions(optionsArr, adId, optionableId);

        this.commonEditAdBasicService.adSaved(Content);
      }
    );
  }

  private initEvents(): void {
    // Init form
    this.subs.sink = this.editAdService.adModelUpdated$.subscribe((adModel) => {
      if (!adModel) {
        throw new Error('Ad model not defined!');
      }
      this.commonEditAdBasicService.AdModel = adModel!;
      if (!this.contentTemplateForm) {
        this.initForm(this.commonEditAdBasicService.AdModel);
        this.loadOptions(adModel.baseable!.id);
      }
    });

    // Update option list after adding / updating option
    this.subs.sink = this.actions$
      .pipe(
        ofType(fromOptionActions.setOptionList),
        map((response) => response.payload[0])
      )
      .subscribe((options) => {
        this.options = new Map<number, OptionModel>();
        options.forEach((option, index) => {
          this.options.set(index, option);
        });
        this.cd.detectChanges();
      });

    this.subs.sink = this.adsService.navBarInitialized.subscribe((_) => {
      this.cd.detectChanges();
    });
  }

  private deleteOption(orderNumber: number) {
    this.options.delete(orderNumber);
    this.optionConfigurationItems.splice(orderNumber, 1);
    this.optionConfigurationItems.forEach((item, index) => {
      item.title = OptionText + ' ' + (index + 1);
    });

    const optionFormArr = this.contentTemplateForm.controls
      .optionsFormArr as FormArray;
    optionFormArr.removeAt(orderNumber, { emitEvent: false });
    let updatedOptions = new Map<number, OptionModel>();
    let index = 0;
    this.options.forEach((option) => {
      const updatedOption = new OptionModel();
      Object.assign(updatedOption, option);
      updatedOption.orderNumber = index;
      updatedOptions.set(index, updatedOption);
      index++;
    });
    this.options = updatedOptions;
    this.updateConfigurationItemIds();
    this.optionsService.optionModelsChanged$.next(this.options);
    this.editAdService.adModelUserChange = true;
  }

  private copy(
    origin: ImageOrigin | undefined,
    option: OptionModel,
    type: OriginalOptionImageType
  ): OptionModel {
    let imageOriginal;
    const optionImageOriginal =
      type === OriginalOptionImageType.Thumb
        ? option.thumbImageOriginal
        : option.infoBackgroundImageOriginal;

    if (optionImageOriginal) {
      imageOriginal = optionImageOriginal;
    } else if (origin) {
      imageOriginal = origin[1];
    }

    const newOption = new OptionModel();
    Object.assign(newOption, option);

    if (origin) {
      const id = origin[0];
      if (!newOption.imageOrigin.get(id)) {
        newOption.imageOrigin.set(id, new Map());
      }
      const imageMap = newOption.imageOrigin.get(id);
      imageMap!.set(type, origin[1]);
    }

    if (type === OriginalOptionImageType.Thumb) {
      newOption.thumbImageOriginal = imageOriginal;
    } else {
      // OriginalOptionImageType.Background
      newOption.infoBackgroundImageOriginal = imageOriginal;
    }

    const copiedThumbImageName =
      this.commonEditAdContentService.fileTypesService.copyFileName(
        newOption.thumbImageName
      );
    const copiedBackgroundInfoImageName =
      this.commonEditAdContentService.fileTypesService.copyFileName(
        newOption.infoBackgroundImageName
      );
    newOption.thumbImageName = copiedThumbImageName ?? '';
    newOption.infoBackgroundImageName = copiedBackgroundInfoImageName;

    delete newOption.id;
    this.editAdService.adModelUserChange = true;
    return newOption;
  }

  private updateConfigurationItemIds(): void {
    this.optionConfigurationItems = this.optionConfigurationItems.map(
      (item, index) => {
        const updatedConfigurationItem = new AdConfigurationItemModel();
        Object.assign(updatedConfigurationItem, item);
        updatedConfigurationItem.id = this.OptionIdPrefix + index;
        updatedConfigurationItem.title = OptionText + ' ' + (index + 1);
        return updatedConfigurationItem;
      }
    );
    this.cd.detectChanges();
  }

  private initFormChanges(): void {
    this.contentTemplateForm.controls.headlineText.valueChanges.subscribe(() =>
      this.saveForm()
    );
    this.contentTemplateForm.controls.continuousTextCtrl.valueChanges.subscribe(
      () => this.saveForm()
    );
  }

  private saveForm(): void {
    const curIndex = this.editAdNavigationService.getPageIndex();
    this.editAdNavigationService.editAdPagesSaved[curIndex] = false;

    // Clone ad model
    const currentAdModel = this.commonEditAdBasicService.AdModel;
    const updatedAdModel = new AdBaseModel();
    Object.assign(updatedAdModel, currentAdModel);

    //Clone ad baseable
    const currentBaseable = this.commonEditAdBasicService.AdModel
      .baseable as PickerAdModel;
    const updatedBaseable = new PickerAdModel();
    Object.assign(updatedBaseable, currentBaseable);

    updatedBaseable.headlineContent =
      this.contentTemplateForm.controls.headlineText.value;
    updatedBaseable.continuousTextContent =
      this.contentTemplateForm.controls.continuousTextCtrl.value;
    updatedAdModel.baseable = updatedBaseable;

    this.commonEditAdBasicService.AdModel = updatedAdModel;
    this.editAdNavigationService.adModelChanged$.next(updatedAdModel);
    this.editAdService.adModelUserChange = true;
  }
}
