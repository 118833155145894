import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {Color} from '@angular-material-components/color-picker';

@Injectable({
  providedIn: 'root'
})
export class ColorPickerService {
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public setIndicatorColor(element: HTMLElement, value: any): void {
    if (value && typeof value.rgba === 'string') {
      this.renderer.setStyle(element, 'background', value.rgba);
    }
  }

  public hexToRgb(hex?: string | Color): { r: number, b: number, g: number } | null {
    if (!hex) {
      return null;
    }
    if (typeof hex === 'string') {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
      });
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    } else {
      return {r: hex.r, g: hex.g, b: hex.b};
    }
  }
}

export type ColorControlType = {}
