import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { AppBarService } from '../../layouts/admin-layout/components/app-bar/app-bar.service';
import { RoutingHelperService } from '../../shared/routing/routing-helper.service';
import { Router } from '@angular/router';
import { AdsService } from '../ads.service';
import { AdStatus } from '../../shared/data-structures/ad-data-structures';
import { SubSink } from 'subsink';
import { take } from 'rxjs/operators';
import { AdBaseModel } from '../../shared/models/ad.model';
import { FontsService } from '../../shared/services/fonts.service';
import { AdboxRoutes } from '../../shared/data-repository/routes';
import { EditAdNavigationService } from './edit-ad-navigation.service';
import { EditAdService } from './edit-ad.service';
import { OverlayService } from '../../shared/services/overlay.service';
import { Actions, ofType } from '@ngrx/effects';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { merge } from 'rxjs';
import * as fromAdActions from '../../state/ads/ads.actions';
import { CommonEditAdDesignService } from './common-services/common-edit-ad-design.service';
import { StorageService } from '../../shared/services/storage.service';
import { FileSizeService } from '../../shared/files/file-size.service';

@Component({
  selector: 'app-edit-ad',
  templateUrl: './edit-ad.component.html',
  styleUrls: ['./edit-ad.component.scss'],
})
export class EditAdComponent implements OnInit, OnDestroy {
  @ViewChild('overlayTemplate', { read: TemplateRef })
  overlayTemplate!: TemplateRef<any>;
  public diameter = 48;
  public mode: ProgressSpinnerMode = 'indeterminate';

  private adModel!: AdBaseModel;
  private subs = new SubSink();

  constructor(
    private appBarService: AppBarService,
    private router: Router,
    private actions$: Actions,
    private fontsService: FontsService,
    private adsService: AdsService,
    private fileSizeService: FileSizeService,
    private storageService: StorageService,
    private commonEditAdDesignService: CommonEditAdDesignService,
    private editAdNavigationService: EditAdNavigationService,
    private editAdService: EditAdService,
    private routingHelperService: RoutingHelperService,
    private overlayService: OverlayService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.storageService.clear();
    this.fetchAd();
    this.initFileSizeCalculation();

    this.subs.sink = this.editAdService.adTitleChanged$.subscribe((title) => {
      const currentAdModel = this.adModel;
      let updatedAdModel = new AdBaseModel();
      Object.assign(updatedAdModel, currentAdModel);
      updatedAdModel.title = title;
      this.editAdService.adModelUserChange = true;
      this.editAdService.adModelUpdated$.next(updatedAdModel);
    });

    this.subs.sink = merge(
      this.editAdNavigationService.savedAdTriggered$,
      this.actions$.pipe(ofType(fromAdActions.initializeAdDownload))
    ).subscribe((response) => {
      if (!this.overlayTemplate) {
        return;
      }

      if (this.editAdService.adModelUserChange || response !== null) {
        this.overlayService.showOverlay(
          this.overlayTemplate,
          this.viewContainerRef
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.appBarService.resetBreadcrumbBar();
    this.subs.unsubscribe();
  }

  private fetchAd(): void {
    const segments = this.routingHelperService.getURLSegments(this.router.url);
    const adId = this.editAdNavigationService.parseAdIdFromRoute(segments);

    if (adId < 0) {
      this.router.navigate([AdboxRoutes.Ads, AdboxRoutes.MyAds]);
      return;
    }

    this.subs.sink = this.adsService
      .fetchAd(adId)
      .pipe(take(1))
      .subscribe(([adModel, _]) => {
        let updatedAd;
        if (adModel.status !== AdStatus.AdStatusInProgress) {
          const updatedAdModel = new AdBaseModel();
          Object.assign(updatedAdModel, adModel);
          updatedAdModel.status = AdStatus.AdStatusInProgress;
          this.adsService.updateAd(
            updatedAdModel,
            updatedAdModel.baseable!,
            adModel.id,
            false
          );
          updatedAd = updatedAdModel;
        } else {
          updatedAd = adModel;
        }

        this.appBarService.initializeBreadcrumbStates(adModel);
        this.adModel = updatedAd;
        this.editAdService.adModelUpdated$.next(updatedAd);
      });

    this.subs.sink = this.editAdService.fetchLatestAdModel(adId).subscribe();
  }

  private initFileSizeCalculation(): void | never {
    this.subs.sink = this.editAdNavigationService.adHtmlGenerated$.subscribe(
      ([htmlComposite, downloadZip]) => {
        if (!this.adModel.id) {
          throw new Error('Ad model id is not defined.');
        }
        if (!downloadZip) {
          this.fileSizeService.calculateAdSize(this.adModel.id, htmlComposite);
        }
      }
    );
  }
}
