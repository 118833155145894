import {Dashboard, pageMap} from './page-mapping';
import {RouteInfo} from '../../layouts/admin-layout/admin-layout.routing';
import {AdboxRoutes} from './routes';
import {PageKey} from '../data-structures/navigation-data';
import {UrlSegmentDelimiter} from './global-constants';

export const ROUTES: RouteInfo[] = [
  {
    path: UrlSegmentDelimiter + Dashboard.route, title: 'Dashboard', class: 'dashboard', iconName: 'grid_view', iconOutlined: true
  },
  {
    path: UrlSegmentDelimiter + 'promotions', title: 'Promotions', class: 'promotions', iconName: 'web', iconOutlined: true, subRoutes:
      [
        {
          path: AdboxRoutes.NewPromotion,
          title: 'Neue Promotion',
          class: 'new-promotion',
          iconOutlined: true,
          iconName: 'add'
        },
        {
          path: AdboxRoutes.AllPromotions,
          title: 'Alle Promotions',
          class: 'all-promotions',
          iconOutlined: true,
          iconName: 'add'
        },
        {
          path: 'create-promotion',
          title: 'Promotion erstellen',
          class: 'create-promotion',
          iconOutlined: true,
          iconName: 'add'
        },
        {
          path: 'leads-and-analysis',
          title: 'Leads & Analyse',
          class: 'leads-and-analysis',
          iconOutlined: true,
          iconName: 'add'
        },
        {
          path: 'design-templates',
          title: 'Designvorlagen',
          class: 'design-templates',
          iconOutlined: true,
          iconName: 'add'
        },
        {
          path: 'form-templates',
          title: 'Formularvorlagen',
          class: 'form-templates',
          iconOutlined: true,
          iconName: 'add'
        }
      ]
  },
  {
    path: UrlSegmentDelimiter + AdboxRoutes.Ads,
    title: 'HTML5 Ads',
    class: AdboxRoutes.Ads,
    iconName: 'featured_video',
    iconOutlined: false,
    subRoutes:
      [
        {
          path: pageMap.getRoute(PageKey.NewAd),
          title: pageMap.getTitle(PageKey.NewAd),
          class: AdboxRoutes.NewAd,
          iconOutlined: true,
          iconName: 'add'
        },
        {
          path: pageMap.getRoute(PageKey.MyAds),
          title: pageMap.getTitle(PageKey.MyAds),
          class: AdboxRoutes.MyAds,
          iconOutlined: false,
          iconName: 'dashboard'
        },
        {
          path: pageMap.getRoute(PageKey.MyDesignTemplates),
          title: pageMap.getTitle(PageKey.MyDesignTemplates),
          iconOutlined: false,
          class: 'my-design-templates',
          iconName: 'auto_fix_high'
        },
        {
          path: pageMap.getRoute(PageKey.AnalysisAndTracking),
          title: pageMap.getTitle(PageKey.AnalysisAndTracking),
          iconOutlined: false,
          class: 'analysis-and-tracking',
          iconName: 'analytics'
        },
        {
          path: pageMap.getRoute(PageKey.Archive),
          title: pageMap.getTitle(PageKey.Archive),
          class: 'archive',
          iconOutlined: false,
          iconName: 'inventory_2'
        },
        {
          path: pageMap.getRoute(PageKey.TemplateGallery),
          title: pageMap.getTitle(PageKey.TemplateGallery),
          iconOutlined: true,
          class: 'template-gallery',
          iconName: 'backup_table'
        },
      ]
  }
];

export const subNavItemHeight = 48;
export const subNavFirstItemPadding = 22;
