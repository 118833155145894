<div
  class="text-container"
  [ngStyle]="{
    background:
      cardValues.background_type === 'color'
        ? cardValues.background_color
        : 'url(\'' + cardValues.background_image_file + '\')',
    width: cardValues.width + '%',
    'padding-left': cardValues.paddingLeft + cardValues.marginUnit,
    'padding-top': cardValues.paddingTop + cardValues.marginUnit,
    'padding-right': cardValues.paddingRight + cardValues.marginUnit,
    'padding-bottom': cardValues.paddingBottom + cardValues.marginUnit
  }"
>
  <div class="d-flex justify-content-between align-items-center w-100">
    <div
      class="w-100"
      [ngStyle]="{
        'text-align': cardValues.logo.style_image.alignSelf
      }"
    >
      <img
        *ngIf="cardValues.logo.image_file"
        [src]="
          sanitizer.bypassSecurityTrustResourceUrl(
            '/api/image/' + cardValues.logo.image_file
          )
        "
        class="object-fit-contain"
        [ngStyle]="{
          width: cardValues.logo.style_image.width + 'px'
        }"
        [alt]="cardValues.logo.image_title"
        [title]="cardValues.logo.image_title"
      />
    </div>
    <ul class="d-flex gap-2">
      <li *ngFor="let element of cardValues.menu_elements">
        <a
          #link
          (mouseenter)="link.setAttribute('data-hovered', 'true')"
          (mouseleave)="link.setAttribute('data-hovered', 'false')"
          [ngStyle]="{
            'font-family': cardValues.styles_menu.fontFamily,
            'font-size': cardValues.styles_menu.fontSize + 'px',
            'line-height': cardValues.styles_menu.lineHeight
              ? cardValues.styles_menu.lineHeight
              : 'normal',
            'font-weight': cardValues.styles_menu.fontWeight,
            'font-style': cardValues.styles_menu.fontStyle,
            'text-decoration': cardValues.styles_menu.textDecoration,
            'text-transform': cardValues.styles_menu.textTransform,
            'text-align': cardValues.styles_menu.textAlign,
            color:
              link.getAttribute('data-hovered') === 'true'
                ? cardValues.styles_menu.hover_color
                : cardValues.styles_menu.color
          }"
          [href]="
            element.heading_type === 'Abschnitt'
              ? pathName + element.url
              : sanitizer.bypassSecurityTrustUrl(element.url)
          "
        >
          {{ element.content }}</a
        >
      </li>
    </ul>
  </div>
</div>
