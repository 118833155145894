import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewPromotionTypePickerComponent } from './new-promotion-type-picker/new-promotion-type-picker.component';
import { NewPromotionModalComponent } from './new-promotion-modal/new-promotion-modal.component';
import { NewPromotionSettingsComponent } from './new-promotion-settings/new-promotion-settings.component';
import { NewPromotionLayoutComponent } from './new-promotion-layout/new-promotion-layout.component';
import { RouterModule } from '@angular/router';
import { NewPromotionRoutes } from './new-promotion.routing';
import { ReactiveFormsModule } from '@angular/forms';
import { NewPromotionLayoutTabComponent } from './new-promotion-layout-tab/new-promotion-layout-tab.component';
import { NewPromotionSettingsTabComponent } from './new-promotion-settings-tab/new-promotion-settings-tab.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutBlockCardComponent } from './new-promotion-layout-tab/layout-block-card/layout-block-card.component';
import { AddNewBlockModalComponent } from './add-new-block-modal/add-new-block-modal.component';
import { QuizBlockExpandedViewComponent } from './new-promotion-layout-tab/expanded-views/quiz-block-expanded-view/quiz-block-expanded-view.component';
import { QuizBlockPreviewComponent } from './block-previews/quiz-block-preview/quiz-block-preview.component';
import { BlockPreviewWrapperComponent } from './block-previews/block-preview-wrapper/block-preview-wrapper.component';
import { MatIconModule } from '@angular/material/icon';
import { TextBlockPreviewComponent } from './block-previews/text-block-preview/text-block-preview.component';
import { TextBlockExpandedViewComponent } from './new-promotion-layout-tab/expanded-views/text-block-expanded-view/text-block-expanded-view.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ImageBlockExpandedViewComponent } from './new-promotion-layout-tab/expanded-views/image-block-expanded-view/image-block-expanded-view.component';
import { ImageBlockPreviewComponent } from './block-previews/image-block-preview/image-block-preview.component';
import { VideoBlockPreviewComponent } from './block-previews/video-block-preview/video-block-preview.component';
import { VideoBlockExpandedViewComponent } from './new-promotion-layout-tab/expanded-views/video-block-expanded-view/video-block-expanded-view.component';
import { ImageTextBlockExpandedViewComponent } from './new-promotion-layout-tab/expanded-views/image-text-block-expanded-view/image-text-block-expanded-view.component';
import { ImageTextBlockPreviewComponent } from './block-previews/image-text-block-preview/image-text-block-preview.component';
import { GalleryBlockPreviewComponent } from './block-previews/gallery-block-preview/gallery-block-preview.component';
import { GalleryBlockExpandedViewComponent } from './new-promotion-layout-tab/expanded-views/gallery-block-expanded-view/gallery-block-expanded-view.component';
import { ImageBannerBlockExpandedViewComponent } from './new-promotion-layout-tab/expanded-views/image-banner-block-expanded-view/image-banner-block-expanded-view.component';
import { ImageBannerBlockPreviewComponent } from './block-previews/image-banner-block-preview/image-banner-block-preview.component';
import { BlockStylesViewComponent } from './new-promotion-layout-tab/expanded-views/shared-views/block-styles-view/block-styles-view.component';
import { BlockSectionComponent } from './new-promotion-layout-tab/expanded-views/shared-views/block-section/block-section.component';
import { HeadingStylesViewComponent } from './new-promotion-layout-tab/expanded-views/shared-views/heading-styles-view/heading-styles-view.component';
import { TextStylesViewComponent } from './new-promotion-layout-tab/expanded-views/shared-views/text-styles-view/text-styles-view.component';
import { ButtonStylesViewComponent } from './new-promotion-layout-tab/expanded-views/shared-views/button-styles-view/button-styles-view.component';
import { ImageStylesViewComponent } from './new-promotion-layout-tab/expanded-views/shared-views/image-styles-view/image-styles-view.component';
import { ColumnsStylesViewComponent } from './new-promotion-layout-tab/expanded-views/shared-views/columns-styles-view/columns-styles-view.component';
import { ButtonBlockExpandedViewComponent } from './new-promotion-layout-tab/expanded-views/button-block-expanded-view/button-block-expanded-view.component';
import { ButtonBlockPreviewComponent } from './block-previews/button-block-preview/button-block-preview.component';
import { ColumnsBlockPreviewComponent } from './block-previews/columns-block-preview/columns-block-preview.component';
import { ColumnsBlockExpandedViewComponent } from './new-promotion-layout-tab/expanded-views/columns-block-expanded-view/columns-block-expanded-view.component';
import { SpacingBlockExpandedViewComponent } from './new-promotion-layout-tab/expanded-views/spacing-block-expanded-view/spacing-block-expanded-view.component';
import { SpacingBlockPreviewComponent } from './block-previews/spacing-block-preview/spacing-block-preview.component';
import { NavbarBlockPreviewComponent } from './block-previews/navbar-block-preview/navbar-block-preview.component';
import { NavbarBlockExpandedViewComponent } from './new-promotion-layout-tab/expanded-views/navbar-block-expanded-view/navbar-block-expanded-view.component';
import { MenuItemStylesViewComponent } from './new-promotion-layout-tab/expanded-views/shared-views/menu-item-styles-view/menu-item-styles-view.component';
import { ImageStylesViewAltComponent } from './new-promotion-layout-tab/expanded-views/shared-views/image-styles-view-alt/image-styles-view-alt.component';
import { FooterBlockPreviewComponent } from './block-previews/footer-block-preview/footer-block-preview.component';
import { FooterBlockExpandedViewComponent } from './new-promotion-layout-tab/expanded-views/footer-block-expanded-view/footer-block-expanded-view.component';

@NgModule({
  declarations: [
    NewPromotionTypePickerComponent,
    NewPromotionModalComponent,
    NewPromotionSettingsComponent,
    NewPromotionLayoutComponent,
    NewPromotionLayoutTabComponent,
    NewPromotionSettingsTabComponent,
    LayoutBlockCardComponent,
    AddNewBlockModalComponent,
    QuizBlockExpandedViewComponent,
    QuizBlockPreviewComponent,
    BlockPreviewWrapperComponent,
    TextBlockPreviewComponent,
    TextBlockExpandedViewComponent,
    ImageBlockExpandedViewComponent,
    ImageBlockPreviewComponent,
    VideoBlockPreviewComponent,
    VideoBlockExpandedViewComponent,
    ImageTextBlockExpandedViewComponent,
    ImageTextBlockPreviewComponent,
    GalleryBlockPreviewComponent,
    GalleryBlockExpandedViewComponent,
    ImageBannerBlockExpandedViewComponent,
    ImageBannerBlockPreviewComponent,
    BlockStylesViewComponent,
    BlockSectionComponent,
    HeadingStylesViewComponent,
    TextStylesViewComponent,
    ButtonStylesViewComponent,
    ImageStylesViewComponent,
    ColumnsStylesViewComponent,
    ButtonBlockExpandedViewComponent,
    ButtonBlockPreviewComponent,
    ColumnsBlockPreviewComponent,
    ColumnsBlockExpandedViewComponent,
    SpacingBlockExpandedViewComponent,
    SpacingBlockPreviewComponent,
    NavbarBlockPreviewComponent,
    NavbarBlockExpandedViewComponent,
    MenuItemStylesViewComponent,
    ImageStylesViewAltComponent,
    FooterBlockPreviewComponent,
    FooterBlockExpandedViewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(NewPromotionRoutes),
    ReactiveFormsModule,
    DragDropModule,
    MatIconModule,
    EditorModule,
  ],
})
export class NewPromotionModule {}
