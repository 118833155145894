import {Component, HostBinding, Input} from '@angular/core';
import { NewPromotionService } from '../../../../new-promotion.service';

@Component({
  selector: 'app-heading-styles-view',
  templateUrl: './heading-styles-view.component.html',
  styleUrls: ['./heading-styles-view.component.scss'],
})
export class HeadingStylesViewComponent {
  @HostBinding('class.w-100')
  @Input() rootFormGroup!: any;

  constructor(public newPromotionService: NewPromotionService) {}
}
