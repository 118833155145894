import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FontSource, FontsService } from './fonts.service';
import { PickerSlug, StorySlug } from '../data-repository/api-slugs';
import storyAdCSS from '../../ads/ad-template-types/story-ad/style.css';
import pickerAdCSS from '../../ads/ad-template-types/picker-ad/style.css';
import { FontModel } from '../models/font.model';

@Injectable({
  providedIn: 'root',
})
export class HtmlGeneratorService {
  private renderer: Renderer2;
  private fontsAttachedToDocument = new Array<string>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private fontsService: FontsService,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public getAssets(adTypeName: unknown): [string, string, string] | never {
    let jsFileName, cssFileName, clickTagInit;
    switch (adTypeName) {
      case StorySlug:
        jsFileName = 'story-ad.js';
        cssFileName = storyAdCSS;
        clickTagInit = 'story-click-tag-init.js';
        break;
      case PickerSlug:
        jsFileName = 'picker-ad.js';
        cssFileName = pickerAdCSS;
        clickTagInit = 'picker-click-tag-init.js';
        break;
      default:
        throw new Error('Ad type is not defined.');
    }

    return [jsFileName, cssFileName, clickTagInit];
  }

  /**
   * Attach fonts to HTML document.
   */
  public attachFonts(): void {
    let document = this.document;

    if (this.fontsService.fonts && this.fontsService.fonts.length > 0) {
      const linkFonts = this.fontsService.fonts.filter(
        (font) => font.source === FontSource[FontSource.Link]
      );
      this.attachLinkImportNode(document, linkFonts);

      this.fontsService.fonts
        .filter((font) => font.source === FontSource[FontSource.Custom])
        .forEach((font) => {
          // Only append font to document's head section if it is not already there and if it is a custom font.
          if (!this.fontsAttachedToDocument.includes(font.title)) {
            const fontTypes = document.createElement('style');
            const fontNode = this.createFontFaceNode(font, document);
            fontTypes.appendChild(fontNode);
            document.head.appendChild(fontTypes);
          }
          this.fontsAttachedToDocument.push(font.title);
        });
    }
  }

  public buildHTML(
    title: string,
    adTypeName: string,
    content: string,
    selectedFontNames: Array<string>,
    downloadZip: boolean
  ): string {
    const newDoc = document.implementation.createHTMLDocument(title);
    this.buildHead(newDoc, adTypeName, selectedFontNames, downloadZip);
    this.buildBody(newDoc, content);

    return newDoc.getElementsByTagName('html')[0].outerHTML;
  }

  private buildHead(
    newDoc: Document,
    adTypeName: string,
    selectedFontNames: Array<string>,
    downloadZip: boolean
  ): void {
    const metaCharset = document.createElement('meta');
    metaCharset.setAttribute('charset', 'UTF-8');
    newDoc.head.appendChild(metaCharset);

    const metaInitialScale = document.createElement('meta');
    metaInitialScale.name = 'viewport';
    metaInitialScale.setAttribute('initial-scale', '1');
    metaInitialScale.setAttribute('width', 'device-width');
    newDoc.head.appendChild(metaInitialScale);

    const metaAdSize = document.createElement('meta');
    metaAdSize.name = 'ad.size';
    metaAdSize.setAttribute('content', 'width=300,height=600');
    newDoc.head.appendChild(metaAdSize);

    const customCSSLink = document.createElement('link');
    customCSSLink.type = 'text/css';
    customCSSLink.rel = 'stylesheet';
    customCSSLink.href = 'style.css';
    newDoc.head.appendChild(customCSSLink);

    const clickTagJSLink = document.createElement('script');
    clickTagJSLink.type = 'text/javascript';
    clickTagJSLink.defer = true;
    clickTagJSLink.src = 'clicktag.js';
    newDoc.head.appendChild(clickTagJSLink);

    const customJSLink = document.createElement('script');
    customJSLink.type = 'text/javascript';
    customJSLink.defer = true;
    customJSLink.type = 'module';
    customJSLink.src = this.getAssets(adTypeName)[0];
    newDoc.head.appendChild(customJSLink);

    const clickTagInitializationLink = document.createElement('script');
    clickTagInitializationLink.type = 'text/javascript';
    clickTagInitializationLink.defer = true;
    clickTagInitializationLink.src = this.getAssets(adTypeName)[2];
    newDoc.head.appendChild(clickTagInitializationLink);

    this.attachFontsForZip(newDoc, selectedFontNames, downloadZip);
  }

  private buildBody(newDoc: Document, content: string): void {
    newDoc.body.innerHTML = content;
    const replaceables = newDoc.body.querySelectorAll('.replace-base64');

    // Replace base64 by corresponding file names.
    replaceables.forEach((element) => {
      const fileName = element.getAttribute('file-name');
      if (fileName) {
        // Replace logo
        if (element.classList.contains('logo')) {
          this.renderer.removeAttribute(element, 'src');
          this.renderer.setAttribute(element, 'src', fileName);
        } else {
          // Replace slides, options, etc.
          this.renderer.removeStyle(element, 'background-image');
          this.renderer.setStyle(
            element,
            'background-image',
            'url( ' + fileName + ' )'
          );
        }
      }
    });
  }

  /**
   * This function is called every time the new zip size has to be calculated.
   * Attach the font via @import, as link attribute or via font-face declaration.
   */
  private attachFontsForZip(
    newDoc: Document,
    selectedFontNames: Array<string>,
    downloadZip: boolean
  ) {
    const selectedFontModels =
      this.fontsService.retrieveSelectedFonts(selectedFontNames);

    if (selectedFontModels) {
      const selectedFontsWritable = new Array(...selectedFontModels);
      const selectedFontGroups = selectedFontsWritable.reduce(
        (group: any, font) => {
          const { source } = font;
          group[source] = group[source] ?? [];
          group[source].push(font);
          return group;
        },
        {}
      );

      for (const fontSource in selectedFontGroups) {
        switch (fontSource) {
          case FontSource[FontSource.Custom]:
            this.attachBase64ForFont(newDoc, selectedFontGroups[fontSource]);
            break;
          case FontSource[FontSource.Link]:
            this.attachLinkImportNode(
              newDoc,
              selectedFontGroups[fontSource],
              downloadZip
            );
            break;
          case FontSource[FontSource.System]:
            break;
          default:
            throw new Error('Font source is not supported.');
        }
      }
    }
  }

  private attachBase64ForFont(
    newDoc: Document,
    selectedFonts: Array<FontModel>
  ) {
    const fontTypes = newDoc.createElement('style');
    if (selectedFonts && selectedFonts.length > 0) {
      selectedFonts.forEach((font) => {
        const fontNode = this.createFontFaceNode(font, newDoc, false);
        fontTypes.appendChild(fontNode);
      });
    }
    newDoc.head.appendChild(fontTypes);
  }

  private attachLinkImportNode(
    newDoc: Document,
    fonts: Array<FontModel>,
    downloadZip = false
  ): void {
    const fontTypes = newDoc.createElement('style');
    if (fonts && fonts.length > 0) {
      fonts.forEach((font) => {
        if (!this.fontsAttachedToDocument.includes(font.title) || downloadZip) {
          const fontNode = document.createTextNode(
            ` @import url('${font.content}');`
          );
          fontTypes.appendChild(fontNode);
          this.fontsAttachedToDocument.push(font.title);
        }
      });
    }
    newDoc.head.appendChild(fontTypes);
  }

  private createFontFaceNode(
    font: FontModel,
    document: Document,
    addBase64 = true
  ): Text {
    const data = addBase64
      ? 'data:application/octet-stream;base64,' + font.content
      : font.title + '.' + font.type;
    return document.createTextNode(`@font-face { font-family: "${font.title}";
      src: url(${data}) format("${this.fontsService.getFormat(font.type)}");
    }`);
  }
}
