import { NgModule } from '@angular/core';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import {
  _MatMenuDirectivesModule,
  MatMenuModule,
} from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { FilterMenuComponent } from './filter-menu/filter-menu.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from '../shared.module';
import { FilterLabelPipe } from './filter-menu/filter-label.pipe';

@NgModule({
  declarations: [ContextMenuComponent, FilterMenuComponent, FilterLabelPipe],
  exports: [ContextMenuComponent, FilterMenuComponent, FilterLabelPipe],
  imports: [
    BrowserModule,
    _MatMenuDirectivesModule,
    FormsModule,
    MatInputModule,
    MatMenuModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatIconModule,
    MatSelectModule,
    ReactiveFormsModule,
    SharedModule,
  ],
})
export class MenusModule {}
