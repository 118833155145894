import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationCancel, NavigationEnd, Router} from '@angular/router';
import {filter, map, take} from 'rxjs/operators';
import {pageMap} from '../../../../shared/data-repository/page-mapping';
import {AdboxRoutes} from '../../../../shared/data-repository/routes';
import {AppBarService} from './app-bar.service';
import {ViewMode} from '../../../../shared/data-structures/view-mode';
import {PageKey} from '../../../../shared/data-structures/navigation-data';
import {RoutingHelperService} from '../../../../shared/routing/routing-helper.service';
import {AdsService} from '../../../../ads/ads.service';
import {switchMap} from 'rxjs';
import {AdTypeService} from '../../../../ads/ad-type.service';
import {UrlQueryDelimiter, UrlSegmentDelimiter} from '../../../../shared/data-repository/global-constants';
import {FilterMenuService} from '../../../../shared/menus/filter-menu/filter-menu.service';
import {AdBaseModel} from '../../../../shared/models/ad.model';
import {Actions, ofType} from '@ngrx/effects';
import {SnackBarService} from '../../../../shared/snack-bar/snack-bar.service';
import {SubSink} from 'subsink';
import {EditAdService} from '../../../../ads/edit-ad/edit-ad.service';
import * as fromAdActions from '../../../../state/ads/ads.actions';

@Component({
  selector: 'app-bar',
  templateUrl: './app-bar.component.html',
  styleUrls: ['./app-bar.component.scss']
})
export class AppBarComponent implements OnInit, OnDestroy {
  @ViewChild('titleInput') titleInput!: ElementRef<HTMLInputElement>;
  public readonly PageKey = PageKey;
  public readonly MyAdsSlug = AdboxRoutes.MyAds;
  public readonly DesignSlug = AdboxRoutes.Design;
  public readonly ContentSlug = AdboxRoutes.Content;
  public readonly AnimationSlug = AdboxRoutes.Animation;
  public readonly DownloadSlug = AdboxRoutes.Download;
  public readonly TemplateGallerySlug = AdboxRoutes.TemplateGallery;
  public readonly ViewMode = ViewMode;

  public AppBarView = AppBarView;
  public appBarView = AppBarView.OnlyTitle;
  public appBarViewMeta: any;
  public viewMode = ViewMode.Matrix;
  public adModel!: AdBaseModel;
  public title = '';
  public editModeEnabled = false;
  public type = '';
  public url!: string;

  private subs = new SubSink();

  constructor(public router: Router,
              public editAdService: EditAdService,
              private routingHelperService: RoutingHelperService,
              private adsService: AdsService,
              private actions$: Actions,
              private snackBarService: SnackBarService,
              private filterMenuService: FilterMenuService,
              private adTypeService: AdTypeService,
              private appBarService: AppBarService) {
  }

  ngOnInit(): void {
    this.editModeEnabled = false;
    this.setAppBarElements(this.router.url);

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel),
        map(_ => this.router.routerState)
      )
      .subscribe(data => {
        this.setAppBarElements(data.snapshot.url);
        this.filterMenuService.filterReset$.next(true);
      });

    this.adUpdated();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public toggleView(page: PageKey, viewMode: ViewMode): void {
    this.viewMode = viewMode;
    this.appBarService.viewModeChanged.next([page, viewMode]);
  }

  public changeAdTitle(): void {
    this.editModeEnabled = false;
    this.editAdService.adTitleChanged$.next(this.title);
  }

  public enableEditMode(): void {
    this.editModeEnabled = true;
    window.setTimeout(() => {
      this.titleInput.nativeElement.focus();
    }, 0)
  }

  public openFilterMenu(): void {
    this.filterMenuService.openFilter$.next(null);
  }

  private setAppBarElements(url: string): void {
    let page = url.substring(url.lastIndexOf(UrlSegmentDelimiter) + 1);

    if (url.includes(UrlQueryDelimiter)) {
      page = url.substring(url.lastIndexOf(UrlSegmentDelimiter) + 1, url.lastIndexOf(UrlQueryDelimiter));
    }

    if (page) {
      this.url = url;
      pageMap.forEach(entry => {
        if (entry.route === page) {
          this.title = entry.title;
        }
      });

      this.routingHelperService.currentPage = page;

      if (page.includes(this.MyAdsSlug)) {
        this.appBarView = AppBarView.MatrixListSwitch;
        this.viewMode = ViewMode.Matrix;
      } else if (page.includes(this.TemplateGallerySlug)) {
        this.appBarView = AppBarView.ShowFilter;
      } else if (page.includes(this.DesignSlug) ||
        page === this.ContentSlug ||
        page === this.AnimationSlug ||
        page === this.DownloadSlug) {
        this.setEditAdTitleAndType();
        this.appBarView = AppBarView.EditAd;
      } else {
        this.appBarView = AppBarView.OnlyTitle;
      }
    }
  }

  private setEditAdTitleAndType() {
    const segments = this.routingHelperService.getURLSegments(this.router.url);
    const adId = segments ? +segments[3].path : null;
    if (!adId || isNaN(+adId)) {
      throw new Error('Invalid ad id.');
    }

    this.adsService.fetchAd(adId)
      .pipe(
        take(1),
        switchMap(([ad, _]) => {
          this.adModel = ad;
          this.title = ad.title!;
          return this.adTypeService.getAdType(ad.adTypeId!)
        })
      )
      .subscribe(adType => {
        this.type = adType?.title!;
      });
  }

  private adUpdated(): void {
    this.subs.sink = this.actions$.pipe(
      ofType(fromAdActions.setAdsList)
    ).subscribe(_ => {
      this.editModeEnabled = false;
    });
  }
}

enum AppBarView {
  OnlyTitle,
  MatrixListSwitch,
  EditAd,
  ShowFilter,
}
