import {ActionReducerMap} from '@ngrx/store';
import {adTypesReducer} from './ad-types/ad-types.reducers';
import {AdTypesEffects} from './ad-types/ad-types.effects';
import {AdTypeModel} from '../shared/models/ad-type.model';
import {AdBaseModel} from '../shared/models/ad.model';
import {AdsEffects} from './ads/ads.effects';
import {adsReducer} from './ads/ads.reducer';
import {slidesReducer} from './slides/slides.reducer';
import {SlidesEffects} from './slides/slides.effects';
import {SlideModel} from '../shared/models/slide.model';
import {FontModel} from '../shared/models/font.model';
import {fontsReducer} from './fonts/fonts.reducer';
import {FontsEffects} from './fonts/fonts.effects';
import {FileTypesEffects} from './file-types/file-types.effects';
import {fileTypesReducer} from './file-types/file-types.reducer';
import {FileTypeModel} from '../shared/models/file-type.model';
import {imagesReducer} from './images/images.reducer';
import {ImagesEffects} from './images/images.effects';
import {ImageModel} from '../shared/models/image.model';
import {optionsReducer} from './options/options.reducer';
import {OptionsEffects} from './options/options.effects';
import {OptionModel} from '../shared/models/option.model';

export interface State {
  adTypes: ReadonlyArray<AdTypeModel>;
  ads: ReadonlyArray<AdBaseModel>;
  slides: ReadonlyArray<SlideModel>;
  fonts: ReadonlyArray<FontModel>;
  fileTypes: FileTypeModel;
  image: ReadonlyArray<ImageModel>;
  options: ReadonlyArray<OptionModel>;
}

export const effects = [AdTypesEffects, AdsEffects, SlidesEffects, FontsEffects, FileTypesEffects, ImagesEffects, OptionsEffects];

export const reducers: ActionReducerMap<State> = {
  adTypes: adTypesReducer,
  ads: adsReducer,
  slides: slidesReducer,
  fonts: fontsReducer,
  fileTypes: fileTypesReducer,
  image: imagesReducer,
  options: optionsReducer
};
