import { Component, Input, OnInit } from '@angular/core';
import { LayoutCard, NewPromotionService } from '../../new-promotion.service';

@Component({
  selector: 'app-layout-block-card',
  templateUrl: './layout-block-card.component.html',
  styleUrls: ['./layout-block-card.component.scss'],
})
export class LayoutBlockCardComponent implements OnInit {
  @Input() card!: LayoutCard;
  @Input() index!: number;

  constructor(private newPromotionService: NewPromotionService) {}

  ngOnInit(): void {}

  toggleSelectedState() {
    this.newPromotionService.toggleSelectedLayoutCard(this.card);
  }

  get isSelected() {
    return this.newPromotionService.selectedLayoutCard?.id === this.card.id;
  }

  deleteBlock() {
    this.newPromotionService.deleteBlock(this.card.id);
  }

  expandCard() {
    this.newPromotionService.setLayoutCardExpanded(this.card, true);
  }

  duplicateCard() {
    this.newPromotionService.duplicateCard({
      card: this.card,
      index: this.index + 1,
    });
  }
}
