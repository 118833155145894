<div
  class="d-flex w-100 gap-2 justify-content-center align-items-center px-2 top-bar"
  style="background: #eae8fd; height: 60px"
>
  <div class="col-1" style="width: 25px">
    <button type="button" class="bg-transparent border-0" (click)="goBack()">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icons/arrow_back_24px">
          <path
            id="icon"
            d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
            fill="#332E45"
          />
        </g>
      </svg>
    </button>
  </div>
  <div class="col-2">
    <span
      class="ps-2 w-75 d-flex justify-content-start align-items-center"
      style="font-size: 18px; color: #332e45"
      >Meine erste Quiz Promotion</span
    >
  </div>
  <div class="col-6">
    <div class="d-flex flex-row justify-content-between">
      <a
        type="button"
        class="d-flex flex-row justify-content-center align-items-center border-0 rounded-1 link-offset-1 link-underline-opacity-0 link-underline"
        style="background: #6e72eb; padding: 8px"
        href="./branding-bearbeiten.html"
      >
        <span class="text-white">
          <i>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M16.6666 5.00001H9.99996L8.33329 3.33334H3.33329C2.41663 3.33334 1.66663 4.08334 1.66663 5.00001V15C1.66663 15.9167 2.41663 16.6667 3.33329 16.6667H16.6666C17.5833 16.6667 18.3333 15.9167 18.3333 15V6.66668C18.3333 5.75001 17.5833 5.00001 16.6666 5.00001ZM14.95 14.1667L12.5 12.7333L10.05 14.1667L10.7 11.3917L8.54163 9.52501L11.3833 9.28334L12.5 6.66668L13.6166 9.28334L16.4583 9.52501L14.3 11.3917L14.95 14.1667Z"
                fill="white"
              />
            </svg>
          </i>
          <span style="padding: 0 2px">BRANDING</span>
          <i>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M5.83337 7.91666L10 12.0833L14.1667 7.91666H5.83337Z"
                fill="white"
              />
            </svg>
          </i>
        </span>
      </a>
      <div class="d-flex flex-row">
        <button
          type="button"
          class="d-flex flex-row justify-content-center align-items-center border-0 rounded-start-1 px-1 preview-type-btn"
          (click)="setPreviewType('Desktop')"
          [ngClass]="{
            'preview-type-btn-active':
              newPromotionService.previewType === 'Desktop'
          }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M16.6667 2.5H3.33335C2.41669 2.5 1.66669 3.25 1.66669 4.16667V12.5C1.66669 13.4167 2.41669 14.1667 3.33335 14.1667H8.33335V15.8333H6.66669V17.5H13.3334V15.8333H11.6667V14.1667H16.6667C17.5834 14.1667 18.3334 13.4167 18.3334 12.5V4.16667C18.3334 3.25 17.5834 2.5 16.6667 2.5Z"
              fill="white"
            />
          </svg>
        </button>
        <button
          type="button"
          class="d-flex flex-row justify-content-center align-items-center border-0 preview-type-btn"
          (click)="setPreviewType('Tab')"
          [ngClass]="{
            'preview-type-btn-active': newPromotionService.previewType === 'Tab'
          }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M17.5 3.33334H2.49998C1.58331 3.33334 0.833313 4.08334 0.833313 5.00001V15C0.833313 15.9167 1.58331 16.6667 2.49998 16.6667H17.5C18.4166 16.6667 19.1583 15.9167 19.1583 15L19.1666 5.00001C19.1666 4.08334 18.4166 3.33334 17.5 3.33334ZM15.8333 15H4.16665V5.00001H15.8333V15Z"
              fill="#332E45"
            />
          </svg>
        </button>
        <button
          type="button"
          class="d-flex flex-row justify-content-center align-items-center border-0 rounded-end-1 px-1 preview-type-btn"
          (click)="setPreviewType('Mobile')"
          [ngClass]="{
            'preview-type-btn-active':
              newPromotionService.previewType === 'Mobile'
          }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M14.1667 0.841677L5.83335 0.833344C4.91669 0.833344 4.16669 1.58334 4.16669 2.50001V17.5C4.16669 18.4167 4.91669 19.1667 5.83335 19.1667H14.1667C15.0834 19.1667 15.8334 18.4167 15.8334 17.5V2.50001C15.8334 1.58334 15.0834 0.841677 14.1667 0.841677ZM14.1667 15.8333H5.83335V4.16668H14.1667V15.8333Z"
              fill="#332E45"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="d-flex flex-row justify-content-end me-5">
      <button
        class="bg-transparent border-0 sidebar-type-btn"
        (click)="setSidebarType('Settings')"
        [ngClass]="{
          'sidebar-type-btn-active':
            newPromotionService.sidebarView === 'Settings'
        }"
      >
        <span class="d-flex flex-column">
          <i>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Icon">
                <path
                  id="Vector"
                  d="M3 17V19H9V17H3ZM3 5V7H13V5H3ZM13 21V19H21V17H13V15H11V21H13ZM7 9V11H3V13H7V15H9V9H7ZM21 13V11H11V13H21ZM15 9H17V7H21V5H17V3H15V9Z"
                  fill="#6E72EB"
                />
              </g>
            </svg>
          </i>
          <span style="">Einstellungen</span>
        </span>
      </button>
      <button class="bg-transparent border-0 ms-2">
        <span
          class="d-flex flex-column sidebar-type-btn"
          (click)="setSidebarType('Layout')"
          [ngClass]="{
            'sidebar-type-btn-active':
              newPromotionService.sidebarView === 'Layout'
          }"
        >
          <i>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Icon">
                <path
                  id="Vector"
                  d="M11 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H11V21ZM13 21H19C20.1 21 21 20.1 21 19V12H13V21ZM21 10V5C21 3.9 20.1 3 19 3H13V10H21Z"
                  fill="#6E72EB"
                />
              </g>
            </svg>
          </i>
          <span>Layout</span>
        </span>
      </button>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
<app-add-new-block-modal></app-add-new-block-modal>
