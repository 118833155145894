<div
  class="text-container"
  [ngStyle]="{
    background:
      cardValues.background_type === 'color'
        ? cardValues.background_color
        : 'url(\'' + cardValues.background_image_file + '\')',
    width: cardValues.width + '%',
    'padding-left': cardValues.paddingLeft + cardValues.marginUnit,
    'padding-top': cardValues.paddingTop + cardValues.marginUnit,
    'padding-right': cardValues.paddingRight + cardValues.marginUnit,
    'padding-bottom': cardValues.paddingBottom + cardValues.marginUnit
  }"
>

  <div class="gallery-grid">
    <div
      *ngFor="let currentImage of images"
      class="w-100"
      [ngStyle]="{
      'text-align': currentImage.style_image.alignSelf
    }"
    >
      <img
        *ngIf="currentImage.image_file"
        [src]="
        sanitizer.bypassSecurityTrustResourceUrl(
          '/api/image/' + currentImage.image_file
        )
      "
        [ngStyle]="{
        width: currentImage.style_image.width + '%'
      }"
        [alt]="currentImage.image_title"
        [title]="currentImage.image_title"
      />
    </div>
  </div>
</div>
