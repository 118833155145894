import { Expose } from 'class-transformer';

export class ImageModel {
  @Expose({ name: 'id' })
  public id = -1;
  @Expose({ name: 'image_original' })
  public imageOriginal: string | undefined = '';
  @Expose({ name: 'background_image_original' })
  public backgroundImageOriginal: string | undefined = '';
  @Expose({ name: 'thumb_image_original' })
  public thumbImageOriginal: string | undefined = '';
}
