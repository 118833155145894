<ng-container *ngIf="breadcrumbItems">
  <nav aria-label="breadcrumb" class="position-relative">
    <ol class="breadcrumb">
      <li
        class="breadcrumb-item position-relative"
        [ngClass]="BreadcrumbState[breadcrumbItemStates[i]]"
        *ngFor="let item of breadcrumbItems; let i = index">
        <div class="item-wrapper d-inline-block text-decoration-none">
          <div class="step d-flex justify-content-center align-items-center position-relative">
            <span class="number">{{i + 1}}</span>
          </div>
          <h5 class="title position-relative">{{item.title}}</h5>
        </div>
      </li>
    </ol>
  </nav>
</ng-container>
