import { Component } from '@angular/core';
import { NewPromotionService } from '../../../new-promotion.service';
import { UploadService } from '../../../../../backend-services/upload.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-video-block-expanded-view',
  templateUrl: './video-block-expanded-view.component.html',
  styleUrls: ['./video-block-expanded-view.component.scss'],
})
export class VideoBlockExpandedViewComponent {
  constructor(
    public newPromotionService: NewPromotionService,
    private uploadService: UploadService,
    public sanitizer: DomSanitizer
  ) {}

  closeCard() {
    this.newPromotionService.layoutCardExpanded = false;
  }

  toggleStyle(
    control: AbstractControl | null | undefined,
    values: { activeValue: any; defaultValue: any }
  ) {
    if (control?.value === values.activeValue) {
      control?.setValue(values.defaultValue);
    } else {
      control?.setValue(values.activeValue);
    }
  }

  handleVideoChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.uploadService.uploadImage(file).subscribe({
        next: (res) => {
          console.log(res);
          if (res.success && res.data?.file) {
            this.newPromotionService
              .selectedLayoutCard!.data.get('video_file')
              ?.setValue(res.data.file);
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
    }
  }

  deleteImageFile() {
    const file =
      this.newPromotionService.selectedLayoutCard!.data.get(
        'video_file'
      )?.value;
    if (file) {
      this.uploadService.deleteImage(file).subscribe({ error: console.log });
    }

    this.newPromotionService
      .selectedLayoutCard!.data.get('video_file')
      ?.setValue('');
  }
}
