import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AdminLayoutService } from '../../admin-layout.service';
import { ContextMenuItem } from '../../../../shared/menus/context-menu/context-menu-item';
import { RouteInfo } from '../../admin-layout.routing';
import {
  ROUTES,
  subNavFirstItemPadding,
  subNavItemHeight,
} from '../../../../shared/data-repository/nav-data';
import { RoutingHelperService } from '../../../../shared/routing/routing-helper.service';
import { AdboxRoutes } from '../../../../shared/data-repository/routes';
import { filter, map } from 'rxjs/operators';
import {
  ProductSubtitle,
  ProductTitle,
} from '../../../../shared/data-repository/global-text-snippets';
import { SidebarService } from './sidebar.service';
import { AuthService } from '../../../../backend-services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, AfterViewInit {
  @ViewChildren('parentListItem') public parentListItem!: QueryList<ElementRef>;
  @ViewChild('profileNavBar') public profileNavBar!: ElementRef<HTMLElement>;
  @ViewChild('menuToggle') public menuToggle!: ElementRef<HTMLAnchorElement>;
  @ViewChild('sidebarWrapper')
  public sidebarWrapper!: ElementRef<HTMLAnchorElement>;
  @Input() public aside!: HTMLElement;
  @Input() public nav!: HTMLElement;
  @Input() public main!: HTMLElement;
  public menuItems!: RouteInfo[];
  public productTitle = ProductTitle;
  public productSubTitle = ProductSubtitle;
  public subNavItemHeight = subNavItemHeight;
  public subNavFirstItemPadding = subNavFirstItemPadding;
  public isEditAdsRoute = false;
  public contextMenuItems: ContextMenuItem[] = [
    {
      symbol: 'settings',
      label: 'Einstellungen',
      isDisabled: () => {
        return false;
      },
      clickHandler: () => {
        this.router.navigate(['account', 'settings']);
      },
    },
    {
      symbol: 'notifications',
      label: 'Benachrichtigungen',
      isDisabled: () => {
        return false;
      },
      clickHandler: () => {
        this.router.navigate(['account', 'notifications']);
      },
    },
  ];
  public MyAds = AdboxRoutes.MyAds;
  public AccountRoute = AdboxRoutes.Account;
  public MyProfileRoute = AdboxRoutes.MyProfile;
  public UserManagementRoute = AdboxRoutes.UserManagement;
  private isCompressed = false;
  private currentRoute!: string;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private sidebarService: SidebarService,
    private routingHelperService: RoutingHelperService,
    private adminLayoutService: AdminLayoutService,
    private authService: AuthService
  ) {
    this.setRouteData(router.url);
  }

  ngOnInit(): void {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.adminLayoutService.menuItemsClosed.subscribe(() => {
      this.closeAll();
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((_) => this.router.routerState)
      )
      .subscribe((data) => {
        this.setRouteData(data.snapshot.url);
        this.resetSideBarPosition();
      });
  }

  ngAfterViewInit(): void {
    this.parentListItem.forEach((el) => {
      if (el.nativeElement.classList.contains(this.currentRoute)) {
        if (el.nativeElement.classList.contains('has-submenu')) {
          this.renderer.addClass(el.nativeElement, 'expanded');
        }
        this.renderer.addClass(el.nativeElement, 'active');
      }
    });
  }

  public toggleMenu(parentListEl: HTMLElement): void {
    const item = parentListEl.getAttribute('data-item');
    this.closeMenus(item!);

    const classList = parentListEl.classList;
    if (classList.contains('expanded')) {
      parentListEl.classList.remove('expanded');
      // Call "scrollSidebar" method to position sidebar correctly after collapsing the flyout menu.
      this.sidebarService.menuCollapsed.next(null);
    } else {
      parentListEl.classList.add('expanded');
    }
  }

  public closeMenus(item: string): void {
    this.parentListItem.forEach((el) => {
      if (!el.nativeElement.classList.contains(item)) {
        this.renderer.removeClass(el.nativeElement, 'expanded');
      }
    });
  }

  public closeAll(): void {
    this.menuItems.forEach((item) => {
      this.closeMenus(item.class);
    });
  }

  public goTo(path: string, parentListEl: HTMLElement): void {
    this.removeActiveClass();
    this.router.navigate([path]).then((success) => {
      if (success) {
        const item = parentListEl.getAttribute('data-item');
        this.closeMenus(item!);

        this.renderer.addClass(parentListEl, 'active');
      }
    });
  }

  public removeActiveClass(): void {
    this.parentListItem.forEach((el) => {
      this.renderer.removeClass(el.nativeElement, 'active');
    });
  }

  public toggleSidebar(): void {
    if (this.isCompressed) {
      this.renderer.removeClass(
        this.menuToggle.nativeElement,
        'justify-content-center'
      );
      this.renderer.removeClass(this.aside, 'compressed');
    } else {
      const settings =
        this.profileNavBar.nativeElement.querySelector('#settings');
      const notifications =
        this.profileNavBar.nativeElement.querySelector('#notifications');
      this.renderer.addClass(settings, 'd-none');
      this.renderer.addClass(notifications, 'd-none');
      this.renderer.addClass(this.aside, 'compressed');
    }
    this.isCompressed = !this.isCompressed;
    this.renderer.setStyle(this.nav, 'width', this.main.clientWidth + 'px');
  }

  public transitionEnd($event: TransitionEvent) {
    if (
      $event.propertyName === 'transform' &&
      $event.target &&
      ($event.target as HTMLElement).innerText === 'menu_open'
    ) {
      const settings =
        this.profileNavBar.nativeElement.querySelector('#settings');
      const notifications =
        this.profileNavBar.nativeElement.querySelector('#notifications');
      if (this.isCompressed) {
        this.renderer.addClass(
          this.menuToggle.nativeElement,
          'justify-content-center'
        );
      } else {
        this.renderer.removeClass(settings, 'd-none');
        this.renderer.removeClass(notifications, 'd-none');
      }
    }
  }

  private setRouteData(url: string): void {
    const segments = this.routingHelperService.getURLSegments(url);
    if (!segments) {
      return;
    }
    this.currentRoute = segments[0].path;
    this.isEditAdsRoute = segments[1] && segments[1].path === AdboxRoutes.Edit;
  }

  /**
   * After each navigation to a new route, the sidebar nav is positioned to its origin.
   */
  private resetSideBarPosition() {
    this.renderer.setStyle(
      this.sidebarWrapper.nativeElement,
      'position',
      'absolute'
    );
    this.renderer.removeStyle(this.sidebarWrapper.nativeElement, 'top');
    this.renderer.removeStyle(this.sidebarWrapper.nativeElement, 'bottom');
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  protected readonly AdboxRoutes = AdboxRoutes;

  logout() {
    this.authService.logout();
  }

}
