<ng-container *ngIf="adModel !== null">
  <div id="renderable">
    <!-- Needed because this whole template is duplicated by html2canvas library -->
    <div id="picker-preview" class="ad-container">
      <div
        id="picker-ad-wrapper"
        style="height: 600px; width: 300px"
        [style.background]="'#' + adModel.backgroundColor"
      >
        <section id="picker-preview-header">
          <div
            class="picker-logo d-flex"
            [ngStyle]="commonAdTemplateService.alignLogo(adModel)"
          >
            <img
              class="replace-base64 logo"
              *ngIf="adModel.logo !== undefined && adModel.logo !== ''"
              [attr.file-name]="adModel.logoImageName"
              [src]="sanitizedLogo"
            />
          </div>
        </section>

        <section
          id="picker-preview-body"
          class="selectionarea position-relative h-50"
        >
          <div class="instruction w-100">
            <!-- mögliche Animationen bei h2 und h3: animate__fadeInUp, animate__fadeIn, animate__slideInLeft -->
            <h2
              class="color1 animate__animated"
              [ngClass]="'animate__' + pickerAdModel.animationType"
              [style.font-size.px]="pickerAdModel.headlineFontSize"
              [style.color]="'#' + pickerAdModel.headlineFontColor"
              [style.font-family]="pickerAdModel.headlineFontType"
            >
              {{ pickerAdModel.headlineContent! }}
            </h2>
            <br/>
            <h3
              class="color1 animate__animated animate__delay-1s"
              [ngClass]="'animate__' + pickerAdModel.animationType"
              [style.font-size.px]="pickerAdModel.continuousTextFontSize"
              [style.color]="'#' + pickerAdModel.continuousTextFontColor"
              [style.font-family]="pickerAdModel.continuousTextFontType"
            >
              {{ pickerAdModel.continuousTextContent }}
            </h3>
          </div>

          <div id="slidewrapper" class="w-100 position-relative">
            <div id="slider" class="slider">
              <div id="options">
                <ng-container *ngFor="let option of options; let i = index">
                  <div class="option option-{{ i + 1 }} animate__animated">
                    <div
                      class="item btn replace-base64"
                      [attr.file-name]="option.thumbImageName"
                      [style.border-radius]="
                        option.shape === 'circle' ? '100%' : '0'
                      "
                      [style.background-color]="
                        '#' + option.thumbBackgroundColor
                      "
                      [style.background-image]="
                        option.thumbImageCropped | safeHtml
                      "
                    ></div>
                  </div>
                </ng-container>
              </div>
              <button id="prev">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"/>
                  <path
                    d="M15.61 7.41L14.2 6l-6 6 6 6 1.41-1.41L11.03 12l4.58-4.59z"
                  />
                </svg>
              </button>
              <button
                id="next"
                class="animate__animated animate__fadeIn animate__delay-5s"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"/>
                  <path
                    d="M10.02 6L8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </section>

        <section
          class="imagearea position-relative"
          [style.font-size.px]="pickerAdModel.infoTextFontSize"
          [style.color]="'#' + pickerAdModel.infoTextFontType"
          [style.font-family]="pickerAdModel.infoTextFontType"
        >
          <div
            id="picker-preview-background-label"
            *ngIf="options === undefined || options.length === 0"
            class="d-flex justify-content-center align-items-center position-absolute w-100 h-100 top-0"
          >
            {{ Background }}
          </div>
          <div
            class="images animate__animated animate__fadeIn w-100 h-100"
            *ngIf="options !== undefined && options.length > 0"
          >
            <div
              *ngFor="let option of options; let i = index"
              class="image w-100 h-100 position-absolute replace-base64"
              [style.background-color]="'#' + option.infoBackgroundColor"
              [style.background-image]=" option.infoBackgroundImageCropped | safeHtml"
              [attr.file-name]="option.infoBackgroundImageName"
            >
              <div class="subline" [style.color]="'#' + option.infoTextColor">
                {{ option.infoText }}
              </div>
            </div>
          </div>
          <div id="cta" class="cta animate__animated animate__repeat-2">
            <a
              class="btn btn-default w-100 d-flex justify-content-center align-items-center"
              [style.font-size.px]="this.adModel.ctaButtonTextSize"
              [style.font-family]="this.adModel.ctaButtonTextFontType"
              [style.background]="'#' + this.adModel.ctaButtonBackgroundColor"
              [style.color]="'#' + this.adModel.ctaButtonTextColor"
            >
              {{ adModel.ctaButtonText }}
            </a>
          </div>
        </section>
      </div>
    </div>
  </div>
</ng-container>
