import { Component, Inject } from '@angular/core';
import {
  AdSizeInfo,
  Close,
  GDN,
  IABStandard,
} from '../../data-repository/global-text-snippets';
import { FileSizeInfo } from '../../data-structures/file';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileSizeInfoBox } from '../../data-repository/info-boxes';
import {
  GDNThresholdBinary,
  GDNThresholdDecimal,
  IABThresholdBinary,
  IABThresholdDecimal,
} from '../../data-repository/global-constants';

@Component({
  selector: 'app-ad-size-info-dialog',
  templateUrl: './ad-size-info-dialog.component.html',
  styleUrls: ['./ad-size-info-dialog.component.scss'],
})
export class AdSizeInfoDialogComponent {
  public AdSizeInfo = AdSizeInfo;
  public Close = Close;
  public FileSizeInfo = FileSizeInfo;
  public fileSizeStatus = FileSizeInfo.OkForAll;
  public FileSizeInfoBox = FileSizeInfoBox;
  public fileSize = 0;
  public IABThresholdDecimal = IABThresholdDecimal;
  public GDNThresholdDecimal = GDNThresholdDecimal;
  public IABStandard = IABStandard;
  public GDN = GDN;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any) {
    if (data?.fileSizeStatus) {
      this.fileSizeStatus = data.fileSizeStatus;
    }

    if (data?.fileSize) {
      this.fileSize = data.fileSize;
    }
  }

  public getIcon(publisher: string): string {
    let threshold = 0;
    if (publisher === IABStandard) {
      threshold = IABThresholdBinary;
    } else if (publisher === GDN) {
      threshold = GDNThresholdBinary;
    }

    if (this.fileSize < threshold) {
      return 'done';
    } else {
      return 'error';
    }
  }

  public getFileSizeInfo(publisher: string): string {
    let threshold = 0;
    if (publisher === IABStandard) {
      threshold = IABThresholdBinary;
    } else if (publisher === GDN) {
      threshold = GDNThresholdBinary;
    }

    if (this.fileSize < threshold) {
      return FileSizeInfo[FileSizeInfo.OkForAll];
    } else {
      return FileSizeInfo[FileSizeInfo.OkForAny];
    }
  }
}
