import { Component } from '@angular/core';
import { NewPromotionService } from '../../../new-promotion.service';

@Component({
  selector: 'app-button-block-expanded-view',
  templateUrl: './button-block-expanded-view.component.html',
  styleUrls: ['./button-block-expanded-view.component.scss'],
})
export class ButtonBlockExpandedViewComponent {
  constructor(public newPromotionService: NewPromotionService) {}

  closeCard() {
    this.newPromotionService.layoutCardExpanded = false;
  }
}
