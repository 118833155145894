<div
  class="modal fade"
  id="newPromotionModal"
  data-bs-backdrop="static"
  tabindex="-1"
  aria-labelledby="newPromotionModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div
        class="position-relative row rounded-2 bg-white px-4"
        style="color: #332e45"
      >
        <div class="px-4 py-2">
          <div
            class="row d-flex flex-row justify-content-start align-items-center mb-2"
          >
            <h4 class="mb-2 pt-4 modal-title">Neuer Block</h4>
          </div>
          <div class="row my-1">
            <input
              type="text"
              class="form-control mb-3 form-input"
              placeholder="Titel"
              [formControl]="newPromotionService.titleControl"
            />
            <select
              class="form-select form-select-lg mb-3 py-2"
              aria-label=".form-select-lg example"
            >
              <option selected>Branding</option>
            </select>
          </div>
          <div class="row">
            <div class="d-flex flex-row justify-content-end mt-2 mb-4 px-0">
              <button
                class="bg-transparent border-0 px-2 py-1 form-btn"
                data-bs-dismiss="modal" aria-label="Close"
              >
                Abbrechen
              </button>
              <button
                class="bg-transparent border-0 px-2 py-1 form-btn"
                data-bs-dismiss="modal"
                [disabled]="!newPromotionService.titleControl.value"
                (click)="handleSubmit()"
              >
                Erstellen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
