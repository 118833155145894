import {createReducer, on} from '@ngrx/store';
import {ImageModel} from '../../shared/models/image.model';
import {setOriginalImage, updateOriginalImage} from './images.actions';

export const initialState: ReadonlyArray<ImageModel> = [];

export const imagesReducer = createReducer(
  initialState,
  on(setOriginalImage, updateOriginalImage, (state, {payload}): ReadonlyArray<ImageModel> => {
    const index = state.findIndex(image => image.id === payload.id);
    if (index < 0) {
      const copiedState = [...state];
      copiedState.push(payload)
      return [...copiedState];
    } else {
      const existingImageModel = [...state][index];
      const updatedImageModel = new ImageModel();
      Object.assign(updatedImageModel, existingImageModel);

      updatedImageModel.thumbImageOriginal = payload.thumbImageOriginal;
      updatedImageModel.backgroundImageOriginal = payload.backgroundImageOriginal;
      updatedImageModel.imageOriginal = payload.imageOriginal;

      const copiedState = [...state];
      copiedState[index] = updatedImageModel;
      return copiedState;
    }
  })
);
