<div
  class="container d-flex flex-column justify-content-between position-relative"
  style="min-height: 90vh"
>
  <form class="row pb-5" [formGroup]="userForm">
    <div class="col-lg-6 mb-apd-0 mb-2">
      <div class="card box-shadow">
        <h3 class="p-2 text-uppercase border-bottom mb-0">Persönliche Daten</h3>
        <div class="text-center">
          <div class="d-block pt-4 pb-4 w-100 d-flex justify-content-center">
            <div class="user-avatar">
              <app-profile-picture
                [firstName]="firstNameControl.value"
                [lastName]="lastNameControl.value"
                [is_custom]="true"
              ></app-profile-picture>
            </div>
          </div>
        </div>
        <!-- form -->
        <div class="block__personal-data p-5">
          <div
            class="form-group mb-2 d-flex flex-column"
            [ngClass]="{
              'was-validated': firstNameControl.touched
            }"
          >
            <input
              class="form-control order-1"
              type="text"
              name="vorname"
              id="vorname"
              required
              formControlName="first_name"
            />
            <label class="order-0" for="email"><span>Vorname</span></label>
            <div class="order-2 invalid-feedback">Ungültiges Vorname</div>
          </div>
          <!-- end form group -->
          <div
            class="form-group mb-2 d-flex flex-column"
            [ngClass]="{
              'was-validated': lastNameControl.touched
            }"
          >
            <input
              class="form-control order-1"
              type="text"
              name="nachname"
              id="nachname"
              required
              formControlName="last_name"
            />
            <label class="order-0" for="email"><span>Nachname</span></label>
            <div class="order-2 invalid-feedback">Ungültiges Nachname</div>
          </div>
          <!-- end form group -->
          <div
            class="form-group d-flex flex-column"
            [ngClass]="{
              'was-validated': emailControl.touched
            }"
          >
            <input
              class="form-control order-1"
              type="email"
              name="email"
              id="email"
              required
              formControlName="email"
            />
            <label class="order-0" for="email"><span>E-Mail</span></label>
            <div class="order-2 invalid-feedback">Ungültige E-Mail</div>
          </div>
        </div>
        <!-- form -->
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card box-shadow mb-2">
        <div class="p-2 d-flex justify-content-between align-items-center">
          <label
            class="form-check-label mb-0 text-normal font-size-14 color-text"
            for="switchCh0"
            >Profil aktiviert</label
          >
          <div class="d-flex justify-content-between align-items-center">
            <div class="form-check form-switch ms-1">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="switchCh0"
                formControlName="enabled"
              />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="card box-shadow mb-2" *ngIf="!id || isSuperAdmin">
        <h3
          class="p-2 border-bottom d-flex justify-content-between align-items-center mb-0"
        >
          <span class="text-uppercase">Passwort</span>
          <span class="d-flex justify-content-between align-items-center">
            <label
              class="form-check-label mb-0 text-normal font-size-14 color-text"
              for="switchCh1"
              >Passwort setzen</label
            >
            <span class="form-check form-switch ms-1">
              <input
                class="form-check-input order-1"
                type="checkbox"
                role="switch"
                id="switchCh1"
                formControlName="set_password"
              />
            </span>
          </span>
        </h3>
        <div class="p-2" *ngIf="!id && !setPasswordControl.value">
          Der neue Benutzer erhält eine E-Mail zum Setzen seines Passwortes.
        </div>
        <div
          class="p-2 pw-container"
          [ngClass]="{ hidden: !setPasswordControl.value }"
        >
          <div
            class="form-group form-group-328"
            [ngClass]="{
              'was-validated':
                setPasswordControl.value && passwordControl.touched
            }"
          >
            <input
              class="form-control"
              type="password"
              name="pass"
              id="pass"
              required
              #passwordElement
              [formControl]="passwordControl"
            />
          </div>
        </div>
      </div>
      <!-- end card -->
      <div class="card box-shadow mb-2">
        <h3 class="p-2 border-bottom mb-0">
          <span class="text-uppercase">Rolle</span>
        </h3>
        <div class="ps-2 pe-2 pb-2 pt-1">
          <div
            class="form-group form-group-328 d-flex flex-column"
            [ngClass]="{
              'was-validated': roleIdControl.touched
            }"
          >
            <select
              class="form-select order-1"
              aria-label="select role"
              name="role"
              id="role"
              required
              formControlName="role_id"
            >
              <option [value]="role.id" *ngFor="let role of filteredRoles">
                {{ role.name }}
              </option>
            </select>
            <label class="order-0" for="role"><span>Rolle</span></label>
          </div>
        </div>
      </div>
      <!-- end card -->
      <div class="card box-shadow" *ngIf="isSuperAdmin">
        <h3 class="p-2 border-bottom mb-0">
          <span class="text-uppercase">Kunde</span>
        </h3>
        <div class="ps-2 pe-2 pb-2 pt-1">
          <div
            class="form-group form-group-328 d-flex flex-column"
            [ngClass]="{
              'was-validated': customerIdControl.touched
            }"
          >
            <select
              class="form-select order-1"
              aria-label="select kunde"
              name="kundle"
              id="kundle"
              formControlName="customer_id"
              #customerIdElement
            >
              <option value="">-- None --</option>
              <option
                [value]="customer.id"
                *ngFor="let customer of allCustomers"
              >
                {{ customer.label }}
              </option>
            </select>
            <label class="order-0" for="kundle"><span>Kunde</span></label>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
  </form>

  <div class="action-btn-container">
    <div class="container">
      <div class="d-flex justify-content-center justify-content-lg-end flex-wrap pt-lg-4 pt-3">
        <button
          data-bs-toggle="modal"
          data-bs-target="#confirmBackModal"
          class="d-none"
          #confirmBackModalTrigger
        ></button>
        <button
          class="btn btn-white btn-circle mb-1"
          (click)="goBackCheck()"
          [disabled]="userFormLoading"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M18.3334 10.0837H7.17758L12.3017 4.95949L11.0001 3.66699L3.66675 11.0003L11.0001 18.3337L12.2926 17.0412L7.17758 11.917H18.3334V10.0837Z"
              fill="#6E72EB"
            />
          </svg>
        </button>
        <button
          (click)="handleSubmit()"
          [disabled]="userFormLoading"
          class="btn btn-indigo btn-indigo-2 ms-2 "
        >
          {{ id ? "Daten speichern" : "Neuen Benutzer anlegen" }}
        </button>
      </div>
    </div>
  </div>

  <!--  Modal-->
  <div
    class="modal fade"
    id="confirmBackModal"
    tabindex="-1"
    aria-labelledby="confirmBackModalBackdrop"
    aria-hidden="true"
    #confirmBackModalBackdrop
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card box-shadow p-2 border-0 modal-content-inner">
          <!-- end top block -->
          <p class="fs-5 fw-bold my-2 c-line-height-1">
            Ihre Daten wurden noch nicht gespeichert - Möchten Sie fortfahren?
          </p>
          <div class="d-flex justify-content-between w-100 gap-4 mt-4">
            <button
              class="btn btn-indigo-outline w-100"
              data-bs-dismiss="modal"
            >
              Nein
            </button>
            <button
              class="btn btn-indigo w-100"
              (click)="goBack()"
              data-bs-dismiss="modal"
            >
              Ja
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
