import { Component, OnDestroy, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { EditAdNavigationService } from '../../edit-ad-navigation.service';
import {
  BackgroundColor,
  Bottom,
  Color,
  CreateTemplate,
  Direction,
  Distance,
  FontSize,
  FontType,
  Headline,
  Horizontally,
  Left,
  MyTemplates,
  Right,
  Text,
  TextColor,
  Top,
  UploadFont,
  UploadImage,
  Vertically,
} from 'src/app/shared/data-repository/global-text-snippets';
import { AdConfigurationItemModel } from '../../../../shared/models/ad-configuration-item.model';
import { ColorPickerIntegratable } from '../../../../shared/controls/color-picker/color-picker-integratable';
import {
  FontSizeOptions,
  HorizontalLogoAlignmentOptions,
  VerticalLogoAlignmentOptions,
} from 'src/app/shared/data-repository/dropdown-options';
import { SubSink } from 'subsink';
import { merge } from 'rxjs';
import { Design } from '../../../../shared/data-repository/page-mapping';
import {
  AdDesignFormControls,
  CommonEditAdDesignService,
} from '../../common-services/common-edit-ad-design.service';
import { CommonEditAdBasicService } from '../../common-services/common-edit-ad-basic.service';

@Component({
  templateUrl: './story-ad-design.component.html',
  styleUrls: ['./story-ad-design.component.scss'],
})
export class StoryAdDesignComponent
  implements OnInit, OnDestroy, ColorPickerIntegratable
{
  // Constants
  public Color = Color;
  public TextColor = TextColor;
  public BackgroundColor = BackgroundColor;
  public HorizontalLogoAlignmentOptions = HorizontalLogoAlignmentOptions;
  public VerticalLogoAlignmentOptions = VerticalLogoAlignmentOptions;
  public UploadImage = UploadImage;
  public FontSizeOptions = FontSizeOptions;
  public Distance = Distance;
  public Headline = Headline;
  public UploadFont = UploadFont;
  public Direction = Direction;
  public Horizontally = Horizontally;
  public Vertically = Vertically;
  public Left = Left;
  public Right = Right;
  public Top = Top;
  public Bottom = Bottom;
  public FontSize = FontSize;
  public FontType = FontType;
  public CreateTemplate = CreateTemplate;
  public MyTemplates = MyTemplates;
  public Text = Text;

  public displayErroneousInputs = false;
  public color: ThemePalette = 'primary';
  public AdDesignFormControls = AdDesignFormControls;

  // Configuration items
  public designTemplateConfigurationItem = new AdConfigurationItemModel();
  public logoConfigurationItem = new AdConfigurationItemModel();
  public backgroundConfigurationItem = new AdConfigurationItemModel();
  public textConfigurationItem = new AdConfigurationItemModel();
  public buttonConfigurationItem = new AdConfigurationItemModel();
  public adDesignComponent = this;

  private subs = new SubSink();

  constructor(
    public editAdNavigationService: EditAdNavigationService,
    public commonEditAdDesignService: CommonEditAdDesignService,
    private commonEditAdBasicService: CommonEditAdBasicService
  ) {}

  ngOnInit(): void {
    this.commonEditAdDesignService.initConfigurationItems(
      this.designTemplateConfigurationItem,
      this.logoConfigurationItem,
      this.backgroundConfigurationItem,
      this.textConfigurationItem,
      this.buttonConfigurationItem
    );
    this.commonEditAdDesignService.designTemplateForm = undefined;
    this.initEvents();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private initEvents(): void {
    const fontsService = this.commonEditAdDesignService.fontsService;
    this.subs.sink = merge(
      fontsService.selectFonts(),
      fontsService.fontsFetched$
    ).subscribe((fonts) => {
      if (fonts) {
        this.commonEditAdDesignService.fontTypeOptions =
          fontsService.toDropDownOption(fonts);
      }
    });

    // Init form
    const editAdService = this.commonEditAdDesignService.editAdService;
    this.subs.sink = editAdService.adModelUpdated$.subscribe((adModel) => {
      if (!adModel) {
        throw new Error('Ad model is not defined');
      }
      this.commonEditAdBasicService.AdModel = adModel;
      if (!this.commonEditAdDesignService.designTemplateForm) {
        this.commonEditAdDesignService.initDesignForm(adModel);
      }
    });

    this.subs.sink = this.editAdNavigationService.savedAdTriggered$.subscribe(
      () => {
        this.commonEditAdBasicService.adSaved(Design);
      }
    );
  }
}
