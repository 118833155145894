<div id="navigation" [style]="{'width': 'calc(' + width + 'px - 1.5rem)'}" [hidden]="!showControls">
  <div id="controls">
    <button id="back"
            mat-button
            [hidden]="isFirstPage"
            (click)="prev()"
            type="button"
            class="primary white excessive mat-elevation-z16 ms-3">
      {{NavigateBackwards}}
    </button>
    <button id="save"
            mat-button
            [hidden]="isLastPage"
            (click)="save()"
            type="button"
            class="primary white icon-btn mat-elevation-z16 ms-3">
      <span class="material-icons">save</span>
    </button>
    <button id="save-and-continue"
            mat-button
            [hidden]="isLastPage"
            (click)="saveAndContinue()"
            type="button"
            class="primary excessive mat-elevation-z16 ms-3">{{NavigateForward}}
    </button>
    <button id="download"
            mat-button
            type="button"
            [disabled]="false"
            (click)="download()"
            [hidden]="!isLastPage"
            class="primary icon-btn excessive mat-elevation-z16 ms-3">
      <!--  replace with  [disabled]="downloadBtnDisabled" -->
      <span class="material-icons">download</span>
      <span class="label">{{DownloadAd}}</span>
    </button>
  </div>
</div>
