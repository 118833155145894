import { Injectable } from '@angular/core';
import * as fromAdsActions from '../../state/ads/ads.actions';
import { HTMLAdComposite } from '../../ads/edit-ad/ad-reflection/ad-generate-html.directive';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class FileSizeService {
  constructor(private store: Store) {}

  public calculateAdSize(adId: number, htmlComposite: HTMLAdComposite): void {
    this.store.dispatch(
      fromAdsActions.calculateAdFileSize({ payload: [adId, htmlComposite] })
    );
  }
}
