import {Component, Input} from '@angular/core';
import {AdConfigurationItemModel} from '../../../shared/models/ad-configuration-item.model';

@Component({
  selector: 'app-ad-configuration-item',
  templateUrl: './ad-configuration-item.component.html',
  styleUrls: ['./ad-configuration-item.component.scss']
})
export class AdConfigurationItemComponent {
  @Input() public item!: AdConfigurationItemModel;
  @Input() public copyCallback?: (orderNr: number) => void;
  @Input() public removeCallback?: (orderNr: number) => void;
  @Input() public orderNr?: number;

  public showTooltip(): void {
    alert('show tooltip');
  }

  public execCopyCallback(): void {
    if (typeof this.copyCallback === 'function' && typeof this.orderNr !== 'undefined') {
      this.copyCallback(this.orderNr);
    }
  }

  public execRemoveCallback(): void {
    if (typeof this.removeCallback === 'function' && typeof this.orderNr !== 'undefined') {
      this.removeCallback(this.orderNr);
    }
  }
}
