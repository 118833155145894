export class AdConfigurationItemModel {
  public id = '';
  public title = '';
  public hasSubItems = false;
  public altered = false;
  public collapsed = true;
  public cloneable = false;
  public sortable = false;
  public removable = false;
}
