import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from './snack-bar.component';
import {SnackBarErrorDurationInSeconds, SnackBarSuccessDurationInSeconds} from '../data-repository/global-constants';

@Injectable()
export class SnackBarService {

  constructor(public snackBar: MatSnackBar) {
  }

  public openSnackBar(message: string, type: SnackBarType): void {
    const duration = type === SnackBarType.Info ? SnackBarSuccessDurationInSeconds : SnackBarErrorDurationInSeconds;
    this.snackBar.openFromComponent(SnackBarComponent, {
      panelClass: SnackBarType[type],
      duration: duration * 1000,
      data: {
        type,
        message,
        preClose: () => {
          this.snackBar.dismiss()
        }
      }
    });
  }
}

export enum SnackBarType {
  Info,
  Error,
}
