import { Component, OnDestroy, OnInit } from '@angular/core';

import { AdsService } from '../../../ads.service';
import { EditAdNavigationService } from '../../edit-ad-navigation.service';
import { SubSink } from 'subsink';
import { MatDialog } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import * as fromAdsActions from '../../../../state/ads/ads.actions';
import { map } from 'rxjs/operators';
import { AdboxRoutes } from '../../../../shared/data-repository/routes';
import { HttpOperation } from '../../../../shared/services/endpoint.service';
import * as NavWrapper from '../../../../shared/data-repository/page-mapping';
import { AdsInfoBoxService } from '../../../ads-info-box.service';
import { CommonEditAdBasicService } from '../../common-services/common-edit-ad-basic.service';
import { CommonEditAdDownloadService } from '../../common-services/common-edit-ad-download.service';
import { EditAdService } from '../../edit-ad.service';
import { Router } from '@angular/router';

@Component({
  templateUrl: './story-ad-download.component.html',
  styleUrls: ['./story-ad-download.component.scss'],
})
export class StoryAdDownloadComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  constructor(
    public commonEditAdDownloadService: CommonEditAdDownloadService,
    private adsService: AdsService,
    private editAdNavigationService: EditAdNavigationService,
    private dialog: MatDialog,
    private actions$: Actions,
    private router: Router,
    private editAdService: EditAdService,
    private commonEditAdBasicService: CommonEditAdBasicService,
    private adsInfoBoxService: AdsInfoBoxService
  ) {}

  ngOnInit(): void {
    this.commonEditAdDownloadService.initConfigurationItems();
    this.initEvents();
    this.commonEditAdDownloadService.downloadAdInfoBox =
      this.adsInfoBoxService.DownloadAdInfoBox;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private initEvents(): void {
    this.subs.sink = this.editAdNavigationService.adHtmlGenerated$.subscribe(
      ([htmlComposite, downloadZip]) => {
        if (downloadZip) {
          this.commonEditAdDownloadService.openDialog(
            this.dialog,
            htmlComposite
          );
        }
      }
    );

    this.subs.sink = this.editAdNavigationService.savedAdTriggered$.subscribe(
      () => {
        this.commonEditAdBasicService.adSaved(NavWrapper.Download, false);
      }
    );

    this.subs.sink = this.actions$
      .pipe(
        ofType(fromAdsActions.setAdDownload),
        map((result) => result.payload)
      )
      .subscribe((zip) => {
        this.commonEditAdDownloadService.downloadAd(zip);
      });

    this.subs.sink = this.actions$
      .pipe(
        ofType(fromAdsActions.NoOp),
        map((result) => result.payload)
      )
      .subscribe((httpOperation) => {
        if (
          httpOperation === HttpOperation.Put &&
          this.commonEditAdDownloadService.adDownloaded
        ) {
          this.router.navigate([AdboxRoutes.Ads, AdboxRoutes.MyAds]);
          this.commonEditAdDownloadService.adDownloaded = false;
        }
      });

    // Init form
    this.subs.sink = this.editAdService.adModelUpdated$.subscribe((adModel) => {
      if (!adModel) {
        throw new Error('Ad model is undefined.');
      }
      this.commonEditAdDownloadService.updateForm(adModel);
    });
  }
}
