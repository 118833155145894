import { Inject, Injectable } from '@angular/core';
import { filter, map, Observable, Subject, switchMap, tap } from 'rxjs';
import { FontModel } from '../models/font.model';
import { Store } from '@ngrx/store';
import * as fromFontsActions from '../../state/fonts/fonts.actions';
import { Actions, ofType } from '@ngrx/effects';
import { DOCUMENT } from '@angular/common';
import { SnackBarService, SnackBarType } from '../snack-bar/snack-bar.service';
import {
  ErrorOccurred,
  FileTypeNotSupported,
  FontSuccessfullyAdded,
  FontTooLarge,
} from '../data-repository/global-text-snippets';
import { selectFonts } from '../../state/fonts/fonts.selectors';
import { DropdownOption } from '../data-structures/dropdown-option';
import { FileTypesService } from '../files/file-types.service';
import { HttpOperation } from './endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class FontsService {
  public fontsFetched$ = new Subject<ReadonlyArray<FontModel>>();
  public fonts!: ReadonlyArray<FontModel>;
  private maxFontFileSize = 0;

  constructor(
    private store: Store,
    private snackBarService: SnackBarService,
    private fileTypeService: FileTypesService,
    private actions$: Actions
  ) {
    this.initEvents();
  }

  public selectFonts(): Observable<ReadonlyArray<FontModel>> {
    return this.store.select(selectFonts);
  }

  /*
    Retrieve selected fonts to attach them to the zip.
   */
  public retrieveSelectedFonts(
    selectedFonts: Array<string>
  ): ReadonlyArray<FontModel> | null {
    if (this.fonts && this.fonts.length > 0) {
      return this.fonts.filter((font) => selectedFonts.includes(font.title));
    }
    return null;
  }

  public fetchFonts(): void {
    this.store.dispatch(fromFontsActions.fetchFontList());
    this.actions$
      .pipe(
        ofType(fromFontsActions.setFontList),
        map((response) => response.payload)
      )
      .subscribe((fonts) => {
        this.fonts = fonts;
        this.fontsFetched$.next(fonts);
      });
  }

  public addFont(fontFile: File): Observable<HttpOperation> {
    const fileType = fontFile!.name.split('.').pop();
    return this.fileTypeService.getFontTypes().pipe(
      filter((acceptedFontTypes) => {
        return this.checkFontFile(acceptedFontTypes, fileType, fontFile);
      }),
      switchMap((_) => {
        this.store.dispatch(fromFontsActions.addFont({ payload: fontFile }));
        return this.actions$.pipe(
          ofType(fromFontsActions.NoOp),
          tap((_) => {
            this.snackBarService.openSnackBar(
              FontSuccessfullyAdded,
              SnackBarType.Info
            );
            this.fetchFonts();
          }),
          map((response) => response.payload)
        );
      })
    );
  }

  public toDropDownOption(
    font: FontModel | readonly FontModel[]
  ): DropdownOption[] {
    const fontArr = Array.isArray(font) ? font : [font];
    const fontTypeOptions = fontArr.map((font) => {
      return {
        key: font.title,
        value: font.title,
      };
    });
    return fontTypeOptions;
  }

  public getFormat(type: string): string {
    switch (type) {
      case 'ttf':
        return 'truetype';
      case 'otf':
        return 'opentype';
      default:
        return type;
    }
  }

  private initEvents() {
    this.fileTypeService
      .getMaxFontFileSize()
      .subscribe((maxFontFileSize) => (this.maxFontFileSize = maxFontFileSize));

    this.actions$
      .pipe(
        ofType(fromFontsActions.httpFail),
        map((response) => response.payload)
      )
      .subscribe((message: string) => {
        this.snackBarService.openSnackBar(message, SnackBarType.Error);
      });
  }

  private checkFontFile(
    acceptedFontTypes: ReadonlyArray<string>,
    fileType: string | undefined,
    fontFile: File
  ): boolean {
    if (!fileType || !acceptedFontTypes.includes(fileType.toLowerCase())) {
      this.snackBarService.openSnackBar(
        ErrorOccurred + FileTypeNotSupported,
        SnackBarType.Error
      );
      return false;
    }
    if (fontFile.size > this.maxFontFileSize * Math.pow(10, 6)) {
      const message = FontTooLarge + this.maxFontFileSize + ' MB';
      this.snackBarService.openSnackBar(message, SnackBarType.Error);
      return false;
    }
    return true;
  }
}

export enum FontSource {
  Custom,
  System,
  Link,
}
