import { Injectable } from '@angular/core';
import { Observable, of, Subject, take } from 'rxjs';
import { SlideModel } from '../../../shared/models/slide.model';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import * as fromSlidesActions from '../../../state/slides/slides.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { selectFilteredSlides } from '../../../state/slides/slides.selectors';
import {
  SnackBarService,
  SnackBarType,
} from '../../../shared/snack-bar/snack-bar.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StoryAdTypeDesignator } from '../../../shared/data-repository/global-constants';
import {
  EndpointService,
  HttpOperation,
} from '../../../shared/services/endpoint.service';
import { HttpErrorService } from '../../../shared/services/http-error.service';
import { AdBaseModel } from '../../../shared/models/ad.model';
import { CropperArea } from '../../../shared/models/cropper-area';

@Injectable({
  providedIn: 'root',
})
export class SlidesService {
  public slideModelsChanged$ = new Subject<Map<number, SlideModel>>();

  constructor(
    private store: Store,
    private snackBarService: SnackBarService,
    private actions$: Actions
  ) {
    this.initEvents();
  }

  public fetchSlides(
    slideableId: number
  ): Observable<ReadonlyArray<SlideModel>> {
    return this.store.select(selectFilteredSlides(slideableId)).pipe(
      take(1),
      switchMap((slides) => {
        if (typeof slides === 'undefined' || slides.length === 0) {
          this.store.dispatch(
            fromSlidesActions.fetchSlideList({
              slideableId,
              operation: HttpOperation.Get,
            })
          );
          return this.actions$.pipe(
            ofType(fromSlidesActions.setSlideList),
            map((response) => {
              if (!response) {
                throw new Error('Slides could not be fetched from server.');
              }
              return response.payload[0];
            })
          );
        } else {
          return of(slides);
        }
      })
    );
  }

  public addSlides(
    slides: SlideModel[],
    adId: number,
    slideableId: number
  ): void {
    this.store.dispatch(
      fromSlidesActions.addSlides({
        payload: [slides, adId, slideableId],
      })
    );
  }

  public duplicateSlides(
    originalAd: AdBaseModel,
    duplicatedAd: AdBaseModel
  ): void {
    this.store.dispatch(
      fromSlidesActions.duplicateSlides({ payload: [originalAd, duplicatedAd] })
    );
  }

  public executeFetchSlidesRequest(
    slideableId: number,
    endpointService: EndpointService,
    http: HttpClient,
    httpErrorService: HttpErrorService
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('slideable_id', slideableId);
    params = params.set('slideable_type', StoryAdTypeDesignator);

    const endpoint = endpointService.getSlidesSlug(HttpOperation.Get);
    return http.get<any>(endpoint, { params }).pipe(
      catchError((error) => {
        return httpErrorService.handleError('Slides', fromSlidesActions, error);
      })
    );
  }

  /**
   *
   * @param duplicateAd: Needed when ad is duplicated to set original images
   */
  public executePostSlidesRequest(
    slidesHttp: Record<string, any>,
    slideableId: number,
    endpointService: EndpointService,
    http: HttpClient,
    httpErrorService: HttpErrorService
  ): Observable<any> {
    const endpoint = endpointService.getSlidesSlug(HttpOperation.Post);
    let params = new HttpParams().set('slideable_id', slideableId);
    params = params.set('slideable_type', StoryAdTypeDesignator);

    slidesHttp = slidesHttp.map((slide: any) => {
      delete slide.slideable_type;
      delete slide.slideable_id;
      return slide;
    });

    return http.post<any>(endpoint, slidesHttp, { params }).pipe(
      map(() => {
        return fromSlidesActions.fetchSlideList({
          slideableId,
          operation: HttpOperation.Post,
        });
      }),
      catchError((error) => {
        return httpErrorService.handleError('Slides', fromSlidesActions, error);
      })
    );
  }

  public deleteIds(updatedSlideModel: SlideModel): void {
    delete updatedSlideModel.id;

    const updatedImageCropperArea = new CropperArea();
    Object.assign(updatedImageCropperArea, updatedSlideModel.imageCropperArea);
    delete updatedImageCropperArea.id;

    updatedSlideModel.imageCropperArea = updatedImageCropperArea;
  }

  private initEvents() {
    this.actions$
      .pipe(
        ofType(fromSlidesActions.httpFail),
        map((response) => response.payload)
      )
      .subscribe((message: string) => {
        this.snackBarService.openSnackBar(message, SnackBarType.Error);
      });
  }
}
