<form class="form configuration-items-wrapper"
      [formGroup]="commonEditAdDesignService.designTemplateForm"
      *ngIf="commonEditAdDesignService.designTemplateForm"
      [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">

  <!-- ############ Designvorlagen -->
  <app-ad-configuration-item [item]="designTemplateConfigurationItem">
    <div class="col-6 d-flex align-items-center">
      <mat-form-field appearance="fill">
        <mat-label>{{MyTemplates}}</mat-label>
        <mat-select
          formControlName="designTemplate">
          <mat-option value="1">
            Bergwandern
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col d-flex align-items-center">
      <button mat-button class="secondary icon-btn"><span class="material-icons">add_box</span>
        {{CreateTemplate}}
      </button>
    </div>
  </app-ad-configuration-item>

  <!-- ############ Logo -->
  <app-ad-configuration-item [id]="'logo'" [item]="logoConfigurationItem">
    <div class="col-12 mt-1">
      <div class="row mt-2">
        <div class="col-12 mt-1">
          <ng-container
            *ngIf="commonEditAdDesignService.logoBase64 === undefined || commonEditAdDesignService.logoBase64 === ''">
            <input type="text" formControlName="logo" hidden>
            <input
              #fileInput
              (change)="commonEditAdDesignService.logoChanged($event)"
              type="file"
              accept="image/*"
              hidden>
            <button ly-button
                    mat-button
                    (click)="fileInput.click()"
                    class="secondary icon-btn"><span class="material-icons">upload</span>{{UploadImage}}
            </button>
          </ng-container>
          <span id="logo-preview" class="position-relative"
            *ngIf="commonEditAdDesignService.logoBase64 !== undefined && commonEditAdDesignService.logoBase64 !== ''">
                      <img [src]="commonEditAdDesignService.logoBase64" height="70">
                      <div class="hoverable-icons position-absolute">
                        <a class="icon material-icons ms-2 text-decoration-none mat-elevation-z0 bg-white"
                           (click)="commonEditAdDesignService.resetLogo()">delete</a>
                        </div>
          </span>
        </div>

        <div class="col-12 mt-3 mb-2">
          <hr/>
        </div>

        <div class="col-12"><h6 class="mb-0">{{Direction}}</h6></div>
        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label>{{Horizontally}}</mat-label>
            <mat-select formControlName="horizontalLogoAlignment">
              <mat-option
                [value]="option.key"
                *ngFor="let option of HorizontalLogoAlignmentOptions">
                {{option.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label>{{Vertically}}</mat-label>
            <mat-select formControlName="verticalLogoAlignment">
              <mat-option
                [value]="option.key"
                *ngFor="let option of VerticalLogoAlignmentOptions">
                {{option.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-12"><h6 class="mb-0">{{Distance}}</h6></div>
        <div class="col-2">
          <mat-form-field appearance="fill">
            <mat-label>{{ Left }}</mat-label>
            <input matInput formControlName="logoLeft"/>
          </mat-form-field>
        </div>
        <div class="col-2">
          <mat-form-field appearance="fill">
            <mat-label>{{ Top }}</mat-label>
            <input matInput formControlName="logoTop"/>
          </mat-form-field>
        </div>
        <div class="col-2">
          <mat-form-field appearance="fill">
            <mat-label>{{ Bottom }}</mat-label>
            <input matInput formControlName="logoBottom"/>
          </mat-form-field>
        </div>
        <div class="col-2">
          <mat-form-field appearance="fill">
            <mat-label>{{ Right }}</mat-label>
            <input matInput formControlName="logoRight"/>
          </mat-form-field>
        </div>
      </div>
    </div>

  </app-ad-configuration-item>

  <!-- ############ Hintergrund -->
  <app-ad-configuration-item [item]="backgroundConfigurationItem">
    <div class="col-6 d-flex align-items-center">
      <app-color-picker
        [label]="Color"
        [control]="commonEditAdDesignService.designTemplateForm.controls.backgroundColorCtrl"
        [controlType]="AdDesignFormControls.Background"
        [component]="adDesignComponent"></app-color-picker>
    </div>
  </app-ad-configuration-item>

  <!-- ############ Texte -->
  <app-ad-configuration-item [item]="textConfigurationItem">
    <div class="col-12 d-flex align-items-center mt-3 mb-2">
      <button mat-button class="secondary icon-btn mt-1"
              (click)="fontInput.click()">
        <span class="material-icons">upload</span>
        {{UploadFont}}
      </button>
      <input #fontInput type="file" (change)="commonEditAdDesignService.uploadFont($event)" hidden>
      <span class="font-file-name ms-4"
            *ngIf="commonEditAdDesignService.fontFileName !== ''">{{commonEditAdDesignService.fontFileName}}</span>
    </div>

    <div class="col-12">
      <hr/>
    </div>

    <div class="col-12 mt-1">
      <div class="row mt-2">
        <div class="col-12"><h6 class="mb-0">{{Headline}}</h6></div>
        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label>{{FontType}}</mat-label>
            <mat-select formControlName="headlineFontType">
              <mat-option
                [value]="option.key"
                *ngFor="let option of commonEditAdDesignService.fontTypeOptions">
                {{option.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label>{{FontSize}}</mat-label>
            <mat-select formControlName="headlineFontSize">
              <mat-option [value]="option.key" *ngFor="let option of FontSizeOptions">
                {{option.value}}px
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <hr/>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mt-3"><h6 class="mb-0">{{Text}}</h6></div>
        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label>{{FontType}}</mat-label>
            <mat-select formControlName="textFontType">
              <mat-option
                [value]="option.key"
                *ngFor="let option of commonEditAdDesignService.fontTypeOptions">
                {{option.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-6">
          <mat-form-field appearance="fill">
            <mat-label>{{FontSize}}</mat-label>
            <mat-select formControlName="textFontSize">
              <mat-option [value]="option.key" *ngFor="let option of FontSizeOptions">
                {{option.value}}px
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

    </div>
  </app-ad-configuration-item>

  <!-- ############ Buttons -->
  <app-ad-configuration-item [item]="buttonConfigurationItem">
    <div class="col-12 mt-3">
      <div class="row">
        <div class="col-12"><h6 class="mb-0">CTA Button</h6></div>
        <div class="col-6">
          <mat-form-field appearance="fill" class="mb-0">
            <mat-label>{{FontType}}</mat-label>
            <mat-select formControlName="ctaButtonTextFontType">
              <mat-option
                [value]="option.key"
                *ngFor="let option of commonEditAdDesignService.fontTypeOptions">
                {{option.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-6">
          <mat-form-field appearance="fill" class="mb-0">
            <mat-label>{{FontSize}}</mat-label>
            <mat-select formControlName="ctaButtonTextSize">
              <mat-option [value]="option.key" *ngFor="let option of FontSizeOptions">
                {{option.value}}px
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <app-color-picker
            [label]="TextColor"
            [control]="commonEditAdDesignService.designTemplateForm.controls.ctaButtonTextColorCtrl"
            [controlType]="AdDesignFormControls.CtaBtnTextColor"
            [component]="adDesignComponent"></app-color-picker>
        </div>

        <div class="col-6">
          <app-color-picker
            [label]="BackgroundColor"
            [control]="commonEditAdDesignService.designTemplateForm.controls.ctaButtonBackgroundColorCtrl"
            [controlType]="AdDesignFormControls.CtaBtnBackgroundColor"
            [component]="adDesignComponent"></app-color-picker>
        </div>
      </div>

    </div>
  </app-ad-configuration-item>

  <app-edit-ad-navigation></app-edit-ad-navigation>

</form>
