import {Pipe, PipeTransform} from '@angular/core';
import {AdStatus} from '../data-structures/ad-data-structures';
import {AdStatusDone, AdStatusInProgress, AdStatusNew} from '../data-repository/global-text-snippets';

@Pipe({
  name: 'adStatus'
})
export class AdStatusPipe implements PipeTransform {

  transform(value: AdStatus): string | never {
    switch (value) {
      case AdStatus.AdStatusNew:
        return AdStatusNew;
      case AdStatus.AdStatusInProgress:
        return AdStatusInProgress;
      case AdStatus.AdStatusDone:
        return AdStatusDone;
      default:
        throw new Error('Unknown ad status.');
    }
  }

}
