import {Component, Input, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {ContextMenuItem} from './context-menu-item';
import {Position} from '../../data-structures/position';

@Component({
  selector: 'app-context-menu',
  exportAs: 'context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent {
  @ViewChild(MatMenuTrigger) public contextMenu!: MatMenuTrigger;
  @Input() disabledExpression!: (data: any) => boolean;
  public contextMenuPosition: Position = {x: 0, y: 0};

  onContextMenu(event: MouseEvent | PointerEvent,
                contextMenuItems: ContextMenuItem[],
                data: unknown) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX;
    this.contextMenuPosition.y = event.clientY;
    this.contextMenu.menuData = {'items': contextMenuItems, data};
    this.contextMenu.openMenu();
  }
}
