import {createAction, props} from '@ngrx/store';
import {OptionModel} from '../../shared/models/option.model';
import {AdBaseModel} from '../../shared/models/ad.model';
import {HttpOperation} from '../../shared/services/endpoint.service';

export const addOptions = createAction('[Options] Add Options', props<{ payload: [ReadonlyArray<OptionModel>, number, number] }>());
export const duplicateOptions = createAction('[Options] Duplicate Options', props<{ payload: [AdBaseModel, AdBaseModel] }>());
export const fetchOptionList = createAction('[Options] Fetch Options', props<{ optionableId: number, operation: HttpOperation }>());
export const setOptionList = createAction('[Options] Set Options', props<{ payload: [ReadonlyArray<OptionModel>, HttpOperation] }>());
export const httpFail = createAction('[Options] Http fail', props<{ payload: string }>());
