import { Component } from '@angular/core';
import { NewPromotionService } from '../../../new-promotion.service';
import {AbstractControl} from "@angular/forms";
import {getTinyMCEConfig} from "../../../../../utils/default-form-data-builder";

@Component({
  selector: 'app-text-block-expanded-view',
  templateUrl: './text-block-expanded-view.component.html',
  styleUrls: ['./text-block-expanded-view.component.scss'],
})
export class TextBlockExpandedViewComponent {
  public readonly tineMCEConfig = getTinyMCEConfig();

  constructor(public newPromotionService: NewPromotionService) {}

  closeCard() {
    this.newPromotionService.layoutCardExpanded = false;
  }

  toggleStyle(
    control: AbstractControl | null | undefined,
    values: { activeValue: any; defaultValue: any }
  ) {
    if (control?.value === values.activeValue) {
      control?.setValue(values.defaultValue);
    } else {
      control?.setValue(values.activeValue);
    }
  }
}
