import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { LyDialog } from '@alyle/ui/dialog';
import { UploadImage } from '../../data-repository/global-text-snippets';
import { SubSink } from 'subsink';
import { ImageService } from '../../services/image.service';
import { ImageAlteringDialogComponent } from '../../dialogs/image-altering-dialog/image-altering-dialog.component';
import { take } from 'rxjs';
import { IncludesImage } from '../../interfaces/includes-image';
import { PickerSlug, StorySlug } from '../../data-repository/api-slugs';
import { OriginalOptionImageType } from '../../../ads/edit-ad/edit-ad.service';
import { ImageCropperData } from '../../data-structures/types';
import { ImageComposite } from '../../data-structures/image';
import { map } from 'rxjs/operators';
import { CropperArea } from '../../models/cropper-area';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnDestroy {
  @Input() public imageWrapper!: IncludesImage;
  @Input() public imageCropped!: string | null;
  @Input() public imageOriginal!: string | undefined;
  @Input() public adTypeName!: string;
  @Input() public originalImageType!: OriginalOptionImageType;
  @Input() public imageCropperData!: ImageCropperData;
  @Input() public cropperArea!: CropperArea;
  @Output() public cropperResult$ = new EventEmitter<
    [ImageComposite | null, CropperArea]
  >(); //orig image and cropped image
  public UploadImage = UploadImage;

  private subs = new SubSink();

  constructor(
    private _dialog: LyDialog,
    private imageService: ImageService,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public openCropperDialog(): void | never {
    if (!this.imageWrapper) {
      throw new Error('Image wrapper is not defined.');
    }

    if (this.imageOriginal) {
      this.openDialog(this.imageOriginal);
      return;
    }

    let imageOriginal = null;
    let id = null;
    if (this.imageWrapper.imageOrigin.size > 0) {
      this.imageWrapper.imageOrigin.forEach((entry, key) => {
        const img = entry.get(this.originalImageType);
        if (typeof img !== 'undefined') {
          imageOriginal = img;
          id = key;
        }
      });
    }

    if (imageOriginal) {
      this.openDialog(imageOriginal);
    } else {
      let originalImageId =
        !this.imageWrapper.id || this.imageWrapper.id < 0
          ? id
          : this.imageWrapper.id;
      if (!originalImageId) {
        throw new Error('Original image id is not defined.');
      }
      this.fetchOriginalImage(originalImageId);
    }
  }

  public resetImage(): void {
    this.imageCropped = '';
    this.cropperArea = {
      id: this.cropperArea.id,
      x: 0,
      y: 0,
      rotate: 0,
      quality: 100,
      scale: 0,
    };
    this.cropperResult$.next([null, this.cropperArea]);
  }

  public openDialog(image: string | Event): void {
    if (!image) {
      throw new Error('Image data is invalid.');
    }

    this.imageService.originalImageData$.next(image);
    this.imageService.imageCropperConfig$.next([
      this.imageCropperData,
      this.cropperArea,
    ]);
    this.subs.sink = this._dialog
      .open<ImageAlteringDialogComponent, Event>(ImageAlteringDialogComponent, {
        width: 900,
        backdropClass: 'image-upload-backdrop',
        disableClose: true,
      })
      .afterClosed.pipe(
        map((result) => {
          return result
            ? [result[0], result[1], result[2]]
            : [null, null, null];
        })
      )
      .subscribe(([result, quality, originalImage]) => {
        if (result && result.dataURL) {
          this.imageCropped = result.dataURL;

          const imageComposite: ImageComposite = [
            originalImage,
            this.imageCropped,
            result.name,
          ];
          const updatedCropperArea: CropperArea = {
            id: this.cropperArea.id,
            quality,
            x: result.xOrigin,
            y: result.yOrigin,
            rotate: result.rotation,
            scale: result.scale.toFixed(3),
          };
          this.cropperResult$.next([imageComposite, updatedCropperArea]);
          this._cd.markForCheck();
        }
      });
  }

  private fetchOriginalImage(imageWrapperId: number): void | never {
    this.subs.sink = this.imageService
      .selectOriginalImage(imageWrapperId, this.adTypeName, [
        this.originalImageType,
      ])
      .pipe(take(1))
      .subscribe((image) => {
        let imageOriginal = undefined;
        if (this.adTypeName === StorySlug) {
          if (!image || !image.imageOriginal) {
            throw new Error('Story Ad original image is not defined.');
          }
          imageOriginal = image.imageOriginal;
        } else if (this.adTypeName === PickerSlug) {
          if (
            !image ||
            (!image.backgroundImageOriginal && !image.thumbImageOriginal)
          ) {
            throw new Error('Picker Ad original image is not defined.');
          }
          imageOriginal =
            this.originalImageType === OriginalOptionImageType.Background
              ? image.backgroundImageOriginal
              : image.thumbImageOriginal;
        }

        if (!imageOriginal) {
          throw new Error('Original image could not be set.');
        }

        this.openDialog(imageOriginal);
      });
  }
}
