export default class PickerAd {
  constructor() {
    this.selectedOptionOffset = 0;
    this.selectedOptionIndex = 0;
    this.carousel = null;
    this.thumbsContainer = null;
    this.next = null;
    this.prev = null;
    this.pickerItems = null;
    this.imageItems = null;
    this.width = 0;
    this.gap = 8;
    this.isInitialized = false;
  }

  resetAndStart() {
    this.carousel = document.querySelectorAll("#renderable #slider")[0];
    this.thumbsContainer = document.querySelectorAll("#renderable #options")[0];
    this.next = document.querySelectorAll("#renderable #next")[0]
    this.prev = document.querySelectorAll("#renderable #prev")[0];

    this.pickerItems = Array.from(document.querySelectorAll("#renderable .option"));
    this.imageItems = Array.from(document.querySelectorAll("#renderable .image"));
    const thumbs = Array.from(document.querySelectorAll("#renderable .option"));

    if (this.selectedOptionIndex >= this.imageItems.length) {
      this.selectedOptionIndex = 0;
    }

    for (let i = 0; i < this.imageItems.length; i++) {
      if (i !== this.selectedOptionIndex) {
        if(typeof this.imageItems[i] === 'undefined') {
          throw new Error('image items');
        }
        if(typeof thumbs[i] === 'undefined') {
          throw new Error('hmmm');
        }

        this.imageItems[i].classList.add('hidden');
        thumbs[i].classList.remove('selected');
      } else {
        this.imageItems[i].classList.remove('hidden');
        thumbs[i].classList.add('selected');
      }
    }

    this.width = this.carousel.offsetWidth;
    document.getElementById("options").style.left = '0';
    this.next.style.visibility = 'hidden';
    this.prev.style.visibility = 'hidden';

    this.addEventListener();
  }

  prevClicked() {
    const newselectedOptionOffset = parseInt(this.selectedOptionOffset) + this.width / 2;
    this.selectedOptionOffset = newselectedOptionOffset;

    this.thumbsContainer.style.left = this.selectedOptionOffset + "px";

    if (this.selectedOptionOffset >= 0) {
      this.prev.style.visibility = "hidden";
      this.thumbsContainer.style.left = "0px";
    }
    if (this.selectedOptionOffset > (-1) * (this.thumbsContainer.scrollWidth - this.width - this.gap)) {
      this.next.style.visibility = "visible";
    }
  }

  nextClicked() {
    const newselectedOptionOffset = parseInt(this.selectedOptionOffset) - this.width / 2;
    this.selectedOptionOffset = newselectedOptionOffset;

    if (this.selectedOptionOffset * (-1) > this.thumbsContainer.scrollWidth - this.width) {
      this.selectedOptionOffset = ((-1) * (this.thumbsContainer.scrollWidth - this.width));
    }
    this.thumbsContainer.style.left = this.selectedOptionOffset + "px";

    if (this.selectedOptionOffset !== 0) {
      this.prev.style.visibility = "visible";
    }
    if (this.selectedOptionOffset < (-1) * (this.thumbsContainer.scrollWidth - this.width - this.gap)) {
      this.next.style.visibility = "hidden";
    }
  }

  addEventListener() {
    if (this.prev && this.next && this.pickerItems.length > 0 && this.imageItems.length > 0) {
      if (this.pickerItems.length <= 4) {
        this.next.style.visibility = "hidden";
      } else {
        this.next.style.visibility = "visible";
      }

      if (!this.isInitialized) {
        this.prev.addEventListener('click', this.prevClicked.bind(this));
        this.next.addEventListener('click', this.nextClicked.bind(this));
        window.addEventListener("resize", e => (this.width = this.carousel.offsetWidth));
        this.isInitialized = true;
      }

      /* Picker */
      for (let i = 0; i < this.pickerItems.length; i++) {
        this.pickerItems[i].addEventListener("click", this.toggleOption.bind(this));
      }
    }
  }

  toggleOption($event) {
    const target = $event.currentTarget;
    for (let i = 0; i < this.pickerItems.length; i++) {
      if (this.pickerItems[i] === target) {
        this.selectedOptionIndex = i;
        this.pickerItems[i].classList.add('selected');
        this.imageItems[i].classList.remove('hidden');
      } else {
        this.pickerItems[i].classList.remove('selected');
        this.imageItems[i].classList.add('hidden');
      }
    }
  }
}

// @Object(PickerAd)
