import {Component, Inject} from '@angular/core';
import {Attention, Cancel, Ok} from '../../data-repository/global-text-snippets';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent {
  public Ok = Ok;
  public Cancel = Cancel;
  public Attention = Attention;
  public content = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data?.content) {
      this.content = data.content;
    }
  }
}
