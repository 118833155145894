import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdboxRoutes } from '../../../shared/data-repository/routes';
import { AdTypeModel } from '../../../shared/models/ad-type.model';
import { SubSink } from 'subsink';
import { AdTypeService } from '../../ad-type.service';
import { AdBaseModel } from '../../../shared/models/ad.model';
import { AdsService } from '../../ads.service';
import { SlideModel } from '../../../shared/models/slide.model';
import { Router } from '@angular/router';
import { RoutingHelperService } from '../../../shared/routing/routing-helper.service';
import { Actions, ofType } from '@ngrx/effects';
import { SnackBarService } from '../../../shared/snack-bar/snack-bar.service';
import { EditAdNavigationService } from '../edit-ad-navigation.service';
import { FontsService } from '../../../shared/services/fonts.service';
import {
  PickerSlug,
  StorySlug,
} from '../../../shared/data-repository/api-slugs';
import {
  GDNThresholdBinary,
  GDNThresholdDecimal,
  IABThresholdBinary,
  UrlSegmentDelimiter,
} from 'src/app/shared/data-repository/global-constants';
import {
  AdFileSize,
  Reload,
} from 'src/app/shared/data-repository/global-text-snippets';
import { MatDialog } from '@angular/material/dialog';
import { AdSizeInfoDialogComponent } from '../../../shared/dialogs/ad-size-info-dialog/ad-size-info-dialog.component';
import { FileSizeInfo } from '../../../shared/data-structures/file';
import * as fromAdActions from '../../../state/ads/ads.actions';
import { map } from 'rxjs/operators';
import { HtmlGeneratorService } from '../../../shared/services/html-generator.service';

@Component({
  selector: 'app-ad-reflection',
  templateUrl: './ad-reflection.component.html',
  styleUrls: ['./ad-reflection.component.scss'],
})
export class AdReflectionComponent implements OnInit {
  @Output() public cssEmitter = new EventEmitter<string>();
  @Input() public adModel!: AdBaseModel;
  @Input() public hideControls = false;
  public slides!: ReadonlyArray<SlideModel>;
  public adTypes: ReadonlyArray<AdTypeModel> = [];
  public css = '';
  public FileSizeInfo = FileSizeInfo;
  public fileSizeStatus = FileSizeInfo.OkForSome;
  public Reload = Reload;

  // Constants
  public AdFileSize = AdFileSize;
  public AdsRoute = AdboxRoutes.Ads;
  public UrlSegmentDelimiter = UrlSegmentDelimiter;
  public AdPreviewRoute = AdboxRoutes.AdPreview;
  public StorySlug = StorySlug;
  public PickerSlug = PickerSlug;

  public fileSize: number | null = null;

  private subs: SubSink = new SubSink();

  constructor(
    public adTypeService: AdTypeService,
    private editAdNavigationService: EditAdNavigationService,
    private routingHelperService: RoutingHelperService,
    private router: Router,
    private actions$: Actions,
    private fontsService: FontsService,
    private htmlGeneratorService: HtmlGeneratorService,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
    private adsService: AdsService
  ) {}

  ngOnInit(): void {
    this.initEvents();

    if (!this.adModel) {
      const segments = this.routingHelperService.getURLSegments(
        this.router.url
      );
      const adId = this.editAdNavigationService.parseAdIdFromRoute(segments);
      if (adId < 0) {
        this.router.navigate([AdboxRoutes.Ads, AdboxRoutes.MyAds]);
        return;
      }

      this.subs.sink = this.adsService
        .fetchAd(+adId)
        .subscribe(([adModel, _]) => {
          this.adModel = adModel;
        });
    }
  }

  public emitCSS(css: string) {
    this.cssEmitter.next(css);
  }

  public refresh(): void {
    this.editAdNavigationService.refreshPreviewClicked$.next(null);
  }

  public openFileSizeInfoDialog(): void {
    this.dialog.open(AdSizeInfoDialogComponent, {
      width: '640px',
      data: {
        fileSizeStatus: this.fileSizeStatus,
        fileSize: this.fileSize,
      },
    });
  }

  private initEvents() {
    this.subs.sink = this.adTypeService.getAdTypes().subscribe((adTypes) => {
      this.adTypes = adTypes;
    });

    this.subs.sink = this.fontsService.fontsFetched$.subscribe(() => {
      this.htmlGeneratorService.attachFonts();
    });

    this.subs.sink = this.actions$
      .pipe(
        ofType(fromAdActions.setAdFileSize),
        map((response) => response.payload)
      )
      .subscribe((fileSize) => {
        this.fileSize = fileSize;
        this.checkPublisherThresholds();
      });
  }

  private checkPublisherThresholds(): void {
    if (!this.fileSize) {
      this.fileSizeStatus = FileSizeInfo.OkForAny;
      return;
    }

    if (
      this.fileSize > IABThresholdBinary &&
      this.fileSize > GDNThresholdBinary
    ) {
      this.fileSizeStatus = FileSizeInfo.OkForAny;
    } else if (
      this.fileSize < IABThresholdBinary &&
      this.fileSize < GDNThresholdBinary
    ) {
      this.fileSizeStatus = FileSizeInfo.OkForAll;
    } else {
      this.fileSizeStatus = FileSizeInfo.OkForSome;
    }
  }
}
