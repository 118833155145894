import { createAction, props } from '@ngrx/store';
import { SlideModel } from '../../shared/models/slide.model';
import { AdBaseModel } from '../../shared/models/ad.model';
import { HttpOperation } from '../../shared/services/endpoint.service';

export const addSlides = createAction(
  '[Slides] Add Slides',
  props<{ payload: [ReadonlyArray<SlideModel>, number, number] }>()
);
export const duplicateSlides = createAction(
  '[Slides] Duplicate Slides',
  props<{ payload: [AdBaseModel, AdBaseModel] }>()
);
export const fetchSlideList = createAction(
  '[Slides] Fetch Slides',
  props<{ slideableId: number; operation: HttpOperation }>()
);
export const setSlideList = createAction(
  '[Slides] Set Slides',
  props<{ payload: [ReadonlyArray<SlideModel>, HttpOperation] }>()
);
export const httpFail = createAction(
  '[Slides] Http fail',
  props<{ payload: string }>()
);
