import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppBarService } from '../../layouts/admin-layout/components/app-bar/app-bar.service';
import { SubSink } from 'subsink';
import { PageKey } from '../../shared/data-structures/navigation-data';
import { ViewMode } from '../../shared/data-structures/view-mode';
import { AdBaseModel } from '../../shared/models/ad.model';
import { ContextMenuItem } from '../../shared/menus/context-menu/context-menu-item';
import { ActivatedRoute, Router } from '@angular/router';
import { AdboxRoutes } from '../../shared/data-repository/routes';
import {
  Archive,
  Delete,
  Duplicate,
  Edit,
  Filter,
  NewAd,
  Reporting,
  Show,
} from '../../shared/data-repository/global-text-snippets';
import { CreateAdDialogComponent } from '../../shared/dialogs/create-ad-dialog/create-ad-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AdsService } from '../ads.service';
import {
  SnackBarService,
  SnackBarType,
} from '../../shared/snack-bar/snack-bar.service';
import { HttpOperation } from '../../shared/services/endpoint.service';
import { Actions, ofType } from '@ngrx/effects';
import * as fromAdsActions from '../../state/ads/ads.actions';
import { AdStatus } from '../../shared/data-structures/ad-data-structures';
import {
  AdSuccessfullyCreated,
  AdSuccessfullyDeleted,
} from '../../shared/data-repository/snack-bar';
import { map } from 'rxjs';
import {
  FilterBaseableType,
  FilterStatus,
  UrlSegmentDelimiter,
} from '../../shared/data-repository/global-constants';
import { AdTypeService } from '../ad-type.service';
import { FilterMenuService } from '../../shared/menus/filter-menu/filter-menu.service';
import { SlidesService } from '../ad-template-types/story-ad/slides.service';
import { AdTypeModel } from '../../shared/models/ad-type.model';
import { OptionsService } from '../ad-template-types/picker-ad/options.service';

@Component({
  selector: 'app-my-ads',
  templateUrl: './my-ads.component.html',
  styleUrls: ['./my-ads.component.scss'],
})
export class MyAdsComponent implements OnInit, OnDestroy {
  public Filter = Filter;
  public selectedFilter = new Array<[string, string]>();
  public adTypes!: ReadonlyArray<AdTypeModel>;

  private sortAndFilter = this.filterMenuService.createDefaultSortAndFilter();

  public contextMenuItems: ContextMenuItem[] = [
    {
      symbol: 'edit',
      label: Edit,
      isDisabled: (ad: AdBaseModel) => {
        return ad.status === AdStatus.AdStatusDone;
      },
      clickHandler: (ad: AdBaseModel) => {
        this.subs.sink = this.adTypeService
          .getAdTypeName(ad)
          .subscribe((name) => {
            this.router.navigate(
              [
                this.AdsRoute,
                this.EditRoute,
                name,
                ad.id,
                this.EditDesignRoute,
              ],
              { state: { accessible: true } }
            );
          });
      },
    },
    {
      symbol: 'remove_red_eye',
      label: Show,
      isDisabled: () => {
        return false;
      },
      clickHandler: (ad: AdBaseModel) => {
        const url = this.router.serializeUrl(
          this.router.createUrlTree([
            UrlSegmentDelimiter,
            AdboxRoutes.Ads,
            AdboxRoutes.AdPreview,
            ad.id,
          ])
        );
        window.open(url, '_blank');
      },
    },
    {
      symbol: 'content_copy',
      label: Duplicate,
      isDisabled: () => {
        return false;
      },
      clickHandler: (ad: AdBaseModel) => {
        this.adsService.duplicateAd(ad);
      },
    },
    {
      symbol: 'delete_outline',
      label: Delete,
      isDisabled: () => {
        return false;
      },
      clickHandler: (ad: AdBaseModel) => {
        this.adsService.deleteAd(ad.id!);
      },
    },
    {
      symbol: 'inventory_2',
      label: Archive,
      isDisabled: () => {
        return false;
      },
      clickHandler: () => {
        this.router.navigate(['account', 'notifications']);
      },
    },
    {
      symbol: 'bar_chart',
      label: Reporting,
      isDisabled: () => {
        return false;
      },
      clickHandler: () => {
        this.router.navigate(['account', 'notifications']);
      },
    },
  ];

  public NewAd = NewAd;
  public ViewMode = ViewMode;
  public mode: ViewMode = ViewMode.Matrix;
  public AdsRoute = AdboxRoutes.Ads;
  public EditDesignRoute = AdboxRoutes.Design;
  public EditRoute = AdboxRoutes.Edit;
  public total!: number;

  private subs = new SubSink();

  constructor(
    private appBarService: AppBarService,
    private dialog: MatDialog,
    private adsService: AdsService,
    private slidesService: SlidesService,
    private optionsService: OptionsService,
    private adTypeService: AdTypeService,
    private filterMenuService: FilterMenuService,
    private actions$: Actions,
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initAdTypes();
    this.initChips();
    this.initEvents();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public createAd(): void {
    this.dialog.open(CreateAdDialogComponent, {
      width: '376px',
    });
  }

  public removeFilter(filter: [string, string]): void {
    const key = filter[0];
    const value = filter[1];

    if (key === FilterBaseableType || key === FilterStatus) {
      const filterMap = this.sortAndFilter.filter.get(key) as Map<
        string,
        string
      >;
      filterMap.delete(value);
    } else {
      // Ad title
      this.sortAndFilter.filter.delete(key);
    }

    // If there are empty filter maps left, remove them.
    const updatedFilter = this.filterMenuService.purgeFilter(
      this.sortAndFilter
    );

    this.sortAndFilter = {
      ...this.sortAndFilter,
      filter: updatedFilter,
    };
    this.filterMenuService.sortAndFilterChanged$.next(this.sortAndFilter);
  }

  private initEvents() {
    this.subs.sink = this.actions$
      .pipe(
        ofType(fromAdsActions.setAdsList),
        map((response) => response.payload)
      )
      .subscribe((payload) => {
        if (payload[1] === HttpOperation.Post) {
          this.filterMenuService.filterReset$.next(false);
          this.snackBarService.openSnackBar(
            AdSuccessfullyCreated,
            SnackBarType.Info
          );
        } else if (payload[1] === HttpOperation.Delete) {
          this.filterMenuService.filterReset$.next(false);
          this.snackBarService.openSnackBar(
            AdSuccessfullyDeleted,
            SnackBarType.Info
          );
        }
        this.total = payload[2];
      });

    this.subs.sink = this.appBarService.viewModeChanged.subscribe(
      ([page, mode]) => {
        if (page === PageKey.MyAds) {
          this.mode = mode;
        }
      }
    );

    // Duplicate ad
    this.subs.sink = this.actions$
      .pipe(
        ofType(fromAdsActions.setDuplicatedAd),
        map((response) => response.payload)
      )
      .subscribe(([originalAd, duplicatedAd]) => {
        this.snackBarService.openSnackBar(
          AdSuccessfullyCreated,
          SnackBarType.Info
        );
      });
  }

  private initAdTypes(): void {
    this.adTypeService.getAdTypes().subscribe((adTypes) => {
      this.adTypes = adTypes;
    });
  }

  private initChips(): void {
    this.subs.sink = this.filterMenuService.sortAndFilterChanged$.subscribe(
      (sortAndFilter) => {
        if (!sortAndFilter) {
          return;
        }
        this.sortAndFilter = sortAndFilter;
        this.selectedFilter = this.filterMenuService.flattenFilter(
          sortAndFilter.filter
        );
      }
    );

    this.filterMenuService.filterReset$.subscribe(() => {
      this.selectedFilter = [];
    });
  }
}
