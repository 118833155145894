<div class="container-fluid">
  <div class="d-flex mt-sm-0 mt-2 me-md-0 me-sm-4 justify-content-between"
       *ngIf="url !== undefined">
    <ng-container [ngSwitch]="appBarView">
      <ng-container *ngSwitchCase="AppBarView.MatrixListSwitch" [ngTemplateOutlet]="matrixListSwitch"></ng-container>
      <ng-container *ngSwitchCase="AppBarView.EditAd" [ngTemplateOutlet]="editAd"></ng-container>
      <ng-container *ngSwitchCase="AppBarView.ShowFilter" [ngTemplateOutlet]="filter"></ng-container>
      <ng-container *ngSwitchDefault [ngTemplateOutlet]="onlyTitle"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #matrixListSwitch>
  <a class="material-icons toggle-view me-1 text-decoration-none user-select-none"
     (click)="toggleView(PageKey.MyAds, ViewMode.Matrix)"
     [class.disabled]="viewMode === ViewMode.Table">apps</a>
  <a class="material-icons toggle-view me-3 text-decoration-none user-select-none"
     (click)="toggleView(PageKey.MyAds, ViewMode.Table)"
     [class.disabled]="viewMode === ViewMode.Matrix">menu</a>
  <h1 class="mat-headline mb-0">{{title}}</h1>
  <a class="material-icons filter ms-auto text-decoration-none user-select-none"
     (click)="openFilterMenu()">filter_list</a>
</ng-template>

<ng-template #editAd>
  <div class="left">
    <h1 class="mat-headline mb-0"
        *ngIf="!editModeEnabled">
      {{title}}
      <span id="edit-ad-title"
            class="material-icons ms-3"
            (click)="enableEditMode()">
      edit</span></h1>
    <div id="edit-ad-title-input"
         *ngIf="editModeEnabled">
      <input [(ngModel)]="title" #titleInput>
      <span id="save-updated-ad-title"
            class="material-icons ms-2"
            (click)="changeAdTitle()">save</span>
    </div>
    <h4 class="mb-0">{{type}}</h4>
  </div>
  <div class="right d-flex align-items-center">
    <app-breadcrumb-bar [adModel]="adModel"></app-breadcrumb-bar>
  </div>
</ng-template>

<ng-template #filter>
  <h1 class="mat-headline mb-0">{{title}}</h1>
  <a class="material-icons filter ms-auto text-decoration-none user-select-none"
     (click)="openFilterMenu()">filter_list</a>
</ng-template>


<ng-template #onlyTitle><h1 class="mat-headline mb-0">{{title}}</h1></ng-template>
