import {Component, ElementRef, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators,} from '@angular/forms';
import {AuthService} from '../../backend-services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  formGroup = new FormGroup({
    password: new FormControl('', [
      Validators.pattern(/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/),
    ]),
  });
  token = '';
  email = '';
  serverErrorMessage?: string = undefined;
  formIsSubmitting = false;
  success = false;

  @ViewChild('passwordElement') passwordElement: ElementRef | undefined;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.passwordControl.valueChanges.subscribe(() => {
      this.onPasswordChange();
    });

    this.route.queryParamMap.subscribe((params) => {
      const token = params.get('token');
      const email = params.get('email');

      if (!token || !email) {
        this.router.navigate(['/']);
      } else {
        this.token = token;
        this.email = email;
      }
    });

    // redirect to dashboard if logged in
    this.authService.isAuthenticated.subscribe((res) => {
      if (res) {
        this.router.navigate(['/']);
      }
    });
  }

  get passwordControl() {
    return this.formGroup.get('password') as AbstractControl;
  }

  onPasswordChange() {
    if (this.passwordControl.valid) {
      this.passwordElement?.nativeElement.setCustomValidity('');
    } else {
      this.passwordElement?.nativeElement.setCustomValidity('invalid');
    }
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    this.serverErrorMessage = undefined;
    this.success = false;

    if (this.formGroup.valid) {
      this.formIsSubmitting = true;
      this.formGroup.disable();
      this.authService
        .resetPasswordWithToken({
          new_password: this.passwordControl.value,
          token: this.token,
        })
        .subscribe({
          error: (res) => {
            console.log(res.error);
            if (res.error?.error === 'NOT_FOUND') {
              this.serverErrorMessage = res.error?.message;
            } else {
              this.serverErrorMessage = 'Something went wrong';
            }

            this.formIsSubmitting = false;
            this.formGroup.enable();
          },
          next: (res) => {
            if (res.success) {
              this.authService.login({
                password: this.passwordControl.value,
                email: this.email,
              });
            }

            this.formIsSubmitting = false;
            this.formGroup.enable();
            this.success = true;
          },
        });
    }
  }
}
