import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  AnimationDuration,
  AnimationDurationTitle,
  AnimationRepetitions,
  AnimationRepetitionsTitle,
  AnimationType,
  AnimationTypeTitle,
} from 'src/app/shared/data-repository/global-text-snippets';
import { AdConfigurationItemModel } from '../../../../shared/models/ad-configuration-item.model';
import { EditAdNavigationService } from '../../edit-ad-navigation.service';
import { SubSink } from 'subsink';
import { AdBaseModel, StoryAdModel } from '../../../../shared/models/ad.model';
import { ActivatedRoute } from '@angular/router';
import { AnimationTypeStoryOptions } from '../../../../shared/data-repository/dropdown-options';
import { numberValidator } from '../../../../shared/forms/custom-validator';
import { AdsService } from '../../../ads.service';
import { EditAdService } from '../../edit-ad.service';
import { Animations } from '../../../../shared/data-repository/page-mapping';
import { CommonEditAdBasicService } from '../../common-services/common-edit-ad-basic.service';

@Component({
  templateUrl: './story-ad-animation.component.html',
  styleUrls: ['./story-ad-animation.component.scss'],
})
export class StoryAdAnimationComponent implements OnInit, OnDestroy {
  // Constants
  public AnimationType = AnimationType;
  public AnimationTypeOptions = AnimationTypeStoryOptions;

  public animationTemplateForm!: FormGroup;
  public displayErroneousInputs = false;
  public AnimationDuration = AnimationDuration;
  public AnimationRepetitions = AnimationRepetitions;

  public animationFunctionConfigurationItem: AdConfigurationItemModel =
    new AdConfigurationItemModel();
  public animationDurationConfigurationItem: AdConfigurationItemModel =
    new AdConfigurationItemModel();
  public animationRepetitionConfigurationItem: AdConfigurationItemModel =
    new AdConfigurationItemModel();

  private subs = new SubSink();

  constructor(
    private editAdNavigationService: EditAdNavigationService,
    private editAdService: EditAdService,
    private activatedRoute: ActivatedRoute,
    private adsService: AdsService,
    private commonEditAdBasicService: CommonEditAdBasicService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initConfigurationItems();
    this.initEvents();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private initForm(adModel: AdBaseModel): void {
    const model = adModel;
    const baseable = model.baseable as StoryAdModel;

    this.animationTemplateForm = this.formBuilder.group({
      animationType: new FormControl(baseable.animationType),
      animationDuration: new FormControl(
        baseable.animationDuration,
        numberValidator({ min: 100 })
      ),
      animationRepetitions: new FormControl(
        baseable.repetitionCount,
        numberValidator({ min: 1 })
      ),
    });
    this.initFormCtrlChanges();
  }

  private initFormCtrlChanges(): void {
    this.animationTemplateForm.valueChanges.subscribe((formValues) => {
      if (!this.animationTemplateForm.valid) {
        this.editAdNavigationService.saveOrDownloadBtnDisabled$.next(true);
        return;
      }

      const curIndex = this.editAdNavigationService.getPageIndex();
      this.editAdNavigationService.editAdPagesSaved[curIndex] = false;

      const currentAdModel = this.commonEditAdBasicService.AdModel;
      const updatedModel = new AdBaseModel();
      Object.assign(updatedModel, currentAdModel);

      const baseable = new StoryAdModel();
      Object.assign(baseable, currentAdModel?.baseable);
      baseable.animationDuration = formValues.animationDuration;
      baseable.animationType = formValues.animationType;
      baseable.repetitionCount = formValues.animationRepetitions;

      updatedModel.baseable = baseable;
      this.editAdNavigationService.adModelChanged$.next(updatedModel);
      this.commonEditAdBasicService.AdModel = updatedModel;
      this.editAdNavigationService.saveOrDownloadBtnDisabled$.next(false);
      this.editAdService.adModelUserChange = true;
    });
  }

  private initConfigurationItems(): void {
    this.animationFunctionConfigurationItem.id = 'animation-function';
    this.animationFunctionConfigurationItem.title = AnimationTypeTitle;
    this.animationFunctionConfigurationItem.collapsed = false;

    this.animationDurationConfigurationItem.id = 'animation-duration';
    this.animationDurationConfigurationItem.title = AnimationDurationTitle;

    this.animationRepetitionConfigurationItem.id = 'animation-repetitions';
    this.animationRepetitionConfigurationItem.title = AnimationRepetitionsTitle;
  }

  private initEvents() {
    // Init form
    this.subs.sink = this.editAdService.adModelUpdated$.subscribe((adModel) => {
      if (!adModel) {
        throw new Error('Ad model is not defined.');
      }

      if (!this.animationTemplateForm) {
        this.initForm(adModel);
      }
    });

    this.subs.sink = this.editAdNavigationService.savedAdTriggered$.subscribe(
      () => {
        this.commonEditAdBasicService.adSaved(Animations, false);
      }
    );
  }
}
