<form
  [formGroup]="newPromotionService.selectedLayoutCard!.data"
  class="w-100 d-flex flex-column"
>
  <ul class="list-unstyled">
    <li>
      <div class="list-item-dropdown-title" style="background: #6e72eb">
        <div
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
        >
          <div
            class="d-flex justify-content-between align-items-center text-white"
          >
            <div style="font-weight: bold">Menüleiste</div>
            <button class="transparent-btn" (click)="closeCard()">
              <i class="material-icons">close</i>
            </button>
          </div>
        </div>
      </div>
    </li>
    <app-block-section title="BLOCK">
      <app-block-styles-view
        [rootFormGroup]="newPromotionService.selectedLayoutCard!.data"
      ></app-block-styles-view>
    </app-block-section>
    <app-block-section title="Menüleiste">
      <div class="w-100">
        <div
          class="form-check form-switch d-flex justify-content-start align-items-center gap-1 mb-1"
        >
          <input
            class="form-check-input switch-check-input"
            type="checkbox"
            role="switch"
            formControlName="show_logo"
            id="show-logo"
          />
          <label
            class="form-check-label"
            for="show-logo"
            style="font-size: 14px"
            >Logo</label
          >
        </div>
      </div>
      <app-menu-item-styles-view
        [rootFormGroup]="
          newPromotionService.selectedLayoutCard?.data?.get('styles_menu')
        "
      ></app-menu-item-styles-view>
    </app-block-section>
    <app-block-section title="LOGO">
      <app-image-styles-view-alt
        [rootFormGroup]="
          newPromotionService.selectedLayoutCard?.data?.get('logo')
        "
      ></app-image-styles-view-alt>
    </app-block-section>
    <app-block-section title="Menü Elemente">
      <ul
        class="w-100 ps-2"
        formArrayName="menu_elements"
        cdkDropList
        (cdkDropListDropped)="drop($event)"
      >
        <li
          class="w-100 mb-3"
          *ngFor="let current_element of menu_elements.controls; index as i"
          [formGroupName]="i"
        >
          <div class="w-100 list-item-dropdown-title" cdkDrag>
            <div
              style="letter-spacing: 0.7px; line-height: 16px"
              class="w-100 text-uppercase"
              role="button"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#menu-collapse-' + i"
              aria-expanded="false"
            >
              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="d-flex justify-content-start align-items-center gap-1 w-25"
                >
                  <button class="transparent-btn">
                    <i class="material-icons">drag_handle</i>
                  </button>
                  <button class="transparent-btn" (click)="duplicateMenuElement(i)">
                    <i class="material-icons">content_copy</i>
                  </button>
                  <button class="transparent-btn" (click)="deleteMenuElement(i)">
                    <i class="material-icons">delete</i>
                  </button>
                </span>
                <span class="w-75" style="color: #332e45; font-weight: bold"
                  >Navitem {{ i + 1 }}</span
                >
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="expand_less">
                      <path
                        id="Vector"
                        d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                        fill="#332E45"
                      />
                    </g>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div class="collapse" [id]="'menu-collapse-' + i">
            <div class="w-100 d-flex flex-column">
              <input class="form-control my-2" formControlName="content">
              <select class="form-control my-2" formControlName="heading_type">
                <option value="Abschnitt">Abschnitt</option>
                <option value="URL">URL</option>
              </select>
              <select class="form-control my-2" formControlName="url" *ngIf="current_element.get('heading_type')?.value === 'Abschnitt'">
                <option [value]="rootCard.value" *ngFor="let rootCard of rootCardList">{{ rootCard.title }}</option>
              </select>
              <input class="form-control my-2" formControlName="url" *ngIf="current_element.get('heading_type')?.value === 'URL'">
            </div>
          </div>
        </li>
      </ul>
      <button
        class="w-100 bg-white mt-2 mb-3 dashed-btn"
        (click)="addNewMenuElement()"
      >
        <i class="material-icons">add</i>
        <span class="ms-2 text-uppercase">NEUEs MEnü Element</span>
      </button>
    </app-block-section>
  </ul>
</form>
