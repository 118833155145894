import { Component } from '@angular/core';
import { NewPromotionService } from '../../../new-promotion.service';
import { UploadService } from '../../../../../backend-services/upload.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AbstractControl } from '@angular/forms';
import {getTinyMCEConfig} from "../../../../../utils/default-form-data-builder";

@Component({
  selector: 'app-image-banner-block-expanded-view',
  templateUrl: './image-banner-block-expanded-view.component.html',
  styleUrls: ['./image-banner-block-expanded-view.component.scss'],
})
export class ImageBannerBlockExpandedViewComponent {
  public readonly tineMCEConfig = getTinyMCEConfig();

  constructor(
    public newPromotionService: NewPromotionService,
    private uploadService: UploadService,
    public sanitizer: DomSanitizer
  ) {}


  closeCard() {
    this.newPromotionService.layoutCardExpanded = false;
  }

  toggleStyle(
    control: AbstractControl | null | undefined,
    values: { activeValue: any; defaultValue: any }
  ) {
    if (control?.value === values.activeValue) {
      control?.setValue(values.defaultValue);
    } else {
      control?.setValue(values.activeValue);
    }
  }

  handleImageChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.uploadService.uploadImage(file).subscribe({
        next: (res) => {
          if (res.success && res.data?.file) {
            this.newPromotionService
              .selectedLayoutCard!.data.get('image_file')
              ?.setValue(res.data.file);
            this.newPromotionService
              .selectedLayoutCard!.data.get('image_thumb_file')
              ?.setValue(res.data.thumb);
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
    }
  }

  deleteImageFile() {
    const file =
      this.newPromotionService.selectedLayoutCard!.data.get(
        'image_file'
      )?.value;
    if (file) {
      this.uploadService.deleteImage(file).subscribe({ error: console.log });
    }

    this.newPromotionService
      .selectedLayoutCard!.data.get('image_file')
      ?.setValue('');
    this.newPromotionService
      .selectedLayoutCard!.data.get('image_thumb_file')
      ?.setValue('');
  }
}
