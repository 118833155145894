import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { ImageModel } from '../models/image.model';
import * as fromImageActions from '../../state/images/images.actions';
import { SnackBarService, SnackBarType } from '../snack-bar/snack-bar.service';
import { switchMap, take } from 'rxjs/operators';
import { selectFilteredImage } from '../../state/images/images.selectors';
import { OriginalOptionImageType } from 'src/app/ads/edit-ad/edit-ad.service';
import { ImageCropperData } from '../data-structures/types';
import { CropperArea } from '../models/cropper-area';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  public originalImageData$ = new BehaviorSubject<string | Event | null>(null);
  public imageCropperConfig$ = new BehaviorSubject<
    [ImageCropperData | null, CropperArea | null]
  >([null, null]);

  constructor(
    private actions$: Actions,
    private snackBarService: SnackBarService,
    private store: Store
  ) {
    this.initEvents();
  }

  /**
   * Only update state
   */
  public updateOriginalImage(imageModel: ImageModel): void {
    this.store.dispatch(
      fromImageActions.updateOriginalImage({ payload: imageModel })
    );
  }

  public selectOriginalImage(
    imageWrapperId: number,
    adTypeName: string,
    types?: Array<OriginalOptionImageType>
  ): Observable<ImageModel> {
    if (!imageWrapperId) {
      throw new Error('Slide id not defined');
    }

    const origImage$ = this.store.select(
      selectFilteredImage(imageWrapperId, adTypeName, types)
    );
    return origImage$.pipe(
      take(1),
      switchMap((origImage) => {
        if (typeof origImage === 'undefined') {
          this.store.dispatch(
            fromImageActions.fetchOriginalImage({
              payload: [imageWrapperId, adTypeName, types],
            })
          );
          return this.actions$.pipe(
            ofType(fromImageActions.setOriginalImage),
            map((response) => {
              const image = response.payload;
              if (!image) {
                throw new Error(
                  'Original image could not be fetched from server.'
                );
              }
              return image;
            })
          );
        } else {
          return of(origImage);
        }
      })
    );
  }

  public rotateBase64Image90Degree(
    imageType: ImageType,
    base64data: string,
    degrees: number,
    callback: any
  ): void {
    const canvas = document.createElement('canvas');
    document.body.appendChild(canvas);
    const ctx = canvas!.getContext('2d');
    const image = new Image();
    image.src = base64data;
    image.onload = function () {
      var w = image.width;
      var h = image.height;
      var rads = (degrees * Math.PI) / 180;
      var c = Math.cos(rads);
      var s = Math.sin(rads);
      if (s < 0) {
        s = -s;
      }
      if (c < 0) {
        c = -c;
      }
      //use translated width and height for new canvas
      canvas.width = h * s + w * c;
      canvas.height = h * c + w * s;
      //draw the rect in the center of the newly sized canvas
      ctx!.translate(canvas.width / 2, canvas.height / 2);
      ctx!.rotate((degrees * Math.PI) / 180);
      ctx!.drawImage(image, -image.width / 2, -image.height / 2);
      callback(canvas!.toDataURL('image/' + imageType, 1));
      document.body.removeChild(canvas);
    };
  }

  public getImageType(base64: string): ImageType {
    const [, type] = base64.split(';')[0].split('/');
    switch (type) {
      case 'jpg':
        return ImageType.JPEG;
      case 'jpeg':
        return ImageType.JPEG;
      case 'png':
        return ImageType.PNG;
      default:
        throw new Error('Image type is not supported');
    }
  }

  private initEvents(): void {
    this.actions$
      .pipe(
        ofType(fromImageActions.httpFail),
        map((response) => response.payload)
      )
      .subscribe((message: string) => {
        this.snackBarService.openSnackBar(message, SnackBarType.Error);
      });
  }
}

export enum ImageType {
  JPEG = 'jpeg',
  PNG = 'png',
  UNDEFINED = 'undefined',
}
