<div
  class="container d-flex flex-column justify-content-between"
  style="min-height: 90vh"
>
  <div class="row pb-5">
    <div class="col-lg-6 mb-apd-0 mb-2">
      <div class="card box-shadow">
        <h3 class="p-2 text-uppercase border-bottom mb-0">Persönliche Daten</h3>
        <div class="d-flex justify-content-center align-items-center py-3 px-4">
          <div class="user-avatar">
            <app-profile-picture></app-profile-picture>
          </div>
        </div>
        <!-- form -->
        <form
          class="block__personal-data mx-3 mx-md-4 mx-lg-5 pb-5 ps-2 pe-2"
          [formGroup]="personalDataForm"
        >
          <div class="form-group mb-2 d-flex flex-column">
            <input
              class="form-control order-1"
              type="text"
              name="vorname"
              id="vorname"
              formControlName="first_name"
            />
            <label class="order-0" for="email"><span>Vorname</span></label>
            <div class="order-2 invalid-feedback">Ungültiges Vorname</div>
          </div>
          <!-- end form group -->
          <div class="form-group mb-2 d-flex flex-column">
            <input
              class="form-control order-1"
              type="text"
              name="nachname"
              id="nachname"
              formControlName="last_name"
            />
            <label class="order-0" for="email"><span>Nachname</span></label>
            <div class="order-2 invalid-feedback">Ungültiges Nachname</div>
          </div>
          <!-- end form group -->
          <div class="form-group d-flex flex-column">
            <input
              class="form-control order-1"
              type="email"
              name="email"
              id="email"
              formControlName="email"
            />
            <label class="order-0" for="email"><span>E-Mail</span></label>
            <div class="order-2 invalid-feedback">Ungültige E-Mail</div>
          </div>
        </form>
        <!-- form -->
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card box-shadow">
        <h3 class="p-2 text-uppercase border-bottom">Passwort</h3>
        <div class="p-2">
          <button
            class="btn btn-border-indigo btn-w-icon btn-password-change pw-btn-border"
            data-bs-toggle="modal"
            data-bs-target="#passwordChangeModal"
          >
            <i>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M15.4999 7.08333H14.6666V5.41667C14.6666 3.11667 12.7999 1.25 10.4999 1.25C8.19992 1.25 6.33325 3.11667 6.33325 5.41667V7.08333H5.49992C4.58325 7.08333 3.83325 7.83333 3.83325 8.75V17.0833C3.83325 18 4.58325 18.75 5.49992 18.75H15.4999C16.4166 18.75 17.1666 18 17.1666 17.0833V8.75C17.1666 7.83333 16.4166 7.08333 15.4999 7.08333ZM10.4999 14.5833C9.58325 14.5833 8.83325 13.8333 8.83325 12.9167C8.83325 12 9.58325 11.25 10.4999 11.25C11.4166 11.25 12.1666 12 12.1666 12.9167C12.1666 13.8333 11.4166 14.5833 10.4999 14.5833ZM13.0833 7.08333H7.91658V5.41667C7.91658 3.99167 9.07492 2.83333 10.4999 2.83333C11.9249 2.83333 13.0833 3.99167 13.0833 5.41667V7.08333Z"
                />
              </svg> </i
            ><span>Passwort ändern</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="action-btn-container">
    <div class="text-lg-end text-center">
      <button
        class="btn btn-indigo btn-indigo-2"
        [disabled]="personalDataFormIsSubmitting"
        (click)="saveProfileData()"
      >
        Profil speichern
      </button>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="passwordChangeModal"
    tabindex="-1"
    aria-labelledby="passwordChangeModal"
    aria-hidden="true"
    #passwordChangeModalBackdrop
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card box-shadow p-5 border-0 modal-content-inner">
          <div class="text-center mb-4">
            <a href="#" class="link__logo d-inline-block text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="72"
                height="72"
                viewBox="0 0 72 72"
                fill="none"
              >
                <path
                  d="M60 6H12C9 6 6 8.7 6 12V21.03C6 23.19 7.29 25.05 9 26.1V60C9 63.3 12.3 66 15 66H57C59.7 66 63 63.3 63 60V26.1C64.71 25.05 66 23.19 66 21.03V12C66 8.7 63 6 60 6ZM57 60H15V27H57V60ZM60 21H12V12H60V21Z"
                  fill="#332E45"
                />
                <path d="M45 36H27V42H45V36Z" fill="#332E45" />
              </svg>
              <strong class="d-block">AdBox</strong>
              <span>Ad & Promotionbuilder</span>
            </a>
          </div>
          <!-- end top block -->
          <h3 class="form-name mb-1">Passwort vergessen</h3>
          <form
            action="#"
            class="needs-validation"
            novalidate
            [formGroup]="passwordDataForm"
            (submit)="savePassword()"
          >
            <div
              class="form-group mb-2 d-flex flex-column"
              [ngClass]="{
                'was-validated': passwordControl.touched
              }"
            >
              <input
                class="form-control order-1"
                type="password"
                name="passwordElement"
                id="passwordElement"
                required
                formControlName="password"
                #passwordElement
              />
              <label class="order-0" for="passwordElement"
                ><span>Altes Passwort</span></label
              >
              <div class="order-2 invalid-feedback mb-n1">
                Ungültiges Passwort
              </div>
            </div>
            <!-- end group -->
            <div
              class="form-group mb-2 d-flex flex-column"
              [ngClass]="{
                'was-validated': newPasswordControl.touched
              }"
            >
              <input
                class="form-control order-1"
                type="password"
                name="newPasswordElement"
                id="newPasswordElement"
                required
                #newPasswordElement
                formControlName="new_password"
              />
              <label class="order-0" for="newPasswordElement"
                ><span>Neues Passwort</span></label
              >
              <div class="order-2 invalid-feedback mb-n1">
                Das Passwort muss mindestens 8 Zeichen lang sein, einen
                Großbuchstaben und ein Sonderzeichen enthalten.
              </div>
            </div>
            <!-- end group -->
            <div
              class="form-group mb-2 d-flex flex-column position-relative"
              [ngClass]="{
                'was-validated': confirmPasswordControl.touched
              }"
            >
              <input
                class="form-control order-1"
                type="password"
                name="confirmPasswordElement"
                id="confirmPasswordElement"
                required
                formControlName="confirm_password"
                #confirmPasswordElement
              />
              <label class="order-0" for="confirmPasswordElement"
                ><span>Passwort wiederholen</span></label
              >
              <div class="order-2 invalid-feedback mb-n1">
                Passwörter stimmen nicht überein
              </div>
            </div>
            <!-- end group -->
            <div class="text-form mb-2">
              <i
                >Hinweis: Das Passwort muss mindestens 8 Zeichen lang sein,
                einen Großbuchstaben und ein Sonderzeichen enthalten</i
              >.
            </div>
            <div class="form-group">
              <button
                class="btn btn-indigo w-100 text-uppercase"
                type="submit"
                [disabled]="passwordDataForm.disabled"
              >
                Passwort Ändern
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
