<div class="container-fluid">
  <!-- Cards -->
  <section class="row">
    <ng-container *ngFor="let ad of adTypes">
      <div class="card-wrapper col-xxl-3 col-xl-4 col-sm-6 mb-xl-0 mb-0">
        <div class="card light-blue">
          <div class="card-header text-start">
            <img [src]="sanitizer.bypassSecurityTrustResourceUrl(ad.symbol)">
          </div>
          <div class="card-body text-start">
            <h2 class="mat-title title">{{ad.title}}</h2>
            <h4>{{ad.subline}}</h4>
          </div>
          <div class="card-footer d-flex justify-content-end align-items-center">
            <a class="icon material-icons text-decoration-none eye-btn position-relative"
               [routerLink]="UrlSegmentDelimiter + AdboxRoutes.Ads + UrlSegmentDelimiter + AdboxRoutes.TemplateGallery"
               [queryParams]="{t:ad.title}">remove_red_eye</a>
            <a class="icon material-icons text-decoration-none d-inline-block add" (click)="openDialog(ad)">add</a>
          </div>
        </div>
      </div>
    </ng-container>
    <a (click)="openDialog()"
      class="card-wrapper text-decoration-none d-block col-xxl-3 col-xl-4 col-sm-6 mb-xl-0 mb-0">
      <div class="card disabled">
        <div class="card-body text-start d-flex justify-content-center align-items-center flex-column">
          <h2 class="mat-h2 mb-3">{{NewAd}}</h2>
          <div class="icon material-icons">add</div>
        </div>
      </div>
    </a>
  </section>

  <!-- Info boxes -->
  <section class="row mt-4">
    <ng-container *ngFor="let infoBox of infoBoxes">
      <div class="card-wrapper col-xxl-4 col-xl-6 col-sm-6 mb-xl-0 mb-0">
        <app-info-box [infoBox]="infoBox"></app-info-box>
      </div>
    </ng-container>
  </section>
</div>
