import { Component, Input } from '@angular/core';
import { LayoutCard } from '../../new-promotion.service';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-text-block-preview',
  templateUrl: './text-block-preview.component.html',
  styleUrls: ['./text-block-preview.component.scss'],
})
export class TextBlockPreviewComponent {
  @Input() card!: LayoutCard;

  constructor(public sanitizer: DomSanitizer) {}

  get cardValues() {
    return this.card.data.value;
  }
}
