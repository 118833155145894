<div class="container mt-3">
  <div class="row">
    <div class="col-xxl-6 col-xl-5 col-sm-5">
      <app-ad-reflection></app-ad-reflection>
    </div>
    <div class="col-xxl-6 col-xl-7 col-sm-7 d-flex flex-column" appEditAd data-html2canvas-ignore="true">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<ng-template #overlayTemplate>
  <mat-spinner [diameter]="diameter" [mode]="mode"></mat-spinner>
</ng-template>

