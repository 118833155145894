import { Injectable } from '@angular/core';
import {
  FileNotParsable,
  FileTypeNotSupported,
  ImageTooLarge,
} from '../data-repository/global-text-snippets';
import { FileTypesService } from './file-types.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  public base64Encoded$ = new Subject<string>();
  private maxImageFileSize = 0;
  private reader = new FileReader();

  constructor(private fileTypeService: FileTypesService) {
    this.fileTypeService
      .getMaxImageFileSize()
      .subscribe(
        (maxImageFileSize) => (this.maxImageFileSize = maxImageFileSize)
      );
  }

  public toBase64(
    $event: Event,
    success: (result: string, fileName: string) => void,
    errorFn: (message: string) => void,
    acceptedFileTypes: string[]
  ): void {
    const file = ($event.target as HTMLInputElement).files![0];
    if (!file) {
      throw new Error('file is not defined.');
    }

    if (file.size > this.maxImageFileSize * Math.pow(10, 6)) {
      const message = ImageTooLarge + this.maxImageFileSize + ' MB';
      errorFn(message);
      return;
    }

    const fileType = file!.name.split('.').pop();
    if (!fileType || !acceptedFileTypes.includes(fileType.toLowerCase())) {
      errorFn(FileTypeNotSupported);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (!reader.result) {
        errorFn(FileNotParsable);
        return;
      }

      const result = reader.result.toString();
      success(result, file.name);
    };
    reader.onerror = function (error) {
      errorFn(FileNotParsable);
    };
  }

  public toBase64FromBlob(blob: Blob): void {
    this.reader.readAsDataURL(blob);
    this.reader.onloadend = () => {
      this.base64Encoded$.next(this.reader.result as string);
    };
  }

  public toBlob(b64Data: string, contentType = '', sliceSize = 512): Blob {
    const byteCharacters = atob(
      b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')
    );
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
