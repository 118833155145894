import { Component, Input, OnInit } from '@angular/core';
import { LayoutCard } from '../../new-promotion.service';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-image-block-preview',
  templateUrl: './image-block-preview.component.html',
  styleUrls: ['./image-block-preview.component.scss'],
})
export class ImageBlockPreviewComponent {
  @Input() card!: LayoutCard;

  constructor(public sanitizer: DomSanitizer) {}

  get cardValues() {
    return this.card.data.value;
  }
}
