import {Component} from '@angular/core';

@Component({
  selector: 'app-promotions-overview',
  templateUrl: './promotions-overview.component.html',
  styleUrls: ['./promotions-overview.component.scss']
})
export class PromotionsOverviewComponent {

}
