import {NavigationWrapper, PageKey, PageMap,} from '../data-structures/navigation-data';
import {AdboxRoutes} from './routes';

///#### Dashboard ###///
export const Dashboard: NavigationWrapper = {
  title: 'Dashboard',
  route: AdboxRoutes.Dashboard,
};

///#### Promotions ###///
export const AllPromotions: NavigationWrapper = {
  title: 'Promotions Übersicht',
  route: AdboxRoutes.AllPromotions,
};

export const NewPromotion: NavigationWrapper = {
  title: 'Neue Promotion',
  route: AdboxRoutes.NewPromotion,
};

///#### Ads ###///
export const NewAd: NavigationWrapper = {
  title: 'Neues Ad',
  route: AdboxRoutes.NewAd,
};

export const MyAds: NavigationWrapper = {
  title: 'Meine Ads',
  route: AdboxRoutes.MyAds,
};

export const MyDesignTemplates: NavigationWrapper = {
  title: 'Meine Designvorlagen',
  route: AdboxRoutes.MyDesignTemplates,
};

export const Edit: NavigationWrapper = {
  title: '',
  route: AdboxRoutes.Edit,
};

export const Design: NavigationWrapper = {
  title: 'Design',
  route: AdboxRoutes.Design,
};

export const Content: NavigationWrapper = {
  title: 'Inhalt',
  route: AdboxRoutes.Content,
};

export const Animations: NavigationWrapper = {
  title: 'Animation',
  route: AdboxRoutes.Animation,
};

export const Download: NavigationWrapper = {
  title: 'Download',
  route: AdboxRoutes.Download,
};

export const AdPreview: NavigationWrapper = {
  title: 'Vorschau',
  route: AdboxRoutes.AdPreview,
};

export const AnalysisAndTracking: NavigationWrapper = {
  title: 'Analysis & Tracking',
  route: AdboxRoutes.AnalysisAndTracking,
};

export const Archive: NavigationWrapper = {
  title: 'Archiv',
  route: AdboxRoutes.Archive,
};

export const TemplateGallery: NavigationWrapper = {
  title: 'Template Galerie',
  route: AdboxRoutes.TemplateGallery,
};

export const MyProfile: NavigationWrapper = {
  title: 'Mein Profil',
  route: AdboxRoutes.MyProfile,
};

export const UsersList: NavigationWrapper = {
  title: 'Benutzerverwaltung',
  route: AdboxRoutes.UserManagement,
};

export const ManageSingleUser: NavigationWrapper = {
  title: 'Benutzerprofil',
  route: AdboxRoutes.ManageSingleUser
}

export const ManageSingleCustomer: NavigationWrapper = {
  title: 'Kundenprofil',
  route: AdboxRoutes.ManageSingleCustomer
}

export const pageMap = new PageMap<PageKey, NavigationWrapper>([
  [PageKey.Dashboard, Dashboard],
  [PageKey.AllPromotions, AllPromotions],
  [PageKey.NewPromotion, NewPromotion],
  [PageKey.NewAd, NewAd],
  [PageKey.MyAds, MyAds],
  [PageKey.MyDesignTemplates, MyDesignTemplates],
  [PageKey.Edit, Edit],
  [PageKey.EditDesign, Design],
  [PageKey.EditContent, Content],
  [PageKey.EditAnimation, Animations],
  [PageKey.EditDownload, Download],
  [PageKey.EditDownload, Download],
  [PageKey.AnalysisAndTracking, AnalysisAndTracking],
  [PageKey.AdPreview, AdPreview],
  [PageKey.Archive, Archive],
  [PageKey.TemplateGallery, TemplateGallery],
  [PageKey.MyProfile, MyProfile],
  [PageKey.UsersList, UsersList],
  [PageKey.ManageSingleUser, ManageSingleUser],
  [PageKey.ManageSingleCustomer, ManageSingleCustomer],
]);
