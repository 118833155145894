<div
  class="text-container"
  [ngStyle]="{
    background:
      cardValues.background_type === 'color'
        ? cardValues.background_color
        : 'url(\'' + cardValues.background_image_file + '\')',
    width: cardValues.width + '%',
    'padding-left': cardValues.paddingLeft + cardValues.marginUnit,
    'padding-top': cardValues.paddingTop + cardValues.marginUnit,
    'padding-right': cardValues.paddingRight + cardValues.marginUnit,
    'padding-bottom': cardValues.paddingBottom + cardValues.marginUnit
  }"
>
  <div class="row">
    <div class="col-12 col-md-6">
      <p
        class="my-3"
        [ngStyle]="{
          'font-family': cardValues.style_text.fontFamily,
          'font-size': cardValues.style_text.fontSize + 'px',
          'line-height': cardValues.style_text.lineHeight
            ? cardValues.style_text.lineHeight
            : 'normal',
          color: cardValues.style_text.color,
          width:
            cardValues.style_column.width + cardValues.style_column.marginUnit
        }"
        [innerHTML]="sanitizer.bypassSecurityTrustHtml(cardValues.content)"
      ></p>
    </div>
    <div class="col-12 col-md-6">
      <div
        [ngStyle]="{
          'text-align': cardValues.style_image.alignSelf,
          width:
            cardValues.style_column.width + cardValues.style_column.marginUnit
        }"
      >
        <img
          [src]="
            sanitizer.bypassSecurityTrustResourceUrl(
              '/api/image/' + cardValues.image_file
            )
          "
          class="responsize-image"
          [ngStyle]="{
            width: cardValues.style_image.width + '%'
          }"
          [alt]="cardValues.image_title"
          [title]="cardValues.image_title"
        />
      </div>
    </div>
  </div>
</div>
