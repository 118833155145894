import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NavigationWrapper } from '../../shared/data-structures/navigation-data';
import {
  Animations,
  Content,
  Design,
  Download,
} from '../../shared/data-repository/page-mapping';
import { RoutingHelperService } from '../../shared/routing/routing-helper.service';
import { AdBaseModel } from '../../shared/models/ad.model';
import { HTMLAdComposite } from './ad-reflection/ad-generate-html.directive';
import { UnknownAdId } from '../../shared/data-repository/global-text-snippets';
import {
  SnackBarService,
  SnackBarType,
} from '../../shared/snack-bar/snack-bar.service';
import { UrlSegment } from '@angular/router';
import { PickerSlug, StorySlug } from '../../shared/data-repository/api-slugs';

@Injectable({
  providedIn: 'root',
})
export class EditAdNavigationService {
  public editAdPagesSaved = [true, true, true, true];
  public adModelChanged$ = new Subject<AdBaseModel>();
  public savedAdTriggered$ = new Subject();
  public downloadInitiated$ = new Subject();
  public adHtmlGenerated$ = new Subject<[HTMLAdComposite, boolean]>(); // Zip file was generated or file size was calculated
  public refreshPreviewClicked$ = new Subject(); // Refresh preview button was clicked
  public adModelSaved$ = new Subject<[AdBaseModel, boolean]>();
  public thumbnailGenerationInitiated$ = new Subject<AdBaseModel>();
  public thumbnailGenerated$ = new Subject<boolean>();
  public saveOrDownloadBtnDisabled$ = new Subject<boolean>();

  private storyAdEditAdPages: NavigationWrapper[] = [
    Design,
    Content,
    Animations,
    Download,
  ];
  private pickerAdEditAdPages: NavigationWrapper[] = [
    Design,
    Content,
    Animations,
    Download,
  ];

  constructor(
    private routingHelperService: RoutingHelperService,
    private snackBarService: SnackBarService
  ) {}

  public getEditViewPages(adType: string) {
    switch (adType) {
      case StorySlug:
        return this.storyAdEditAdPages;
      case PickerSlug:
        return this.pickerAdEditAdPages;
      default:
        throw new Error('Ad type not supported');
    }
  }

  public nextPage(): [NavigationWrapper | null, number] {
    const index = this.getPageIndex();
    let nextIndex = -1;
    let nextPage = null;

    if (index >= 0 && index + 1 <= this.storyAdEditAdPages.length) {
      nextIndex = index + 1;
      nextPage = this.storyAdEditAdPages[nextIndex];
    }
    return [nextPage, nextIndex];
  }

  public prevPage(): [NavigationWrapper | null, number] {
    const index = this.getPageIndex();
    let prevIndex = -1;
    let prevPage = null;

    if (index > 0) {
      prevIndex = index - 1;
      prevPage = this.storyAdEditAdPages[prevIndex];
    }
    return [prevPage, prevIndex];
  }

  public isFirstPage(): boolean {
    const index = this.getPageIndex();
    return index >= 0 && index === 0;
  }

  public isLastPage(): boolean {
    const index = this.getPageIndex();
    return index >= 0 && index === this.storyAdEditAdPages.length - 1;
  }

  public getPageIndex(): number {
    const curRoute = this.routingHelperService.currentPage;
    let index = -1;
    if (curRoute) {
      index = this.storyAdEditAdPages.findIndex((p) => p.route === curRoute);
    }
    return index;
  }

  public parseAdIdFromRoute(segments: UrlSegment[] | null): number {
    if (!segments || segments.length !== 5) {
      return -1;
    }
    const adId = +segments[3].path;
    if (!adId || isNaN(adId)) {
      this.snackBarService.openSnackBar(UnknownAdId, SnackBarType.Error);
      return -1;
    }
    return adId;
  }
}
