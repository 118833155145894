import { Injectable } from '@angular/core';
import { Observable, of, Subject, take } from 'rxjs';
import { OptionModel } from '../../../shared/models/option.model';
import { selectFilteredOptions } from '../../../state/options/options.selectors';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromOptionsActions from '../../../state/options/options.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  SnackBarService,
  SnackBarType,
} from '../../../shared/snack-bar/snack-bar.service';
import { AdBaseModel } from '../../../shared/models/ad.model';
import {
  EndpointService,
  HttpOperation,
} from '../../../shared/services/endpoint.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpErrorService } from '../../../shared/services/http-error.service';
import { PickerAdTypeDesignator } from '../../../shared/data-repository/global-constants';
import { CropperArea } from '../../../shared/models/cropper-area';

@Injectable({
  providedIn: 'root',
})
export class OptionsService {
  public optionModelsChanged$ = new Subject<Map<number, OptionModel>>();

  constructor(
    private store: Store,
    private snackBarService: SnackBarService,
    private actions$: Actions
  ) {
    this.initEvents();
  }

  public fetchOptions(
    optionableId: number
  ): Observable<ReadonlyArray<OptionModel>> {
    return this.store.select(selectFilteredOptions(optionableId)).pipe(
      take(1),
      switchMap((options) => {
        if (typeof options === 'undefined' || options.length === 0) {
          this.store.dispatch(
            fromOptionsActions.fetchOptionList({
              optionableId,
              operation: HttpOperation.Get,
            })
          );
          return this.actions$.pipe(
            ofType(fromOptionsActions.setOptionList),
            map((response) => {
              if (!response) {
                throw new Error('Options could not be fetched from server.');
              }
              return response.payload[0];
            })
          );
        } else {
          return of(options);
        }
      })
    );
  }

  public addOptions(
    options: OptionModel[],
    adId: number,
    optionableId: number
  ): void {
    this.store.dispatch(
      fromOptionsActions.addOptions({
        payload: [options, adId, optionableId],
      })
    );
  }

  public duplicateOptions(
    originalAd: AdBaseModel,
    duplicatedAd: AdBaseModel
  ): void {
    this.store.dispatch(
      fromOptionsActions.duplicateOptions({
        payload: [originalAd, duplicatedAd],
      })
    );
  }

  /**
   *
   * @param duplicateAd: Needed to clone original images when duplicate an ad.
   */
  public executeFetchOptionsRequest(
    optionableId: number,
    endpointService: EndpointService,
    http: HttpClient,
    httpErrorService: HttpErrorService
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('optionable_id', optionableId);
    params = params.set('optionable_type', PickerAdTypeDesignator);

    const endpoint = endpointService.getOptionsSlug(HttpOperation.Get);
    return http.get<any>(endpoint, { params }).pipe(
      catchError((error) => {
        return httpErrorService.handleError(
          'Options',
          fromOptionsActions,
          error
        );
      })
    );
  }

  /**
   *
   * @param duplicateAd: Needed when ad is duplicated to set original images
   */
  public executePostOptionsRequest(
    optionsHttp: Record<string, any>,
    optionableId: number,
    endpointService: EndpointService,
    http: HttpClient,
    httpErrorService: HttpErrorService
  ): Observable<any> {
    const endpoint = endpointService.getOptionsSlug(HttpOperation.Post);
    let params = new HttpParams().set('optionable_id', optionableId);
    params = params.set('optionable_type', PickerAdTypeDesignator);

    optionsHttp = optionsHttp.map((option: any) => {
      delete option.optionable_type;
      delete option.optionable_id;
      return option;
    });

    return http.post<any>(endpoint, optionsHttp, { params }).pipe(
      map(() => {
        return fromOptionsActions.fetchOptionList({
          optionableId,
          operation: HttpOperation.Post,
        });
      }),
      catchError((error) => {
        return httpErrorService.handleError(
          'Options',
          fromOptionsActions,
          error
        );
      })
    );
  }

  public deleteIds(updatedOptionModel: OptionModel): void {
    delete updatedOptionModel.id;

    const updatedThumbImageCropperArea = new CropperArea();
    Object.assign(
      updatedThumbImageCropperArea,
      updatedOptionModel.thumbImageCropperArea
    );
    delete updatedThumbImageCropperArea.id;

    const updatedBackgroundImageCropperArea = new CropperArea();
    Object.assign(
      updatedBackgroundImageCropperArea,
      updatedOptionModel.infoBackgroundImageCropperArea
    );
    delete updatedBackgroundImageCropperArea.id;

    updatedOptionModel.thumbImageCropperArea = updatedThumbImageCropperArea;
    updatedOptionModel.infoBackgroundImageCropperArea =
      updatedBackgroundImageCropperArea;
  }

  private initEvents() {
    this.actions$
      .pipe(
        ofType(fromOptionsActions.httpFail),
        map((response) => response.payload)
      )
      .subscribe((message: string) => {
        this.snackBarService.openSnackBar(message, SnackBarType.Error);
      });
  }
}
