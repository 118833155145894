import {MaxFileUpload} from '../data-structures/file';
import {Expose} from 'class-transformer';

export class FileTypeModel {
  public fonts: string[] = [];
  public images: string[] = [];
  @Expose({name: 'max_file_uploads'})
  public maxFileUploads: MaxFileUpload = {
    fonts: -1,
    images: -1
  };
}
