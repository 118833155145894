import {Injectable} from '@angular/core';
import {InfoBoxModel} from '../shared/models/info-box.model';
import {CreateAdInfoBoxOne, CreateAdInfoBoxTwo, DownloadAdInfoBoxTwo} from '../shared/data-repository/info-boxes';

@Injectable({
  providedIn: 'root'
})
export class AdsInfoBoxService {

  private createAdInfoBoxes: InfoBoxModel[] = [
    CreateAdInfoBoxOne,
    CreateAdInfoBoxTwo
  ];

  private downloadAdInfoBox = DownloadAdInfoBoxTwo;

  public get CreateAdInfoBoxes(): InfoBoxModel[] {
    return this.createAdInfoBoxes;
  }

  public get DownloadAdInfoBox(): InfoBoxModel {
    return this.downloadAdInfoBox;
  }
}
