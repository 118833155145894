<h1 mat-dialog-title>{{Attention}}</h1>
<div mat-dialog-content>
  <div>{{content}}</div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true">{{Cancel}}</button>
  <button mat-button
          [mat-dialog-close]
          type="submit">{{Ok}}</button>
</div>
