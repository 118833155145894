import { Component } from '@angular/core';
import { NewPromotionService } from '../../../new-promotion.service';
import { UploadService } from '../../../../../backend-services/upload.service';
import { DomSanitizer } from '@angular/platform-browser';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-gallery-block-expanded-view',
  templateUrl: './gallery-block-expanded-view.component.html',
  styleUrls: ['./gallery-block-expanded-view.component.scss'],
})
export class GalleryBlockExpandedViewComponent {
  constructor(
    public newPromotionService: NewPromotionService,
    private uploadService: UploadService,
    public sanitizer: DomSanitizer,
    private fb: FormBuilder
  ) {}

  closeCard() {
    this.newPromotionService.layoutCardExpanded = false;
  }

  get images() {
    return this.newPromotionService.selectedLayoutCard!.data.get(
      'images'
    ) as FormArray;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.images.controls,
      event.previousIndex,
      event.currentIndex
    );
  }

  toggleStyle(
    control: AbstractControl | null | undefined,
    values: { activeValue: any; defaultValue: any }
  ) {
    if (control?.value === values.activeValue) {
      control?.setValue(values.defaultValue);
    } else {
      control?.setValue(values.activeValue);
    }
  }

  handleImageChange(event: any, index: number) {
    const file = event.target.files[0];
    if (file) {
      this.uploadService.uploadImage(file).subscribe({
        next: (res) => {
          if (res.success && res.data?.file) {
            this.getImageFormGroup(index)
              .get('image_file')
              ?.setValue(res.data.file);
            this.getImageFormGroup(index)
              .get('image_thumb_file')
              ?.setValue(res.data.thumb);
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
    }
  }

  deleteImageFile(index: number) {
    const file = this.getImageFormGroup(index).get('image_file')?.value;
    if (file) {
      this.uploadService.deleteImage(file).subscribe({ error: console.log });
    }

    this.getImageFormGroup(index).get('image_file')?.setValue('');
    this.getImageFormGroup(index).get('image_thumb_file')?.setValue('');
  }

  duplicateImage(index: number) {
    const values = this.getImageFormGroup(index).value;
    (
      this.newPromotionService.selectedLayoutCard!.data.get(
        'images'
      ) as FormArray
    ).push(
      this.fb.group({
        image_file: this.fb.control(values.image_file),
        image_thumb_file: this.fb.control(values.image_thumb_file),
        image_title: this.fb.control(values.image_title),
        style_image: this.fb.group({
          alignSelf: this.fb.control(values.style_image.alignSelf),
          width: this.fb.control(values.style_image.width),
        }),
      })
    );
  }

  addNewImage() {
    (
      this.newPromotionService.selectedLayoutCard!.data.get(
        'images'
      ) as FormArray
    ).push(
      this.fb.group({
        image_file: this.fb.control(''),
        image_thumb_file: this.fb.control(''),
        image_title: this.fb.control(''),
        style_image: this.fb.group({
          alignSelf: this.fb.control('auto'),
          width: this.fb.control(100),
        }),
      })
    );
  }

  deleteImage(index: number) {
    this.deleteImageFile(index);
    (
      this.newPromotionService.selectedLayoutCard!.data.get(
        'images'
      ) as FormArray
    ).removeAt(index);
  }

  getImageFormGroup(index: number) {
    return (
      this.newPromotionService.selectedLayoutCard!.data.get(
        'images'
      ) as FormArray
    ).at(index) as FormGroup;
  }
}
