import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {NewPromotionService} from "../new-promotion.service";

@Component({
  selector: 'app-new-promotion-modal',
  templateUrl: './new-promotion-modal.component.html',
  styleUrls: ['./new-promotion-modal.component.scss'],
})
export class NewPromotionModalComponent implements OnInit {
  constructor(private router: Router, public newPromotionService: NewPromotionService) {}

  ngOnInit(): void {}

  handleSubmit() {
    this.router.navigate(['promotions/new-promotion/e/settings']);
  }
}
