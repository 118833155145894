import { Injectable } from '@angular/core';
import { AdBaseModel } from '../../../shared/models/ad.model';
import { FormGroup } from '@angular/forms';
import { EditAdNavigationService } from '../edit-ad-navigation.service';
import { CommonEditAdDesignService } from './common-edit-ad-design.service';
import { EditAdService } from '../edit-ad.service';
import { CommonEditAdBasicService } from './common-edit-ad-basic.service';
import { FileTypesService } from '../../../shared/files/file-types.service';

@Injectable({
  providedIn: 'root',
})
export class CommonEditAdContentService {
  public imageFetching = false;

  constructor(
    private commonEditAdDesignService: CommonEditAdDesignService,
    public fileTypesService: FileTypesService,
    private editAdService: EditAdService,
    private commonEditAdBasicService: CommonEditAdBasicService,
    private editAdNavigationService: EditAdNavigationService
  ) {}

  public initCTACtrlChanges(contentTemplateForm: FormGroup): void {
    contentTemplateForm.controls.ctaBtnText.valueChanges.subscribe(() => {
      const curIndex = this.editAdNavigationService.getPageIndex();
      this.editAdNavigationService.editAdPagesSaved[curIndex] = false;

      const currentAdModel = this.commonEditAdBasicService.AdModel;
      const updatedAdModel = new AdBaseModel();
      Object.assign(updatedAdModel, currentAdModel);
      updatedAdModel.ctaButtonText =
        contentTemplateForm.controls.ctaBtnText.value;
      this.commonEditAdBasicService.AdModel = updatedAdModel;
      this.editAdNavigationService.adModelChanged$.next(updatedAdModel);
      this.editAdService.adModelUserChange = true;
    });
  }
}
