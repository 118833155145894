import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function numberValidator(prms?: Range): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let val: number = +control.value;

    if (isNaN(val) || /\D/.test(val.toString())) {
      return {"number": true};
    }
    if (prms) {
      if (prms.min && prms.max && !isNaN(prms.min) && !isNaN(prms.max)) {
        return val < prms.min || val > prms.max ? {"number": true} : null;
      } else if (prms.min && !isNaN(prms.min)) {
        return val < prms.min ? {"number": true} : null;
      } else if (prms.max && !isNaN(prms.max)) {
        return val > prms.max ? {"number": true} : null;
      } else {
        return null;
      }
    }
    return null;
  };
}

type Range = {
  min?: number;
  max?: number;
}
