import { createAction, props } from '@ngrx/store';
import { AdBaseModel } from '../../shared/models/ad.model';
import { AdBaseableModel } from '../../shared/data-structures/ad-data-structures';
import { HTMLAdComposite } from '../../ads/edit-ad/ad-reflection/ad-generate-html.directive';
import { HttpOperation } from '../../shared/services/endpoint.service';
import { SortAndFilterComposite } from '../../shared/data-structures/sort-and-filter';
import { PaginationComposite } from '../../shared/data-structures/pagination';

export const addAd = createAction(
  '[Ads] Add Ad',
  props<{ payload: AdBaseModel }>()
);
export const updateAd = createAction(
  '[Ads] Update Ad',
  props<{
    payload: [AdBaseModel, AdBaseableModel | undefined, number | null, boolean];
  }>()
);
export const deleteAd = createAction(
  '[Ads] Delete Ad',
  props<{ payload: number }>()
);
export const duplicateAd = createAction(
  '[Ads] Duplicate Ad',
  props<{ payload: AdBaseModel }>()
);
export const setDuplicatedAd = createAction(
  '[Ads] Set Duplicated Ad',
  props<{ payload: [AdBaseModel, AdBaseModel] }>()
);
export const fetchAdsList = createAction(
  '[Ads] Fetch Ads',
  props<{
    payload: [
      number | null,
      HttpOperation,
      SortAndFilterComposite?,
      PaginationComposite?
    ];
  }>()
);
export const fetchSingleAd = createAction(
  '[Ads] Fetch Ad',
  props<{ payload: number }>()
);
export const setAdsList = createAction(
  '[Ads] Set Ads',
  props<{ payload: [ReadonlyArray<AdBaseModel>, HttpOperation, number] }>()
);
export const NoOp = createAction(
  '[Ads] No Op',
  props<{ payload: HttpOperation }>()
);
export const calculateAdFileSize = createAction(
  '[Ad] Calculate File Size',
  props<{ payload: [number, HTMLAdComposite] }>()
);
export const setAdFileSize = createAction(
  '[Ad] Set Download Ad',
  props<{ payload: number }>()
);
export const initializeAdDownload = createAction(
  '[Ad] Init Download Ad',
  props<{ payload: [number, HTMLAdComposite] }>()
);
export const setAdDownload = createAction(
  '[Ad] Set Download Ad',
  props<{ payload: Blob }>()
);
export const httpFail = createAction(
  '[Ads] Http fail',
  props<{ payload: string }>()
);
