<form
  [formGroup]="newPromotionService.selectedLayoutCard!.data"
  class="w-100 d-flex flex-column"
>
  <ul class="list-unstyled">
    <li>
      <div class="list-item-dropdown-title" style="background: #6e72eb">
        <div
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
        >
          <div
            class="d-flex justify-content-between align-items-center text-white"
          >
            <div style="font-weight: bold">GALERIE</div>
            <button class="transparent-btn" (click)="closeCard()">
              <i class="material-icons">close</i>
            </button>
          </div>
        </div>
      </div>
    </li>
    <app-block-section title="BLOCK">
      <app-block-styles-view
        [rootFormGroup]="newPromotionService.selectedLayoutCard!.data"
      ></app-block-styles-view>
    </app-block-section>
    <app-block-section title="BILDER">
      <ul
        class="w-100 ps-2"
        formArrayName="images"
        cdkDropList
        (cdkDropListDropped)="drop($event)"
      >
        <li
          class="w-100 mb-3"
          *ngFor="let currentImg of images.controls; index as i"
          [formGroupName]="i"
        >
          <div class="w-100 list-item-dropdown-title" cdkDrag>
            <div
              style="letter-spacing: 0.7px; line-height: 16px"
              class="w-100 text-uppercase"
              role="button"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#image-collapse-' + i"
              aria-expanded="false"
            >
              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="d-flex justify-content-start align-items-center gap-1 w-25"
                >
                  <button class="transparent-btn">
                    <i class="material-icons">drag_handle</i>
                  </button>
                  <button class="transparent-btn" (click)="duplicateImage(i)">
                    <i class="material-icons">content_copy</i>
                  </button>
                  <button class="transparent-btn" (click)="deleteImage(i)">
                    <i class="material-icons">delete</i>
                  </button>
                </span>
                <span class="w-75" style="color: #332e45; font-weight: bold"
                  >BILD {{ i + 1 }}</span
                >
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="expand_less">
                      <path
                        id="Vector"
                        d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                        fill="#332E45"
                      />
                    </g>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div class="collapse" [id]="'image-collapse-' + i">
            <div
              class="py-2 w-100 px-4 d-flex gap-2"
              *ngIf="getImageFormGroup(i).get('image_file')?.value"
            >
              <button class="transparent-btn" (click)="deleteImageFile(i)">
                <i class="material-icons">delete</i>
              </button>
              <img
                alt=""
                [src]="
                  sanitizer.bypassSecurityTrustResourceUrl(
                    '/api/image/' +
                      getImageFormGroup(i).get('image_thumb_file')?.value
                  )
                "
              />
            </div>
            <input
              type="file"
              class="d-none"
              accept="image/png, image/gif, image/jpeg"
              #imgInput
              (change)="handleImageChange($event, i)"
            />
            <button
              (click)="imgInput.click()"
              class="mb-1 w-100 border-1 rounded-2 py-2 gap-1 d-flex justify-content-center align-items-center bg-white my-2"
              style="color: #6e72eb; border-color: #6e72eb"
            >
              <i class="material-icons">upload</i>
              <span
                class="text-uppercase"
                style="
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px;
                  letter-spacing: 0.5px;
                "
                >bILD HOCHLADEN</span
              >
            </button>
            <label style="font-size: 14px; color: #332e45" class="my-2"
              >Ausrichtung</label
            >
            <div class="row w-100">
              <div class="col-12 col-md-6">
                <div
                  class="col gap-1 d-flex flex-row justify-content-between align-items-center"
                >
                  <button
                    class="form-control"
                    (click)="
                      toggleStyle(
                        getImageFormGroup(i)
                          .get('style_image')
                          ?.get('alignSelf'),
                        { activeValue: 'left', defaultValue: 'auto' }
                      )
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      [ngClass]="{
                        'text-style-icon-selected':
                          getImageFormGroup(i)
                            .get('style_image')
                            ?.get('alignSelf')?.value === 'left'
                      }"
                      fill="currentColor"
                    >
                      <path
                        d="M3.83317 18.8334H2.1665V2.16675H3.83317V18.8334ZM18.8332 6.33342H5.49984V8.83342H18.8332V6.33342ZM13.8332 12.1667H5.49984V14.6667H13.8332V12.1667Z"
                      />
                    </svg>
                  </button>
                  <button
                    class="form-control bg-white"
                    (click)="
                      toggleStyle(
                        getImageFormGroup(i)
                          .get('style_image')
                          ?.get('alignSelf'),
                        { activeValue: 'center', defaultValue: 'auto' }
                      )
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="currentColor"
                      [ngClass]="{
                        'text-style-icon-selected':
                          getImageFormGroup(i)
                            .get('style_image')
                            ?.get('alignSelf')?.value === 'center'
                      }"
                    >
                      <path
                        d="M9.66667 2.16675H11.3333V6.33342H18V8.83342H11.3333V12.1667H15.5V14.6667H11.3333V18.8334H9.66667V14.6667H5.5V12.1667H9.66667V8.83342H3V6.33342H9.66667V2.16675Z"
                      />
                    </svg>
                  </button>
                  <button
                    class="form-control bg-white"
                    (click)="
                      toggleStyle(
                        getImageFormGroup(i)
                          .get('style_image')
                          ?.get('alignSelf'),
                        { activeValue: 'right', defaultValue: 'auto' }
                      )
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="currentColor"
                      [ngClass]="{
                        'text-style-icon-selected':
                          getImageFormGroup(i)
                            .get('style_image')
                            ?.get('alignSelf')?.value === 'right'
                      }"
                    >
                      <path
                        d="M17.1665 2.16675H18.8332V18.8334H17.1665V2.16675ZM2.1665 8.83342H15.4998V6.33342H2.1665V8.83342ZM7.1665 14.6667H15.4998V12.1667H7.1665V14.6667Z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div class="col-12 col-md-6" formGroupName="style_image">
                <div
                  class="d-flex w-100 flex-row justify-content-between align-items-center"
                >
                  <label for="breite2" style="font-size: 14px; color: #332e45"
                    >Breite</label
                  >
                  <label for="breite2" style="font-size: 14px; color: #332e45"
                    >{{
                      getImageFormGroup(i).get("style_image")?.get("width")
                        ?.value
                    }}%</label
                  >
                </div>
                <input
                  type="range"
                  class="form-range"
                  id="breite2"
                  formControlName="width"
                />
              </div>
            </div>
            <input
              type="text"
              class="form-control my-2"
              formControlName="image_title"
              placeholder="Text"
              aria-label="text"
            />
          </div>
        </li>
      </ul>
      <button
        class="w-100 bg-white mt-2 mb-3 dashed-btn"
        (click)="addNewImage()"
      >
        <i class="material-icons">add</i>
        <span class="ms-2 text-uppercase">NEUEs bild</span>
      </button>
    </app-block-section>
  </ul>
</form>
