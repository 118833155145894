<form
  [formGroup]="newPromotionService.selectedLayoutCard!.data"
  class="w-100 d-flex flex-column"
>
  <ul class="list-unstyled">
    <li>
      <div class="list-item-dropdown-title" style="background: #6e72eb">
        <div
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
        >
          <div
            class="d-flex justify-content-between align-items-center text-white"
          >
            <div style="font-weight: bold">BILD/TEXT</div>
            <button class="transparent-btn" (click)="closeCard()">
              <i class="material-icons">close</i>
            </button>
          </div>
        </div>
      </div>
    </li>
    <app-block-section title="BLOCK">
      <app-block-styles-view
        [rootFormGroup]="newPromotionService.selectedLayoutCard!.data"
      ></app-block-styles-view>
    </app-block-section>
    <app-block-section title="TEXT">
      <app-text-styles-view
        [rootFormGroup]="
          newPromotionService.selectedLayoutCard!.data.get('style_text')
        "
      ></app-text-styles-view>
      <div class="w-100">
        <editor [init]="tineMCEConfig" formControlName="content"></editor>
      </div>
    </app-block-section>
    <app-block-section title="Bild">
      <app-image-styles-view
        [rootFormGroup]="newPromotionService.selectedLayoutCard!.data"
      ></app-image-styles-view>
    </app-block-section>
    <app-block-section title="SPALTEN">
      <app-columns-styles-view
        [rootFormGroup]="
          newPromotionService.selectedLayoutCard!.data.get('style_column')
        "
      ></app-columns-styles-view>
    </app-block-section>
  </ul>
</form>
