import {createReducer, on} from '@ngrx/store';
import {AdTypeModel} from '../../shared/models/ad-type.model';
import {setAdTypeList} from './ad-types.actions';


export const initialState: ReadonlyArray<AdTypeModel> = [];

export const adTypesReducer = createReducer(
  initialState,
  on(setAdTypeList, (state, {payload}): ReadonlyArray<AdTypeModel> => {
    return [...payload];
  })
);
