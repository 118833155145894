import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-block-section',
  templateUrl: './block-section.component.html',
  styleUrls: ['./block-section.component.scss'],
})
export class BlockSectionComponent {
  @Input() title!: string;

  constructor() {}

  get encodedTitle() {
    return this.title.replace(/[^a-zA-Z0-9]/g, '-')
  }
}
