import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AdminLayoutModule } from './layouts/admin-layout/admin-layout.module';
import { ComponentsModule } from './layouts/admin-layout/components/components.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PromotionsOverviewComponent } from './promotions/promotions-overview/promotions-overview.component';
import { ProfileComponent } from './account/profile/profile.component';
import { CreateAdComponent } from './ads/create-ad/create-ad.component';
import { SettingsComponent } from './account/settings/settings.component';
import { NotificationComponent } from './account/notification/notification.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import {
  MAT_COLOR_FORMATS,
  NGX_MAT_COLOR_FORMATS,
} from '@angular-material-components/color-picker';
import { AdsModule } from './ads/ads.module';
import { SnackBarComponent } from './shared/snack-bar/snack-bar.component';
import { SharedModule } from './shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { effects, reducers } from './state';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getDutchPaginatorIntl } from './shared/data-repository/german-paginator-intl';
import { ReactiveFormsModule } from '@angular/forms';
import { BackendServicesModule } from './backend-services/backend-services.module';
import { AuthModule } from './auth/auth.module';
import { UsersListViewPageComponent } from './user-management/users-list-view-page/users-list-view-page.component';
import { ManageSingleUserComponent } from './user-management/user/manage-single-user.component';
import { ManageSingleCustomerComponent } from './user-management/customer/manage-single-customer.component';
import { RoleNameDisplayComponent } from './user-management/role-name-display/role-name-display.component';
import { CompanyNameDisplayComponent } from './user-management/company-name-display/company-name-display.component';
import { ToastrModule } from 'ngx-toastr';
import { CustomerLogoDisplayComponent } from './user-management/customer-logo-display/customer-logo-display.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    DashboardComponent,
    PromotionsOverviewComponent,
    ProfileComponent,
    CreateAdComponent,
    SettingsComponent,
    NotificationComponent,
    PageNotFoundComponent,
    SnackBarComponent,
    UsersListViewPageComponent,
    ManageSingleUserComponent,
    ManageSingleCustomerComponent,
    RoleNameDisplayComponent,
    CompanyNameDisplayComponent,
    CustomerLogoDisplayComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AdminLayoutModule,
    AdsModule,
    ComponentsModule,
    SharedModule,
    HttpClientModule,
    EffectsModule.forRoot(effects),
    StoreModule.forRoot(reducers),
    ReactiveFormsModule,
    AuthModule,
    BackendServicesModule,
    ToastrModule.forRoot({
      autoDismiss: true,
      closeButton: true,
      newestOnTop: true,
      timeOut: 4000,
      positionClass: 'toast-top-right',
    }),
    EditorModule,
  ],
  providers: [
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
