import {createReducer, on} from '@ngrx/store';
import {addAd, deleteAd, setAdsList, updateAd} from './ads.actions';
import {AdBaseModel} from '../../shared/models/ad.model';
import {ArrayHelper} from '../../shared/helper/array-helper';


export const initialState: ReadonlyArray<AdBaseModel> = [];
export const adsReducer = createReducer(
  initialState,
  on(setAdsList, (state, {payload: [ads, _, _1]}): ReadonlyArray<AdBaseModel> => {
    const mergedArr = [...state, ...ads];
    const condition = (first: AdBaseModel, second: AdBaseModel) => {
      return first.id === second.id
    };
    const filteredArr = ArrayHelper.filterDuplicates(mergedArr, condition);
    return [...filteredArr];
  }),
  on(addAd, (state, {payload: adBaseModel}) => {
    if (state.findIndex(a => a.id === adBaseModel.id) > -1) return state;

    return [...state, adBaseModel];
  }),
  on(updateAd, (state, {payload: [adBaseModel, _]}) => {
    const index = state.findIndex(entry => entry.id === adBaseModel.id);
    const updatedState = [
      ...state
    ];
    updatedState[index] = adBaseModel;
    return updatedState;
  }),
  on(deleteAd, (state, {payload: adId}) => {
    const updatedState = state.filter(entry => entry.id === adId);
    return [...updatedState];
  })
);
