import { Component, Input } from '@angular/core';
import { LayoutCard } from '../../new-promotion.service';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-navbar-block-preview',
  templateUrl: './navbar-block-preview.component.html',
  styleUrls: ['./navbar-block-preview.component.scss'],
})
export class NavbarBlockPreviewComponent {
  @Input() card!: LayoutCard;

  constructor(public sanitizer: DomSanitizer) {}

  get cardValues() {
    return this.card.data.value;
  }

  get pathName() {
    return window.location.pathname
  }
}
