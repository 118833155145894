<form
  [formGroup]="newPromotionService.selectedLayoutCard!.data"
  class="w-100 d-flex flex-column"
>
  <ul class="list-unstyled">
    <li>
      <div class="list-item-dropdown-title" style="background: #6e72eb">
        <div
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
        >
          <div
            class="d-flex justify-content-between align-items-center text-white"
          >
            <div style="font-weight: bold">Video</div>
            <button class="transparent-btn" (click)="closeCard()">
              <i class="material-icons">close</i>
            </button>
          </div>
        </div>
      </div>
    </li>
    <app-block-section title="BLOCK">
      <app-block-styles-view
        [rootFormGroup]="newPromotionService.selectedLayoutCard!.data"
      ></app-block-styles-view>
    </app-block-section>
    <li>
      <div class="list-item-dropdown-title">
        <a
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#überschrift-1-collapse"
          aria-expanded="false"
        >
          <span class="d-flex justify-content-between align-items-center">
            <span style="color: #332e45; font-weight: bold">Video</span>
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="expand_less">
                  <path
                    id="Vector"
                    d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                    fill="#332E45"
                  />
                </g>
              </svg>
            </span>
          </span>
        </a>
      </div>
      <div class="collapse" id="überschrift-1-collapse">
        <div
          class="w-100 p-2 d-flex flex-column align-items-start justify-content-center gap-1"
        >
          <div
            class="py-2 w-100 px-4 d-flex gap-2"
            *ngIf="
              this.newPromotionService.selectedLayoutCard!.data.get(
                'image_file'
              )?.value
            "
          >
            <button class="transparent-btn" (click)="deleteImageFile()">
              <i class="material-icons">delete</i>
            </button>
            <img
              alt=""
              [src]="
                sanitizer.bypassSecurityTrustResourceUrl(
                  '/api/image/' +
                    this.newPromotionService.selectedLayoutCard!.data.get(
                      'image_thumb_file'
                    )?.value
                )
              "
            />
          </div>
          <select
            class="form-select my-3"
            aria-label="select role"
            name="role"
            id="video_type"
            formControlName="video_type"
          >
            <option value="0">URL</option>
          </select>
          <input
            type="file"
            class="d-none"
            accept="video/mp4,video/x-m4v,video/*"
            #videoInput
            (change)="handleVideoChange($event)"
          />
          <button
            (click)="videoInput.click()"
            class="mb-1 w-100 border-1 rounded-2 py-2 gap-1 d-flex justify-content-center align-items-center bg-white my-2"
            style="color: #6e72eb; border-color: #6e72eb"
          >
            <i class="material-icons">upload</i>
            <span
              class="text-uppercase"
              style="
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.5px;
              "
              >Video HOCHLADEN</span
            >
          </button>
          <div class="w-100 mt-2" formGroupName="style_video">
            <div
              class="d-flex w-100 flex-row justify-content-between align-items-center"
            >
              <label for="breite2" style="font-size: 14px; color: #332e45"
                >Breite</label
              >
              <label for="breite2" style="font-size: 14px; color: #332e45"
                >{{
                  newPromotionService
                    .selectedLayoutCard!.data.get("style_video")
                    ?.get("width")?.value
                }}%</label
              >
            </div>
            <input
              type="range"
              class="form-range"
              id="breite2"
              formControlName="width"
            />
          </div>
          <div class="w-100 row">
            <div class="col-12 col-md-6 mt-2">
              <label for="breite2" style="font-size: 14px; color: #332e45"
                >Autoplay</label
              >
              <div
                class="w-100 d-flex flex-row justify-content-start align-items-center gap-4 mt-2"
              >
                <div class="d-flex gap-2 align-items-center">
                  <input
                    class="form-check-input radio-border"
                    type="radio"
                    formControlName="autoplay"
                    [value]="true"
                    id="autoplay-true"
                    checked
                  />
                  <label
                    class="form-check-label"
                    for="autoplay-true"
                    style="font-size: 14px"
                  >
                    ja
                  </label>
                </div>
                <div class="d-flex gap-2 align-items-center">
                  <input
                    class="form-check-input radio-border"
                    type="radio"
                    formControlName="autoplay"
                    id="autoplay-false"
                    [value]="false"
                  />
                  <label
                    class="form-check-label"
                    for="autoplay-false"
                    style="font-size: 14px"
                  >
                    nein
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <label style="font-size: 14px; color: #332e45" class="my-2"
                >Ausrichtung</label
              >
              <div class="row w-100">
                <div class="col-12">
                  <div
                    class="col gap-1 d-flex flex-row justify-content-between align-items-center"
                  >
                    <button
                      class="form-control"
                      (click)="
                        toggleStyle(
                          newPromotionService
                            .selectedLayoutCard!.data.get('style_video')
                            ?.get('alignSelf'),
                          { activeValue: 'left', defaultValue: 'auto' }
                        )
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        [ngClass]="{
                          'text-style-icon-selected':
                            newPromotionService
                              .selectedLayoutCard!.data.get('style_video')
                              ?.get('alignSelf')?.value === 'left'
                        }"
                        fill="currentColor"
                      >
                        <path
                          d="M3.83317 18.8334H2.1665V2.16675H3.83317V18.8334ZM18.8332 6.33342H5.49984V8.83342H18.8332V6.33342ZM13.8332 12.1667H5.49984V14.6667H13.8332V12.1667Z"
                        />
                      </svg>
                    </button>
                    <button
                      class="form-control bg-white"
                      (click)="
                        toggleStyle(
                          newPromotionService
                            .selectedLayoutCard!.data.get('style_video')
                            ?.get('alignSelf'),
                          { activeValue: 'center', defaultValue: 'auto' }
                        )
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="currentColor"
                        [ngClass]="{
                          'text-style-icon-selected':
                            newPromotionService
                              .selectedLayoutCard!.data.get('style_video')
                              ?.get('alignSelf')?.value === 'center'
                        }"
                      >
                        <path
                          d="M9.66667 2.16675H11.3333V6.33342H18V8.83342H11.3333V12.1667H15.5V14.6667H11.3333V18.8334H9.66667V14.6667H5.5V12.1667H9.66667V8.83342H3V6.33342H9.66667V2.16675Z"
                        />
                      </svg>
                    </button>
                    <button
                      class="form-control bg-white"
                      (click)="
                        toggleStyle(
                          newPromotionService
                            .selectedLayoutCard!.data.get('style_video')
                            ?.get('alignSelf'),
                          { activeValue: 'right', defaultValue: 'auto' }
                        )
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="currentColor"
                        [ngClass]="{
                          'text-style-icon-selected':
                            newPromotionService
                              .selectedLayoutCard!.data.get('style_video')
                              ?.get('alignSelf')?.value === 'right'
                        }"
                      >
                        <path
                          d="M17.1665 2.16675H18.8332V18.8334H17.1665V2.16675ZM2.1665 8.83342H15.4998V6.33342H2.1665V8.83342ZM7.1665 14.6667H15.4998V12.1667H7.1665V14.6667Z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</form>
