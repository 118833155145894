<mat-form-field appearance="fill" class="color-picker">
  <mat-label>{{label}}</mat-label>
  <input matInput
         [ngxMatColorPicker]="colorPicker"
         [formControl]="formControl">
  <ngx-mat-color-toggle matSuffix [for]="colorPicker"></ngx-mat-color-toggle>
  <ngx-mat-color-picker #colorPicker
                        (opened)="opened()"

                        [color]="color"></ngx-mat-color-picker>
  <div class="color-indicator" #colorPickerIndicator></div>
</mat-form-field>
