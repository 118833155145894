<form
  class="form configuration-items-wrapper"
  *ngIf="contentTemplateForm"
  [formGroup]="contentTemplateForm"
  [ngClass]="{ displayErroneousInputs: displayErroneousInputs }"
>
  <!-- ############ Texte -->
  <app-ad-configuration-item [item]="textConfigurationItem">
    <div class="col-12 mt-1">
      <div class="row mt-2">
        <div class="col-6">
          <h6 class="mb-0">{{ Headline }}</h6>
          <mat-form-field appearance="fill">
            <mat-label>{{ Text }}</mat-label>
            <input matInput formControlName="headlineText" />
          </mat-form-field>
        </div>

        <div class="col-6">
          <h6 class="mb-0">{{ ContinuousText }}</h6>
          <mat-form-field appearance="fill">
            <mat-label>{{ Text }}</mat-label>
            <input matInput formControlName="continuousTextCtrl" />
          </mat-form-field>
        </div>
      </div>
    </div>
  </app-ad-configuration-item>

  <!-- ############ Optionen -->
  <app-ad-configuration-item [item]="optionWrapperConfigurationItem">
    <div id="option-container" #optionList formArrayName="optionsFormArr">
      <app-ad-configuration-item
        *ngFor="let item of optionConfigurationItems; let i = index"
        [item]="item"
        [orderNr]="i"
        [copyCallback]="copyOption.bind(this)"
        [removeCallback]="removeOption.bind(this)"
      >
        <div class="col-12 mt-2 pt-1" [formGroupName]="i">
          <input class="orderNumber" formControlName="orderNumber" hidden />
          <div class="row mt-2">
            <div class="col-12">
              <h6 class="mb-0">{{ Thumbnail }}</h6>
            </div>
            <div class="col-6">
              <mat-form-field appearance="fill">
                <mat-label>{{ Shape }}</mat-label>
                <mat-select formControlName="shapeCtrl">
                  <mat-option [value]="'circle'">
                    {{ Round }}
                  </mat-option>
                  <mat-option [value]="'rect'">
                    {{ Square }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-6">
              <app-color-picker
                *ngIf="
                  contentTemplateForm.controls.optionsFormArr !== undefined
                "
                [label]="BackgroundColor"
                [control]="
                  contentTemplateForm.controls.optionsFormArr
                    .get(i.toString())!
                    .get('thumbnailBackgroundPickerCtrl')!
                "
                [controlType]="AdContentFormControls.BackgroundColor"
                [component]="adContentComponent"
              ></app-color-picker>
            </div>

            <div class="col-6 d-flex align-items-center">
              <app-image-upload
                (cropperResult$)="
                  imageChanged($event, i, OriginalOptionImageType.Thumb)
                "
                [adTypeName]="PickerSlug"
                [originalImageType]="OriginalOptionImageType.Thumb"
                [imageCropped]="getOption(i).thumbImageCropped"
                [imageOriginal]="getOption(i).thumbImageOriginal"
                [imageWrapper]="getOption(i)"
                [cropperArea]="getOption(i).thumbImageCropperArea"
                [imageCropperData]="thumbImageCropperData"
              ></app-image-upload>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <hr />
            </div>
          </div>

          <div class="row">
            <div class="col-12 mt-3">
              <h6 class="mb-0">{{ InfoText }}</h6>
            </div>
            <div class="col-6">
              <mat-form-field appearance="fill">
                <mat-label>{{ Text }}</mat-label>
                <input matInput formControlName="infoTextCtrl" />
              </mat-form-field>
            </div>

            <div class="col-6">
              <app-color-picker
                *ngIf="
                  contentTemplateForm.controls.optionsFormArr !== undefined
                "
                [label]="TextColor"
                [control]="
                  contentTemplateForm.controls.optionsFormArr
                    .get(i.toString())!
                    .get('infoTextColorCtrl')!
                "
                [controlType]="AdContentFormControls.TextColor"
                [component]="adContentComponent"
              ></app-color-picker>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <hr />
            </div>
          </div>

          <div class="row">
            <div class="col-12 mt-3">
              <h6 class="mb-0">{{ InfoArea }}</h6>
            </div>
            <div class="col-6 d-flex align-items-center">
              <app-image-upload
                (cropperResult$)="
                  imageChanged($event, i, OriginalOptionImageType.Background)
                "
                [adTypeName]="PickerSlug"
                [originalImageType]="OriginalOptionImageType.Background"
                [imageCropped]="getOption(i).infoBackgroundImageCropped"
                [imageOriginal]="getOption(i).infoBackgroundImageOriginal"
                [imageWrapper]="getOption(i)"
                [cropperArea]="getOption(i).infoBackgroundImageCropperArea"
                [imageCropperData]="backgroundImageCropperData"
              ></app-image-upload>
            </div>

            <div class="col-6">
              <app-color-picker
                *ngIf="
                  contentTemplateForm.controls.optionsFormArr !== undefined
                "
                [label]="BackgroundColor"
                [control]="
                  contentTemplateForm.controls.optionsFormArr
                    .get(i.toString())!
                    .get('infoBackgroundColorCtrl')!
                "
                [controlType]="AdContentFormControls.BackgroundColor"
                [component]="adContentComponent"
              ></app-color-picker>
            </div>
          </div>
        </div>
      </app-ad-configuration-item>
    </div>

    <a
      class="accordion dummy text-decoration-none"
      *ngIf="options.size < MaxOptionCount"
      (click)="addNewOption()"
    >
      <div class="accordion-item">
        <div
          class="accordion-header card-header d-flex justify-content-center align-items-center text-uppercase"
        >
          <span class="material-icons-sharp">add</span>
          <span class="label">{{ NewOption }}</span>
        </div>
      </div>
    </a>
  </app-ad-configuration-item>

  <!-- ############ CTA Button -->
  <app-ad-configuration-item
    [item]="ctaBtnConfigurationItem"
    class="d-block"
    style="margin-top: 35px"
  >
    <div class="col-6 d-flex align-items-center">
      <mat-form-field appearance="fill">
        <mat-label>{{ Text }}</mat-label>
        <input matInput formControlName="ctaBtnText" />
      </mat-form-field>
    </div>
  </app-ad-configuration-item>
</form>

<app-edit-ad-navigation></app-edit-ad-navigation>
