import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminLayoutRoutes } from './admin-layout.routing';
import { RouterModule } from '@angular/router';
import { ScrollDirective } from './scroll.directive';
import { AdminLayoutResizeDirective } from './admin-layout-resize.directive';
import {BackendServicesModule} from "../../backend-services/backend-services.module";
import {NewPromotionModule} from "../../promotions/new-promotion/new-promotion.module";

@NgModule({
  declarations: [ScrollDirective, AdminLayoutResizeDirective],
  exports: [ScrollDirective, AdminLayoutResizeDirective],
  imports: [CommonModule, RouterModule.forChild(AdminLayoutRoutes), BackendServicesModule, NewPromotionModule],
})
export class AdminLayoutModule {}
