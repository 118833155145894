import {Directive, HostListener} from '@angular/core';
import {AdminLayoutService} from './admin-layout.service';

@Directive({
  selector: '[appAdminLayoutResize]'
})
export class AdminLayoutResizeDirective {

  constructor(private adminLayoutService: AdminLayoutService) { }

  @HostListener('window:resize', ['$event'])
  onResize(_: any) {
    this.adminLayoutService.viewportResized.next(null);
  }
}
