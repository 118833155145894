import {Injectable} from '@angular/core';
import {Actions, ofType} from '@ngrx/effects';
import * as fromAdsActions from '../../state/ads/ads.actions';
import * as fromSlidesActions from '../../state/slides/slides.actions';
import * as fromOptionsActions from '../../state/options/options.actions';
import {BehaviorSubject, combineLatest, filter, map, mergeMap, Observable, Subject, take, tap} from 'rxjs';
import {HttpOperation} from '../../shared/services/endpoint.service';
import {AdsService} from '../ads.service';
import {AdBaseModel} from '../../shared/models/ad.model';
import {SnackBarService, SnackBarType} from '../../shared/snack-bar/snack-bar.service';
import {EditAdNavigationService} from './edit-ad-navigation.service';
import {OverlayService} from '../../shared/services/overlay.service';
import {AdSuccessfullySaved} from '../../shared/data-repository/snack-bar';
import {AdTypeService} from '../ad-type.service';
import {NavigationWrapper} from '../../shared/data-structures/navigation-data';
import {AdTypeModel} from '../../shared/models/ad-type.model';
import {PickerSlug, StorySlug} from '../../shared/data-repository/api-slugs';
import {AdboxRoutes} from '../../shared/data-repository/routes';

@Injectable({
  providedIn: 'root'
})
export class EditAdService {
  public adModelUpdated$ = new BehaviorSubject<AdBaseModel | null>(null); // Fired as soon as ad was updated on the server
  public adTitleChanged$ = new Subject<string>();
  public adModelUserChange = false; // Only true if user has changed some property in one of the forms

  private currentAdModel!: AdBaseModel;
  private saveClicked = false;
  private createThumbnail = true;

  constructor(private actions$: Actions,
              private adsService: AdsService,
              private adTypeService: AdTypeService,
              private overlayService: OverlayService,
              private editAdNavigationService: EditAdNavigationService,
              private snackBarService: SnackBarService) {
    this.initAdSaved();
    this.initErrorHandling();
  }

  /**
   * Function that always emits the latest ad data model after updating the ad
   */
  public fetchLatestAdModel(adId: number): Observable<[AdBaseModel, HttpOperation]> {
    return this.actions$.pipe(
      ofType(
        fromAdsActions.setAdsList,
        fromAdsActions.NoOp
      ),
      map(response => {
        return (response.type === fromAdsActions.NoOp.type) ? response.payload : response.payload[1];
      }),
      filter(operation => operation === HttpOperation.Put),
      mergeMap(_ => {
        return this.adsService.fetchAd(adId)
          .pipe(
            tap(response => this.adModelUpdated$.next(response[0]))
          );
      })
    )
  }

  public adSaved(adModel: AdBaseModel, navWrapper: NavigationWrapper, createThumbnail = true): void | never {
    this.adTypeService.getAdType(adModel.adTypeId!)
      .subscribe(adType => {
        if (!adType) {
          throw new Error('Ad type is not defined');
        }
        this.allRequestsDone(adType, navWrapper);
        this.adsService.updateAd(adModel, adModel.baseable!, adModel.id, false);
        this.currentAdModel = adModel;
        this.saveClicked = true;
        this.createThumbnail = createThumbnail;
      })
  }

  private initAdSaved(): void {
    this.actions$.pipe(
      ofType(
        fromAdsActions.NoOp, // PUT request
        fromSlidesActions.setSlideList
      ),
      map(result => result.payload)
    ).subscribe((_) => {
      if (this.saveClicked) {
        this.editAdNavigationService.adModelSaved$.next([this.currentAdModel, this.createThumbnail]);
        this.saveClicked = false;
      }
    });
  }

  private allRequestsDone(currentAdType: AdTypeModel, navWrapper: NavigationWrapper): void | never {
    if (!navWrapper || !currentAdType) {
      throw new Error('Current navigation page or current ad type is not defined.');
    }
    const adSavedEvents$: any = [this.editAdNavigationService.thumbnailGenerated$];

    // If ad type is a story ad or a picker ad and we save the ad on the content page, we have to wait until all slide
    // or option requests have been finished.
    if ((currentAdType.name === StorySlug || currentAdType.name === PickerSlug)
      && navWrapper.route === AdboxRoutes.Content) {
      adSavedEvents$.push(this.waitForPostRequest(currentAdType.name));
    }

    combineLatest(adSavedEvents$)
      .pipe(take(1))
      .subscribe(() => {
        this.overlayService.hideOverlay();
        this.snackBarService.openSnackBar(AdSuccessfullySaved, SnackBarType.Info);
      });
  }

  private waitForPostRequest(adTypeName: string): Observable<any> | never {
    if (adTypeName === StorySlug) {
      return this.actions$.pipe(
        ofType(fromSlidesActions.setSlideList),
        map(response => response.payload),
        filter(payload => payload[1] === HttpOperation.Post)
      );
    } else if (adTypeName === PickerSlug) {
      return this.actions$.pipe(
        ofType(fromOptionsActions.setOptionList),
        map(response => response.payload),
        filter(payload => payload[1] === HttpOperation.Post)
      );
    } else {
      throw new Error('Ad type is not supported.');
    }
  }

  private initErrorHandling(): void {
    this.actions$.pipe(
      ofType(fromAdsActions.httpFail)
    ).subscribe(_ => {
      this.overlayService.hideOverlay();
    });
  }
}

export enum OriginalOptionImageType {
  Thumb = 'thumb',
  Background = 'background'
}
