import {createReducer, on} from '@ngrx/store';
import {OptionModel} from '../../shared/models/option.model';
import {addOptions, setOptionList} from './options.actions';
import {ArrayHelper} from '../../shared/helper/array-helper';


export const initialState: ReadonlyArray<OptionModel> = [];

export const optionsReducer = createReducer(
  initialState,
  on(setOptionList, (state, {payload: [options, _]}): ReadonlyArray<OptionModel> => {
    const mergedArr = [...state, ...options];
    const condition = (first: OptionModel, second: OptionModel) => {
      return first.id === second.id
    };
    const filteredArr = ArrayHelper.filterDuplicates(mergedArr, condition);
    return [...filteredArr];
  }),
  on(addOptions, (state, {payload: [options, _]}): ReadonlyArray<OptionModel> => {
    const newOptions = options.filter(option => typeof option.id !== 'undefined');
    const merged = [...initialState, ...newOptions];
    const uniqueOptions = merged.filter(function (item, pos) {
      return merged.indexOf(item) == pos;
    })
    return [...uniqueOptions];
  })
);
