export interface NavigationWrapper {
  title: string;
  route: string;
}

export enum PageKey {
  Dashboard,
  AllPromotions,
  NewPromotion,
  NewAd,
  MyAds,
  MyDesignTemplates,
  Edit,
  EditDesign,
  EditContent,
  EditAnimation,
  EditDownload,
  AnalysisAndTracking,
  AdPreview,
  Archive,
  TemplateGallery,
  MyProfile,
  UsersList,
  ManageSingleUser,
  ManageSingleCustomer
}

export class PageMap<K, V> extends Map<PageKey, NavigationWrapper> {
  public getRoute(key: PageKey): string {
    return this.get(key)!.route;
  }

  public getTitle(key: PageKey): string {
    return this.get(key)!.title;
  }
}

