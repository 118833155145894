import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AppBarService} from '../app-bar.service';
import {EditAdNavigationService} from '../../../../../ads/edit-ad/edit-ad-navigation.service';
import {NavigationWrapper} from '../../../../../shared/data-structures/navigation-data';
import {SubSink} from 'subsink';
import {RoutingHelperService} from '../../../../../shared/routing/routing-helper.service';
import {Router} from '@angular/router';
import {EditAdService} from '../../../../../ads/edit-ad/edit-ad.service';
import {AdTypeService} from '../../../../../ads/ad-type.service';
import {combineLatest} from 'rxjs';
import {AdBaseModel} from '../../../../../shared/models/ad.model';

@Component({
  selector: 'app-breadcrumb-bar',
  templateUrl: './breadcrumb-bar.component.html',
  styleUrls: ['./breadcrumb-bar.component.scss']
})
export class BreadcrumbBarComponent implements OnInit, OnDestroy {
  @Input() adModel!: AdBaseModel;
  public breadcrumbItems: NavigationWrapper[] = [];
  public breadcrumbItemStates: Array<BreadcrumbState> = []
  public BreadcrumbState = BreadcrumbState;
  public parentUrl = '';
  private subs = new SubSink();

  constructor(public appBarService: AppBarService,
              private routingHelperService: RoutingHelperService,
              private router: Router,
              private editAdService: EditAdService,
              private adTypeService: AdTypeService,
              private editAdNavigationService: EditAdNavigationService) {
  }

  ngOnInit(): void {
    const model = this.adModel;
    const breadcrumbStateChanged$ = this.appBarService.breadcrumbStateChanged;
    const fetchAdTypeName$ = this.adTypeService.getAdTypeName(model);

    this.subs.sink = combineLatest([
      breadcrumbStateChanged$,
      fetchAdTypeName$
    ]).subscribe(([breadcrumbItemStates, adTypeName]) => {
      this.breadcrumbItems = this.editAdNavigationService.getEditViewPages(adTypeName);
      this.breadcrumbItemStates = breadcrumbItemStates;
    });

    this.parentUrl = this.routingHelperService.parentURL(this.router.url);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

export enum BreadcrumbState {
  Current,
  Edited,
  Saved,
  Future
}
