import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NewPromotionService } from '../new-promotion.service';

@Component({
  selector: 'app-new-promotion-layout',
  templateUrl: './new-promotion-layout.component.html',
  styleUrls: ['./new-promotion-layout.component.scss'],
})
export class NewPromotionLayoutComponent implements OnInit {
  constructor(
    public newPromotionService: NewPromotionService,
    private location: Location
  ) {}

  ngOnInit(): void {}

  setPreviewType(type: typeof NewPromotionService.prototype.previewType) {
    this.newPromotionService.previewType = type;
  }

  setSidebarType(type: typeof NewPromotionService.prototype.sidebarView) {
    this.newPromotionService.sidebarView = type;
  }

  goBack() {
    this.location.back();
  }
}
