import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdboxRoutes } from '../../shared/data-repository/routes';
import { User, UserService } from '../../backend-services/user.service';
import {
  Customer,
  CustomerService,
} from '../../backend-services/customer.service';
import { AuthService } from '../../backend-services/auth.service';

const defaultParams: {
  management_type: string;
  page: number;
  limit: number;
  sortBy?: string;
  sortDir?: string;
} = {
  management_type: 'users',
  page: 1,
  limit: 10,
};

@Component({
  selector: 'app-users-list-view-page',
  templateUrl: './users-list-view-page.component.html',
  styleUrls: ['./users-list-view-page.component.scss'],
})
export class UsersListViewPageComponent {
  queryParams = { ...defaultParams };

  public UserManagementRoute = AdboxRoutes.UserManagement;
  public ManageSingleUserRoute = AdboxRoutes.ManageSingleUser;
  public ManageSingleCustomerRoute = AdboxRoutes.ManageSingleCustomer;

  allUsers: User[] = [];
  allCustomers: Customer[] = [];
  totalUsers = 100;

  // capabilities of the current users
  canEditUsers = false;
  canEditCustomers = false;
  isSuperAdmin = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private customerService: CustomerService,
    private authService: AuthService
  ) {
    this.route.queryParamMap.subscribe((params) => {
      let queryParams: Record<string, string | null> = {};
      params.keys.forEach((k) => {
        queryParams[k] = params.get(k);
      });

      // add default params if not there
      this.queryParams.management_type =
        queryParams['management_type'] ?? this.queryParams.management_type;
      this.queryParams.page =
        // @ts-ignore
        parseInt(queryParams['page']) || this.queryParams.page;
      this.queryParams.limit =
        // @ts-ignore
        parseInt(queryParams['limit']) || this.queryParams.limit;
      this.queryParams.sortBy = queryParams['sortBy'] ?? undefined;
      this.queryParams.sortDir = queryParams['sortDir'] ?? undefined;

      this.handleQueryParamChange();
    });

    this.authService.profile.subscribe({
      next: (profile) => {
        this.canEditUsers = !!profile?.role?.capabilities?.MANAGE_USERS;
        this.canEditCustomers = !!profile?.role?.capabilities?.EDIT_CUSTOMER;

        this.handleQueryParamChange();
      },
    });

    this.authService.isSuperAdmin.subscribe({
      next: (isSuperAdmin) => (this.isSuperAdmin = isSuperAdmin),
    });
  }

  changeManagementType(newType: string) {
    this.queryParams = {
      ...defaultParams,
      management_type: newType,
    };

    this.handleQueryParamChange();
  }

  paginationMessage() {
    const start = (this.queryParams.page - 1) * this.queryParams.limit + 1;
    const end = Math.min(start + +this.queryParams.limit - 1, this.totalUsers);
    return `${start}-${end} von ${this.totalUsers}`;
  }

  changePage(type: 'first' | 'previous' | 'next' | 'last') {
    const lastPage = Math.ceil(this.totalUsers / this.queryParams.limit);

    switch (type) {
      case 'first':
        this.queryParams.page = 1;
        break;
      case 'previous':
        this.queryParams.page = Math.max(this.queryParams.page - 1, 1);
        break;
      case 'next':
        this.queryParams.page = Math.min(+this.queryParams.page + 1, lastPage);
        break;
      case 'last':
        this.queryParams.page = lastPage;
        break;
    }

    this.handleQueryParamChange();
  }

  changeSort(field: string, direction?: 'asc' | 'desc') {
    // change sort direction if its the already sorted field, else just set field
    if (this.queryParams.sortBy === field) {
      this.queryParams.sortDir =
        this.queryParams.sortDir === 'desc' ? 'asc' : 'desc';
    } else {
      this.queryParams.sortBy = field;
      this.queryParams.sortDir = 'asc';
    }

    // change the sort direction if its explicitly set
    if (direction) {
      this.queryParams.sortDir = direction;
    }

    this.handleQueryParamChange();
  }

  isLastPage() {
    return (
      this.queryParams.page ===
      Math.ceil(this.totalUsers / this.queryParams.limit)
    );
  }

  handleQueryParamChange() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
      queryParamsHandling: 'merge',
    });

    // fetch users
    if (this.queryParams.management_type === 'users') {
      this.userService.getUsers(this.queryParams).subscribe({
        next: (res) => {
          if (res.success) {
            this.allUsers = res.data;
            this.totalUsers = res.meta.total_count
          }
        },
        error: (err) => console.log(err),
      });
    }

    if (
      this.queryParams.management_type === 'customers' &&
      this.authService.isSuperAdmin.value
    ) {
      // fetch customers if user is SuperAdmin
      this.customerService.getCustomers(this.queryParams).subscribe({
        next: (res) => {
          if (res.success) {
            this.allCustomers = res.data;
            this.totalUsers = res.meta.total_count
          }
        },
        error: (err) => console.log(err),
      });
    }
  }
}
