import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorService {
  public handleError(identifier: string,
                     actionType: any,
                     error: any): Observable<Action> {
    let message = 'Ein allgemeiner Fehler bei den ' + identifier + '  ist aufgetreten. ' + error.message;

    return of(actionType.httpFail({payload: message}));
  }
}
