import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { AuthService } from '../../backend-services/auth.service';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss'],
})
export class ProfilePictureComponent implements OnInit, OnChanges{
  nameString = '';

  @Input() firstName?: string;
  @Input() lastName?: string;
  @Input() is_custom: boolean = false;

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    if (this.firstName || this.lastName || this.is_custom) {
      this.formartNameString(this.firstName ?? '', this.lastName ?? '');
    } else {
      this.authService.profile.subscribe((profile) => {
        if (profile) {
          this.formartNameString(profile.first_name, profile.last_name);
        }
      });
    }
  }

  formartNameString(firstName: string, lastName: string) {
    this.nameString = `${firstName?.charAt(0)}${lastName?.charAt(0)}`;
  }

  ngOnChanges(_: SimpleChanges): void {
    this.formartNameString(this.firstName ?? '', this.lastName ?? '');
  }
}
