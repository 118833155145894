<div class="row w-100" [formGroup]="rootFormGroup">
  <div class="col-12 col-md-6">
    <label style="font-size: 14px; color: #332e45" class="my-2">Abstand</label>
    <div
      class="col gap-3 d-flex flex-row justify-content-start align-items-center"
    >
      <div class="d-flex gap-2 align-items-center">
        <input
          class="form-check-input radio-border"
          type="radio"
          formControlName="marginUnit"
          value="px"
          id="px-2"
          checked
        />
        <label class="form-check-label" for="px-2" style="font-size: 14px">
          px
        </label>
      </div>
      <div class="d-flex gap-2 align-items-center">
        <input
          class="form-check-input radio-border"
          type="radio"
          formControlName="marginUnit"
          id="percentage-2"
          value="%"
        />
        <label
          class="form-check-label"
          for="percentage-2"
          style="font-size: 14px"
        >
          %
        </label>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6">
    <div
      class="d-flex w-100 flex-row justify-content-between align-items-center my-2"
    >
      <label style="font-size: 14px; color: #332e45">Breite</label>
      <label style="font-size: 14px; color: #332e45"
        >{{ rootFormGroup.get("width")?.value }}%</label
      >
    </div>
    <input type="range" class="form-range" formControlName="width" />
  </div>
</div>
