import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromSlidesActions from '../slides/slides.actions';
import { setSlideList } from '../slides/slides.actions';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {
  EndpointService,
  HttpOperation,
} from '../../shared/services/endpoint.service';
import { HttpErrorService } from '../../shared/services/http-error.service';
import { AdTypeService } from '../../ads/ad-type.service';
import { SlideModel } from '../../shared/models/slide.model';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { SlidesService } from '../../ads/ad-template-types/story-ad/slides.service';
import { combineLatest, concatMap, of } from 'rxjs';

@Injectable()
export class SlidesEffects {
  fetchSlides$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromSlidesActions.fetchSlideList),
      switchMap((action) => {
        return combineLatest([
          this.slidesService.executeFetchSlidesRequest(
            action.slideableId,
            this.endpointService,
            this.http,
            this.httpErrorService
          ),
          of(action.operation),
        ]);
      }),
      map(([json, operation]: [any[], HttpOperation]) => {
        let slides = plainToInstance(SlideModel, json);
        // Attach an ordinal number
        slides = slides.map((slide, index) => {
          return {
            ...slide,
            orderNumber: index,
          };
        });
        return setSlideList({ payload: [slides, operation] }) || [];
      })
    );
  });

  addSlides$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromSlidesActions.addSlides),
      map((action) => action.payload),
      mergeMap(([slides, _, slideableId]) => {
        const slidesHttp = instanceToPlain(slides);
        return this.slidesService.executePostSlidesRequest(
          slidesHttp,
          slideableId,
          this.endpointService,
          this.http,
          this.httpErrorService
        );
      })
    );
  });

  duplicateSlides$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromSlidesActions.duplicateSlides),
      map((action) => action.payload),
      switchMap(([originalAd, duplicatedAd]) => {
        const fetchSlides$ = this.slidesService.executeFetchSlidesRequest(
          originalAd.baseable!.id,
          this.endpointService,
          this.http,
          this.httpErrorService
        );
        return combineLatest([fetchSlides$, of(originalAd), of(duplicatedAd)]);
      }),
      concatMap(([slidesHttp, originalAd, newAd]) => {
        const updatedSlides = slidesHttp.map((slideHttp: any) => {
          const slide = plainToInstance(SlideModel, slideHttp);
          this.slidesService.deleteIds(slide);
          const slidePlain = instanceToPlain(slide);
          return slidePlain;
        });
        return this.slidesService.executePostSlidesRequest(
          updatedSlides,
          newAd.baseable!.id,
          this.endpointService,
          this.http,
          this.httpErrorService
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private endpointService: EndpointService,
    private adTypeService: AdTypeService,
    private slidesService: SlidesService,
    private httpErrorService: HttpErrorService
  ) {}
}
