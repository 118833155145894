import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {NewPromotionService} from "../new-promotion.service";

@Component({
  selector: 'app-new-promotion-layout-tab',
  templateUrl: './new-promotion-layout-tab.component.html',
  styleUrls: ['./new-promotion-layout-tab.component.scss']
})
export class NewPromotionLayoutTabComponent implements OnInit {
  constructor(public newPromotionService: NewPromotionService) { }

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.newPromotionService.layoutCards, event.previousIndex, event.currentIndex);
  }
}
