import { Injectable } from '@angular/core';
import { AdBaseModel } from '../../../shared/models/ad.model';
import { NavigationWrapper } from '../../../shared/data-structures/navigation-data';
import { EditAdService } from '../edit-ad.service';

@Injectable({
  providedIn: 'root',
})
export class CommonEditAdBasicService {
  private adModel!: AdBaseModel;

  constructor(private editAdService: EditAdService) {}

  public get AdModel(): AdBaseModel {
    if (!this.adModel || !this.adModel.baseable) {
      throw new Error('Ad model or ad baseable is not defined.');
    }
    return this.adModel;
  }

  public set AdModel(adBaseModel: AdBaseModel) {
    if (!adBaseModel || !adBaseModel.baseable) {
      throw new Error('Provided ad model or ad baseable is not defined.');
    }
    this.adModel = adBaseModel;
  }

  public adSaved(page: NavigationWrapper, generateThumb = true): void {
    if (!this.adModel || !this.adModel.baseable) {
      throw new Error('No ad model or baseable available.');
    }

    if (this.editAdService.adModelUserChange) {
      this.editAdService.adSaved(this.adModel, page, generateThumb);
      this.editAdService.adModelUserChange = false;
    }
  }
}
