import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AdTypeModel} from '../../models/ad-type.model';
import {AdTypeService} from '../../../ads/ad-type.service';
import {Status, Title, Type} from '../../data-repository/global-text-snippets';
import {AdsService} from '../../../ads/ads.service';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {
  FilterBaseableType,
  FilterStatus,
  PickerAdTypeDesignator,
  StoryAdTypeDesignator
} from '../../data-repository/global-constants';
import {SubSink} from 'subsink';
import {FilterMenuService} from './filter-menu.service';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {debounceTime} from 'rxjs';
import {SortAndFilterComposite} from '../../data-structures/sort-and-filter';
import {PickerSlug, StorySlug} from '../../data-repository/api-slugs';
import {Router} from '@angular/router';
import {AdStatus} from '../../data-structures/ad-data-structures';
import {AdStatusMap, BaseableTypeMap} from '../../data-structures/map';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss'],
  exportAs: 'filter-menu'
})
export class FilterMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('filterContainer') public filterContainer!: ElementRef<HTMLElement>;
  public filterForm!: FormGroup;
  public adTypeControls!: FormArray;
  public adStatusControls!: FormArray;
  public adTypes!: ReadonlyArray<AdTypeModel>;
  public AdStatus = AdStatus;

  // Constants
  public Title = Title;
  public Type = Type;
  public Status = Status;

  public filterWidth = 320;
  public FilterStatus = FilterStatus;

  private formMap = new Map<string, FormControl>();
  private sortAndFilter = this.filterMenuService.createDefaultSortAndFilter();
  private subs = new SubSink();
  private formReset = false;
  private baseableTypesFilter = new BaseableTypeMap();
  private adStatusFilter = new AdStatusMap();

  constructor(public filterMenuService: FilterMenuService,
              private adTypeService: AdTypeService,
              private formBuilder: FormBuilder,
              private renderer: Renderer2,
              private router: Router,
              private adsService: AdsService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.initResetFilter();
    this.initEvents();
  }

  ngAfterViewInit(): void {
    this.filterMenuService.openFilter$
      .subscribe(() => {
        this.renderer.setStyle(this.filterContainer.nativeElement, 'width', this.filterWidth + 'px');
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public adTypeSelected(ad: AdTypeModel, $event: MatCheckboxChange): void {
    this.setAdTypeFilter(ad, $event);

    this.filterMenuService.sortAndFilterChanged$.next(this.sortAndFilter);
    this.adsService.fetchAds(this.sortAndFilter);
  }

  public adStatusSelected(item: string, $event: MatCheckboxChange): void {
    if ($event.checked) {
      const key = item as keyof typeof AdStatus;
      this.adStatusFilter.set(item, AdStatus[key]);
    } else {
      this.adStatusFilter.delete(item);
    }

    if (this.adStatusFilter.size > 0) {
      this.sortAndFilter.filter.set(FilterStatus, this.adStatusFilter);
    } else {
      this.sortAndFilter.filter.delete(FilterStatus);
    }

    this.filterMenuService.sortAndFilterChanged$.next(this.sortAndFilter);
    this.adsService.fetchAds(this.sortAndFilter);
  }

  public close(): void {
    this.renderer.setStyle(this.filterContainer.nativeElement, 'width', '0');
  }

  private getDesignator(adType: string): string {
    switch (adType) {
      case StorySlug:
        return StoryAdTypeDesignator;
      case PickerSlug:
        return PickerAdTypeDesignator;
      default:
        throw new Error('Ad type not supported.');
    }
  }

  private initForm() {
    this.adTypeControls = this.formBuilder.array([]);
    this.adStatusControls = this.formBuilder.array([]);
    for (const value in this.AdStatus) {
      let formControl = new FormControl(false);
      this.adStatusControls.push(formControl);
      this.formMap.set(value, formControl);
    }

    this.filterForm = this.formBuilder.group({
      titleFormControl: '',
      adTypeControls: this.adTypeControls,
      adStatusControls: this.adStatusControls
    });

    this.filterForm.controls.titleFormControl.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(value => {
      if (this.formReset) {
        this.formReset = false;
        return;
      }

      const filter = this.sortAndFilter.filter;
      if (value && value !== '') {
        filter.set('like', 'title,' + value);
      } else {
        filter.delete('like');
      }

      this.sortAndFilter = {
        ...this.sortAndFilter,
        filter
      };
      this.filterMenuService.sortAndFilterChanged$.next(this.sortAndFilter);
      this.adsService.fetchAds(this.sortAndFilter)
    });
  }

  private initResetFilter() {
    this.filterMenuService.filterReset$.subscribe((emitSortAndFilterChange) => {
      const updatedSortAndFilter: SortAndFilterComposite = {
        ...this.sortAndFilter,
        filter: new Map<string, string>()
      }
      if (emitSortAndFilterChange) {
        this.filterMenuService.sortAndFilterChanged$.next(updatedSortAndFilter);
      }
      this.filterForm.reset();
      this.formReset = true;
    });
  }

  private initEvents() {
    this.subs.sink = this.adTypeService.getAdTypes()
      .subscribe(adTypes => {
        this.adTypes = adTypes;
        adTypes.forEach(type => {
          const formControl = new FormControl(false);
          this.adTypeControls.push(formControl);
          this.formMap.set(type.name, formControl);
        });
      });

    this.subs.sink = this.filterMenuService.sortAndFilterChanged$
      .subscribe(sortAndFilter => {
        if (!sortAndFilter) {
          return;
        }

        this.sortAndFilter = {
          ...sortAndFilter
        };

        this.updateControls();
      });

    this.subs.sink = this.router.events
      .subscribe(() => {
        this.close();
      });
  }

  private setAdTypeFilter(ad: AdTypeModel, $event: MatCheckboxChange): void {
    const designator = this.getDesignator(ad.name);
    if ($event.checked) {
      this.baseableTypesFilter.set(ad.name, designator);
    } else {
      this.baseableTypesFilter.delete(ad.name);
    }

    if (this.baseableTypesFilter.size > 0) {
      this.sortAndFilter.filter.set(FilterBaseableType, this.baseableTypesFilter);
    } else {
      this.sortAndFilter.filter.delete(FilterBaseableType);
    }
  }

  private updateControls(): void {
    this.filterForm.reset(false, {emitEvent: false});
    this.sortAndFilter.filter
      .forEach((value, _) => {
        if (value instanceof Map) { // Ad Type or status
          value.forEach((_, controlKey) => {
            const control = this.formMap.get(controlKey);
            control!.setValue(true, {emitEvent: false});
          });
        } else { // Ad title
          this.filterForm.patchValue({
            titleFormControl: value.split(',')[1]
          }, {emitEvent: false})
        }
      });
  }
}
