import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AdTypeModel} from '../../shared/models/ad-type.model';

export const selectAdTypes = createSelector(
  createFeatureSelector('adTypes'),
    (adTypes: AdTypeModel[]) => {
      return adTypes;
    }
);

export const selectAdType = (adTypeId: number) => createSelector(selectAdTypes, (adTypes) => {
  return adTypes.find(type => type.id === adTypeId);
});
