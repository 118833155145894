import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Background,
  Color,
  CtaButton,
  Headline,
  NewSlide,
  ReallyDeleteSlide,
  Slides,
  Text,
  Title,
} from '../../../../shared/data-repository/global-text-snippets';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { AdConfigurationItemModel } from '../../../../shared/models/ad-configuration-item.model';
import Sortable, { SortableEvent } from 'sortablejs';
import { EditAdNavigationService } from '../../edit-ad-navigation.service';
import { SlidesService } from '../../../ad-template-types/story-ad/slides.service';
import { SubSink } from 'subsink';
import { ActivatedRoute } from '@angular/router';
import { AdBaseModel, StoryAdModel } from '../../../../shared/models/ad.model';
import { SlideModel } from '../../../../shared/models/slide.model';
import {
  MaxSlideCount,
  StoryAdTypeDesignator,
} from 'src/app/shared/data-repository/global-constants';
import { AdsService } from '../../../ads.service';
import { Actions, ofType } from '@ngrx/effects';
import { map, take } from 'rxjs';
import { RoutingHelperService } from '../../../../shared/routing/routing-helper.service';
import * as fromSlidesActions from '../../../../state/slides/slides.actions';
import { InfoDialogComponent } from '../../../../shared/dialogs/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ImageModel } from '../../../../shared/models/image.model';
import { Content } from '../../../../shared/data-repository/page-mapping';
import { StorySlug } from '../../../../shared/data-repository/api-slugs';
import { CommonEditAdContentService } from '../../common-services/common-edit-ad-content.service';
import { EditAdService, OriginalOptionImageType } from '../../edit-ad.service';
import { AdContentFormControls } from '../../common-services/common-edit-ad-design.service';
import { ImageService } from '../../../../shared/services/image.service';
import {
  ImageCropperData,
  ImageOrigin,
} from '../../../../shared/data-structures/types';
import { CommonEditAdBasicService } from '../../common-services/common-edit-ad-basic.service';
import { ImageComposite } from '../../../../shared/data-structures/image';
import { CropperArea } from '../../../../shared/models/cropper-area';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  templateUrl: './story-ad-content.component.html',
  styleUrls: ['./story-ad-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoryAdContentComponent implements OnInit, OnDestroy {
  @ViewChild('slideList') slideListElRef!: ElementRef<HTMLElement>;
  public slides: Map<number, SlideModel> = new Map();

  // Constants
  public Background = Background;
  public NewSlide = NewSlide;
  public Headline = Headline;
  public Text = Text;
  public MaxSlideCount = MaxSlideCount;

  public StoryAdSlug = StorySlug;
  public displayErroneousInputs = false;
  public contentTemplateForm!: FormGroup;
  public Color = Color;
  public color: ThemePalette = 'primary';
  public ctaBtnConfigurationItem: AdConfigurationItemModel =
    new AdConfigurationItemModel();
  public slideWrapperConfigurationItem = new AdConfigurationItemModel();
  public slideConfigurationItems: AdConfigurationItemModel[] = [];
  public adContentComponent = this;
  public AdContentFormControls = AdContentFormControls;
  public OriginalImageType = OriginalOptionImageType;
  public slideImageCropperData: ImageCropperData = {
    inputWidth: 300,
    inputHeight: 530,
    outputWidth: 300,
    outputHeight: 530,
    isRound: false,
  };

  private readonly SlideIdPrefix = 'slide-';
  private subs = new SubSink();

  constructor(
    private editAdNavigationService: EditAdNavigationService,
    private editAdService: EditAdService,
    private commonEditAdBasicService: CommonEditAdBasicService,
    private commonEditAdContentService: CommonEditAdContentService,
    private adsService: AdsService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private actions$: Actions,
    private dialog: MatDialog,
    private imageService: ImageService,
    private cd: ChangeDetectorRef,
    private routingHelperService: RoutingHelperService,
    private slidesService: SlidesService
  ) {}

  ngOnInit(): void {
    this.initConfigurationItems();
    this.saveAdClicked();
    this.initEvents();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public addSlide(index: number, slideModel?: SlideModel): void {
    if (this.slides.size >= MaxSlideCount) {
      return;
    }

    if (index < 0) {
      throw new Error('Invalid index');
    }

    const newSlideConfigurationItem = new AdConfigurationItemModel();

    if (!slideModel) {
      newSlideConfigurationItem.title = Title;
      slideModel = this.createSlideModel();
    } else {
      newSlideConfigurationItem.title = slideModel.headline;
      const updatedSlideModel = new SlideModel();
      Object.assign(updatedSlideModel, slideModel);
      updatedSlideModel.orderNumber = index;
      slideModel = updatedSlideModel;
    }
    newSlideConfigurationItem.sortable = true;
    newSlideConfigurationItem.cloneable = true;
    newSlideConfigurationItem.removable = true;

    const slideFormArr = this.contentTemplateForm.controls
      .slidesFormArr as FormArray;
    slideFormArr.push(this.createSlideControl(index, slideModel));
    this.slides.set(index, slideModel);
    this.slideConfigurationItems.push(newSlideConfigurationItem);
    this.updateConfigurationItemIds();
    this.slidesService.slideModelsChanged$.next(this.slides);
  }

  public copySlide(orderNumber: number): void | never {
    const index = this.slides.size;
    const slide = this.slides.get(orderNumber);

    if (!slide) {
      throw new Error('Slide is not defined.');
    }

    if (this.commonEditAdContentService.imageFetching) {
      return;
    }

    const newSlide = new SlideModel();
    Object.assign(newSlide, slide);
    newSlide.imageCropped = slide.imageCropped;

    let originalId = -1;
    if (slide.id && slide.id >= 0) {
      originalId = slide.id;
    } else if (slide.imageOrigin.size > 0) {
      // If a slide has not valid id, the origin is used.
      [originalId] = slide.imageOrigin.keys();
    } else {
      this.copy(index, slide, undefined);
      return;
    }

    this.commonEditAdContentService.imageFetching = true;

    if (!slide.imageOriginal && slide.imageOrigin.size === 0) {
      // The original image is not available
      this.subs.sink = this.imageService
        .selectOriginalImage(slide.id!, StorySlug)
        .pipe(take(1))
        .subscribe((image) => {
          const origin: ImageOrigin = [originalId, image.imageOriginal];
          this.copy(index, slide, origin);
          this.commonEditAdContentService.imageFetching = false;
        });
    } else {
      let imageOriginal;

      if (slide.imageOriginal) {
        imageOriginal = slide.imageOriginal;
      } else {
        const imageOriginMap = slide.imageOrigin.get(originalId)!;
        imageOriginal = imageOriginMap.get(OriginalOptionImageType.Background);
      }

      const origin: ImageOrigin = [slide.id!, imageOriginal];
      this.copy(index, slide, origin);
      this.commonEditAdContentService.imageFetching = false;
    }
  }

  public removeSlide(orderNumber: number): void {
    this.subs.sink = this.dialog
      .open(InfoDialogComponent, {
        data: {
          content: ReallyDeleteSlide,
        },
        scrollStrategy: new NoopScrollStrategy(),
      })
      .afterClosed()
      .subscribe((cancelled) => {
        if (!cancelled) {
          this.deleteSlide(orderNumber);
        }
      });
  }

  /**
   * Update configuration item title
   */
  public slideHeadlineChanged(event: Event, slideIndex: number): void {
    const slideTitle = (event.target! as HTMLInputElement).value;
    this.slideConfigurationItems[slideIndex].title = slideTitle;
  }

  public imageChanged(
    [imgComposite, cropperArea]: [ImageComposite | null, CropperArea],
    i: number
  ): void {
    const slide = this.slides.get(i);
    if (!slide) {
      throw new Error('Slide could not be found.');
    }

    const updatedSlide = new SlideModel();
    Object.assign(updatedSlide, slide);
    let updatedImageOriginal = undefined;
    let updatedImageCropped = null;

    if (imgComposite) {
      updatedImageOriginal = imgComposite[0];
      updatedImageCropped = imgComposite[1];
      updatedSlide.imageName = imgComposite[2] || updatedSlide.imageName;
    }

    updatedSlide.imageOriginal = updatedImageOriginal;
    updatedSlide.imageCropped = updatedImageCropped;
    updatedSlide.imageCropperArea = cropperArea;

    this.slides.set(i, updatedSlide);

    // Update ngrx store
    if (slide.id && slide.id >= 0) {
      const updatedOriginalImage = new ImageModel();
      updatedOriginalImage.imageOriginal = updatedImageOriginal;
      updatedOriginalImage.backgroundImageOriginal = undefined;
      updatedOriginalImage.thumbImageOriginal = undefined;
      updatedOriginalImage.id = slide.id;
      this.imageService.updateOriginalImage(updatedOriginalImage);
    }

    this.editAdService.adModelUserChange = true;
    this.slidesService.slideModelsChanged$.next(this.slides);
  }

  public getSlide(orderNumber: number): SlideModel | never {
    const slide = this.slides.get(orderNumber);
    if (!slide) {
      throw new Error('Slide not found.');
    }
    return slide;
  }

  public addNewSlide(): void {
    this.addSlide(this.slides.size);
    this.editAdService.adModelUserChange = true;
  }

  private initForm(adModel: AdBaseModel): void {
    this.contentTemplateForm = this.formBuilder.group({
      ctaBtnText: new FormControl(adModel.ctaButtonText),
      slidesFormArr: new FormArray([]),
    });
    this.commonEditAdContentService.initCTACtrlChanges(
      this.contentTemplateForm
    );

    window.setTimeout(() => {
      Sortable.create(this.slideListElRef.nativeElement, {
        handle: '.drag-handle',
        onEnd: (evt) => this.slideOrderChanged(evt),
      });
    });
  }

  private slideOrderChanged(evt: SortableEvent): void {
    const elements = evt.to.querySelectorAll('.orderNumber');

    const updatedSlides = new Map<number, SlideModel>();
    const updatedConfigurationItems = new Array<AdConfigurationItemModel>();

    elements.forEach((el: Element, index: number) => {
      // Update slides
      const html = el as HTMLInputElement;
      const orderNumber = +html.value;
      const slide = this.slides.get(orderNumber);
      if (!slide) {
        throw new Error('Slide is not defined.');
      }
      let updatedSlide = new SlideModel();
      Object.assign(updatedSlide, slide);
      updatedSlide.orderNumber = index;
      updatedSlides.set(index, updatedSlide);

      // Update configuration items
      const slideConfigurationItem = this.slideConfigurationItems[orderNumber];
      if (!slideConfigurationItem) {
        throw new Error('Slide configuration item is not defined.');
      }
      updatedConfigurationItems.push(slideConfigurationItem);
    });
    this.slides = updatedSlides;
    this.slideConfigurationItems = updatedConfigurationItems;

    this.updateConfigurationItemIds();
    this.slidesService.slideModelsChanged$.next(this.slides);
    this.editAdService.adModelUserChange = true;
  }

  private initConfigurationItems(): void {
    this.slideWrapperConfigurationItem.id = 'slides';
    this.slideWrapperConfigurationItem.title = Slides;
    this.slideWrapperConfigurationItem.hasSubItems = true;
    this.slideWrapperConfigurationItem.collapsed = false;

    this.ctaBtnConfigurationItem.id = 'cta-btn';
    this.ctaBtnConfigurationItem.title = CtaButton;
  }

  private loadSlides(slidableId: number): void {
    this.subs.sink = this.slidesService
      .fetchSlides(slidableId)
      .pipe(take(1))
      .subscribe((slides) => {
        this.slides = new Map<number, SlideModel>();
        slides.forEach((slide, index) => {
          this.addSlide(index, slide);
        });
      });
  }

  private createSlideControl(orderNr: number, slide: SlideModel): FormGroup {
    const headlineText = slide.headline || '';
    const headlineColor = slide.headlineColor || '';
    const slideText = slide.text || '';
    const slideTextColor = slide.textColor || '';
    const backgroundColor = slide.backgroundColor || '';

    const formCtrl = this.formBuilder.group({
      orderNumber: new FormControl(orderNr),
      headlineText: new FormControl(headlineText),
      headlineTextPickerCtrl: new FormControl(headlineColor),
      slideText: new FormControl(slideText),
      slideTextPickerCtrl: new FormControl(slideTextColor),
      backgroundPickerCtrl: new FormControl(backgroundColor),
    });

    formCtrl.valueChanges.subscribe((values) => {
      this.handleSlideFormValueChange(values);
      this.editAdService.adModelUserChange = true;
    });

    return formCtrl;
  }

  private createSlideModel(): SlideModel {
    const newSlideModel = new SlideModel();
    newSlideModel.headline = Title;
    newSlideModel.headlineColor = 'aabbcc';
    newSlideModel.text = Text;
    newSlideModel.textColor = '333333';
    newSlideModel.orderNumber = this.slides.size;
    newSlideModel.slideableType = StoryAdTypeDesignator;
    newSlideModel.slideableId = (
      this.commonEditAdBasicService.AdModel.baseable as StoryAdModel
    ).id;
    delete newSlideModel.id;
    return newSlideModel;
  }

  private handleSlideFormValueChange(values: any): void {
    const orderNr = values.orderNumber;
    const slideModel = this.slides.get(orderNr);
    if (!slideModel) {
      throw new Error('Slide could not be found.');
    }

    const updatedSlideModel = new SlideModel();
    Object.assign(updatedSlideModel, slideModel);
    updatedSlideModel.headline = values.headlineText;
    updatedSlideModel.headlineColor = values.headlineTextPickerCtrl.hex;
    updatedSlideModel.text = values.slideText;
    updatedSlideModel.textColor = values.slideTextPickerCtrl.hex;
    updatedSlideModel.backgroundColor = values.backgroundPickerCtrl.hex;
    updatedSlideModel.imageCropped = slideModel.imageCropped;
    updatedSlideModel.orderNumber = slideModel.orderNumber;
    if (updatedSlideModel.id! < 0) {
      this.slidesService.deleteIds(updatedSlideModel);
    }

    const key = slideModel.orderNumber;
    this.slides.set(key, updatedSlideModel);
    this.slidesService.slideModelsChanged$.next(this.slides);
  }

  private saveAdClicked(): void {
    this.subs.sink = this.editAdNavigationService.savedAdTriggered$.subscribe(
      () => {
        if (!this.editAdService.adModelUserChange) {
          return;
        }

        const currentAdModel = this.commonEditAdBasicService.AdModel;
        const slideArr = Array.from(this.slides, ([_, slideModel]) => {
          let newSlide = new SlideModel();
          Object.assign(newSlide, slideModel);
          if (newSlide.id! < 0) {
            delete newSlide.id;
          }
          return newSlide;
        });

        const adId = currentAdModel.id!;
        const slideableId = (currentAdModel.baseable as StoryAdModel).id;
        this.slidesService.addSlides(slideArr, adId, slideableId);

        this.commonEditAdBasicService.adSaved(Content);
      }
    );
  }

  private initEvents() {
    // Init form
    this.subs.sink = this.editAdService.adModelUpdated$.subscribe((adModel) => {
      if (!adModel) {
        throw new Error('Ad model not defined!');
      }
      this.commonEditAdBasicService.AdModel = adModel;
      if (!this.contentTemplateForm) {
        this.initForm(adModel);
        this.loadSlides(adModel.baseable!.id);
      }
    });

    // Update slide list after adding / updating slide
    this.subs.sink = this.actions$
      .pipe(
        ofType(fromSlidesActions.setSlideList),
        map((response) => response.payload[0])
      )
      .subscribe((slides) => {
        this.slides = new Map<number, SlideModel>();
        slides.forEach((slide, index) => {
          this.slides.set(index, slide);
        });
        this.cd.detectChanges();
      });

    this.subs.sink = this.adsService.navBarInitialized.subscribe((_) => {
      this.cd.detectChanges();
    });
  }

  private deleteSlide(orderNumber: number) {
    this.slides.delete(orderNumber);
    this.slideConfigurationItems.splice(orderNumber, 1);
    const slideFormArr = this.contentTemplateForm.controls
      .slidesFormArr as FormArray;
    slideFormArr.removeAt(orderNumber, { emitEvent: false });
    let updatedSlides = new Map<number, SlideModel>();
    let index = 0;
    this.slides.forEach((slide) => {
      const updatedSlide = new SlideModel();
      Object.assign(updatedSlide, slide);
      updatedSlide.orderNumber = index;
      updatedSlides.set(index, updatedSlide);
      index++;
    });
    this.slides = updatedSlides;
    this.updateConfigurationItemIds();
    this.slidesService.slideModelsChanged$.next(this.slides);
    this.editAdService.adModelUserChange = true;
  }

  private copy(index: number, slide: SlideModel, origin?: ImageOrigin): void {
    let imageOriginal;
    if (slide.imageOriginal) {
      imageOriginal = slide.imageOriginal;
    } else if (origin) {
      imageOriginal = origin[1];
    }

    const newSlide = new SlideModel();
    Object.assign(newSlide, slide);
    if (origin) {
      const id = origin[0];
      const imageMap = new Map();
      imageMap.set(OriginalOptionImageType.Background, origin[1]);
      newSlide.imageOrigin.set(id, imageMap);
    }
    newSlide.imageOriginal = imageOriginal;
    const copiedImageName =
      this.commonEditAdContentService.fileTypesService.copyFileName(
        newSlide.imageName
      );
    newSlide.imageName = copiedImageName ?? '';

    this.slidesService.deleteIds(newSlide);
    this.addSlide(index, newSlide);
    this.editAdService.adModelUserChange = true;
  }

  private updateConfigurationItemIds(): void {
    this.slideConfigurationItems = this.slideConfigurationItems.map(
      (item, index) => {
        const updatedConfigurationItem = new AdConfigurationItemModel();
        Object.assign(updatedConfigurationItem, item);
        updatedConfigurationItem.id = this.SlideIdPrefix + index;
        return updatedConfigurationItem;
      }
    );
    this.cd.detectChanges();
  }
}
