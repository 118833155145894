import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { StyleRenderer, WithStyles } from '@alyle/ui';
import { LyDialogRef } from '@alyle/ui/dialog';
import {
  ImgCropperConfig,
  ImgCropperError,
  ImgCropperErrorEvent,
  ImgCropperEvent,
  ImgCropperLoaderConfig,
  LyImageCropper,
} from '@alyle/ui/image-cropper';
import { STYLES } from './image-cropper-styles';
import {
  Cancel,
  EditImage,
  ImageQuality,
  ImageTooLarge,
  ImageTypeNotSupported,
  Ok,
  SelectCroppingArea,
  UnknownParsingError,
} from '../../data-repository/global-text-snippets';
import {
  SnackBarService,
  SnackBarType,
} from '../../snack-bar/snack-bar.service';
import { FileTypesService } from '../../files/file-types.service';
import { SubSink } from 'subsink';
import { ImageService, ImageType } from '../../services/image.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { ImageCompressorService } from '../../services/image-compressor.service';
import { FileService } from '../../files/file.service';
import { DOCUMENT } from '@angular/common';
import { CropperArea } from '../../models/cropper-area';

@Component({
  styleUrls: ['image-altering-dialog.component.scss'],
  templateUrl: './image-altering-dialog.component.html',
  providers: [StyleRenderer],
})
export class ImageAlteringDialogComponent
  implements WithStyles, OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(LyImageCropper, { static: true }) public cropper!: LyImageCropper;
  // Constants
  public EditImage = EditImage;
  public Cancel = Cancel;
  public Ok = Ok;
  public ImageQuality = ImageQuality;
  public ready = false;

  public readonly classes = this.sRenderer.renderSheet(STYLES, 'root');
  public scale!: number;
  public minScale!: number;
  public cropperArea: CropperArea = {
    id: -1,
    x: 0,
    y: 0,
    scale: 0,
    rotate: 0,
    quality: 100,
  };
  public modifiedOriginalImage!: string; // The original file that will be transformed (translated, scaled, rotated).
  public untouchedOriginalImage!: string; // This is the image that will never be modified (rotated, scaled, etc.).
  public myConfig!: ImgCropperConfig;
  public imageType = ImageType.UNDEFINED;
  public SelectCroppingArea = SelectCroppingArea;

  private dialogClosePending = false;
  private compressedOriginalImage!: string; // The compressed original image to display in the dialog.
  private maxImageFileSize = 0;
  private subs = new SubSink();
  private cropperAreaSet$ = new BehaviorSubject<CropperArea | null>(null);
  private initialized = false;

  constructor(
    public dialogRef: LyDialogRef,
    public readonly sRenderer: StyleRenderer,
    private fileTypesService: FileTypesService,
    private imageService: ImageService,
    private snackBarService: SnackBarService,
    private imageCompressorService: ImageCompressorService,
    private fileService: FileService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.initCropper();
  }

  ngAfterViewInit() {
    const imageData$ = this.imageService.originalImageData$;
    const dialogAfterOpened$ = this.dialogRef.afterOpened;
    // Load image when dialog animation has finished
    this.subs.sink = combineLatest([
      imageData$,
      this.cropperAreaSet$,
      dialogAfterOpened$,
    ]).subscribe(([imageData, cropperArea, _]) => {
      if (!imageData) {
        return;
      }

      if (cropperArea) {
        this.cropperArea = {
          ...cropperArea,
        };
      }

      if (typeof imageData === 'string') {
        this.imageType = this.imageService.getImageType(imageData);
        this.loadExistingImage(imageData);
      } else {
        this.cropperArea.quality = 100;
        this.cropper.selectInputEvent(imageData);
      }
    });

    this.initEvents();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public onError(e: ImgCropperErrorEvent): void {
    let message = '';
    switch (e.error) {
      case ImgCropperError.Size:
        message = ImageTooLarge + this.maxImageFileSize + ' MB';
        break;
      case ImgCropperError.Type:
        message = ImageTypeNotSupported;
        break;
      default:
        message = message = UnknownParsingError;
    }
    this.dialogRef.close();
    this.snackBarService.openSnackBar(message, SnackBarType.Error);
  }

  public rotate(degrees: number) {
    this.cropper.rotate(degrees);
    this.cropperArea.rotate += degrees;
    const updateOriginalImage = (rotatedImage: string) => {
      this.modifiedOriginalImage = rotatedImage;
    };
    this.imageService.rotateBase64Image90Degree(
      this.imageType,
      this.modifiedOriginalImage,
      degrees,
      updateOriginalImage
    );
  }

  public qualityChanged(quality: number): any {
    const callback = (blob: Blob) => {
      this.fileService.toBase64FromBlob(blob);
    };

    if (!this.modifiedOriginalImage) {
      throw new Error('Original image is invalid.');
    }

    this.cropperArea.quality = quality;
    const blob = this.fileService.toBlob(
      this.modifiedOriginalImage,
      'image/' + this.imageType
    );
    this.imageCompressorService.compress(
      blob,
      quality,
      this.imageType,
      callback
    );
  }

  public close() {
    this.dialogClosePending = true;
    this.cropper.crop(this.myConfig);
  }

  public cropperCleaned(): void {
    this.cropper.loadImage({
      scale: this.cropperArea.scale,
      xOrigin: this.cropperArea.x,
      yOrigin: this.cropperArea.y,
      originalDataURL: this.compressedOriginalImage,
    });
  }

  // Called when user clicks 'OK' in cropper dialog
  public cropped($event: ImgCropperEvent): void {
    if (!this.dialogClosePending) {
      return;
    }
    // Compress the cropped image and return it.
    const callback = (imageBlob: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(imageBlob);
      reader.onloadend = () => {
        $event.rotation = this.cropperArea.rotate;
        $event.dataURL = reader.result as string;
        const closeParams = [
          $event,
          this.cropperArea.quality,
          this.untouchedOriginalImage,
        ];
        this.zone.run(() => {
          this.dialogRef.close(closeParams);
          this.initialized = false;
        });
      };
    };
    const blob = this.fileService.toBlob(
      $event.dataURL!,
      'image/' + this.imageType
    );

    this.imageCompressorService.compress(
      blob,
      this.cropperArea.quality,
      this.imageType,
      callback
    );
  }

  private initCropper(): void {
    this.subs.sink = combineLatest([
      this.fileTypesService.getMaxImageFileSize(),
      this.imageService.imageCropperConfig$,
    ]).subscribe(([maxImageFileSize, [configData, cropperArea]]) => {
      this.myConfig = {
        antiAliased: false,
        output: {
          width: configData!.outputWidth,
          height: configData!.outputHeight,
        },
        fill: null,
        width: configData!.inputWidth,
        height: configData!.inputHeight,
        round: configData!.isRound,
        maxFileSize: maxImageFileSize * Math.pow(10, 6),
        responsiveArea: true,
      };
      this.maxImageFileSize = maxImageFileSize;
      this.cropperAreaSet$.next(cropperArea);
    });

    this.cropper.cropped.subscribe((imgCropperEvent) => {
      this.cropperArea = {
        id: this.cropperArea.id,
        x: imgCropperEvent.xOrigin,
        y: imgCropperEvent.yOrigin,
        scale: imgCropperEvent.scale,
        rotate: this.cropperArea.rotate,
        quality: this.cropperArea.quality,
      };
      this.cropper.clean();
    });

    /**
     * Needed to refresh the image cropper after changing image quality
     */
    this.subs.sink = this.cropper.imageLoaded.subscribe((cropperEvent) => {
      this.ready = true;
      // Set original image data only for the first time.
      if (!this.initialized) {
        this.untouchedOriginalImage = this.untouchedOriginalImage
          ? this.untouchedOriginalImage
          : cropperEvent.originalDataURL!;
        this.imageType =
          this.imageType === ImageType.UNDEFINED
            ? this.imageService.getImageType(this.untouchedOriginalImage)
            : this.imageType;

        this.modifiedOriginalImage = cropperEvent.originalDataURL!;
        this.compressedOriginalImage = cropperEvent.originalDataURL!;
        this.initialized = true;
      } else {
        this.zone.runOutsideAngular(() => {
          this.cd.detectChanges();
          window.setTimeout(() => {
            this.zone.onStable.emit();
          });
        });
      }
    });

    this.subs.sink = this.cropper.error.subscribe((error) => {
      throw new Error('Image Cropper error occurred: ' + error.errorMsg);
    });
  }

  private loadExistingImage(imageData: string) {
    this.untouchedOriginalImage = imageData;

    let callback = (rotatedOriginalImage: string) => {
      const loaderConfig: ImgCropperLoaderConfig = {
        xOrigin: this.cropperArea.x,
        yOrigin: this.cropperArea.y,
        originalDataURL: rotatedOriginalImage,
        scale: +this.cropperArea.scale,
        type: 'image/' + this.imageType,
      };

      this.cropper.loadImage(loaderConfig);
      this.myConfig.type = 'image/' + this.imageType;
      this.modifiedOriginalImage = rotatedOriginalImage;
      this.qualityChanged(this.cropperArea.quality);
    };
    // Rotate original image to prevent flickering during image quality change.
    this.imageService.rotateBase64Image90Degree(
      this.imageType,
      imageData,
      +this.cropperArea.rotate,
      callback
    );
  }

  private initEvents(): void {
    // Called when image quality is changed.
    this.subs.sink = this.fileService.base64Encoded$.subscribe(
      (compressedImgBase64) => {
        this.compressedOriginalImage = compressedImgBase64;
        this.dialogClosePending = false;
        this.cropper.crop();
      }
    );
  }
}
