import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ImageModel} from '../../shared/models/image.model';
import {OriginalOptionImageType} from '../../ads/edit-ad/edit-ad.service';
import {PickerSlug, StorySlug} from '../../shared/data-repository/api-slugs';

export const selectImage = createSelector(
  createFeatureSelector('image'),
  (originalImage: ImageModel[]) => {
    return originalImage;
  }
);

export const selectFilteredImage = (imageWrapperId: number, adTypeName: string, imageTypes?: Array<OriginalOptionImageType>) => createSelector(selectImage, (images) => {
  if (adTypeName === StorySlug) {
    return images
      .filter(image => image.id === imageWrapperId)
      .pop();
  } else if (adTypeName === PickerSlug) {
    let image = images
      .filter(image => image.id === imageWrapperId)
      .pop();

    if (!image) {
      return undefined;
    }

    let originalImage = undefined;
    imageTypes?.forEach(imageType => {
      if (imageType === OriginalOptionImageType.Thumb &&
        image!.thumbImageOriginal !== null &&
        typeof image!.thumbImageOriginal !== 'undefined') {
        originalImage = image;
      } else if (imageType === OriginalOptionImageType.Background &&
        image!.backgroundImageOriginal !== null &&
        typeof image!.backgroundImageOriginal !== 'undefined') {
        originalImage = image;
      }
    });
    return originalImage;
  } else {
    return images.pop();
  }
});
