import {Component, HostBinding, Input} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-block-styles-view',
  templateUrl: './block-styles-view.component.html',
  styleUrls: ['./block-styles-view.component.scss'],
})
export class BlockStylesViewComponent {
  @HostBinding('class.w-100')
  @Input() rootFormGroup!: FormGroup;

  constructor() {}
}
