import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectFontTypes,
  selectImageTypes,
  selectMaxFontFileSize,
  selectMaxImageFileSize,
} from '../../state/file-types/file-types.selectors';
import { Actions, ofType } from '@ngrx/effects';
import * as fromFileTypesActions from '../../state/file-types/file-types.actions';
import { SnackBarService, SnackBarType } from '../snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class FileTypesService {
  private static fileNameCounter = 0;

  constructor(
    private store: Store,
    private actions$: Actions,
    private snackBarService: SnackBarService
  ) {
    this.initEvents();
  }

  public getImageTypes(): Observable<string[]> {
    return this.store.select(selectImageTypes());
  }

  public getFontTypes(): Observable<ReadonlyArray<string>> {
    return this.store.select(selectFontTypes());
  }

  public getMaxImageFileSize(): Observable<number> {
    return this.store.select(selectMaxImageFileSize());
  }

  public getMaxFontFileSize(): Observable<number> {
    return this.store.select(selectMaxFontFileSize());
  }

  public copyFileName(imageName?: string): string | undefined {
    if (!imageName) {
      return undefined;
    }

    const fileTuple = imageName.split('.');
    let fileName = fileTuple[0];
    const fileType = fileTuple[1];

    fileName = fileName + '-' + ++FileTypesService.fileNameCounter;
    return fileName + '.' + fileType;
  }

  private initEvents() {
    this.actions$
      .pipe(
        ofType(fromFileTypesActions.httpFail),
        map((response) => response.payload)
      )
      .subscribe((message: string) => {
        this.snackBarService.openSnackBar(message, SnackBarType.Error);
      });
  }
}
