import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminLayoutService {
  public menuItemsClosed: Subject<boolean> = new Subject();
  public sideBarMoved: BehaviorSubject<number> = new BehaviorSubject(0);
  public viewportResized: Subject<any> = new Subject();
  public windowScrolled = new Subject<[number, number]>();
  public mouseWheelTriggered = new Subject();
}
