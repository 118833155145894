<div id="panel-wrapper" class="wrapper h-100 d-flex flex-column" appScroll appAdminLayoutResize [aside]="aside"
     [main]="main">
  <div id="content-wrapper" class="d-flex flex-grow-1 position-relative">
    <aside class="sidebar sidebar-vertical desktop-expand fixed-start d-flex w-100 h-100"
           data-html2canvas-ignore="true"
           #aside
           [hidden]="!showLayoutComponents"
           (transitionrun)="alignAppBar($event)"
           (transitionend)="alignAppBar($event)">
      <app-sidebar class="position-relative"
                   [aside]="aside"
                   [nav]="nav"
                   [main]="main"></app-sidebar>
    </aside>
    <main id="main-panel"
          class="flex-grow-1"
          [class.isFullscreen]="!showLayoutComponents"
          #main>
      <nav id="navbar" class="navbar navbar-main navbar-expand-lg position-fixed"
           data-html2canvas-ignore="true"
           [hidden]="!showLayoutComponents"
           #nav>
        <app-bar></app-bar>
      </nav>
      <div id="main-content"
           class="h-100 position-relative"
           [style.padding-right]="noPaddingRight ? '0px': ''">
        <router-outlet></router-outlet>
      </div>
    </main>
  </div>
</div>
