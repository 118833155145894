import {AfterViewChecked, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AdminLayoutService} from './admin-layout.service';
import {RoutingHelperService} from '../../shared/routing/routing-helper.service';
import {NavigationEnd, Router, UrlSegment} from '@angular/router';
import {AdPreview, MyAds} from '../../shared/data-repository/page-mapping';
import {SubSink} from 'subsink';
import {filter, map} from 'rxjs/operators';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, AfterViewChecked {
  @ViewChild('nav') nav!: ElementRef<HTMLElement>;
  @ViewChild('aside') aside!: ElementRef<HTMLElement>;
  @ViewChild('main') main!: ElementRef<HTMLElement>;
  public showLayoutComponents = true;
  public noPaddingRight = false;

  private subs = new SubSink();

  constructor(private adminLayoutService: AdminLayoutService,
              private renderer: Renderer2,
              private routingHelperService: RoutingHelperService,
              private router: Router) {
  }

  ngOnInit(): void {
    const segments = this.routingHelperService.getURLSegments(this.router.url);
    if (segments && segments[1]?.path === AdPreview.route) {
      this.showLayoutComponents = false;
    }
    this.setRightPadding(segments);

    this.subs.sink = this.adminLayoutService.viewportResized
      .subscribe(() => this.calcAppBarWidth());

    this.subs.sink = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(_ => this.router.routerState)
      )
      .subscribe(data => {
        const segments = this.routingHelperService.getURLSegments(data.snapshot.url);
        this.setRightPadding(segments);
      });
  }

  ngAfterViewChecked(): void {
    this.adminLayoutService.sideBarMoved.next(this.aside.nativeElement.clientWidth);
    this.calcAppBarWidth();
  }

  public alignAppBar($event: TransitionEvent): void {
    if ($event.target && $event.propertyName === 'max-width') {
      this.adminLayoutService.sideBarMoved.next(this.aside.nativeElement.clientWidth);
    }
  }

  private calcAppBarWidth(): void {
    this.renderer.setStyle(this.nav.nativeElement, 'width', this.main.nativeElement.clientWidth + 'px');
  }

  private setRightPadding(segments: UrlSegment[] | null): void {
    if (segments && segments[1]?.path === MyAds.route) {
      this.noPaddingRight = true;
    } else {
      this.noPaddingRight = false;
    }
  }
}
