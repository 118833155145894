import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdsService} from '../ads.service';
import {AdTypeModel} from '../../shared/models/ad-type.model';
import {InfoBoxModel} from '../../shared/models/info-box.model';
import {MatDialog} from '@angular/material/dialog';
import {CreateAdDialogComponent} from '../../shared/dialogs/create-ad-dialog/create-ad-dialog.component';
import {NewAd} from 'src/app/shared/data-repository/global-text-snippets';
import {AdboxRoutes} from '../../shared/data-repository/routes';
import {DomSanitizer} from '@angular/platform-browser';
import {SubSink} from 'subsink';
import {AdTypeService} from '../ad-type.service';
import {UrlSegmentDelimiter} from '../../shared/data-repository/global-constants';
import {AdsInfoBoxService} from '../ads-info-box.service';

@Component({
  selector: 'app-create-ad',
  templateUrl: './create-ad.component.html',
  styleUrls: ['./create-ad.component.scss']
})
export class CreateAdComponent implements OnInit, OnDestroy {
  // Constants
  public NewAd = NewAd;
  public UrlSegmentDelimiter = UrlSegmentDelimiter;

  public AdboxRoutes = AdboxRoutes;
  public adTypes: ReadonlyArray<AdTypeModel> = [];
  public infoBoxes: InfoBoxModel[] = [];
  private subs: SubSink = new SubSink();

  constructor(public dialog: MatDialog,
              public sanitizer: DomSanitizer,
              private adsService: AdsService,
              private adsInfoBoxService: AdsInfoBoxService,
              private adTypeService: AdTypeService) {
  }

  ngOnInit(): void {
    this.subs.sink = this.adTypeService.getAdTypes()
      .subscribe(adTypes => {
        this.adTypes = adTypes;
      });
    this.infoBoxes = this.adsInfoBoxService.CreateAdInfoBoxes;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public openDialog(adType?: AdTypeModel): void {
    this.dialog.open(CreateAdDialogComponent, {
      width: '376px',
      data: {
        adType
      }
    });
  }

}
