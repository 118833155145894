import { Component } from '@angular/core';
import { NewPromotionService } from '../new-promotion.service';
import {
  PromotionBlock,
  PromotionService,
} from '../../../backend-services/promotion.service';

@Component({
  selector: 'app-add-new-block-modal',
  templateUrl: './add-new-block-modal.component.html',
  styleUrls: ['./add-new-block-modal.component.scss'],
})
export class AddNewBlockModalComponent {
  promotionBlocks: PromotionBlock[] = [];

  constructor(
    private newPromotionService: NewPromotionService,
    private promotionService: PromotionService
  ) {
    this.promotionService.getBlocks().subscribe((res) => {
      this.promotionBlocks = res.data;
    });
  }

  addNewBlock(type: string, block_id: number): void {
    this.newPromotionService.addNewBlock({ type, block_id });
  }
}
