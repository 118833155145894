<h1 class="p-4 mb-0">{{ EditImage }}</h1>
<div class="d-flex">
  <div class="left">
    <div ly-dialog-content [lyPx]="0">
      <ly-img-cropper
        [config]="myConfig"
        [(scale)]="scale"
        (cropped)="cropped($event)"
        (cleaned)="cropperCleaned()"
        (minScale)="minScale = $event"
        (error)="onError($event)"
      >
        <span>Drag and drop image</span>
      </ly-img-cropper>
    </div>
  </div>
  <div class="right flex-grow-1 d-flex flex-column ps-5">
    <h3 class="mt-2">{{SelectCroppingArea}}</h3>
    <div
      id="image-altering-controls"
      class="d-flex justify-content-between"
      *ngIf="ready"
    >
      <a
        (click)="rotate(-90)"
        class="material-icons text-decoration-none user-select-none"
        style="transform: rotateY(180deg)"
      >refresh</a
      >
      <a
        (click)="rotate(90)"
        class="material-icons text-decoration-none user-select-none"
      >refresh</a
      >
      <a
        (click)="cropper.center(); cropper.fitToScreen()"
        class="material-icons text-decoration-none user-select-none"
      >fit_screen</a
      >
      <a
        (click)="cropper.zoomIn()"
        class="material-icons text-decoration-none user-select-none"
      >zoom_in</a
      >
      <a
        (click)="cropper.zoomOut()"
        class="material-icons text-decoration-none user-select-none"
      >zoom_out</a
      >
    </div>

    <div id="image-quality-slider" class="mt-4">
      <div class="example-label-container d-flex justify-content-between">
        <label id="example-name-label" class="example-name-label">{{
          ImageQuality
          }}</label>
        <label class="example-value-label">{{ cropperArea.quality }} %</label>
      </div>
      <mat-slider
        [(ngModel)]="cropperArea.quality"
        class="ps-0 pe-0"
        (ngModelChange)="qualityChanged($event)"
      ></mat-slider>
    </div>

    <div
      class="mt-auto"
      *ngIf="ready"
      [lyJustifyContent]="'flex-end'"
      ly-dialog-actions>
      <button mat-button (click)="this.dialogRef.close()">{{ Cancel }}</button>
      <button
        mat-button
        (click)="close()">
        {{ Ok }}
      </button>
    </div>

  </div>
</div>
