import {
  AdBaseableModel,
  AdStatus,
} from '../data-structures/ad-data-structures';
import { Exclude, Expose, Type } from 'class-transformer';
import { PickerSlug, StorySlug } from '../data-repository/api-slugs';

export class StoryAdModel {
  id = -1;
  @Expose({ name: 'headline_font_type' })
  headlineFontType = '';
  @Expose({ name: 'headline_font_size' })
  headlineFontSize = -1;
  @Expose({ name: 'text_font_type' })
  textFontType = '';
  @Expose({ name: 'text_font_size' })
  textFontSize = -1;
  @Expose({ name: 'animation_type' })
  animationType = '';
  @Expose({ name: 'animation_duration' })
  animationDuration = -1;
  @Expose({ name: 'repetition_count' })
  repetitionCount = -1;
}

export class PickerAdModel {
  id = -1;
  @Expose({ name: 'headline_content' })
  headlineContent = '';
  @Expose({ name: 'headline_font_type' })
  headlineFontType = '';
  @Expose({ name: 'headline_font_size' })
  headlineFontSize = -1;
  @Expose({ name: 'headline_font_color' })
  headlineFontColor = -1;
  @Expose({ name: 'continuous_text_content' })
  continuousTextContent = '';
  @Expose({ name: 'continuous_text_font_type' })
  continuousTextFontType = '';
  @Expose({ name: 'continuous_text_font_size' })
  continuousTextFontSize = -1;
  @Expose({ name: 'continuous_text_font_color' })
  continuousTextFontColor = -1;
  @Expose({ name: 'info_text_font_type' })
  infoTextFontType = '';
  @Expose({ name: 'info_text_font_size' })
  infoTextFontSize = -1;
  @Expose({ name: 'animation_type' })
  animationType = '';
}

export class AdBaseModel {
  id? = -1;
  @Expose({ name: 'created_at' })
  created? = new Date();
  title? = '';
  @Expose({ name: 'status' })
  status? = AdStatus.AdStatusNew;
  @Expose({ name: 'ad_type_id' })
  adTypeId? = -1;
  @Expose({ name: 'baseable_id' })
  baseableId? = -1;
  @Expose({ name: 'baseable_type' })
  baseableType? = '';
  width? = -1;
  height? = -1;
  logo? = '';
  @Expose({ name: 'logo_position_left' })
  logoPositionLeft? = 0;
  @Expose({ name: 'logo_position_top' })
  logoPositionTop? = 0;
  @Expose({ name: 'logo_position_bottom' })
  logoPositionBottom? = 0;
  @Expose({ name: 'logo_position_right' })
  logoPositionRight? = 0;
  @Expose({ name: 'logo_image_name' })
  logoImageName? = '';
  @Expose({ name: 'horizontal_logo_alignment' })
  horizontalLogoAlignment? = '';
  @Expose({ name: 'vertical_logo_alignment' })
  verticalLogoAlignment? = '';
  @Expose({ name: 'cta_button_text_font_type' })
  ctaButtonTextFontType? = '';
  @Expose({ name: 'cta_button_text_size' })
  ctaButtonTextSize? = -1;
  @Expose({ name: 'cta_button_text_color' })
  ctaButtonTextColor? = '';
  @Expose({ name: 'cta_button_text' })
  ctaButtonText? = '';
  @Expose({ name: 'cta_button_background_color' })
  ctaButtonBackgroundColor? = '';
  @Expose({ name: 'background_color' })
  backgroundColor? = '';
  thumbnail? = '';
  publisher? = '';
  @Expose({ name: 'is_template' })
  isTemplate? = false;

  @Type(() => AdBaseModel, {
    discriminator: {
      property: '__type',
      subTypes: [
        { value: StoryAdModel, name: StorySlug },
        { value: PickerAdModel, name: PickerSlug },
      ],
    },
  })
  @Exclude({ toPlainOnly: true })
  baseable?: AdBaseableModel;
}
