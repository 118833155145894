import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ActivateTracking,
  GDN,
  NoDownloadAfterEdit,
  Preview,
  Publisher,
  Remark,
  TargetLink,
} from 'src/app/shared/data-repository/global-text-snippets';
import { InfoBoxModel } from '../../../shared/models/info-box.model';
import { AdConfigurationItemModel } from '../../../shared/models/ad-configuration-item.model';
import { PublisherOptions } from 'src/app/shared/data-repository/dropdown-options';
import { MatSelectChange } from '@angular/material/select';
import { AdBaseModel } from '../../../shared/models/ad.model';
import { InfoDialogComponent } from '../../../shared/dialogs/info-dialog/info-dialog.component';
import { EditAdNavigationService } from '../edit-ad-navigation.service';
import { EditAdService } from '../edit-ad.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonEditAdBasicService } from './common-edit-ad-basic.service';
import { HTMLAdComposite } from '../ad-reflection/ad-generate-html.directive';
import { AdsService } from '../../ads.service';
import { AdStatus } from '../../../shared/data-structures/ad-data-structures';
import { UrlSegmentDelimiter } from '../../../shared/data-repository/global-constants';
import { AdboxRoutes } from '../../../shared/data-repository/routes';
import { OverlayService } from '../../../shared/services/overlay.service';
import moment from 'moment';
import { AdTypeService } from '../../ad-type.service';

@Injectable({
  providedIn: 'root',
})
export class CommonEditAdDownloadService {
  // Constants
  public Publisher = Publisher;
  public TargetLink = TargetLink;
  public ActivateTracking = ActivateTracking;
  public PublisherOptions = PublisherOptions;

  public downloadTemplateForm!: FormGroup;
  public displayErroneousInputs = false;
  public Remark = Remark;
  public downloadAdInfoBox!: InfoBoxModel;

  public adDownloaded = false;
  public previewConfigurationItem = new AdConfigurationItemModel();
  public downloadConfigurationItem = new AdConfigurationItemModel();
  public trackingInvisible = false;
  public openPreviewLink = '';

  constructor(
    private editAdNavigationService: EditAdNavigationService,
    private editAdService: EditAdService,
    private adsService: AdsService,
    private adTypeService: AdTypeService,
    private overlayService: OverlayService,
    private commonEditAdBasicService: CommonEditAdBasicService
  ) {}

  public publisherChanged($event: MatSelectChange): void {
    this.trackingInvisible = $event.source.triggerValue === GDN;
    this.editAdService.adModelUserChange = true;
  }

  public copyToClipboard(): void {
    navigator.clipboard.writeText(this.openPreviewLink);
  }

  public initConfigurationItems(): void {
    this.previewConfigurationItem.id = 'preview';
    this.previewConfigurationItem.title = Preview;
    this.previewConfigurationItem.collapsed = false;

    this.downloadConfigurationItem.id = 'publisher';
    this.downloadConfigurationItem.title = Publisher;
  }

  public initForm(): void {
    this.downloadTemplateForm = new FormGroup({
      publisher: new FormControl(),
      targetLink: new FormControl('', [
        Validators.required,
        Validators.pattern(
          '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
        ),
      ]),
    });
    this.editAdNavigationService.saveOrDownloadBtnDisabled$.next(true);

    if (this.PublisherOptions.length > 0) {
      this.downloadTemplateForm.controls.publisher.patchValue(
        this.PublisherOptions[0].key
      );
    }

    this.initFormCtrlChanges();
  }

  public openDialog(dialog: MatDialog, htmlComposite: HTMLAdComposite): void {
    dialog
      .open(InfoDialogComponent, {
        data: {
          content: NoDownloadAfterEdit,
        },
      })
      .afterClosed()
      .subscribe((cancelled) => {
        if (!cancelled) {
          const currentAdModel = this.commonEditAdBasicService.AdModel;
          if (!currentAdModel) {
            throw new Error('Ad model is not defined.');
          }
          this.adsService.downloadAd(currentAdModel.id!, htmlComposite);
        }
      });
  }

  public downloadAd(zip: Blob): void {
    const currentAdModel = this.commonEditAdBasicService.AdModel;
    this.adTypeService.getAdTypeName(currentAdModel).subscribe((adTypeName) => {
      const link = document.createElement('a');
      const today = moment().format('DD-MM-YYYY');

      link.href = URL.createObjectURL(zip);
      link.download =
        today + '_' + adTypeName + '_300x600_' + currentAdModel.title + '.zip';
      link.target = '_blank';
      link.setAttribute('type', 'hidden');
      document.body.appendChild(link);

      link.click();
      link.remove();

      const updatedAdModel = new AdBaseModel();
      Object.assign(updatedAdModel, currentAdModel);
      updatedAdModel.status = AdStatus.AdStatusDone;
      this.adsService.updateAd(
        updatedAdModel,
        updatedAdModel.baseable!,
        currentAdModel.id,
        false
      );
      this.overlayService.hideOverlay();
      this.adDownloaded = true;
    });
  }

  public updateForm(adModel: AdBaseModel): void {
    this.commonEditAdBasicService.AdModel = adModel!;
    if (!this.downloadTemplateForm) {
      this.initForm();
    }
    this.openPreviewLink =
      window.origin +
      UrlSegmentDelimiter +
      AdboxRoutes.Ads +
      UrlSegmentDelimiter +
      AdboxRoutes.AdPreview +
      UrlSegmentDelimiter +
      this.commonEditAdBasicService.AdModel.id;
  }

  private initFormCtrlChanges(): void {
    this.downloadTemplateForm.valueChanges.subscribe((_) => {
      if (!this.downloadTemplateForm.valid) {
        this.editAdNavigationService.saveOrDownloadBtnDisabled$.next(true);
        return;
      }

      const curIndex = this.editAdNavigationService.getPageIndex();
      this.editAdNavigationService.editAdPagesSaved[curIndex] = false;

      this.editAdNavigationService.saveOrDownloadBtnDisabled$.next(false);
      this.editAdService.adModelUserChange = true;
    });
  }
}
