import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromAdsActions from '../state/ads/ads.actions';
import { addAd } from '../state/ads/ads.actions';
import { AdBaseModel } from '../shared/models/ad.model';
import { Actions, ofType } from '@ngrx/effects';
import { map, switchMap, take } from 'rxjs/operators';
import { selectAd } from '../state/ads/ads.selectors';
import { AdBaseableModel } from '../shared/data-structures/ad-data-structures';
import { HTMLAdComposite } from './edit-ad/ad-reflection/ad-generate-html.directive';
import { HttpOperation } from '../shared/services/endpoint.service';
import { SortAndFilterComposite } from '../shared/data-structures/sort-and-filter';
import { FilterMenuService } from '../shared/menus/filter-menu/filter-menu.service';
import {
  SnackBarService,
  SnackBarType,
} from '../shared/snack-bar/snack-bar.service';
import { PaginationComposite } from '../shared/data-structures/pagination';
import { ItemsPerPage } from '../shared/data-repository/global-constants';
import { AdTypeService } from './ad-type.service';
import {
  AdSuccessfullyCreated,
  AdSuccessfullyDeleted,
} from '../shared/data-repository/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class AdsService {
  public configurationItemWidthUpdated = new BehaviorSubject<number>(0);
  public navBarInitialized = new Subject();

  constructor(
    private store: Store,
    private snackBarService: SnackBarService,
    private filterMenuService: FilterMenuService,
    private adTypeService: AdTypeService,
    private actions$: Actions
  ) {
    this.initEvents();
  }

  public fetchAds(
    sortAndFilter?: SortAndFilterComposite,
    pagination?: PaginationComposite
  ): Observable<[readonly AdBaseModel[], HttpOperation, number]> {
    if (!pagination) {
      pagination = {
        currentPage: 1,
        perPage: ItemsPerPage,
      };
    }
    if (!sortAndFilter || !sortAndFilter.sort) {
      const defaultSortAndFilter =
        this.filterMenuService.createDefaultSortAndFilter();
      sortAndFilter = {
        sort: defaultSortAndFilter.sort,
        filter: new Map<string, string>(),
      };
    }
    this.store.dispatch(
      fromAdsActions.fetchAdsList({
        payload: [null, HttpOperation.Get, sortAndFilter, pagination],
      })
    );
    return this.actions$.pipe(
      ofType(fromAdsActions.setAdsList),
      map((result) => result.payload)
    );
  }

  public fetchAd(id: number): Observable<[AdBaseModel, HttpOperation]> | never {
    const ad$ = this.store.select(selectAd(id));
    return ad$.pipe(
      take(1),
      switchMap((ad) => {
        if (typeof ad === 'undefined') {
          this.store.dispatch(
            fromAdsActions.fetchSingleAd({
              payload: id,
            })
          );
          return this.actions$.pipe(
            ofType(fromAdsActions.setAdsList),
            map((response) => {
              const adModels = response.payload[0];
              if (!adModels || adModels.length === 0) {
                throw new Error('Ad could not be fetched from server.');
              }
              return [adModels[0], response.payload[1]];
            })
          );
        } else {
          return of([ad, HttpOperation.None]);
        }
      }),
      map((result) => {
        return [result[0] as AdBaseModel, result[1] as HttpOperation];
      })
    );
  }

  public createAd(title: string, adTypeId: number): void {
    const newAd: AdBaseModel = {
      adTypeId,
      title,
    };

    this.store.dispatch(
      addAd({
        payload: newAd,
      })
    );
  }

  public duplicateAd(ad: AdBaseModel): void {
    const duplicatedAd = new AdBaseModel();
    Object.assign(duplicatedAd, ad);
    this.store.dispatch(
      fromAdsActions.duplicateAd({
        payload: duplicatedAd,
      })
    );
  }

  public updateAd(
    adBaseModel: AdBaseModel,
    adBaseable: AdBaseableModel,
    filterId: number | null = null,
    reloadAds: boolean
  ): void {
    this.store.dispatch(
      fromAdsActions.updateAd({
        payload: [adBaseModel, adBaseable, filterId, reloadAds],
      })
    );
  }

  public deleteAd(adId: number): void {
    this.store.dispatch(fromAdsActions.deleteAd({ payload: adId }));
  }

  public downloadAd(adId: number, htmlComposite: HTMLAdComposite): void {
    this.store.dispatch(
      fromAdsActions.initializeAdDownload({ payload: [adId, htmlComposite] })
    );
  }

  private initEvents() {
    this.actions$
      .pipe(
        ofType(fromAdsActions.setAdsList),
        map((response) => response.payload)
      )
      .subscribe((payload) => {
        if (payload[1] === HttpOperation.Post) {
          this.snackBarService.openSnackBar(
            AdSuccessfullyCreated,
            SnackBarType.Info
          );
        } else if (payload[1] === HttpOperation.Delete) {
          this.snackBarService.openSnackBar(
            AdSuccessfullyDeleted,
            SnackBarType.Info
          );
        }
      });

    this.actions$
      .pipe(
        ofType(fromAdsActions.httpFail),
        map((response) => response.payload)
      )
      .subscribe((message: string) => {
        this.snackBarService.openSnackBar(message, SnackBarType.Error);
      });
  }
}
