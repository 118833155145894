<div
  class="text-container"
  [ngStyle]="{
    background:
      cardValues.background_type === 'color'
        ? cardValues.background_color
        : 'url(\'' + cardValues.background_image_file + '\')',
    width: cardValues.width + '%',
    'padding-left': cardValues.paddingLeft + cardValues.marginUnit,
    'padding-top': cardValues.paddingTop + cardValues.marginUnit,
    'padding-right': cardValues.paddingRight + cardValues.marginUnit,
    'padding-bottom': cardValues.paddingBottom + cardValues.marginUnit
  }"
>
  <div>
    <h2
      class="my-3"
      [ngStyle]="{
        'font-family': cardValues.style_headline.fontFamily,
        'font-size': cardValues.style_headline.fontSize + 'px',
        'line-height': cardValues.style_headline.lineHeight
          ? cardValues.style_headline.lineHeight
          : 'normal',
        color: cardValues.style_headline.color,
        'font-weight': cardValues.style_headline.fontWeight,
        'font-style': cardValues.style_headline.fontStyle,
        'text-decoration': cardValues.style_headline.textDecoration,
        'text-transform': cardValues.style_headline.textTransform,
        'text-align': cardValues.style_headline.textAlign
      }"
    >
      {{ cardValues.headline_text }}
    </h2>
  </div>
  <p
    class="my-3"
    [ngStyle]="{
      'font-family': cardValues.style_text.fontFamily,
      'font-size': cardValues.style_text.fontSize + 'px',
      'line-height': cardValues.style_text.lineHeight
        ? cardValues.style_text.lineHeight
        : 'normal',
      color: cardValues.style_text.color
    }"
    [innerHTML]="sanitizer.bypassSecurityTrustHtml(cardValues.content)"
  >
  </p>
</div>
