<div
  class="w-100 d-flex flex-column align-items-start justify-content-center gap-1"
  [formGroup]="currentFormGroup"
>
  <button
    class="mb-3 w-100 border-0 rounded-2 py-2 gap-1 d-flex justify-content-center align-items-center"
    style="color: #ffffff; background: #6e72eb"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M16.6665 5.00004H9.99984L8.33317 3.33337H3.33317C2.4165 3.33337 1.6665 4.08337 1.6665 5.00004V15C1.6665 15.9167 2.4165 16.6667 3.33317 16.6667H16.6665C17.5832 16.6667 18.3332 15.9167 18.3332 15V6.66671C18.3332 5.75004 17.5832 5.00004 16.6665 5.00004ZM14.9498 14.1667L12.4998 12.7334L10.0498 14.1667L10.6998 11.3917L8.5415 9.52504L11.3832 9.28337L12.4998 6.66671L13.6165 9.28337L16.4582 9.52504L14.2998 11.3917L14.9498 14.1667Z"
        fill="white"
      />
    </svg>
    <span
      class="text-uppercase"
      style="
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.5px;
      "
      >branding Stil laden</span
    >
  </button>
  <div
    class="py-2 w-100 px-4 d-flex gap-2"
    *ngIf="rootFormGroup.get('image_file')?.value"
  >
    <button class="transparent-btn" (click)="deleteImageFile()">
      <i class="material-icons">delete</i>
    </button>
    <img
      alt=""
      [src]="
        sanitizer.bypassSecurityTrustResourceUrl(
          '/api/image/' + rootFormGroup.get('image_thumb_file')?.value
        )
      "
    />
  </div>
  <input
    type="file"
    class="d-none"
    accept="image/png, image/gif, image/jpeg"
    #imgInput
    (change)="handleImageChange($event)"
  />
  <button
    (click)="imgInput.click()"
    class="mb-1 w-100 border-1 rounded-2 py-2 gap-1 d-flex justify-content-center align-items-center bg-white my-2"
    style="color: #6e72eb; border-color: #6e72eb"
  >
    <i class="material-icons">upload</i>
    <span
      class="text-uppercase"
      style="
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.5px;
      "
      >bILD HOCHLADEN</span
    >
  </button>
  <input type="text" formControlName="image_link" class="form-control my-2">
  <div class="row w-100">
    <div class="col-12" formGroupName="style_image">
      <div
        class="d-flex w-100 flex-row justify-content-between align-items-center"
      >
        <label for="breite2" style="font-size: 14px; color: #332e45"
          >Breite</label
        >
        <label for="breite2" style="font-size: 14px; color: #332e45"
          >{{ rootFormGroup?.get("style_image")?.get('width')?.value }}%</label
        >
      </div>
      <input
        type="range"
        class="form-range"
        min="0"
        max="400"
        id="breite2"
        formControlName="width"
      />
    </div>
  </div>
</div>
