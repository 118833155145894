import { Component } from '@angular/core';
import { NewPromotionService } from '../../../new-promotion.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-navbar-block-expanded-view',
  templateUrl: './navbar-block-expanded-view.component.html',
  styleUrls: ['./navbar-block-expanded-view.component.scss'],
})
export class NavbarBlockExpandedViewComponent {
  constructor(
    public newPromotionService: NewPromotionService,
    private fb: FormBuilder,
    public sanitizer: DomSanitizer
  ) {}

  closeCard() {
    this.newPromotionService.layoutCardExpanded = false;
  }

  get menu_elements() {
    return this.newPromotionService.selectedLayoutCard?.data.get(
      'menu_elements'
    ) as FormArray;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.menu_elements.controls,
      event.previousIndex,
      event.currentIndex
    );
  }

  get rootCardList() {
    return this.newPromotionService.layoutCards.map((c) => ({
      value: '#'+c.id,
      title: c.type,
    }));
  }

  getMenuElementFormGroup(index: number) {
    return this.menu_elements.at(index) as FormGroup;
  }

  duplicateMenuElement(index: number) {
    const values = this.getMenuElementFormGroup(index).value;
    this.menu_elements.push(
      this.fb.group({
        content: this.fb.control(values.content),
        heading_type: this.fb.control(values.heading_type),
        heading_tag: this.fb.control(values.heading_tag),
        url: this.fb.control(values.url),
      })
    );
  }

  addNewMenuElement() {
    this.menu_elements.push(
      this.fb.group({
        content: this.fb.control('Text'),
        heading_type: this.fb.control('Abschnitt'),
        url: this.fb.control('https://'),
      })
    );
  }

  deleteMenuElement(index: number) {
    (
      this.newPromotionService.selectedLayoutCard!.data.get(
        'menu_elements'
      ) as FormArray
    ).removeAt(index);
  }
}
