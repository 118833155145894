<div
  class="text-container"
  [ngStyle]="{
    background:
      cardValues.background_type === 'color'
        ? cardValues.background_color
        : 'url(\'' + cardValues.background_image_file + '\')',
    width: cardValues.width + '%',
    'padding-left': cardValues.paddingLeft + cardValues.marginUnit,
    'padding-top': cardValues.paddingTop + cardValues.marginUnit,
    'padding-right': cardValues.paddingRight + cardValues.marginUnit,
    'padding-bottom': cardValues.paddingBottom + cardValues.marginUnit
  }"
>
  <div class="gallery-grid">
    <div class="flex justify-content-center" *ngFor="let currentContent of contents" [ngStyle]="{
      width: cardValues.style_column.width + cardValues.style_column.marginUnit
    }">
      <p
        class="my-3"
        [ngStyle]="{
          'font-family': currentContent.style_text.fontFamily,
          'font-size': currentContent.style_text.fontSize + 'px',
          'line-height': currentContent.style_text.lineHeight
            ? currentContent.style_text.lineHeight
            : 'normal',
          color: currentContent.style_text.color
        }"
        [innerHTML]="sanitizer.bypassSecurityTrustHtml(currentContent.content)"
      ></p>
    </div>
  </div>
</div>
