import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LayoutCard } from '../promotions/new-promotion/new-promotion.service';
import { concatMap, from, of, switchMap, toArray } from 'rxjs';

export type PromotionBlock = {
  id: number;
  name: string;
  icon: string;
};

@Injectable({
  providedIn: 'root',
})
export class PromotionService {
  constructor(private http: HttpClient) {}

  getPromotionById(id: number) {
    return this.http.get<{ success: true; data: { id: number } }>(
      `/api/promotions/${id}`
    );
  }

  getPromotionLayouts(promotionId: number) {
    return this.http.get<{ success: true; data: { id: number } }>(
      `/api/promotions/${promotionId}/layout`
    );
  }

  getBlocks() {
    return this.http.get<{ success: true; data: PromotionBlock[] }>(
      `/api/promotions/blocks`
    );
  }

  createPromotion(data: any) {
    return this.http.post<{ success: true; data: { id: number } }>(
      `/api/promotions`,
      data
    );
  }

  updateLayoutBlocks(data: LayoutCard[], promotionId: number) {
    return this.http.put<{ success: true; data: any }>(
      `/api/promotions/${promotionId}/layout`,
      data
    );
  }

  createNewQuiz(data: any) {
    return this.http.post<{ success: true; data: { id: number } }>(
      `/api/promotions/quiz`,
      data
    );
  }

  updateQuizQuestions(data: any, quizId: number) {
    return this.http.put(`/api/promotions/quiz/${quizId}/questions`, data);
  }

  createPromotionWithCards(data: any, layoutCards: LayoutCard[]) {
    /**
     * This is a pretty complex way to do things with RXJS 😅
     */
    return from(layoutCards)
      .pipe(
        concatMap((layoutCard) => {
          const layoutCardValue = {
            ...layoutCard,
            data: layoutCard.data.value,
          };
          if (layoutCard.type === 'Quiz') {
            return this.createNewQuiz(layoutCardValue.data).pipe(
              switchMap((r) => {
                const questions = layoutCardValue.data.questions;
                layoutCardValue.data = r.data.id;
                return this.updateQuizQuestions(questions, r.data.id);
              }),
              switchMap(() => of(layoutCardValue))
            );
          } else {
            layoutCardValue.data = JSON.stringify(layoutCardValue.data);
            return of(layoutCardValue);
          }
        }),
        toArray()
      )
      .pipe(
        switchMap((layoutCards) => {
          return this.createPromotion(data).pipe(
            switchMap((r) =>
              this.updateLayoutBlocks(layoutCards, r.data.id).pipe(
                switchMap((layoutBlockData) =>
                  of({ ...r, layoutBlocks: layoutBlockData.data })
                )
              )
            )
          );
        })
      );
  }
}
