import {createFeatureSelector, createSelector} from '@ngrx/store';
import {FileTypeModel} from '../../shared/models/file-type.model';

export const selectFileTypes = createSelector(
  createFeatureSelector('fileTypes'),
  (fileType: FileTypeModel) => {
    return fileType;
  }
);

export const selectFontTypes = () => createSelector(selectFileTypes, (fileTypeModel) => {
  return fileTypeModel.fonts;
});

export const selectImageTypes = () => createSelector(selectFileTypes, (fileTypeModel) => {
  return fileTypeModel.images;
});

export const selectMaxImageFileSize = () => createSelector(selectFileTypes, (fileTypeModel) => {
  return fileTypeModel.maxFileUploads.images;
});

export const selectMaxFontFileSize = () => createSelector(selectFileTypes, (fileTypeModel) => {
  return fileTypeModel.maxFileUploads.fonts;
});
