<input #fileInput type="file" (change)="openDialog($event)" accept="image/*" hidden>

<button ly-button
        mat-button
        *ngIf="!imageCropped"
        (click)="fileInput.click()"
        class="secondary icon-btn"><span class="material-icons">upload</span>
  {{UploadImage}}
</button>

<div class="cropped-preview mt-3 position-relative" *ngIf="imageCropped && imageCropped !== ''">
  <img [src]="imageCropped">
  <div class="hoverable-icons position-absolute">
    <a class="icon material-icons me-2 text-decoration-none mat-elevation-z0 bg-white"
       (click)="openCropperDialog()">edit</a>
    <a class="icon material-icons ms-2 text-decoration-none mat-elevation-z0 bg-white"
       (click)="resetImage()">delete</a>
  </div>
</div>
