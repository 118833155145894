<h1 mat-dialog-title>{{AdSizeInfo}}</h1>
<div mat-dialog-content>
  <div class="container-fluid">
    <div class="row">
      <div class="col mb-5 p-0 text-center d-flex flex-column align-items-center">
        Mit einer Gesamtdateigröße von
        <span id="file-size-indicator">{{ fileSize | fileSize }}</span>
        kann dieses Ad
        <ng-container [ngSwitch]="fileSizeStatus">
          <ng-container *ngSwitchCase="FileSizeInfo.OkForAll">
            für alle
          </ng-container>
          <ng-container *ngSwitchCase="FileSizeInfo.OkForSome">
            nicht für alle
          </ng-container>
          <ng-container *ngSwitchCase="FileSizeInfo.OkForAny">
            für keine
          </ng-container>
        </ng-container>
        Publisher angeliefert werden.
      </div>
    </div>

    <div class="publisher row">
      <div id="gdn"
           [ngClass]="getFileSizeInfo(GDN)"
           class="col entry pt-2 pb-2 d-flex align-items-center d-flex">
        <div class="icon"></div>
        <div class="title ms-4">
          <span style="font-weight: 500">GDN</span> Google Display Netzwerk
        </div>
        <div class="file-info ms-auto d-flex align-items-center">
          <div class="size">{{GDNThresholdDecimal}} KB max.</div>
          <div class="status material-icons-outlined ms-4">
            {{getIcon(GDN)}}
          </div>
        </div>

      </div>
    </div>

    <div class="publisher row mt-2">
      <div id="iab"
           [ngClass]="getFileSizeInfo(IABStandard)"
           class="col entry pt-2 pb-2 d-flex align-items-center d-flex">
        <div class="icon"></div>
        <div class="title ms-4"><span style="font-weight: 500">IAB</span> Standard</div>
        <div class="file-info ms-auto d-flex align-items-center">
          <div class="size">{{IABThresholdDecimal}} KB max.</div>
          <div class="status material-icons-outlined ms-4">
            {{getIcon(IABStandard)}}
          </div>
        </div>
      </div>
    </div>

    <div class="infobox row mt-5">
      <div class="card-wrapper col p-4">
        <h2>{{FileSizeInfoBox.title}}</h2>
        <h4>{{FileSizeInfoBox.text}}</h4>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true">{{Close}}</button>
</div>
