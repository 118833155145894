import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ColorPickerService } from './color-picker.service';
import { ThemePalette } from '@angular/material/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { ColorPickerIntegratable } from './color-picker-integratable';
import { DOCUMENT } from '@angular/common';
import { Color } from '@angular-material-components/color-picker';
import {
  AdContentFormControls,
  AdDesignFormControls,
} from 'src/app/ads/edit-ad/common-services/common-edit-ad-design.service';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements AfterViewInit {
  @ViewChild('colorPickerIndicator')
  public colorPickerIndicator!: ElementRef<HTMLElement>;
  @ViewChild('colorPicker') public colorPicker: any;
  @Input() public controlType!: ControlType;
  @Input() public component!: ColorPickerIntegratable;
  @Input() public label!: string;
  private initialFormControlValue!: Color;

  @Input()
  public set control(value: AbstractControl) {
    this.formControl = value as FormControl;
    const rgb = this.colorPickerService.hexToRgb(this.formControl.value)!;
    if (rgb) {
      this.formControl.patchValue(new Color(rgb.r, rgb.g, rgb.b), {
        emitEvent: false,
      });
    }
  }

  public formControl!: FormControl;
  public color: ThemePalette = 'primary';

  constructor(
    public colorPickerService: ColorPickerService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    this.colorPickerService.setIndicatorColor(
      this.colorPickerIndicator.nativeElement,
      this.formControl.value
    );
  }

  public opened(): void {
    this.initialFormControlValue = this.formControl.value;
    this.attachButtons();
  }

  private attachButtons(): void {
    const wrapper = this.renderer.createElement('div');
    this.renderer.addClass(wrapper, 'button-wrapper');
    this.renderer.appendChild(
      this.document.body.querySelector('.ngx-mat-colorpicker-content'),
      wrapper
    );
    // Ok Btn
    this.appendButton('Ok', wrapper, this.applyColor.bind(this));
    // Cancel Btn
    this.appendButton('Abbechen', wrapper, this.cancel.bind(this));
  }

  private applyColor(): void {
    this.colorPickerService.setIndicatorColor(
      this.colorPickerIndicator.nativeElement,
      this.formControl.value
    );
    this.close();
  }

  private cancel(): void {
    this.formControl.patchValue(this.initialFormControlValue);
    this.close();
  }

  private close(): void {
    this.colorPicker.close();
  }

  private appendButton(
    text: string,
    wrapper: HTMLDivElement,
    callback: () => void
  ): void {
    const btn = this.renderer.createElement('button');
    this.renderer.addClass(btn, 'mat-button');
    const okBtnText = this.renderer.createText(text);
    this.renderer.appendChild(btn, okBtnText);
    this.renderer.appendChild(wrapper, btn);
    this.renderer.listen(btn, 'click', () => callback());
  }
}

type ControlType = AdDesignFormControls | AdContentFormControls;
