<form
  [formGroup]="newPromotionService.selectedLayoutCard!.data"
  class="w-100 d-flex flex-column"
>
  <ul class="list-unstyled">
    <li>
      <div class="list-item-dropdown-title" style="background: #6e72eb">
        <div
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
        >
          <div
            class="d-flex justify-content-between align-items-center text-white"
          >
            <div style="font-weight: bold">Quiz</div>
            <button class="transparent-btn" (click)="closeCard()">
              <i class="material-icons">close</i>
            </button>
          </div>
        </div>
      </div>
    </li>
    <app-block-section title="BLOCK">
      <app-block-styles-view
        [rootFormGroup]="newPromotionService.selectedLayoutCard!.data"
      ></app-block-styles-view>
    </app-block-section>
    <app-block-section title="Überschrift 1">
      <app-heading-styles-view
        [rootFormGroup]="
          newPromotionService.selectedLayoutCard!.data.get('style_headline_1')!
        "
      ></app-heading-styles-view>
    </app-block-section>
    <app-block-section title="Überschrift 2">
      <app-heading-styles-view
        [rootFormGroup]="
          newPromotionService.selectedLayoutCard!.data.get('style_headline_2')!
        "
      ></app-heading-styles-view>
    </app-block-section>
    <app-block-section title="TEXT">
      <app-text-styles-view
        [rootFormGroup]="
          newPromotionService.selectedLayoutCard!.data.get('style_text')!
        "
      ></app-text-styles-view>
    </app-block-section>
    <app-block-section title="Button 1">
      <app-button-styles-view
        [rootFormGroup]="
          newPromotionService.selectedLayoutCard!.data.get(
            'style_button_primary'
          )!
        "
      ></app-button-styles-view>
    </app-block-section>
    <app-block-section title="Button 2">
      <app-button-styles-view
        [rootFormGroup]="
          newPromotionService.selectedLayoutCard!.data.get(
            'style_button_secondary'
          )!
        "
      ></app-button-styles-view>
    </app-block-section>
    <li>
      <div class="list-item-dropdown-title">
        <a
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#startseite-collapse"
          aria-expanded="false"
        >
          <span class="d-flex justify-content-between align-items-center">
            <span style="color: #332e45; font-weight: bold">Startseite</span>
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="expand_less">
                  <path
                    id="Vector"
                    d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                    fill="#332E45"
                  />
                </g>
              </svg>
            </span>
          </span>
        </a>
      </div>
      <div class="collapse" id="startseite-collapse">
        <div
          class="list-item-dropdown-body d-flex flex-column justify-content-center row-gap-2 align-items-center bg-white"
        >
          <div
            class="w-100 d-flex flex-column align-items-start justify-content-center gap-1"
          >
            <div
              class="form-check form-switch d-flex justify-content-start align-items-center gap-1 mb-3"
            >
              <input
                class="form-check-input switch-check-input"
                type="checkbox"
                role="switch"
                formControlName="skip_start_page"
                id="startseite-überspringen"
              />
              <label
                class="form-check-label"
                for="startseite-überspringen"
                style="font-size: 14px"
                >Startseite überspringen</label
              >
            </div>
            <input
              class="form-control my-2"
              type="text"
              formControlName="start_headline_1"
              placeholder="Überschrift 1"
              aria-label="Überschrift 1"
            />
            <input
              class="form-control mb-4"
              type="text"
              placeholder="Überschrift 2"
              formControlName="start_headline_2"
              aria-label="Überschrift 2"
            />
            <div class="w-100">
              <editor
                [init]="tineMCEConfig"
                formControlName="start_text"
              ></editor>
            </div>

            <input
              class="form-control mt-2"
              type="text"
              formControlName="start_button_text"
              placeholder="Buttontext"
              aria-label="Buttontext"
            />
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="list-item-dropdown-title">
        <a
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#fragen-collapse"
          aria-expanded="false"
        >
          <span class="d-flex justify-content-between align-items-center">
            <span style="color: #332e45; font-weight: bold">Fragen</span>
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="expand_less">
                  <path
                    id="Vector"
                    d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                    fill="#332E45"
                  />
                </g>
              </svg>
            </span>
          </span>
        </a>
      </div>
      <div class="collapse" id="fragen-collapse">
        <div
          class="d-flex flex-column justify-content-center row-gap-2 align-items-center bg-white"
        >
          <div
            class="w-100 px-2 d-flex flex-column align-items-start justify-content-center gap-1 my-2"
          >
            <div class="form-group w-100">
              <label for="type">Typ</label>
              <select
                class="form-select"
                aria-label="select type"
                id="type"
                formControlName="questions_type"
              >
                <option [value]="0" selected>Single-Choice</option>
              </select>
            </div>
            <div
              class="form-check form-switch d-flex justify-content-start align-items-center gap-1 mt-2"
            >
              <input
                class="form-check-input switch-check-input"
                formControlName="show_answer_images"
                type="checkbox"
                role="switch"
                id="antworten-anzeigen"
              />
              <label
                class="form-check-label"
                for="antworten-anzeigen"
                style="font-size: 14px"
                >Bilder zu Antworten anzeigen</label
              >
            </div>
          </div>
          <ul
            class="w-100 my-3"
            formArrayName="questions"
            cdkDropList
            (cdkDropListDropped)="drop($event, questions)"
          >
            <li
              class="w-100"
              *ngFor="let question of questions.controls; index as i"
              [formGroupName]="i"
            >
              <div class="w-100 list-item-dropdown-title" cdkDrag>
                <a
                  style="letter-spacing: 0.7px; line-height: 16px"
                  class="w-100 text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
                  href="#"
                  role="button"
                  data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#fragen-collapse-' + i"
                  aria-expanded="false"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span
                      class="d-flex justify-content-start align-items-center gap-1 w-25"
                    >
                      <button class="transparent-btn">
                        <i class="material-icons">drag_handle</i>
                      </button>
                      <button
                        class="transparent-btn"
                        (click)="duplicateQuestion(i)"
                      >
                        <i class="material-icons">content_copy</i>
                      </button>
                      <button
                        class="transparent-btn"
                        (click)="deleteQuestion(i)"
                      >
                        <i class="material-icons">delete</i>
                      </button>
                    </span>
                    <span class="w-75" style="color: #332e45; font-weight: bold"
                      >Frage {{ i + 1 }}</span
                    >
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="expand_less">
                          <path
                            id="Vector"
                            d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                            fill="#332E45"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </a>
              </div>
              <div class="collapse" [id]="'fragen-collapse-' + i">
                <div
                  class="w-100 p-2 d-flex flex-column align-items-start justify-content-center gap-1"
                >
                  <label class="mt-1" style="font-size: 14px; color: #332e45"
                    >Frage</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="question"
                    placeholder="Text"
                  />
                </div>
                <ul
                  class="answers-list-container mt-3"
                  formArrayName="answers"
                  cdkDropList
                  (cdkDropListDropped)="drop($event, getAnswers(i))"
                >
                  <li
                    class="mb-2"
                    *ngFor="let answer of getAnswers(i).controls; index as j"
                    [formGroupName]="j"
                    cdkDrag
                  >
                    <div class="w-100 list-item-dropdown-title">
                      <a
                        style="letter-spacing: 0.7px; line-height: 16px"
                        class="w-100 text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
                        href="#"
                        role="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="
                          '#antwort-collapse-' + i + '-' + j
                        "
                        aria-expanded="false"
                      >
                        <span
                          class="d-flex justify-content-between align-items-center"
                        >
                          <span
                            class="d-flex justify-content-start align-items-center gap-1 w-25"
                          >
                            <button class="transparent-btn z-3">
                              <i class="material-icons">drag_handle</i>
                            </button>
                            <button
                              class="transparent-btn"
                              (click)="duplicateAnswer(i, j)"
                            >
                              <i class="material-icons">content_copy</i>
                            </button>
                            <button
                              class="transparent-btn"
                              (click)="deleteAnswer(i, j)"
                            >
                              <i class="material-icons">delete</i>
                            </button>
                          </span>
                          <span
                            class="w-75"
                            style="color: #332e45; font-weight: bold"
                            >Antwort {{ j + 1 }}</span
                          >
                          <span>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="expand_less">
                                <path
                                  id="Vector"
                                  d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                                  fill="#332E45"
                                />
                              </g>
                            </svg>
                          </span>
                        </span>
                      </a>
                    </div>
                    <div
                      class="collapse"
                      [id]="'antwort-collapse-' + i + '-' + j"
                    >
                      <div
                        class="w-100 p-2 d-flex flex-column align-items-start justify-content-center gap-1"
                      >
                        <input
                          type="text"
                          class="form-control mb-2"
                          formControlName="answer"
                          placeholder="Text"
                          aria-label="text"
                        />
                        <div
                          class="py-2 w-100 px-4 d-flex gap-2"
                          *ngIf="answer.get('image_thumb_file')?.value"
                        >
                          <button
                            class="transparent-btn"
                            (click)="deleteImageFile(i, j)"
                          >
                            <i class="material-icons">delete</i>
                          </button>
                          <img
                            alt=""
                            [src]="
                              sanitizer.bypassSecurityTrustResourceUrl(
                                '/api/image/' +
                                  answer.get('image_thumb_file')?.value
                              )
                            "
                          />
                        </div>
                        <input
                          type="file"
                          class="d-none"
                          accept="image/png, image/gif, image/jpeg"
                          #imgInput
                          (change)="handleQuestionImageChange($event, i, j)"
                        />
                        <button
                          (click)="imgInput.click()"
                          class="mb-1 w-100 border-1 rounded-2 py-2 gap-1 d-flex justify-content-center align-items-center bg-white"
                          style="color: #6e72eb; border-color: #6e72eb"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                          >
                            <path
                              d="M4.6665 17.0832H16.3332V15.4165H4.6665V17.0832ZM4.6665 8.74984H7.99984V13.7498H12.9998V8.74984H16.3332L10.4998 2.9165L4.6665 8.74984Z"
                              fill="#6E72EB"
                            />
                          </svg>
                          <span
                            class="text-uppercase"
                            style="
                              font-size: 14px;
                              font-weight: 500;
                              line-height: 20px;
                              letter-spacing: 0.5px;
                            "
                            >bILD HOCHLADEN</span
                          >
                        </button>

                        <div
                          class="w-50 mb-1 form-check form-switch d-flex justify-content-start align-items-center gap-1"
                        >
                          <input
                            class="form-check-input switch-check-input"
                            type="checkbox"
                            role="switch"
                            formControlName="correct"
                            [id]="'richtige-antwort-' + i + '' + j"
                          />
                          <label
                            class="form-check-label"
                            [for]="'richtige-antwort-' + i + '' + j"
                            style="font-size: 14px"
                            >richtige Antwort</label
                          >
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="w-100 answers-list-container my-2">
                  <button
                    class="w-100 mb-1 bg-white rounded-2 py-1 d-flex justify-content-center align-items-center my-2 py-2"
                    (click)="createNewAnswer(i)"
                    style="
                      border: black dashed 1px;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 16px;
                      letter-spacing: 0.7px;
                    "
                  >
                    <i class="material-icons">add</i>
                    <span class="ms-2 text-uppercase">NEUE ANTWORT</span>
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <button
            class="w-100 bg-white rounded-2 py-1 d-flex justify-content-center align-items-center mt-2 mb-3 py-2"
            (click)="createNewQuestion()"
            style="
              border: black dashed 1px;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              letter-spacing: 0.7px;
            "
          >
            <i class="material-icons">add</i>
            <span class="ms-2 text-uppercase">NEUE Frage</span>
          </button>
        </div>
      </div>
    </li>
    <li>
      <div class="list-item-dropdown-title">
        <a
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#formularseite-collapse"
          aria-expanded="false"
        >
          <span class="d-flex justify-content-between align-items-center">
            <span style="color: #332e45; font-weight: bold">Formularseite</span>
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="expand_less">
                  <path
                    id="Vector"
                    d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                    fill="#332E45"
                  />
                </g>
              </svg>
            </span>
          </span>
        </a>
      </div>
      <div class="collapse" id="formularseite-collapse">
        <div
          class="d-flex flex-column justify-content-center row-gap-2 align-items-center bg-white"
        >
          <div
            class="w-100 px-2 pt-2 d-flex flex-column align-items-start justify-content-center gap-1"
          >
            <input
              class="form-control my-2"
              type="text"
              formControlName="form_headline"
              placeholder="Überschrift 1"
              aria-label="Überschrift 1"
            />
            <div class="w-100 px-0 rounded-2 text-area-with-button">
              <editor
                [init]="tineMCEConfig"
                formControlName="form_text"
              ></editor>
            </div>
            <input
              class="form-control my-2"
              type="text"
              formControlName="form_button_text"
              placeholder="Buttontext"
              aria-label="Buttontext"
            />
          </div>
          <ul
            class="w-100"
            formArrayName="form_fields"
            cdkDropList
            (cdkDropListDropped)="drop($event, formFields)"
          >
            <li
              class="w-100 my-2"
              *ngFor="let form_field of formFields; index as i"
              [formGroupName]="i"
              cdkDrag
            >
              <div class="w-100 list-item-dropdown-title">
                <a
                  style="letter-spacing: 0.7px; line-height: 16px"
                  class="w-100 text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
                  href="#"
                  role="button"
                  data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#form-field-collapse-' + i"
                  aria-expanded="false"
                >
                  <span
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span
                      class="d-flex justify-content-start align-items-center gap-1 w-25"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M13.3332 6H2.6665V7.33333H13.3332V6ZM2.6665 10H13.3332V8.66667H2.6665V10Z"
                          fill="#332E45"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        (click)="duplicateFormField(form_field.value)"
                      >
                        <path
                          d="M10.9998 0.666504H2.99984C2.2665 0.666504 1.6665 1.2665 1.6665 1.99984V11.3332H2.99984V1.99984H10.9998V0.666504ZM12.9998 3.33317H5.6665C4.93317 3.33317 4.33317 3.93317 4.33317 4.6665V13.9998C4.33317 14.7332 4.93317 15.3332 5.6665 15.3332H12.9998C13.7332 15.3332 14.3332 14.7332 14.3332 13.9998V4.6665C14.3332 3.93317 13.7332 3.33317 12.9998 3.33317ZM12.9998 13.9998H5.6665V4.6665H12.9998V13.9998Z"
                          fill="#332E45"
                        />
                      </svg>
                      <button
                        class="transparent-btn"
                        (click)="deleteFormField(i)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M4.00016 12.6667C4.00016 13.4 4.60016 14 5.3335 14H10.6668C11.4002 14 12.0002 13.4 12.0002 12.6667V4.66667H4.00016V12.6667ZM12.6668 2.66667H10.3335L9.66683 2H6.3335L5.66683 2.66667H3.3335V4H12.6668V2.66667Z"
                            fill="#332E45"
                          />
                        </svg>
                      </button>
                    </span>
                    <span
                      class="w-75"
                      style="color: #332e45; font-weight: bold"
                      >{{ form_field.value.label }}</span
                    >
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="expand_less">
                          <path
                            id="Vector"
                            d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                            fill="#332E45"
                          />
                        </g>
                      </svg>
                    </span>
                  </span>
                </a>
              </div>
              <div class="collapse" id="form-field-collapse-{{ i }}">
                <div
                  class="w-100 p-2 d-flex flex-column align-items-start justify-content-center gap-1"
                >
                  <input
                    type="text"
                    class="form-control"
                    aria-label="email-text-1"
                    formControlName="label"
                    placeholder="Label"
                  />
                  <div
                    class="w-50 form-check form-switch d-flex justify-content-start align-items-center gap-1"
                  >
                    <input
                      class="form-check-input switch-check-input"
                      type="checkbox"
                      role="switch"
                      formControlName="isRequired"
                      [id]="'pflichtfeld' + i"
                    />
                    <label
                      class="form-check-label"
                      [for]="'pflichtfeld' + i"
                      style="font-size: 14px"
                      >Pflichtfeld</label
                    >
                  </div>
                </div>
              </div>
            </li>
            <li>
              <button
                class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 w-100 border-0 bg-white rounded-3"
                role="button"
                (click)="createNewFormField()"
              >
                <div
                  class="d-flex justify-content-center align-items-center gap-2 layout-view-title-button-outer"
                  style="border: #332e45 dashed 1px"
                >
                  <div
                    class="d-flex gap-2 align-items-center justify-content-center"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="add">
                        <path
                          id="Vector"
                          d="M12.6666 8.66668H8.66659V12.6667H7.33325V8.66668H3.33325V7.33334H7.33325V3.33334H8.66659V7.33334H12.6666V8.66668Z"
                          fill="#332E45"
                        />
                      </g>
                    </svg>
                    <span>NEUEs Formularfeld</span>
                  </div>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <li>
      <div class="list-item-dropdown-title">
        <a
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#dankeseite-collapse"
          aria-expanded="false"
        >
          <span class="d-flex justify-content-between align-items-center">
            <span style="color: #332e45; font-weight: bold">Dankeseite</span>
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="expand_less">
                  <path
                    id="Vector"
                    d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                    fill="#332E45"
                  />
                </g>
              </svg>
            </span>
          </span>
        </a>
      </div>
      <div class="collapse" id="dankeseite-collapse">
        <div
          class="d-flex flex-column justify-content-center row-gap-2 align-items-center bg-white"
        >
          <div
            class="w-100 px-2 py-2 d-flex flex-column align-items-start justify-content-center gap-1"
          >
            <input
              class="form-control my-2"
              formControlName="thanks_headline"
              type="text"
              placeholder="Überschrift 1"
              aria-label="Überschrift 1"
            />
            <div class="w-100 px-0 rounded-2 text-area-with-button">
              <editor
                [init]="tineMCEConfig"
                formControlName="thanks_text"
              ></editor>
            </div>
            <input
              class="form-control my-2"
              formControlName="thanks_button_text"
              type="text"
              placeholder="Buttontext"
              aria-label="Buttontext"
            />
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="list-item-dropdown-title">
        <a
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#endeseite-collapse"
          aria-expanded="false"
        >
          <span class="d-flex justify-content-between align-items-center">
            <span style="color: #332e45; font-weight: bold">Endeseite</span>
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="expand_less">
                  <path
                    id="Vector"
                    d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                    fill="#332E45"
                  />
                </g>
              </svg>
            </span>
          </span>
        </a>
      </div>
      <div class="collapse" id="endeseite-collapse">
        <div
          class="d-flex flex-column justify-content-center row-gap-2 align-items-center bg-white"
        >
          <div
            class="w-100 px-2 py-2 d-flex flex-column align-items-start justify-content-center gap-1"
          >
            <input
              class="form-control my-2"
              formControlName="end_headline"
              type="text"
              placeholder="Überschrift 1"
              aria-label="Überschrift 1"
            />
            <div class="w-100 px-0 rounded-2 text-area-with-button">
              <editor
                [init]="tineMCEConfig"
                formControlName="end_text"
              ></editor>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</form>
