<form
  class="form configuration-items-wrapper"
  *ngIf="contentTemplateForm"
  [formGroup]="contentTemplateForm"
  [ngClass]="{ displayErroneousInputs: displayErroneousInputs }"
>
  <app-ad-configuration-item [item]="slideWrapperConfigurationItem">
    <div id="slide-container" #slideList formArrayName="slidesFormArr">
      <app-ad-configuration-item
        *ngFor="let item of slideConfigurationItems; let i = index"
        [item]="item"
        [orderNr]="i"
        [copyCallback]="copySlide.bind(this)"
        [removeCallback]="removeSlide.bind(this)"
      >
        <div class="col-12 mt-2 pt-1" [formGroupName]="i">
          <input class="orderNumber" formControlName="orderNumber" hidden />
          <div class="row mt-2">
            <div class="col-12">
              <h6 class="mb-0">{{ Headline }}</h6>
            </div>
            <div class="col-6">
              <mat-form-field appearance="fill">
                <mat-label>{{ Text }}</mat-label>
                <input
                  matInput
                  formControlName="headlineText"
                  (input)="slideHeadlineChanged($event, i)"
                />
              </mat-form-field>
            </div>

            <div class="col-6">
              <app-color-picker
                *ngIf="contentTemplateForm.controls.slidesFormArr !== undefined"
                [label]="Color"
                [control]="
                  contentTemplateForm.controls.slidesFormArr
                    .get(i.toString())!
                    .get('headlineTextPickerCtrl')!
                "
                [controlType]="AdContentFormControls.HeadlineColor"
                [component]="adContentComponent"
              ></app-color-picker>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <hr />
            </div>
          </div>

          <div class="row">
            <div class="col-12 mt-3">
              <h6 class="mb-0">{{ Text }}</h6>
            </div>
            <div class="col-6">
              <mat-form-field appearance="fill">
                <mat-label>{{ Text }}</mat-label>
                <input matInput formControlName="slideText" />
              </mat-form-field>
            </div>

            <div class="col-6">
              <app-color-picker
                *ngIf="contentTemplateForm.controls.slidesFormArr !== undefined"
                [label]="Color"
                [control]="
                  contentTemplateForm.controls.slidesFormArr
                    .get(i.toString())!
                    .get('slideTextPickerCtrl')!
                "
                [controlType]="AdContentFormControls.TextColor"
                [component]="adContentComponent"
              ></app-color-picker>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <hr />
            </div>
          </div>

          <div class="row">
            <div class="col-12 mt-3">
              <h6 class="mb-0">{{ Background }}</h6>
            </div>
            <div class="col-6 d-flex align-items-center">
              <app-image-upload
                (cropperResult$)="imageChanged($event, i)"
                [adTypeName]="StoryAdSlug"
                [imageCropped]="getSlide(i).imageCropped"
                [imageOriginal]="getSlide(i).imageOriginal"
                [imageWrapper]="getSlide(i)"
                [originalImageType]="OriginalImageType.Background"
                [cropperArea]="getSlide(i).imageCropperArea"
                [imageCropperData]="slideImageCropperData"
              ></app-image-upload>
            </div>

            <div class="col-6">
              <app-color-picker
                *ngIf="contentTemplateForm.controls.slidesFormArr !== undefined"
                [label]="Color"
                [control]="
                  contentTemplateForm.controls.slidesFormArr
                    .get(i.toString())!
                    .get('backgroundPickerCtrl')!
                "
                [controlType]="AdContentFormControls.BackgroundColor"
                [component]="adContentComponent"
              ></app-color-picker>
            </div>
          </div>
        </div>
      </app-ad-configuration-item>
    </div>

    <a
      class="accordion dummy text-decoration-none"
      *ngIf="slides.size < MaxSlideCount"
      (click)="addNewSlide()"
    >
      <div class="accordion-item">
        <div
          class="accordion-header card-header d-flex justify-content-center align-items-center text-uppercase"
        >
          <span class="material-icons-sharp">add</span>
          <span class="label">{{ NewSlide }}</span>
        </div>
      </div>
    </a>
  </app-ad-configuration-item>

  <app-ad-configuration-item
    [item]="ctaBtnConfigurationItem"
    class="d-block"
    style="margin-top: 35px"
  >
    <div class="col-6 d-flex align-items-center">
      <mat-form-field appearance="fill">
        <mat-label>{{ Text }}</mat-label>
        <input matInput formControlName="ctaBtnText" />
      </mat-form-field>
    </div>
  </app-ad-configuration-item>
</form>

<app-edit-ad-navigation></app-edit-ad-navigation>
