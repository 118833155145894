<button
  class="text-uppercase link-offset-2 link-underline link-underline-opacity-0 w-100 border-0 draggable-box card rounded-4 shadow"
  [ngClass]="{ 'draggable-box-selected': isSelected }"
  (click)="toggleSelectedState()"
  role="button"
  cdkDrag
>
  <div
    class="d-flex justify-content-between align-items-center gap-2 layout-view-title-button-outer rounded-2 w-100"
  >
    <div class="d-flex gap-4 align-items-end">
      <i class="material-icons">drag_indicator</i>
      <span>{{ card.type }}</span>
    </div>
    <div class="d-flex gap-1 align-items-center">
      <button
        class="link-offset-1 link-underline link-underline-opacity-0 action-icon"
        (click)="expandCard()"
      >
        <i class="material-icons">edit</i>
      </button>
      <button
        class="link-offset-1 link-underline link-underline-opacity-0 action-icon"
        role="button"
        (click)="duplicateCard()"
      >
        <i class="material-icons">content_copy</i>
      </button>
      <button
        class="link-offset-1 link-underline link-underline-opacity-0 action-icon"
        role="button"
      >
        <i class="material-icons">visibility</i>
      </button>
      <button
        class="link-offset-1 link-underline link-underline-opacity-0 border-0 bg-transparent action-icon"
        (click)="deleteBlock()"
        role="button"
      >
        <i class="material-icons">delete</i>
      </button>
    </div>
  </div>
</button>
