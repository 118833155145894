<form
  [formGroup]="newPromotionService.selectedLayoutCard!.data"
  class="w-100 d-flex flex-column"
>
  <ul class="list-unstyled">
    <li>
      <div class="list-item-dropdown-title" style="background: #6e72eb">
        <div
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
        >
          <div
            class="d-flex justify-content-between align-items-center text-white"
          >
            <div style="font-weight: bold">Footer</div>
            <button class="transparent-btn" (click)="closeCard()">
              <i class="material-icons">close</i>
            </button>
          </div>
        </div>
      </div>
    </li>
    <app-block-section title="BLOCK">
      <app-block-styles-view
        [rootFormGroup]="newPromotionService.selectedLayoutCard!.data"
      ></app-block-styles-view>
    </app-block-section>
    <app-block-section title="Footer">
      <app-menu-item-styles-view
        [rootFormGroup]="
          newPromotionService.selectedLayoutCard?.data?.get('styles_links')
        "
      ></app-menu-item-styles-view>

      <div class="mt-3 mb-2 w-100 d-flex flex-column gap-2">
        <div class="mb-3">Elemente</div>
        <div class="d-flex justify-content-between align-items-center w-100">
          <div>
            <div
              class="form-check form-switch d-flex justify-content-start align-items-center gap-1 mb-1"
            >
              <input
                class="form-check-input switch-check-input"
                type="checkbox"
                role="switch"
                formControlName="show_imprint"
                id="show_imprint"
              />
              <label
                class="form-check-label"
                for="show_imprint"
                style="font-size: 14px"
                >Impressum</label
              >
            </div>
          </div>
          <div><i class="material-icons">mode_edit</i></div>
        </div>
        <div class="d-flex justify-content-between align-items-center w-100">
          <div>
            <div
              class="form-check form-switch d-flex justify-content-start align-items-center gap-1 mb-1"
            >
              <input
                class="form-check-input switch-check-input"
                type="checkbox"
                role="switch"
                formControlName="show_data_protection"
                id="show_data_protection"
              />
              <label
                class="form-check-label"
                for="show_data_protection"
                style="font-size: 14px"
                >Datenschutz</label
              >
            </div>
          </div>
          <div><i class="material-icons">mode_edit</i></div>
        </div>
        <div class="d-flex justify-content-between align-items-center w-100">
          <div>
            <div
              class="form-check form-switch d-flex justify-content-start align-items-center gap-1 mb-1"
            >
              <input
                class="form-check-input switch-check-input"
                type="checkbox"
                role="switch"
                formControlName="show_conditions"
                id="show_conditions"
              />
              <label
                class="form-check-label"
                for="show_conditions"
                style="font-size: 14px"
                >Teilnahmebedingungen</label
              >
            </div>
          </div>
          <div><i class="material-icons">mode_edit</i></div>
        </div>
      </div>
    </app-block-section>
  </ul>
</form>
