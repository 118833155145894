import { Exclude, Expose } from 'class-transformer';
import { IncludesImage } from '../interfaces/includes-image';
import { OriginalOptionImageType } from '../../ads/edit-ad/edit-ad.service';
import { CropperArea } from './cropper-area';
import { AdAsset } from '../interfaces/ad-asset';

export class SlideModel implements IncludesImage, AdAsset {
  public id? = -1;
  @Expose({ name: 'created_at' })
  public created? = new Date();
  public headline = '';
  @Expose({ name: 'headline_color' })
  public headlineColor = '';
  public text = '';
  @Expose({ name: 'text_color' })
  public textColor = '';
  @Expose({ name: 'image_original' })
  public imageOriginal?: string = undefined;
  @Expose({ name: 'image_cropped' })
  public imageCropped: string | null = null;
  @Expose({ name: 'image_name' })
  public imageName = '';
  @Expose({ name: 'image_cropper_area' })
  public imageCropperArea = new CropperArea();
  @Expose({ name: 'background_color' })
  public backgroundColor = '';
  @Expose({ name: 'slideable_id' })
  @Exclude({ toPlainOnly: true })
  public slideableId = -1;
  @Expose({ name: 'slideable_type' })
  @Exclude({ toPlainOnly: true })
  public slideableType = '';
  @Exclude()
  public orderNumber = -1;
  @Exclude()
  public imageOrigin = new Map<
    number,
    Map<OriginalOptionImageType, string | undefined>
  >(); // [Original Slide Id, Original Slide Image]
}
