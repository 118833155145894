import {PickerSlug, StorySlug} from '../../shared/data-repository/api-slugs';
import {UrlSegmentDelimiter} from '../../shared/data-repository/global-constants';
import {pageMap} from '../../shared/data-repository/page-mapping';
import {PageKey} from '../../shared/data-structures/navigation-data';
import {StoryAdDesignComponent} from '../../ads/edit-ad/story-ad-form/step-1-ad-design/story-ad-design.component';
import {StoryAdContentComponent} from '../../ads/edit-ad/story-ad-form/step-2-ad-content/story-ad-content.component';
import {StoryAdAnimationComponent} from '../../ads/edit-ad/story-ad-form/step-3-ad-animation/story-ad-animation.component';
import {StoryAdDownloadComponent} from '../../ads/edit-ad/story-ad-form/step-4-ad-download/story-ad-download.component';
import {PickerAdDesignComponent} from '../../ads/edit-ad/picker-ad-form/step-1-ad-design/picker-ad-design.component';
import {PickerAdContentComponent} from '../../ads/edit-ad/picker-ad-form/step-2-ad-content/picker-ad-content.component';
import {PickerAdDownloadComponent} from '../../ads/edit-ad/picker-ad-form/step-4-ad-download/picker-ad-download.component';
import {PickerAdAnimationComponent} from '../../ads/edit-ad/picker-ad-form/step-3-ad-animation/picker-ad-animation.component';

export const StoryAdRoutes = [
  {
    path: StorySlug + UrlSegmentDelimiter + ':id' + UrlSegmentDelimiter + pageMap.getRoute(PageKey.EditDesign),
    component: StoryAdDesignComponent
  },
  {
    path: StorySlug + UrlSegmentDelimiter + ':id' + UrlSegmentDelimiter + pageMap.getRoute(PageKey.EditContent),
    component: StoryAdContentComponent
  },
  {
    path: StorySlug + UrlSegmentDelimiter + ':id' + UrlSegmentDelimiter + pageMap.getRoute(PageKey.EditAnimation),
    component: StoryAdAnimationComponent
  },
  {
    path: StorySlug + UrlSegmentDelimiter + ':id' + UrlSegmentDelimiter + pageMap.getRoute(PageKey.EditDownload),
    component: StoryAdDownloadComponent
  }
];

export const PickerAdRoutes = [
  {
    path: PickerSlug + UrlSegmentDelimiter + ':id' + UrlSegmentDelimiter + pageMap.getRoute(PageKey.EditDesign),
    component: PickerAdDesignComponent
  },
  {
    path: PickerSlug + UrlSegmentDelimiter + ':id' + UrlSegmentDelimiter + pageMap.getRoute(PageKey.EditContent),
    component: PickerAdContentComponent
  },
  {
    path: PickerSlug + UrlSegmentDelimiter + ':id' + UrlSegmentDelimiter + pageMap.getRoute(PageKey.EditAnimation),
    component: PickerAdAnimationComponent
  },
  {
    path: PickerSlug + UrlSegmentDelimiter + ':id' + UrlSegmentDelimiter + pageMap.getRoute(PageKey.EditDownload),
    component: PickerAdDownloadComponent
  },
];
