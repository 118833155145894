import { InfoBoxModel } from '../models/info-box.model';

////############## Create Ad
export const CreateAdInfoBoxOne: InfoBoxModel = {
  title: 'INFO',
  text: 'Du weißt noch nicht genau, welches Ad für deine Kampagne passt? Dann lass dich in unserer Template Gallery inspirieren:',
  buttonText: 'Template Galerie',
};

export const CreateAdInfoBoxTwo: InfoBoxModel = {
  title: 'TIPP',
  text: 'Du hast vor, mehrere Ads im gleichen Design zu erstellen? Mit einer Designvorlage kannst du Farben, Logo, Schriften usw. einmalig definieren und für alle weiteren Ads ganz einfach wiederverwenden.',
  buttonText: 'Designvorlagen',
};

////############## Download Ad
export const DownloadAdInfoBoxTwo: InfoBoxModel = {
  title: 'TIPP',
  text: 'Du hast vor, mehrere Ads im gleichen Design zu erstellen? Mit einer Designvorlage kannst du Farben, Logo, Schriften usw. einmalig definieren und für alle weiteren Ads ganz einfach wiederverwenden.',
  buttonText: 'Designvorlagen',
};

////############## File size dialog
export const FileSizeInfoBox: InfoBoxModel = {
  title: 'TIPP',
  text: 'Verringern Sie die Qualität von Fotos im Bearbeitungsmodus oder reduzieren Sie Inhalte, um die optimale Gesamtgröße für Ihr Ad zu erhalten..',
};
