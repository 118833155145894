<form class="form configuration-items-wrapper"
      [formGroup]="animationTemplateForm"
      [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">

  <!-- ############ Überblendung -->
  <app-ad-configuration-item [item]="animationFunctionConfigurationItem">
    <div class="col-6 d-flex align-items-center">
      <mat-form-field appearance="fill">
        <mat-label>{{AnimationType}}</mat-label>
        <mat-select
          formControlName="animationType">
          <mat-option
            [value]="option.key"
            *ngFor="let option of AnimationTypeOptions">
            {{option.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </app-ad-configuration-item>
</form>

<app-edit-ad-navigation></app-edit-ad-navigation>
