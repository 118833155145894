import { PickerShape } from '../data-structures/shape';
import { Exclude, Expose } from 'class-transformer';
import { IncludesImage } from '../interfaces/includes-image';
import { OriginalOptionImageType } from '../../ads/edit-ad/edit-ad.service';
import { CropperArea } from './cropper-area';
import { AdAsset } from '../interfaces/ad-asset';

export class OptionModel implements IncludesImage, AdAsset {
  public id? = -1;
  public shape: PickerShape = 'rect';
  @Expose({ name: 'thumb_image_cropped' })
  public thumbImageCropped: string | null = null;
  @Expose({ name: 'thumb_image_original' })
  public thumbImageOriginal?: string = undefined;
  @Expose({ name: 'thumb_image_cropper_area' })
  public thumbImageCropperArea = new CropperArea();
  @Expose({ name: 'thumb_background_color' })
  public thumbBackgroundColor = '';
  @Expose({ name: 'thumb_image_name' })
  public thumbImageName = '';
  @Expose({ name: 'info_text' })
  public infoText = '';
  @Expose({ name: 'info_text_color' })
  public infoTextColor = '';
  @Expose({ name: 'background_color' })
  public infoBackgroundColor = '';
  @Expose({ name: 'background_image_cropped' })
  public infoBackgroundImageCropped: string | null = null;
  @Expose({ name: 'background_image_cropper_area' })
  public infoBackgroundImageCropperArea = new CropperArea();
  @Expose({ name: 'background_image_original' })
  public infoBackgroundImageOriginal?: string = undefined;
  @Expose({ name: 'background_image_name' })
  public infoBackgroundImageName?: string = undefined;
  @Expose({ name: 'optionable_id' })
  @Exclude({ toPlainOnly: true })
  public optionableId = -1;
  @Expose({ name: 'optionable_type' })
  @Exclude({ toPlainOnly: true })
  public optionableType = '';
  @Exclude()
  public orderNumber = -1;
  @Exclude()
  public imageOrigin = new Map<
    number,
    Map<OriginalOptionImageType, string | undefined>
  >();
}
