import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AdboxRoutes} from '../../shared/data-repository/routes';

@Injectable({
  providedIn: 'root'
})
export class EditAdGuardService implements CanActivateChild {
  private Ads = AdboxRoutes.Ads;
  private MyAds = AdboxRoutes.MyAds;

  constructor(private router: Router) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.router.getCurrentNavigation()?.extras?.state?.accessible) {
      return true;
    }
    this.router.navigate([this.Ads, this.MyAds]);
    return false;
  }
}
