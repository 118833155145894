<div
  class="text-container"
  [ngStyle]="{
    background:
      cardValues.background_type === 'color'
        ? cardValues.background_color
        : 'url(\'' + cardValues.background_image_file + '\')',
    width: cardValues.width + '%',
    'padding-left': cardValues.paddingLeft + cardValues.marginUnit,
    'padding-top': cardValues.paddingTop + cardValues.marginUnit,
    'padding-right': cardValues.paddingRight + cardValues.marginUnit,
    'padding-bottom': cardValues.paddingBottom + cardValues.marginUnit
  }"
>
  <div
    class="w-100"
    [ngStyle]="{
      'text-align': cardValues.style_image.alignSelf
    }"
  >
    <img
      [src]="
        sanitizer.bypassSecurityTrustResourceUrl(
          '/api/image/' + cardValues.image_file
        )
      "
      [ngStyle]="{
        width: cardValues.style_image.width + '%'
      }"
      [alt]="cardValues.image_title"
      [title]="cardValues.image_title"
    />
  </div>
</div>
