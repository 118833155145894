import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AdboxRoutes} from '../../shared/data-repository/routes';
import {CreateAdDialogComponent} from '../../shared/dialogs/create-ad-dialog/create-ad-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {SubSink} from 'subsink';

@Component({
  selector: 'app-ad-template-gallery',
  templateUrl: './ad-template-gallery.component.html',
  styleUrls: ['./ad-template-gallery.component.scss']
})
export class AdTemplateGalleryComponent implements OnInit {
  public AdsRoute = AdboxRoutes.Ads;
  public AdPreviewRoute = AdboxRoutes.AdPreview;
  public templates = ['a', 'b', 'c', 'd', 'e', 'f']
  private adType!: string;
  private subs = new SubSink();

  constructor(public dialog: MatDialog,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.subs.sink = this.route.queryParams
      .subscribe(params => {
        if (params && params['t']) {
          this.adType = params['t'];
        }
      });
  }

  public openDialog(): void {
    this.dialog.open(CreateAdDialogComponent, {
      width: '376px',
      data: {
        adType: this.adType
      }
    });
  }
}
