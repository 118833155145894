<div
  class="w-100 d-flex flex-column align-items-start justify-content-center gap-1"
  [formGroup]="rootFormGroup"
>
  <div class="row w-100">
    <label style="font-size: 14px; color: #332e45" class="my-2"
      >Ausrichtung</label
    >
    <div class="col-12 col-md-6 mb-3">
      <div
        class="col gap-1 d-flex flex-row justify-content-between align-items-center"
      >
        <button
          class="form-control"
          (click)="
            newPromotionService.toggleStyle(rootFormGroup?.get('alignSelf'), {
              activeValue: 'left',
              defaultValue: 'auto'
            })
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            [ngClass]="{
              'text-style-icon-selected':
                rootFormGroup?.get('alignSelf')?.value === 'left'
            }"
            fill="currentColor"
          >
            <path
              d="M3.83317 18.8334H2.1665V2.16675H3.83317V18.8334ZM18.8332 6.33342H5.49984V8.83342H18.8332V6.33342ZM13.8332 12.1667H5.49984V14.6667H13.8332V12.1667Z"
            />
          </svg>
        </button>
        <button
          class="form-control bg-white"
          (click)="
            newPromotionService.toggleStyle(rootFormGroup?.get('alignSelf'), {
              activeValue: 'center',
              defaultValue: 'auto'
            })
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="currentColor"
            [ngClass]="{
              'text-style-icon-selected':
                rootFormGroup?.get('alignSelf')?.value === 'center'
            }"
          >
            <path
              d="M9.66667 2.16675H11.3333V6.33342H18V8.83342H11.3333V12.1667H15.5V14.6667H11.3333V18.8334H9.66667V14.6667H5.5V12.1667H9.66667V8.83342H3V6.33342H9.66667V2.16675Z"
            />
          </svg>
        </button>
        <button
          class="form-control bg-white"
          (click)="
            newPromotionService.toggleStyle(rootFormGroup?.get('alignSelf'), {
              activeValue: 'right',
              defaultValue: 'auto'
            })
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="currentColor"
            [ngClass]="{
              'text-style-icon-selected':
                rootFormGroup?.get('alignSelf')?.value === 'right'
            }"
          >
            <path
              d="M17.1665 2.16675H18.8332V18.8334H17.1665V2.16675ZM2.1665 8.83342H15.4998V6.33342H2.1665V8.83342ZM7.1665 14.6667H15.4998V12.1667H7.1665V14.6667Z"
            />
          </svg>
        </button>
      </div>
    </div>
    <label style="font-size: 14px; color: #332e45" class="mb-2">Stil</label>
    <button
      class="mb-3 w-100 border-0 rounded-2 py-2 gap-1 d-flex justify-content-center align-items-center"
      style="color: #ffffff; background: #6e72eb"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16.6665 5.00004H9.99984L8.33317 3.33337H3.33317C2.4165 3.33337 1.6665 4.08337 1.6665 5.00004V15C1.6665 15.9167 2.4165 16.6667 3.33317 16.6667H16.6665C17.5832 16.6667 18.3332 15.9167 18.3332 15V6.66671C18.3332 5.75004 17.5832 5.00004 16.6665 5.00004ZM14.9498 14.1667L12.4998 12.7334L10.0498 14.1667L10.6998 11.3917L8.5415 9.52504L11.3832 9.28337L12.4998 6.66671L13.6165 9.28337L16.4582 9.52504L14.2998 11.3917L14.9498 14.1667Z"
          fill="white"
        />
      </svg>
      <span
        class="text-uppercase"
        style="
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.5px;
        "
        >branding Stil laden</span
      >
    </button>
    <div class="row row-cols-2 w-100 row-gap-1">
      <div class="col">
        <select
          class="form-select"
          aria-label="select Schriftart"
          formControlName="fontFamily"
        >
          <option value="" disabled>Schriftart</option>
          <option
            [value]="fontFamily.value"
            *ngFor="let fontFamily of newPromotionService.availableFontFamilies"
          >
            {{ fontFamily.name }}
          </option>
        </select>
      </div>
      <div class="col">
        <select
          class="form-select"
          aria-label="select Schriftart"
          formControlName="fontSize"
        >
          <option value="" disabled>Schriftgröße</option>
          <option
            [value]="fontSize"
            *ngFor="let fontSize of newPromotionService.availableFontSizes"
          >
            {{ fontSize }}
          </option>
        </select>
      </div>
      <div class="col">
        <span
          class="form-control d-flex align-items-center justify-content-between"
        >
          <label style="font-size: 14px; color: #332e45"> Farbe </label>
          <input
            type="color"
            class="color-picker"
            id="stil-color"
            formControlName="color"
            title="Choose your color"
          />
        </span>
      </div>
      <div class="col">
        <span
          class="form-control d-flex align-items-center justify-content-between"
        >
          <label style="font-size: 14px; color: #332e45"> Farbe hover </label>
          <input
            type="color"
            class="color-picker"
            id="hover-color"
            formControlName="hover_color"
            title="Choose your color"
          />
        </span>
      </div>

      <div
        class="col w-50 gap-1 d-flex flex-row justify-content-between align-items-center"
      >
        <button
          class="form-control"
          (click)="
            newPromotionService.toggleStyle(rootFormGroup.get('fontWeight'), {
              activeValue: 'bold',
              defaultValue: 'normal'
            })
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="currentColor"
            class="text-style-icon"
            [ngClass]="{
              'text-style-icon-selected':
                rootFormGroup.get('fontWeight')?.value === 'bold'
            }"
          >
            <path
              d="M13.1877 10.325C13.996 9.76663 14.5627 8.84996 14.5627 7.99996C14.5627 6.11663 13.1043 4.66663 11.2293 4.66663H6.021V16.3333H11.8877C13.6293 16.3333 14.9793 14.9166 14.9793 13.175C14.9793 11.9083 14.2627 10.825 13.1877 10.325ZM8.521 6.74996H11.021C11.7127 6.74996 12.271 7.30829 12.271 7.99996C12.271 8.69163 11.7127 9.24996 11.021 9.24996H8.521V6.74996ZM11.4377 14.25H8.521V11.75H11.4377C12.1293 11.75 12.6877 12.3083 12.6877 13C12.6877 13.6916 12.1293 14.25 11.4377 14.25Z"
            />
          </svg>
        </button>
        <button
          class="form-control bg-white"
          (click)="
            newPromotionService.toggleStyle(rootFormGroup.get('fontStyle'), {
              activeValue: 'italic',
              defaultValue: 'normal'
            })
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            [ngClass]="{
              'text-style-icon-selected':
                rootFormGroup.get('fontStyle')?.value === 'italic'
            }"
            fill="currentColor"
          >
            <path
              d="M8.83333 4.66663V7.16663H10.675L7.825 13.8333H5.5V16.3333H12.1667V13.8333H10.325L13.175 7.16663H15.5V4.66663H8.83333Z"
            />
          </svg>
        </button>
        <button
          class="form-control bg-white"
          (click)="
            newPromotionService.toggleStyle(
              rootFormGroup.get('textDecoration'),
              {
                activeValue: 'underline',
                defaultValue: null
              }
            )
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            [ngClass]="{
              'text-style-icon-selected':
                rootFormGroup.get('textDecoration')?.value === 'underline'
            }"
            fill="currentColor"
          >
            <path
              d="M10.4998 14.6667C13.2582 14.6667 15.4998 12.425 15.4998 9.66667V3H13.4165V9.66667C13.4165 11.275 12.1082 12.5833 10.4998 12.5833C8.8915 12.5833 7.58317 11.275 7.58317 9.66667V3H5.49984V9.66667C5.49984 12.425 7.7415 14.6667 10.4998 14.6667ZM4.6665 16.3333V18H16.3332V16.3333H4.6665Z"
            />
          </svg>
        </button>

        <button
          class="form-control bg-white"
          (click)="
            newPromotionService.toggleStyle(
              rootFormGroup.get('textTransform'),
              {
                activeValue: 'uppercase',
                defaultValue: 'none'
              }
            )
          "
        >
          <span
            [ngClass]="{
              'text-style-icon-selected':
                rootFormGroup.get('textTransform')?.value === 'uppercase'
            }"
            class="text-style-icon"
            style="
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 26px;
            "
            >TT</span
          >
        </button>
      </div>
    </div>
  </div>
</div>
