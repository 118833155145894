export default class StoryAd {
  constructor() {
    // @Exposable(repetition_count)
    this.maxRepetitionCount = -1;
    this.currentActiveIndex = -1;
    this.repetitionCounter = -1;
    this.finished = false;
    this.initialized = false;
    this.nextFn = this.next.bind(this);
    this.cardItems = null;
    this.progressItems = null;
  }

  resetAndStart() {
    this.cardItems = Array.from(document.querySelectorAll('#renderable #story-preview .carditem'));
    this.progressItems = Array.from(document.querySelectorAll('#renderable #story-preview #slide-indicator .progress'));

    if (this.cardItems && this.cardItems.length > 0 && this.progressItems && this.progressItems.length > 0) {
      this.resetVariables();
      this.addEventListener();
      this.resetProgressAndCards();
      this.next();
    }
  }

  next() {
    if (this.currentActiveIndex === this.progressItems.length) {
      this.repetitionCounter++;
      // Finished slide cycles
      if (this.repetitionCounter >= this.maxRepetitionCount) {
        // Progress
        const lastProgress = this.progressItems[this.progressItems.length - 1];
        lastProgress.classList.remove('active');
        lastProgress.classList.add('passed');

        // Slides
        const lastCard = this.cardItems[this.cardItems.length - 1];
        lastCard.classList.remove('hide');

        this.finished = true;
        return;
      }

      this.currentActiveIndex = 0;

      // Progress
      this.progressItems.map(progress => {
        progress.classList.remove('passed');
        progress.classList.remove('active');
      });

      // Slides
      const firstCard = this.cardItems[0];
      const lastCard = this.cardItems[this.cardItems.length - 1];
      lastCard.classList.remove('show');
      lastCard.classList.add('hide');
      firstCard.classList.remove('hide');
      firstCard.classList.add('show');
    }

    if (this.currentActiveIndex > 0) {
      // Progress
      const prevProgress = this.progressItems[this.currentActiveIndex - 1];
      prevProgress.classList.remove('active');
      prevProgress.classList.add('passed');

      // Slides
      this.cardItems.map(card => {
        card.classList.remove('hide');
        card.classList.remove('reverse');
      });

      const prevCard = this.cardItems[this.currentActiveIndex - 1];
      prevCard.classList.remove('show');
      prevCard.classList.add('hide');
    }

    // Progress
    const currentProgress = this.progressItems[this.currentActiveIndex];
    currentProgress.classList.add('active');

    // Slides
    const currentCard = this.cardItems[this.currentActiveIndex];
    currentCard.classList.remove('hide');
    currentCard.classList.add('show');

    this.currentActiveIndex++;
  }

  previousCard() {
    if (this.currentActiveIndex > 1 && !this.finished) {
      const index = this.currentActiveIndex - 1;

      // Progressper
      const currentProgress = this.progressItems[index];
      const prevProgress = this.progressItems[index - 1];
      currentProgress.classList.remove('active');
      prevProgress.classList.remove('passed');
      prevProgress.classList.add('active');

      // Slides
      const currentCard = this.cardItems[index];
      const prevCard = this.cardItems[index - 1];
      currentCard.classList.remove('show');
      currentCard.classList.add('hide');
      currentCard.classList.add('reverse');

      prevCard.classList.remove('hide');
      prevCard.classList.add('show');
      prevCard.classList.add('reverse');

      this.currentActiveIndex--;
    }
  }

  resetVariables() {
    this.repetitionCounter = 0;
    this.currentActiveIndex = 0;
    this.finished = false;
  }

  resetProgressAndCards() {
    // Slides
    this.cardItems.forEach(card => {
      card.classList.remove('show');
      card.classList.remove('reverse');
    });
    // Progress
    this.progressItems.forEach(progress => {
      progress.classList.remove('passed');
      progress.classList.remove('active');
    });
  }

  addEventListener() {
    const navBack = document.getElementById('nav-back');
    const navNext = document.getElementById('nav-next');
    if (navBack && navNext && !this.initialized) {
      navBack.addEventListener('click', this.previousCard.bind(this));
      navNext.addEventListener('click', this.next.bind(this));
      this.initialized = true;
    }

    this.progressItems.map(progress => progress.removeEventListener("animationend", this.nextFn, true));
    this.progressItems.map(progress => progress.addEventListener("animationend", this.nextFn, true));
  }
}

// @Object(StoryAd)
