import { Component, Input } from '@angular/core';
import { LayoutCard } from '../../new-promotion.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-text-block-preview',
  templateUrl: './image-text-block-preview.component.html',
  styleUrls: ['./image-text-block-preview.component.scss'],
})
export class ImageTextBlockPreviewComponent {
  @Input() card!: LayoutCard;

  constructor(public sanitizer: DomSanitizer) {}

  get cardValues() {
    return this.card.data.value;
  }
}
