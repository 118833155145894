import { Component, Input } from '@angular/core';
import { LayoutCard, NewPromotionService } from '../../new-promotion.service';

@Component({
  selector: 'app-block-preview-wrapper',
  templateUrl: './block-preview-wrapper.component.html',
  styleUrls: ['./block-preview-wrapper.component.scss'],
})
export class BlockPreviewWrapperComponent {
  @Input() card!: LayoutCard;
  @Input() index!: number;

  constructor(public newPromotionService: NewPromotionService) {}

  deleteBlock() {
    this.newPromotionService.deleteBlock(this.card.id);
  }

  expandCard() {
    this.newPromotionService.setLayoutCardExpanded(this.card, true);
  }

  duplicateCard() {
    this.newPromotionService.duplicateCard({
      card: this.card,
      index: this.index + 1,
    });
  }
}
