<div style="visibility: hidden; position: fixed"
     [style.left.px]="contextMenuPosition.x"
     [style.top.px]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu"
          backdropClass="transparent">
  <ng-template matMenuContent let-items="items" let-data="data">
    <button *ngFor="let item of items"
            mat-menu-item
            (click)="item.clickHandler(data)"
            class="d-flex align-items-center"
            [disabled]="item.isDisabled(data)">
      <span *ngIf="item.symbol !== null" class="material-icons-outlined me-2 pe-1">{{item.symbol}}</span>
      {{item.label}}
    </button>
  </ng-template>
</mat-menu>
