<div
  class="base-container"
  [ngClass]="{
    'base-container-selected':
      newPromotionService.selectedLayoutCard?.id === card.id,
    'mobile-preview': newPromotionService.previewType === 'Mobile',
    'tablet-preview': newPromotionService.previewType === 'Tab',
    'desktop-preview': newPromotionService.previewType === 'Desktop'
  }"
>
  <div
    class="selected-overlay"
    *ngIf="
      newPromotionService.selectedLayoutCard?.id === card.id &&
      !newPromotionService.layoutCardExpanded
    "
  >
    <div class="btn-circle btn-circle-top">
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.9166 12.4167H12.4166V17.9167H10.5833V12.4167H5.08325V10.5834H10.5833V5.08337H12.4166V10.5834H17.9166V12.4167Z"
          fill="white"
        />
      </svg>
    </div>
    <div class="btn-circle btn-circle-bottom">
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.9166 12.4167H12.4166V17.9167H10.5833V12.4167H5.08325V10.5834H10.5833V5.08337H12.4166V10.5834H17.9166V12.4167Z"
          fill="white"
        />
      </svg>
    </div>
    <div class="selected-overlay-action-items-container">
      <button (click)="expandCard()" class="action-btn">
        <i class="material-icons">edit</i>
      </button>
      <button class="action-btn" role="button" (click)="duplicateCard()">
        <i class="material-icons">content_copy</i>
      </button>
      <button class="action-btn" role="button">
        <i class="material-icons">visibility</i>
      </button>
      <button class="action-btn" role="button" (click)="deleteBlock()">
        <i class="material-icons">delete</i>
      </button>
    </div>
  </div>
  <div
    [id]="card.id"
    class="block-name-container"
    [ngClass]="{
      'block-name-container-selected shadow':
        newPromotionService.selectedLayoutCard?.id === card.id
    }"
  >
    <mat-icon>drag_indicator</mat-icon>
    {{ card.type }}
  </div>
  <app-quiz-block-preview
    *ngIf="card.type === 'Quiz'"
    [card]="card"
  ></app-quiz-block-preview>
  <app-text-block-preview
    *ngIf="card.type === 'Text'"
    [card]="card"
  ></app-text-block-preview>
  <app-image-block-preview
    *ngIf="card.type === 'Bild'"
    [card]="card"
  ></app-image-block-preview>
  <app-video-block-preview
    *ngIf="card.type === 'Video'"
    [card]="card"
  ></app-video-block-preview>
  <app-image-text-block-preview
    *ngIf="card.type === 'Bild/Text'"
    [card]="card"
  ></app-image-text-block-preview>
  <app-gallery-block-preview
    *ngIf="card.type === 'Galerie'"
    [card]="card"
  ></app-gallery-block-preview>
  <app-image-banner-block-preview
    *ngIf="card.type === 'Bildbanner'"
    [card]="card"
  ></app-image-banner-block-preview>
  <app-button-block-preview
    *ngIf="card.type === 'Button'"
    [card]="card"
  ></app-button-block-preview>
  <app-columns-block-preview
    *ngIf="card.type === 'Spalten'"
    [card]="card"
  ></app-columns-block-preview>
  <app-spacing-block-preview
    *ngIf="card.type === 'Abstand'"
    [card]="card"
  ></app-spacing-block-preview>
  <app-navbar-block-preview
    *ngIf="card.type === 'Navbar'"
    [card]="card"
  ></app-navbar-block-preview>
  <app-footer-block-preview
    *ngIf="card.type === 'Footer'"
    [card]="card"
  ></app-footer-block-preview>
</div>
