import {Injectable} from '@angular/core';
import {AdStorageKey, OptionsStorageKey, SlidesStorageKey} from '../data-repository/global-constants';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public setData(key: string, data: any) {
    const jsonData = JSON.stringify(data)
    localStorage.setItem(key, jsonData)
  }

  public getData(key: string): any | null {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }

  public removeData(key: any): any {
    localStorage.removeItem(key)
  }

  public clear(): any {
    this.removeData(AdStorageKey);
    this.removeData(SlidesStorageKey);
    this.removeData(OptionsStorageKey);
  }
}

