import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {EndpointService, HttpOperation} from '../../shared/services/endpoint.service';
import {AdTypeService} from '../../ads/ad-type.service';
import {HttpErrorService} from '../../shared/services/http-error.service';
import * as fromFontsActions from './fonts.actions';
import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {map} from 'rxjs';
import {plainToInstance} from 'class-transformer';
import * as fromSlidesActions from '../slides/slides.actions';
import {FontModel} from '../../shared/models/font.model';

@Injectable()
export class FontsEffects {
  fetchFonts$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromFontsActions.fetchFontList),
        switchMap(() => {
          const endpoint = this.endpointService.getFontsSlug(HttpOperation.Get);
          return this.http.get<any>(endpoint)
            .pipe(
              catchError(error => {
                return this.httpErrorService.handleError('Fonts', fromSlidesActions, error);
              })
            )
        }),
        map((json: any[]) => {
          const slides = plainToInstance(FontModel, json);
          return fromFontsActions.setFontList({payload: slides}) || []
        })
      )
    }
  );

  addFont$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromFontsActions.addFont),
        map(action => action.payload),
        mergeMap((fontData) => {
            const endpoint = this.endpointService.getFontsSlug(HttpOperation.Post);
            var formData: any = new FormData();
            formData.append("file", fontData);

            return this.http.post<any>(endpoint, formData)
              .pipe(
                map(() => {
                  return fromFontsActions.NoOp({payload: HttpOperation.Put})
                }),
                catchError(error => {
                  return this.httpErrorService.handleError('Fonts', fromSlidesActions, error);
                })
              )
          }
        )
      )
    }
  );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private endpointService: EndpointService,
    private adTypeService: AdTypeService,
    private httpErrorService: HttpErrorService
  ) {
  }
}
