<div class="container">
  <div
    class="pb-sm-3 pb-2 pt-sm-0 pt-1 text-end mt-sm-n3 pe-sm-5 new-button-container"
  >
    <a
      [routerLink]="[ManageSingleUserRoute]"
      class="btn btn-indigo btn-indigo-2"
      *ngIf="canEditUsers && queryParams.management_type === 'users'"
      >Neuer Benutzer</a
    >
    <a
      [routerLink]="[ManageSingleCustomerRoute]"
      class="btn btn-indigo btn-indigo-2"
      *ngIf="isSuperAdmin && queryParams.management_type === 'customers'"
      >Neuer Kunde</a
    >
  </div>
  <ul class="nav nav-tabs mb-5 mt-3" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        [ngClass]="{ active: queryParams.management_type === 'users' }"
        (click)="changeManagementType('users')"
        id="a-tab"
        data-bs-toggle="tab"
        data-bs-target="#a-tab-pane"
        type="button"
        role="tab"
        aria-controls="a-tab-pane"
      >
        Benutzer
      </button>
    </li>
    <li class="nav-item" role="presentation" *ngIf="isSuperAdmin">
      <button
        class="nav-link"
        [ngClass]="{ active: queryParams.management_type === 'customers' }"
        (click)="changeManagementType('customers')"
        id="b-tab"
        data-bs-toggle="tab"
        data-bs-target="#b-tab-pane"
        type="button"
        role="tab"
        aria-controls="b-tab-pane"
      >
        Kunden
      </button>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div
      class="tab-pane fade show"
      [ngClass]="{ active: queryParams.management_type === 'users' }"
      id="a-tab-pane"
      role="tabpanel"
      aria-labelledby="a-tab"
      tabindex="0"
    >
      <div class="card table-responsive box-shadow">
        <table class="table mb-0">
          <thead>
            <tr>
              <th scope="col" class="td-w36"></th>
              <th scope="col">
                Vorname
                <button
                  class="sort link-icon-svg-grey ghost-btn transition-all"
                  [ngClass]="{
                    'rotate-up': queryParams.sortDir === 'desc'
                  }"
                  *ngIf="queryParams.sortBy === 'first_name'"
                  (click)="changeSort('first_name')"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z"
                      fill="#332E45"
                    />
                  </svg>
                </button>
                <button
                  class="sort link-icon-svg-grey ghost-btn transition-all"
                  *ngIf="queryParams.sortBy !== 'first_name'"
                  (click)="changeSort('first_name')"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M11.9999 5.83L15.1699 9L16.5799 7.59L11.9999 3L7.40991 7.59L8.82991 9L11.9999 5.83ZM11.9999 18.17L8.82991 15L7.41991 16.41L11.9999 21L16.5899 16.41L15.1699 15L11.9999 18.17Z"
                    />
                  </svg>
                </button>
              </th>
              <th scope="col">
                Nachname
                <button
                  class="sort link-icon-svg-grey ghost-btn transition-all"
                  [ngClass]="{
                    'rotate-up': queryParams.sortDir === 'desc'
                  }"
                  *ngIf="queryParams.sortBy === 'last_name'"
                  (click)="changeSort('last_name')"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z"
                      fill="#332E45"
                    />
                  </svg>
                </button>
                <button
                  class="sort link-icon-svg-grey ghost-btn transition-all"
                  *ngIf="queryParams.sortBy !== 'last_name'"
                  (click)="changeSort('last_name')"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M11.9999 5.83L15.1699 9L16.5799 7.59L11.9999 3L7.40991 7.59L8.82991 9L11.9999 5.83ZM11.9999 18.17L8.82991 15L7.41991 16.41L11.9999 21L16.5899 16.41L15.1699 15L11.9999 18.17Z"
                    />
                  </svg>
                </button>
              </th>
              <th scope="col">
                Email
                <button
                  class="sort link-icon-svg-grey ghost-btn transition-all"
                  [ngClass]="{
                    'rotate-up': queryParams.sortDir === 'desc'
                  }"
                  *ngIf="queryParams.sortBy === 'email'"
                  (click)="changeSort('email')"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z"
                      fill="#332E45"
                    />
                  </svg>
                </button>
                <button
                  class="sort link-icon-svg-grey ghost-btn transition-all"
                  *ngIf="queryParams.sortBy !== 'email'"
                  (click)="changeSort('email')"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M11.9999 5.83L15.1699 9L16.5799 7.59L11.9999 3L7.40991 7.59L8.82991 9L11.9999 5.83ZM11.9999 18.17L8.82991 15L7.41991 16.41L11.9999 21L16.5899 16.41L15.1699 15L11.9999 18.17Z"
                    />
                  </svg>
                </button>
              </th>
              <th scope="col">
                Rolle
                <button
                  class="sort link-icon-svg-grey ghost-btn transition-all"
                  [ngClass]="{
                    'rotate-up': queryParams.sortDir === 'desc'
                  }"
                  *ngIf="queryParams.sortBy === 'role_id'"
                  (click)="changeSort('role_id')"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z"
                      fill="#332E45"
                    />
                  </svg>
                </button>
                <button
                  class="sort link-icon-svg-grey ghost-btn transition-all"
                  *ngIf="queryParams.sortBy !== 'role_id'"
                  (click)="changeSort('role_id')"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M11.9999 5.83L15.1699 9L16.5799 7.59L11.9999 3L7.40991 7.59L8.82991 9L11.9999 5.83ZM11.9999 18.17L8.82991 15L7.41991 16.41L11.9999 21L16.5899 16.41L15.1699 15L11.9999 18.17Z"
                    />
                  </svg>
                </button>
              </th>
              <th scope="col" *ngIf="isSuperAdmin">Kunde</th>
              <th scope="col">
                Status
                <button
                  class="sort link-icon-svg-grey ghost-btn transition-all"
                  [ngClass]="{
                    'rotate-up': queryParams.sortDir === 'desc'
                  }"
                  *ngIf="queryParams.sortBy === 'enabled'"
                  (click)="changeSort('enabled')"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z"
                      fill="#332E45"
                    />
                  </svg>
                </button>
                <button
                  class="sort link-icon-svg-grey ghost-btn transition-all"
                  *ngIf="queryParams.sortBy !== 'enabled'"
                  (click)="changeSort('enabled')"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M11.9999 5.83L15.1699 9L16.5799 7.59L11.9999 3L7.40991 7.59L8.82991 9L11.9999 5.83ZM11.9999 18.17L8.82991 15L7.41991 16.41L11.9999 21L16.5899 16.41L15.1699 15L11.9999 18.17Z"
                    />
                  </svg>
                </button>
              </th>
              <th scope="col" class="td-w24"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of allUsers">
              <td>
                <app-profile-picture
                  [firstName]="user.first_name"
                  [lastName]="user.last_name"
                ></app-profile-picture>
              </td>
              <td>{{ user.first_name }}</td>
              <td>{{ user.last_name }}</td>
              <td>{{ user.email }}</td>
              <td>
                <app-role-name-display
                  [roleId]="user.role_id"
                ></app-role-name-display>
              </td>
              <td *ngIf="isSuperAdmin">
                <app-company-name-display
                  [customerId]="user.customer_id"
                ></app-company-name-display>
              </td>
              <td>
                <span class="status status-active" *ngIf="user.enabled"
                  >Aktiv</span
                >
                <span class="status status-deactive" *ngIf="!user.enabled"
                  >Deaktiviert</span
                >
              </td>
              <td>
                <a
                  class="user-edit link-icon-svg-black"
                  routerLink="/user-management/user/{{ user.id }}"
                  *ngIf="canEditUsers"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 6.19L2.99878 17.25V21H6.74878L17.8088 9.94L14.0588 6.19Z"
                    />
                  </svg>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="table navigation">
          <ul class="pagination">
            <li
              class="page-item"
              [ngClass]="{ 'pointer-events-none': queryParams.page === 1 }"
            >
              <button
                class="page-link link-icon-svg-grey-b"
                [disabled]="queryParams.page === 1"
                (click)="changePage('first')"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M18.2049 16.59L13.6149 12L18.2049 7.41L16.7949 6L10.7949 12L16.7949 18L18.2049 16.59ZM5.79492 6H7.79492V18H5.79492V6Z"
                  />
                </svg>
              </button>
            </li>
            <li
              class="page-item"
              [ngClass]="{ 'pointer-events-none': queryParams.page === 1 }"
            >
              <button
                class="page-link link-icon-svg-grey-b"
                [disabled]="queryParams.page === 1"
                (click)="changePage('previous')"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M15.7049 7.41L14.2949 6L8.29492 12L14.2949 18L15.7049 16.59L11.1249 12L15.7049 7.41Z"
                  />
                </svg>
              </button>
            </li>
            <li class="page-sum d-flex align-items-center">
              <div>{{ paginationMessage() }}</div>
            </li>
            <li
              class="page-item"
              [ngClass]="{ 'pointer-events-none': isLastPage() }"
            >
              <button
                class="page-link link-icon-svg-grey-b"
                [disabled]="isLastPage()"
                (click)="changePage('next')"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.70492 6L8.29492 7.41L12.8749 12L8.29492 16.59L9.70492 18L15.7049 12L9.70492 6Z"
                  />
                </svg>
              </button>
            </li>
            <li
              class="page-item"
              [ngClass]="{ 'pointer-events-none': isLastPage() }"
            >
              <button
                class="page-link link-icon-svg-grey-b"
                [disabled]="isLastPage()"
                (click)="changePage('last')"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.79492 7.41L10.3849 12L5.79492 16.59L7.20492 18L13.2049 12L7.20492 6L5.79492 7.41ZM16.2049 6H18.2049V18H16.2049V6Z"
                  />
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div
      class="tab-pane fade show"
      [ngClass]="{ active: queryParams.management_type === 'customers' }"
      id="b-tab-pane"
      role="tabpanel"
      aria-labelledby="b-tab"
      tabindex="0"
    >
      <div class="card table-responsive box-shadow">
        <table class="table mb-0">
          <thead>
            <tr>
              <th scope="col" class="td-w36"></th>
              <th scope="col">
                Name
                <button
                  class="sort link-icon-svg-grey ghost-btn transition-all"
                  [ngClass]="{
                    'rotate-up': queryParams.sortDir === 'desc'
                  }"
                  *ngIf="queryParams.sortBy === 'label'"
                  (click)="changeSort('label')"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z"
                      fill="#332E45"
                    />
                  </svg>
                </button>
                <button
                  class="sort link-icon-svg-grey ghost-btn transition-all"
                  *ngIf="queryParams.sortBy !== 'label'"
                  (click)="changeSort('label')"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M11.9999 5.83L15.1699 9L16.5799 7.59L11.9999 3L7.40991 7.59L8.82991 9L11.9999 5.83ZM11.9999 18.17L8.82991 15L7.41991 16.41L11.9999 21L16.5899 16.41L15.1699 15L11.9999 18.17Z"
                    />
                  </svg>
                </button>
              </th>
              <th scope="col">Lizenz</th>
              <th scope="col">
                Status
                <button
                  class="sort link-icon-svg-grey ghost-btn transition-all"
                  [ngClass]="{
                    'rotate-up': queryParams.sortDir === 'desc'
                  }"
                  *ngIf="queryParams.sortBy === 'enabled'"
                  (click)="changeSort('enabled')"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z"
                      fill="#332E45"
                    />
                  </svg>
                </button>
                <button
                  class="sort link-icon-svg-grey ghost-btn transition-all"
                  *ngIf="queryParams.sortBy !== 'enabled'"
                  (click)="changeSort('enabled')"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M11.9999 5.83L15.1699 9L16.5799 7.59L11.9999 3L7.40991 7.59L8.82991 9L11.9999 5.83ZM11.9999 18.17L8.82991 15L7.41991 16.41L11.9999 21L16.5899 16.41L15.1699 15L11.9999 18.17Z"
                    />
                  </svg>
                </button>
              </th>
              <th scope="col">Laufzeitende</th>
              <th scope="col">
                Benutzer
                <button
                  class="sort link-icon-svg-grey ghost-btn transition-all"
                  [ngClass]="{
                    'rotate-up': queryParams.sortDir === 'desc'
                  }"
                  *ngIf="queryParams.sortBy === 'user_count'"
                  (click)="changeSort('user_count')"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z"
                      fill="#332E45"
                    />
                  </svg>
                </button>
                <button
                  class="sort link-icon-svg-grey ghost-btn transition-all"
                  *ngIf="queryParams.sortBy !== 'user_count'"
                  (click)="changeSort('user_count')"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M11.9999 5.83L15.1699 9L16.5799 7.59L11.9999 3L7.40991 7.59L8.82991 9L11.9999 5.83ZM11.9999 18.17L8.82991 15L7.41991 16.41L11.9999 21L16.5899 16.41L15.1699 15L11.9999 18.17Z"
                    />
                  </svg>
                </button>
              </th>
              <th scope="col" class="td-w64"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let customer of allCustomers">
              <td>
                <!--                <img-->
                <!--                  src="/assets/imgs/dummy/company4.png"-->
                <!--                  alt=""-->
                <!--                  class="company-icon"-->
                <!--                />-->
                <div class="company-icon">
                  <app-customer-logo-display
                    [customerId]="customer.id"
                  ></app-customer-logo-display>
                </div>
              </td>
              <td>{{ customer.label }}</td>
              <td>(no data)</td>
              <td>
                <span class="status status-active" *ngIf="customer.enabled"
                  >Aktiv</span
                >
                <span class="status status-deactive" *ngIf="!customer.enabled"
                  >Deaktiviert</span
                >
              </td>
              <td>(no data)</td>
              <td>{{ customer.user_count }}</td>
              <td>
                <a
                  class="user-edit d-inline-block link-icon-svg-black"
                  routerLink="/user-management/customer/{{ customer.id }}"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 6.19L2.99878 17.25V21H6.74878L17.8088 9.94L14.0588 6.19Z"
                    />
                  </svg>
                </a>
                <a
                  class="add-user d-inline-block link-icon-svg-black"
                  href="/user-management/user?customer_id={{ customer.id }}"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15 12C17.21 12 19 10.21 19 8C19 5.79 17.21 4 15 4C12.79 4 11 5.79 11 8C11 10.21 12.79 12 15 12ZM15 6C16.1 6 17 6.9 17 8C17 9.1 16.1 10 15 10C13.9 10 13 9.1 13 8C13 6.9 13.9 6 15 6ZM15 14C12.33 14 7 15.34 7 18V20H23V18C23 15.34 17.67 14 15 14ZM9 18C9.22 17.28 12.31 16 15 16C17.7 16 20.8 17.29 21 18H9ZM6 15V12H9V10H6V7H4V10H1V12H4V15H6Z"
                    />
                  </svg>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="table navigation">
          <ul class="pagination">
            <li
              class="page-item"
              [ngClass]="{ 'pointer-events-none': queryParams.page === 1 }"
            >
              <button
                class="page-link link-icon-svg-grey-b"
                [disabled]="queryParams.page === 1"
                (click)="changePage('first')"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M18.2049 16.59L13.6149 12L18.2049 7.41L16.7949 6L10.7949 12L16.7949 18L18.2049 16.59ZM5.79492 6H7.79492V18H5.79492V6Z"
                  />
                </svg>
              </button>
            </li>
            <li
              class="page-item"
              [ngClass]="{ 'pointer-events-none': queryParams.page === 1 }"
            >
              <button
                class="page-link link-icon-svg-grey-b"
                [disabled]="queryParams.page === 1"
                (click)="changePage('previous')"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M15.7049 7.41L14.2949 6L8.29492 12L14.2949 18L15.7049 16.59L11.1249 12L15.7049 7.41Z"
                  />
                </svg>
              </button>
            </li>
            <li class="page-sum d-flex align-items-center">
              <div>{{ paginationMessage() }}</div>
            </li>
            <li
              class="page-item"
              [ngClass]="{ 'pointer-events-none': isLastPage() }"
            >
              <button
                class="page-link link-icon-svg-grey-b"
                [disabled]="isLastPage()"
                (click)="changePage('next')"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.70492 6L8.29492 7.41L12.8749 12L8.29492 16.59L9.70492 18L15.7049 12L9.70492 6Z"
                  />
                </svg>
              </button>
            </li>
            <li
              class="page-item"
              [ngClass]="{ 'pointer-events-none': isLastPage() }"
            >
              <button
                class="page-link link-icon-svg-grey-b"
                [disabled]="isLastPage()"
                (click)="changePage('last')"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.79492 7.41L10.3849 12L5.79492 16.59L7.20492 18L13.2049 12L7.20492 6L5.79492 7.41ZM16.2049 6H18.2049V18H16.2049V6Z"
                  />
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
