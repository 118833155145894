import {createReducer, on} from '@ngrx/store';
import {setFileTypesList} from './file-types.actions';
import {FileTypeModel} from '../../shared/models/file-type.model';

export const initialState: FileTypeModel = {
  fonts: [],
  images: [],
  maxFileUploads: {
    fonts: -1,
    images: -1
  }
};

export const fileTypesReducer = createReducer(
  initialState,
  on(setFileTypesList, (state, {payload}): FileTypeModel => {
    const newFileTypeModel = new FileTypeModel();
    Object.assign(newFileTypeModel, payload);
    return newFileTypeModel;
  })
);
