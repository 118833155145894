<div
  class="modal fade"
  id="addNewBlockModal"
  data-bs-backdrop="static"
  tabindex="-1"
  aria-labelledby="addNewBlockModal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-xl new-block-modal">
    <div class="modal-content">
      <div class="modal-title">
        <div class="row mb-2" style="border-bottom: 1px solid #f8f7fa">
          <h4
            class="mb-2 px-4 pt-4"
            style="font-style: normal; font-weight: 500; line-height: 26px"
          >
            Neuer Block
          </h4>
        </div>
      </div>
      <div
        class="modal-body position-relative row rounded-2"
        style="color: #332e45"
      >
        <div class="">
          <nav class="px-4" style="background: #f8f7fe">
            <div
              class="w-100 row justify-content-between nav nav-tabs"
              id="nav-tab"
              role="tablist"
            >
              <button
                class="nav-link active col nav-tab-custom"
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-home"
                type="button"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                Alle ({{ promotionBlocks.length }})
              </button>
              <button
                class="nav-link col nav-tab-custom"
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                Layout Blöcke (12)
              </button>
              <button
                class="nav-link col nav-tab-custom"
                id="nav-contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-contact"
                type="button"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
              >
                Promotions (3)
              </button>
            </div>
          </nav>
          <div class="row w-100 pb-1" id="nav-tabContent">
            <div
              class="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
              tabindex="0"
            >
              <div class="block-item-container">
                <div
                  class="col nav-button"
                  *ngFor="let promotionBlock of promotionBlocks"
                >
                  <button
                    class="bg-white border-0"
                    data-bs-toggle="modal"
                    data-bs-target="#addNewBlockModal"
                    (click)="
                      addNewBlock(promotionBlock.name, promotionBlock.id)
                    "
                  >
                    <span class="d-flex flex-column justify-content-center">
                      <span
                        class="rounded-2"
                        style="padding: 20px; border: 1px solid #d7ddea"
                      >
                        <i
                          class="material-icons"
                          *ngIf="
                            promotionBlock.icon !== 'bars' &&
                            promotionBlock.icon !== 'border-all'
                          "
                          >{{ promotionBlock.icon }}</i
                        >
                        <i
                          class="material-icons"
                          *ngIf="promotionBlock.icon === 'border-all'"
                          >border_all</i
                        >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="33"
                          viewBox="0 0 32 33"
                          fill="none"
                          *ngIf="promotionBlock.icon === 'bars'"
                        >
                          <path
                            d="M5.3335 20.5H26.6668V17.8334H5.3335V20.5ZM5.3335 25.8334H26.6668V23.1667H5.3335V25.8334ZM5.3335 15.1667H26.6668V12.5H5.3335V15.1667ZM5.3335 7.16669V9.83335H26.6668V7.16669H5.3335Z"
                            fill="#332E45"
                          />
                        </svg>
                      </span>
                      <span class="button-with-text py-1">{{
                        promotionBlock.name
                      }}</span>
                    </span>
                  </button>
                </div>
              </div>
              <div class="mt-5 block-item-container">
                <div class="col nav-button">
                  <button
                    class="bg-white border-0"
                    data-bs-toggle="modal"
                    data-bs-target="#addNewBlockModal"
                    (click)="addNewBlock('Quiz', 14)"
                  >
                    <span class="d-flex flex-column justify-content-center">
                      <span
                        class="rounded-2"
                        style="padding: 20px; border: 1px solid #d7ddea"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="33"
                          viewBox="0 0 32 33"
                          fill="none"
                        >
                          <path
                            d="M12.5332 22.6333L6.39984 16.5L12.5332 10.3667L10.6665 8.5L2.6665 16.5L10.6665 24.5L12.5332 22.6333ZM19.4665 22.6333L25.5998 16.5L19.4665 10.3667L21.3332 8.5L29.3332 16.5L21.3332 24.5L19.4665 22.6333Z"
                            fill="#332E45"
                          />
                        </svg>
                      </span>
                      <span class="button-with-text py-1">Quiz</span>
                    </span>
                  </button>
                </div>
                <div class="col nav-button">
                  <button class="bg-white border-0">
                    <span class="d-flex flex-column justify-content-center">
                      <span
                        class="rounded-2"
                        style="padding: 20px; border: 1px solid #d7ddea"
                      >
                        <svg
                          width="32"
                          height="33"
                          viewBox="0 0 32 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4 4.5V15.1667H14.6667V4.5H4ZM12 12.5H6.66667V7.16667H12V12.5ZM4 17.8333V28.5H14.6667V17.8333H4ZM12 25.8333H6.66667V20.5H12V25.8333ZM17.3333 4.5V15.1667H28V4.5H17.3333ZM25.3333 12.5H20V7.16667H25.3333V12.5ZM17.3333 17.8333V28.5H28V17.8333H17.3333ZM25.3333 25.8333H20V20.5H25.3333V25.8333Z"
                            fill="#332E45"
                          />
                        </svg>
                      </span>
                      <span class="button-with-text py-1">Memory</span>
                    </span>
                  </button>
                </div>
                <div class="col nav-button">
                  <button class="bg-white border-0">
                    <span class="d-flex flex-column justify-content-center">
                      <span
                        class="rounded-2"
                        style="padding: 20px; border: 1px solid #d7ddea"
                      >
                        <svg
                          width="32"
                          height="33"
                          viewBox="0 0 32 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M26.6665 5.83331H5.33317C3.8665 5.83331 2.6665 7.03331 2.6665 8.49998V24.5C2.6665 25.9666 3.8665 27.1666 5.33317 27.1666H26.6665C28.1332 27.1666 29.3332 25.9666 29.3332 24.5V8.49998C29.3332 7.03331 28.1332 5.83331 26.6665 5.83331ZM10.6665 15.1666H5.33317V8.49998H10.6665V15.1666ZM18.6665 15.1666H13.3332V8.49998H18.6665V15.1666ZM26.6665 15.1666H21.3332V8.49998H26.6665V15.1666ZM10.6665 24.5H5.33317V17.8333H10.6665V24.5ZM18.6665 24.5H13.3332V17.8333H18.6665V24.5ZM26.6665 24.5H21.3332V17.8333H26.6665V24.5Z"
                            fill="#332E45"
                          />
                        </svg>
                      </span>
                      <span class="button-with-text py-1"
                        >Vorfreude-Kalender</span
                      >
                    </span>
                  </button>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
                tabindex="0"
              >
                ...
              </div>
              <div
                class="tab-pane fade"
                id="nav-contact"
                role="tabpanel"
                aria-labelledby="nav-contact-tab"
                tabindex="0"
              >
                ...
              </div>
              <div
                class="tab-pane fade"
                id="nav-disabled"
                role="tabpanel"
                aria-labelledby="nav-disabled-tab"
                tabindex="0"
              >
                ...
              </div>
            </div>
          </div>
          <div class="row w-100" style="border-top: 1px solid #f8f7fa">
            <div
              class="d-flex flex-row gap-1 justify-content-end mt-2 px-4 pb-4"
            >
              <button
                class="bg-transparent border-0 px-4 py-1"
                role="button"
                data-bs-toggle="modal"
                data-bs-target="#addNewBlockModal"
                style="
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  letter-spacing: 0.5px;
                  text-transform: uppercase;
                "
              >
                Abbrechen
              </button>
              <button
                class="bg-transparent border-0 px-4 py-1"
                style="
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  letter-spacing: 0.5px;
                  text-transform: uppercase;
                "
              >
                Hinzufügen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
