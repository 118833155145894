import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { PaginationData } from '../utils/shared-types';

export type Customer = {
  id: number;
  enabled: boolean;
  license_id?: number;
  label?: string;
  company?: string;
  vat_nr?: string;
  first_name: string;
  last_name: string;
  email: string;
  billing_street?: string;
  billing_house_number?: string;
  billing_zip?: string;
  billing_city?: string;
  billing_email?: string;
  invoice_by_mail: boolean;
  created_at: string;
  updated_at: string;
  user_count?: number;
  logo_file: string | null;
};

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  // cache get customer by id requests
  customerCacheMap = new Map<number, BehaviorSubject<Customer | undefined>>();

  constructor(private http: HttpClient) {}

  getCustomers(params?: any) {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((k) => {
      if (k === 'sortBy' && params[k] !== undefined) {
        searchParams.set('order[column]', params[k]);
      } else if (k === 'sortDir' && params[k] !== undefined) {
        searchParams.set('order[direction]', params[k]);
      } else if (params[k] !== undefined && params[k] !== 'management_type') {
        searchParams.set(k, params[k]);
      }
    });

    return this.http.get<{
      success: boolean;
      data: Customer[];
      meta: PaginationData;
    }>(`/api/customers?${searchParams.toString()}`);
  }

  getCustomerById(id: number) {
    if (this.customerCacheMap.get(id)) {
      return this.customerCacheMap.get(id) as BehaviorSubject<
        Customer | undefined
      >;
    } else {
      return this.http
        .get<{ success: boolean; data: Customer }>(`/api/customers/${id}`)
        .pipe((res) => {
          // create map item
          const ob = new BehaviorSubject<Customer | undefined>(undefined);
          this.customerCacheMap.set(id, ob);

          res.subscribe({
            next: (res) => {
              if (res.success) {
                ob.next(res.data);
              }
            },
            error: (err) => {
              console.log(err);
              ob.error('Error Occurred');
            },
          });

          return ob;
        });
    }
  }

  createCustomer(data: any) {
    return this.http.post<{ success: boolean; data: Customer }>(
      '/api/customers',
      data
    );
  }

  updateCustomer(id: number, data: any) {
    this.customerCacheMap.delete(id);
    return this.http.put<{ success: boolean; data: Customer }>(
      `/api/customers/${id}`,
      data
    );
  }

  getCustomerLogoById(id: number) {
    return this.http.get<{ success: boolean; data: Customer }>(
      `/api/customers/${id}/logo/`
    );
  }

  updateCustomerLogo(id: number, logo: File) {
    const data = new FormData();
    data.set('file', logo);
    return this.http.put<{ success: boolean; data: Customer }>(
      `/api/customers/${id}/logo/`,
      logo
    );
  }
}
