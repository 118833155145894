<div
  class="w-100 d-flex position-relative"
  style="background: #e0e8fb; height: 95.5vh"
>
  <div class="col-9 overflow-x-auto">
    <app-block-preview-wrapper
      [card]="newPromotionService.selectedLayoutCard"
      *ngIf="
        newPromotionService.selectedLayoutCard &&
        newPromotionService.layoutCardExpanded
      "
    ></app-block-preview-wrapper>
    <div
      class="d-flex flex-column"
      *ngIf="!newPromotionService.layoutCardExpanded"
    >
      <app-block-preview-wrapper
        [card]="card"
        [index]="i"
        *ngFor="let card of newPromotionService.layoutCards; index as i"
      ></app-block-preview-wrapper>
    </div>
  </div>
  <div class="col-3 h-100 shadow bg-white overflow-y-auto">
    <app-new-promotion-settings-tab
      *ngIf="newPromotionService.sidebarView === 'Settings'"
    ></app-new-promotion-settings-tab>
    <app-new-promotion-layout-tab
      *ngIf="newPromotionService.sidebarView === 'Layout'"
    ></app-new-promotion-layout-tab>
  </div>
  <div class="position-fixed d-flex" style="bottom: 40px; right: 60px">
    <div class="d-flex justify-content-end">
      <button class="bg-white rounded-circle border-0 p-2 shadow">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="icon">
            <path
              id="Vector"
              d="M11 4.125C6.41666 4.125 2.50249 6.97583 0.916656 11C2.50249 15.0242 6.41666 17.875 11 17.875C15.5833 17.875 19.4975 15.0242 21.0833 11C19.4975 6.97583 15.5833 4.125 11 4.125ZM11 15.5833C8.46999 15.5833 6.41666 13.53 6.41666 11C6.41666 8.47 8.46999 6.41667 11 6.41667C13.53 6.41667 15.5833 8.47 15.5833 11C15.5833 13.53 13.53 15.5833 11 15.5833ZM11 8.25C9.47832 8.25 8.24999 9.47833 8.24999 11C8.24999 12.5217 9.47832 13.75 11 13.75C12.5217 13.75 13.75 12.5217 13.75 11C13.75 9.47833 12.5217 8.25 11 8.25Z"
              fill="#6E72EB"
            />
          </g>
        </svg>
      </button>
      <button
        (click)="newPromotionService.handleSubmit()"
        class="rounded-5 border-0 px-4 py-2 shadow text-white ms-2"
        style="
          background: #6e72eb;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.5px;
        "
      >
        SPEICHERN
      </button>
    </div>
  </div>
</div>
