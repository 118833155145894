export const SnackBarSuccessDurationInSeconds = 3;
export const SnackBarErrorDurationInSeconds = 6;
export const AdStorageKey = 'Ad';
export const SlidesStorageKey = 'Slides';
export const OptionsStorageKey = 'Options';
export const Asc = 'asc';
export const Desc = 'desc';
export const UrlSegmentDelimiter = '/';
export const UrlQueryDelimiter = '?';
export const MaxSlideCount = 10;
export const MaxOptionCount = 10;
export const ItemsPerPage = 10;

// Publisher
export const IABThresholdBinary = 81920; // in binary bytes
export const GDNThresholdBinary = 153600; // in binary bytes
export const IABThresholdDecimal = 80; // kb in decimal
export const GDNThresholdDecimal = 150; // kb in decimal

// Filter
export const FilterStatus = 'status';
export const FilterBaseableType = 'baseable_type';

// Designators
export const StoryAdTypeDesignator = 'App\\Models\\StoryAd';
export const PickerAdTypeDesignator = 'App\\Models\\PickerAd';
