import { Injectable } from '@angular/core';
import {
  ParamMap,
  PRIMARY_OUTLET,
  UrlSegment,
  UrlSegmentGroup,
  UrlSerializer,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlSegmentDelimiter } from '../data-repository/global-constants';

@Injectable({
  providedIn: 'root',
})
export class RoutingHelperService {
  public currentPage = '';

  constructor(private serializer: UrlSerializer) {}

  public getURLSegments(url: string | UrlTree): UrlSegment[] | null {
    let tree = url;
    if (typeof url === 'string') {
      tree = this.serializer.parse(url);
    }
    const group: UrlSegmentGroup = (tree as UrlTree).root.children[
      PRIMARY_OUTLET
    ];
    return group?.segments;
  }

  public toURLTree(url: string): UrlTree {
    return this.serializer.parse(url);
  }

  public parentURL(url: string | UrlTree): string {
    const segments = this.getURLSegments(url);
    let parentURL = '';

    if (segments && segments.length > 0) {
      segments.pop();
      parentURL = segments.join(UrlSegmentDelimiter);
    }
    return parentURL;
  }

  public serialize(tree: UrlTree): string {
    return this.serializer.serialize(tree);
  }

  public fetchAdIdFromRoute(
    paramMap: Observable<ParamMap>
  ): Observable<number> {
    return paramMap.pipe(
      map((params) => {
        const id = params.get('id');
        if (!id || isNaN(+id)) {
          throw new Error('Could not parse ad id.');
        }
        return +id;
      })
    );
  }
}
