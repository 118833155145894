<div class="page-body p-5 w-100 d-flex justify-content-center">
  <div class="card-container">
    <div class="row row-cols-1 row-cols-lg-2 row-cols-xxl-3 g-4">
      <div class="col">
        <div class="add-card rounded-3 shadow ps-3 pe-2">
          <div
            class="d-flex flex-row mb-1 justify-content-sm-start align-items-center"
            style="width: 152px; height: 54px"
          >
            <svg
              width="135"
              height="2"
              viewBox="0 0 135 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Group 1">
                <rect
                  id="rectangle"
                  width="23"
                  height="2"
                  rx="1"
                  fill="#2E2960"
                />
                <rect
                  id="rectangle_2"
                  x="28"
                  width="23"
                  height="2"
                  rx="1"
                  fill="#2E2960"
                />
                <rect
                  id="rectangle_3"
                  x="56"
                  width="23"
                  height="2"
                  rx="1"
                  fill="#2E2960"
                />
                <rect
                  id="rectangle_4"
                  x="84"
                  width="23"
                  height="2"
                  rx="1"
                  fill="white"
                />
                <rect
                  id="rectangle_5"
                  x="112"
                  width="23"
                  height="2"
                  rx="1"
                  fill="white"
                />
              </g>
            </svg>
          </div>
          <div class="row">
            <div class="row">
              <h4>Formula</h4>
            </div>
            <div class="row">
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</p>
            </div>
          </div>
          <div
            class="d-flex flex-row justify-content-end align-items-center mt-n1"
          >
            <div class="me-1">
              <svg
                width="25"
                height="25"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="icon">
                  <path
                    id="Vector"
                    d="M10.9998 4.125C6.4165 4.125 2.50234 6.97583 0.916504 11C2.50234 15.0242 6.4165 17.875 10.9998 17.875C15.5832 17.875 19.4973 15.0242 21.0832 11C19.4973 6.97583 15.5832 4.125 10.9998 4.125ZM10.9998 15.5833C8.46984 15.5833 6.4165 13.53 6.4165 11C6.4165 8.47 8.46984 6.41667 10.9998 6.41667C13.5298 6.41667 15.5832 8.47 15.5832 11C15.5832 13.53 13.5298 15.5833 10.9998 15.5833ZM10.9998 8.25C9.47817 8.25 8.24984 9.47833 8.24984 11C8.24984 12.5217 9.47817 13.75 10.9998 13.75C12.5215 13.75 13.7498 12.5217 13.7498 11C13.7498 9.47833 12.5215 8.25 10.9998 8.25Z"
                    fill="#332E45"
                  />
                </g>
              </svg>
            </div>
            <button
              type="button"
              class="border-0 add-button rounded-circle shadow"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M17.4168 11.9166H11.9168V17.4166H10.0835V11.9166H4.5835V10.0833H10.0835V4.58331H11.9168V10.0833H17.4168V11.9166Z"
                    fill="white"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="add-card rounded-3 shadow ps-3 pe-2">
          <div
            class="d-flex flex-row mb-1 justify-content-sm-start align-items-center"
            style="width: 152px; height: 54px"
          >
            <div
              class="d-flex flex-row justify-content-center align-items-center rounded-start-2"
              style="
                background: #2e2960;
                color: white;
                width: 49px;
                height: 26px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              "
            >
              A
            </div>
            <div
              class="d-flex flex-row justify-content-center align-items-center rounded-end-2"
              style="
                color: #2e2960;
                width: 49px;
                height: 26px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              "
            >
              B
            </div>
          </div>
          <div class="row">
            <div class="row">
              <h4>Quiz</h4>
            </div>
            <div class="row">
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</p>
            </div>
          </div>
          <div
            class="d-flex flex-row justify-content-end align-items-center mt-n1"
          >
            <div class="me-1">
              <svg
                width="25"
                height="25"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="icon">
                  <path
                    id="Vector"
                    d="M10.9998 4.125C6.4165 4.125 2.50234 6.97583 0.916504 11C2.50234 15.0242 6.4165 17.875 10.9998 17.875C15.5832 17.875 19.4973 15.0242 21.0832 11C19.4973 6.97583 15.5832 4.125 10.9998 4.125ZM10.9998 15.5833C8.46984 15.5833 6.4165 13.53 6.4165 11C6.4165 8.47 8.46984 6.41667 10.9998 6.41667C13.5298 6.41667 15.5832 8.47 15.5832 11C15.5832 13.53 13.5298 15.5833 10.9998 15.5833ZM10.9998 8.25C9.47817 8.25 8.24984 9.47833 8.24984 11C8.24984 12.5217 9.47817 13.75 10.9998 13.75C12.5215 13.75 13.7498 12.5217 13.7498 11C13.7498 9.47833 12.5215 8.25 10.9998 8.25Z"
                    fill="#332E45"
                  />
                </g>
              </svg>
            </div>
            <button
              type="button"
              class="border-0 add-button rounded-circle shadow"
              data-bs-toggle="modal" data-bs-target="#newPromotionModal"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M17.4168 11.9166H11.9168V17.4166H10.0835V11.9166H4.5835V10.0833H10.0835V4.58331H11.9168V10.0833H17.4168V11.9166Z"
                    fill="white"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="add-card rounded-3 shadow ps-3 pe-2">
          <div
            class="d-flex flex-row mb-1 justify-content-sm-start align-items-center"
            style="width: 152px; height: 54px"
          >
            <div
              class="d-flex flex-row justify-content-center align-items-center rounded-start-2"
              style="
                background: #2e2960;
                color: white;
                width: 49px;
                height: 26px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              "
            >
              ?
            </div>
            <div
              class="d-flex flex-row justify-content-center align-items-center rounded-end-2"
              style="
                color: #2e2960;
                width: 49px;
                height: 26px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              "
            >
              ?
            </div>
          </div>
          <div class="row">
            <div class="row">
              <h4>Memory</h4>
            </div>
            <div class="row">
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</p>
            </div>
          </div>
          <div
            class="d-flex flex-row justify-content-end align-items-center mt-n1"
          >
            <div class="me-1">
              <svg
                width="25"
                height="25"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="icon">
                  <path
                    id="Vector"
                    d="M10.9998 4.125C6.4165 4.125 2.50234 6.97583 0.916504 11C2.50234 15.0242 6.4165 17.875 10.9998 17.875C15.5832 17.875 19.4973 15.0242 21.0832 11C19.4973 6.97583 15.5832 4.125 10.9998 4.125ZM10.9998 15.5833C8.46984 15.5833 6.4165 13.53 6.4165 11C6.4165 8.47 8.46984 6.41667 10.9998 6.41667C13.5298 6.41667 15.5832 8.47 15.5832 11C15.5832 13.53 13.5298 15.5833 10.9998 15.5833ZM10.9998 8.25C9.47817 8.25 8.24984 9.47833 8.24984 11C8.24984 12.5217 9.47817 13.75 10.9998 13.75C12.5215 13.75 13.7498 12.5217 13.7498 11C13.7498 9.47833 12.5215 8.25 10.9998 8.25Z"
                    fill="#332E45"
                  />
                </g>
              </svg>
            </div>
            <button
              type="button"
              class="border-0 add-button rounded-circle shadow"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M17.4168 11.9166H11.9168V17.4166H10.0835V11.9166H4.5835V10.0833H10.0835V4.58331H11.9168V10.0833H17.4168V11.9166Z"
                    fill="white"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="add-card rounded-3 shadow ps-3 pe-2">
          <div
            class="d-flex flex-row mb-1 justify-content-sm-start align-items-center"
            style="width: 152px; height: 54px"
          >
            <svg
              width="52"
              height="44"
              viewBox="0 0 52 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 32.5C24.5604 32.5 31.5 25.3366 31.5 16.5C31.5 7.66344 24.5604 0.5 16 0.5C7.43959 0.5 0.5 7.66344 0.5 16.5C0.5 25.3366 7.43959 32.5 16 32.5Z"
                stroke="#2E2960"
                stroke-dasharray="2 2"
              />
              <path
                d="M36 39C44.8366 39 52 31.8366 52 23C52 14.1634 44.8366 7 36 7C27.1634 7 20 14.1634 20 23C20 31.8366 27.1634 39 36 39Z"
                fill="#2E2960"
              />
              <g clip-path="url(#clip0_833_3318)">
                <path
                  d="M33.0804 31.3259L29.0873 27.792C28.8141 27.5497 28.4971 27.3802 28.1478 27.2866L27.1223 27.0119L28.934 20.2504C29.1846 19.315 28.6319 18.3577 27.6966 18.1071C26.7612 17.8564 25.8039 18.4092 25.5533 19.3445L22.3103 31.4476L18.8713 29.6323C18.5205 29.4538 18.0959 29.4608 17.7499 29.67L16.561 30.366L20.2308 37.1348C20.5443 37.6778 21.3373 38.1439 21.9346 38.3039L28.8764 40.164C30.0033 40.4659 31.1703 39.8969 31.627 38.8236L33.6837 33.9878C34.0756 33.0661 33.8304 31.9979 33.0804 31.3259Z"
                  fill="white"
                />
                <path
                  d="M37.7631 19.4395C36.6536 17.089 33.5248 14.8374 29.4679 13.7504C25.411 12.6634 21.5755 13.0488 19.4394 14.5297L17.8968 11.8577L16.387 17.4923L22.0216 19.0021L20.3139 16.0443C21.8303 14.8926 25.2172 14.4232 29.0149 15.4408C32.8126 16.4584 35.5111 18.5583 36.2485 20.314L33.2907 22.0216L38.9253 23.5314L40.435 17.8969L37.7631 19.4395Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_833_3318">
                  <rect
                    width="28"
                    height="28"
                    fill="white"
                    transform="translate(16.2471 9) rotate(15)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="row">
            <div class="row">
              <h4>Adventkalender</h4>
            </div>
            <div class="row">
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</p>
            </div>
          </div>
          <div
            class="d-flex flex-row justify-content-end align-items-center mt-n1"
          >
            <div class="me-1">
              <svg
                width="25"
                height="25"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="icon">
                  <path
                    id="Vector"
                    d="M10.9998 4.125C6.4165 4.125 2.50234 6.97583 0.916504 11C2.50234 15.0242 6.4165 17.875 10.9998 17.875C15.5832 17.875 19.4973 15.0242 21.0832 11C19.4973 6.97583 15.5832 4.125 10.9998 4.125ZM10.9998 15.5833C8.46984 15.5833 6.4165 13.53 6.4165 11C6.4165 8.47 8.46984 6.41667 10.9998 6.41667C13.5298 6.41667 15.5832 8.47 15.5832 11C15.5832 13.53 13.5298 15.5833 10.9998 15.5833ZM10.9998 8.25C9.47817 8.25 8.24984 9.47833 8.24984 11C8.24984 12.5217 9.47817 13.75 10.9998 13.75C12.5215 13.75 13.7498 12.5217 13.7498 11C13.7498 9.47833 12.5215 8.25 10.9998 8.25Z"
                    fill="#332E45"
                  />
                </g>
              </svg>
            </div>
            <button
              type="button"
              class="border-0 add-button rounded-circle shadow"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M17.4168 11.9166H11.9168V17.4166H10.0835V11.9166H4.5835V10.0833H10.0835V4.58331H11.9168V10.0833H17.4168V11.9166Z"
                    fill="white"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="add-card rounded-3 shadow ps-3 pe-2">
          <div
            class="d-flex flex-row mb-1 justify-content-sm-start align-items-center"
            style="width: 152px; height: 54px"
          >
            <svg
              width="52"
              height="44"
              viewBox="0 0 52 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 32.5C24.5604 32.5 31.5 25.3366 31.5 16.5C31.5 7.66344 24.5604 0.5 16 0.5C7.43959 0.5 0.5 7.66344 0.5 16.5C0.5 25.3366 7.43959 32.5 16 32.5Z"
                stroke="#2E2960"
                stroke-dasharray="2 2"
              />
              <path
                d="M36 39C44.8366 39 52 31.8366 52 23C52 14.1634 44.8366 7 36 7C27.1634 7 20 14.1634 20 23C20 31.8366 27.1634 39 36 39Z"
                fill="#2E2960"
              />
              <g clip-path="url(#clip0_833_3318)">
                <path
                  d="M33.0804 31.3259L29.0873 27.792C28.8141 27.5497 28.4971 27.3802 28.1478 27.2866L27.1223 27.0119L28.934 20.2504C29.1846 19.315 28.6319 18.3577 27.6966 18.1071C26.7612 17.8564 25.8039 18.4092 25.5533 19.3445L22.3103 31.4476L18.8713 29.6323C18.5205 29.4538 18.0959 29.4608 17.7499 29.67L16.561 30.366L20.2308 37.1348C20.5443 37.6778 21.3373 38.1439 21.9346 38.3039L28.8764 40.164C30.0033 40.4659 31.1703 39.8969 31.627 38.8236L33.6837 33.9878C34.0756 33.0661 33.8304 31.9979 33.0804 31.3259Z"
                  fill="white"
                />
                <path
                  d="M37.7631 19.4395C36.6536 17.089 33.5248 14.8374 29.4679 13.7504C25.411 12.6634 21.5755 13.0488 19.4394 14.5297L17.8968 11.8577L16.387 17.4923L22.0216 19.0021L20.3139 16.0443C21.8303 14.8926 25.2172 14.4232 29.0149 15.4408C32.8126 16.4584 35.5111 18.5583 36.2485 20.314L33.2907 22.0216L38.9253 23.5314L40.435 17.8969L37.7631 19.4395Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_833_3318">
                  <rect
                    width="28"
                    height="28"
                    fill="white"
                    transform="translate(16.2471 9) rotate(15)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="row">
            <div class="row">
              <h4>Vorfreude-Kalender</h4>
            </div>
            <div class="row">
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</p>
            </div>
          </div>
          <div
            class="d-flex flex-row justify-content-end align-items-center mt-n1"
          >
            <div class="me-1">
              <svg
                width="25"
                height="25"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="icon">
                  <path
                    id="Vector"
                    d="M10.9998 4.125C6.4165 4.125 2.50234 6.97583 0.916504 11C2.50234 15.0242 6.4165 17.875 10.9998 17.875C15.5832 17.875 19.4973 15.0242 21.0832 11C19.4973 6.97583 15.5832 4.125 10.9998 4.125ZM10.9998 15.5833C8.46984 15.5833 6.4165 13.53 6.4165 11C6.4165 8.47 8.46984 6.41667 10.9998 6.41667C13.5298 6.41667 15.5832 8.47 15.5832 11C15.5832 13.53 13.5298 15.5833 10.9998 15.5833ZM10.9998 8.25C9.47817 8.25 8.24984 9.47833 8.24984 11C8.24984 12.5217 9.47817 13.75 10.9998 13.75C12.5215 13.75 13.7498 12.5217 13.7498 11C13.7498 9.47833 12.5215 8.25 10.9998 8.25Z"
                    fill="#332E45"
                  />
                </g>
              </svg>
            </div>
            <button
              type="button"
              class="border-0 add-button rounded-circle shadow"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M17.4168 11.9166H11.9168V17.4166H10.0835V11.9166H4.5835V10.0833H10.0835V4.58331H11.9168V10.0833H17.4168V11.9166Z"
                    fill="white"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="add-card rounded-3 shadow ps-3 pe-2">
          <div
            class="d-flex flex-row mb-1 justify-content-sm-start align-items-center"
            style="width: 152px; height: 54px"
          >
            <svg
              width="52"
              height="44"
              viewBox="0 0 52 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 32.5C24.5604 32.5 31.5 25.3366 31.5 16.5C31.5 7.66344 24.5604 0.5 16 0.5C7.43959 0.5 0.5 7.66344 0.5 16.5C0.5 25.3366 7.43959 32.5 16 32.5Z"
                stroke="#2E2960"
                stroke-dasharray="2 2"
              />
              <path
                d="M36 39C44.8366 39 52 31.8366 52 23C52 14.1634 44.8366 7 36 7C27.1634 7 20 14.1634 20 23C20 31.8366 27.1634 39 36 39Z"
                fill="#2E2960"
              />
              <g clip-path="url(#clip0_833_3318)">
                <path
                  d="M33.0804 31.3259L29.0873 27.792C28.8141 27.5497 28.4971 27.3802 28.1478 27.2866L27.1223 27.0119L28.934 20.2504C29.1846 19.315 28.6319 18.3577 27.6966 18.1071C26.7612 17.8564 25.8039 18.4092 25.5533 19.3445L22.3103 31.4476L18.8713 29.6323C18.5205 29.4538 18.0959 29.4608 17.7499 29.67L16.561 30.366L20.2308 37.1348C20.5443 37.6778 21.3373 38.1439 21.9346 38.3039L28.8764 40.164C30.0033 40.4659 31.1703 39.8969 31.627 38.8236L33.6837 33.9878C34.0756 33.0661 33.8304 31.9979 33.0804 31.3259Z"
                  fill="white"
                />
                <path
                  d="M37.7631 19.4395C36.6536 17.089 33.5248 14.8374 29.4679 13.7504C25.411 12.6634 21.5755 13.0488 19.4394 14.5297L17.8968 11.8577L16.387 17.4923L22.0216 19.0021L20.3139 16.0443C21.8303 14.8926 25.2172 14.4232 29.0149 15.4408C32.8126 16.4584 35.5111 18.5583 36.2485 20.314L33.2907 22.0216L38.9253 23.5314L40.435 17.8969L37.7631 19.4395Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_833_3318">
                  <rect
                    width="28"
                    height="28"
                    fill="white"
                    transform="translate(16.2471 9) rotate(15)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="row">
            <div class="row">
              <h4>Voting</h4>
            </div>
            <div class="row">
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</p>
            </div>
          </div>
          <div
            class="d-flex flex-row justify-content-end align-items-center mt-n1"
          >
            <div class="me-1">
              <svg
                width="25"
                height="25"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="icon">
                  <path
                    id="Vector"
                    d="M10.9998 4.125C6.4165 4.125 2.50234 6.97583 0.916504 11C2.50234 15.0242 6.4165 17.875 10.9998 17.875C15.5832 17.875 19.4973 15.0242 21.0832 11C19.4973 6.97583 15.5832 4.125 10.9998 4.125ZM10.9998 15.5833C8.46984 15.5833 6.4165 13.53 6.4165 11C6.4165 8.47 8.46984 6.41667 10.9998 6.41667C13.5298 6.41667 15.5832 8.47 15.5832 11C15.5832 13.53 13.5298 15.5833 10.9998 15.5833ZM10.9998 8.25C9.47817 8.25 8.24984 9.47833 8.24984 11C8.24984 12.5217 9.47817 13.75 10.9998 13.75C12.5215 13.75 13.7498 12.5217 13.7498 11C13.7498 9.47833 12.5215 8.25 10.9998 8.25Z"
                    fill="#332E45"
                  />
                </g>
              </svg>
            </div>
            <button
              type="button"
              class="border-0 add-button rounded-circle shadow"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M17.4168 11.9166H11.9168V17.4166H10.0835V11.9166H4.5835V10.0833H10.0835V4.58331H11.9168V10.0833H17.4168V11.9166Z"
                    fill="white"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="add-card rounded-3 shadow ps-3 pe-2">
          <div
            class="d-flex flex-row mb-1 justify-content-sm-start align-items-center"
            style="width: 152px; height: 54px"
          >
            <svg
              width="52"
              height="44"
              viewBox="0 0 52 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 32.5C24.5604 32.5 31.5 25.3366 31.5 16.5C31.5 7.66344 24.5604 0.5 16 0.5C7.43959 0.5 0.5 7.66344 0.5 16.5C0.5 25.3366 7.43959 32.5 16 32.5Z"
                stroke="#2E2960"
                stroke-dasharray="2 2"
              />
              <path
                d="M36 39C44.8366 39 52 31.8366 52 23C52 14.1634 44.8366 7 36 7C27.1634 7 20 14.1634 20 23C20 31.8366 27.1634 39 36 39Z"
                fill="#2E2960"
              />
              <g clip-path="url(#clip0_833_3318)">
                <path
                  d="M33.0804 31.3259L29.0873 27.792C28.8141 27.5497 28.4971 27.3802 28.1478 27.2866L27.1223 27.0119L28.934 20.2504C29.1846 19.315 28.6319 18.3577 27.6966 18.1071C26.7612 17.8564 25.8039 18.4092 25.5533 19.3445L22.3103 31.4476L18.8713 29.6323C18.5205 29.4538 18.0959 29.4608 17.7499 29.67L16.561 30.366L20.2308 37.1348C20.5443 37.6778 21.3373 38.1439 21.9346 38.3039L28.8764 40.164C30.0033 40.4659 31.1703 39.8969 31.627 38.8236L33.6837 33.9878C34.0756 33.0661 33.8304 31.9979 33.0804 31.3259Z"
                  fill="white"
                />
                <path
                  d="M37.7631 19.4395C36.6536 17.089 33.5248 14.8374 29.4679 13.7504C25.411 12.6634 21.5755 13.0488 19.4394 14.5297L17.8968 11.8577L16.387 17.4923L22.0216 19.0021L20.3139 16.0443C21.8303 14.8926 25.2172 14.4232 29.0149 15.4408C32.8126 16.4584 35.5111 18.5583 36.2485 20.314L33.2907 22.0216L38.9253 23.5314L40.435 17.8969L37.7631 19.4395Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_833_3318">
                  <rect
                    width="28"
                    height="28"
                    fill="white"
                    transform="translate(16.2471 9) rotate(15)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="row">
            <div class="row">
              <h4>Umfrage</h4>
            </div>
            <div class="row">
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</p>
            </div>
          </div>
          <div
            class="d-flex flex-row justify-content-end align-items-center mt-n1"
          >
            <div class="me-1">
              <svg
                width="25"
                height="25"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="icon">
                  <path
                    id="Vector"
                    d="M10.9998 4.125C6.4165 4.125 2.50234 6.97583 0.916504 11C2.50234 15.0242 6.4165 17.875 10.9998 17.875C15.5832 17.875 19.4973 15.0242 21.0832 11C19.4973 6.97583 15.5832 4.125 10.9998 4.125ZM10.9998 15.5833C8.46984 15.5833 6.4165 13.53 6.4165 11C6.4165 8.47 8.46984 6.41667 10.9998 6.41667C13.5298 6.41667 15.5832 8.47 15.5832 11C15.5832 13.53 13.5298 15.5833 10.9998 15.5833ZM10.9998 8.25C9.47817 8.25 8.24984 9.47833 8.24984 11C8.24984 12.5217 9.47817 13.75 10.9998 13.75C12.5215 13.75 13.7498 12.5217 13.7498 11C13.7498 9.47833 12.5215 8.25 10.9998 8.25Z"
                    fill="#332E45"
                  />
                </g>
              </svg>
            </div>
            <button
              type="button"
              class="border-0 add-button rounded-circle shadow"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M17.4168 11.9166H11.9168V17.4166H10.0835V11.9166H4.5835V10.0833H10.0835V4.58331H11.9168V10.0833H17.4168V11.9166Z"
                    fill="white"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="col">
        <div
          class="add-card rounded-3 ps-3 pe-2 d-flex flex-column justify-content-center align-items-center"
          style="
            background: transparent;
            border-width: 1px;
            border-color: #aea9b1;
            border-style: dashed;
          "
        >
          <div class="row">
            <h4 style="color: #d7ddea">Leere Promotion</h4>
          </div>
          <div class="row">
            <div class="d-flex flex-row align-items-center">
              <button
                type="button"
                class="border-0 add-button rounded-circle"
                style="background: #d7ddea"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      d="M17.4168 11.9166H11.9168V17.4166H10.0835V11.9166H4.5835V10.0833H10.0835V4.58331H11.9168V10.0833H17.4168V11.9166Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-new-promotion-modal></app-new-promotion-modal>
