<button id="new-ad"
        mat-button
        class="primary excessive position-fixed mat-elevation-z16"
        (click)="createAd()">{{NewAd}}
</button>

<div class="container position-absolute" [hidden]="selectedFilter.length === 0">
  <div class="row">
    <div id="filter-wrapper" class="col-auto d-flex align-items-center">
      {{Filter}}:
    </div>
    <div class="col chips">
      <mat-chip-list aria-label="Fish selection">
        <mat-chip class="me-2" *ngFor="let filter of selectedFilter">
          {{filter[1] | filterLabel: filter[0] : adTypes}}
          <span class="material-icons close-btn" role="button" (click)="removeFilter(filter)">close</span>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
</div>

<div id="my-ads-wrapper" class="container-fluid">
  <div class="row">
    <div class="col" [style.margin-top.px]="selectedFilter.length > 0 ? 63 : 0">
      <!-- Cards -->
      <section id="cards-view" class="my-ads-wrapper" *ngIf="mode === ViewMode.Matrix">
        <app-my-ads-cards-view [total]="total"
                               [contextMenuItems]="contextMenuItems"></app-my-ads-cards-view>
      </section>

      <!-- Table -->
      <section id="table-view" class="row my-ads-wrapper pe-0" *ngIf="mode === ViewMode.Table">
        <app-my-ads-table-view [total]="total"
                               [contextMenuItems]="contextMenuItems"></app-my-ads-table-view>
      </section>
    </div>
    <div class="col flex-grow-0 pe-0 position-relative" style="top: -44px">
      <app-filter-menu></app-filter-menu>
    </div>
  </div>
</div>
