<div class="filter-overlay-container mat-elevation-z0 position-sticky" #filterContainer [style.width.px]="0">
  <div id="container-wrapper" class="position-sticky">
    <div class="container">
      <form class="form"
            [formGroup]="filterForm">
        <section id="header" class="row">
          <div class="col d-flex align-items-center">
            <div class="headline mat-headline mb-0">Filter</div>
          </div>
          <div class="col d-flex align-items-center justify-content-end">
            <a class="material-icons text-decoration-none" style="font-size: 22px;" (click)="close()">close</a>
          </div>
        </section>

        <section id="search" class="row">
          <div class="col">
            <mat-form-field appearance="fill">
              <mat-label>{{Title}}</mat-label>
              <input type="text"
                     matInput
                     formControlName="titleFormControl"
                     placeholder="Bsp.: Adventkalender"
                     (click)="$event.stopPropagation()">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
        </section>

        <section id="type" class="row">
          <div class="col">
            <h3 class="mat-subheading-2">{{Type}}</h3>
            <ng-container formArrayName="adTypeControls">
              <div class="row mb-4"
                   *ngFor="let adTypeCtrl of this.adTypeControls.controls; let i = index">
                <div class="col">
                  <mat-checkbox (click)="$event.stopPropagation()"
                                [formControlName]="i"
                                (change)="adTypeSelected(adTypes[i], $event)">
                    {{adTypes[i].title}}
                  </mat-checkbox>
                </div>
              </div>
            </ng-container>
          </div>
        </section>

        <section id="status" class="row mt-2">
          <div class="col">
            <h3 class="mat-subheading-2">{{Status}}</h3>
            <ng-container formArrayName="adStatusControls">
              <div class="row mb-4"
                   *ngFor="let item of AdStatus | enumToArray; let i = index">
                <div class="col">
                  <mat-checkbox (click)="$event.stopPropagation()"
                                [formControlName]="i"
                                (change)="adStatusSelected(item, $event);">
                    {{item | filterLabel: FilterStatus}}
                  </mat-checkbox>
                </div>
              </div>
            </ng-container>
          </div>
        </section>

        <section id="format" class="row">
          <div class="col">
            <mat-form-field appearance="fill">
              <mat-label>Format</mat-label>
              <mat-select (click)="$event.stopPropagation()">
                <mat-option value="300x600">
                  300 x 600
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </section>
      </form>
    </div>
  </div>
</div>
