<div
  class="text-container"
  [ngStyle]="{
    background:
      cardValues.background_type === 'color'
        ? cardValues.background_color
        : 'url(\'' + cardValues.background_image_file + '\')',
    width: cardValues.width + '%',
    'padding-left': cardValues.paddingLeft + cardValues.marginUnit,
    'padding-top': cardValues.paddingTop + cardValues.marginUnit,
    'padding-right': cardValues.paddingRight + cardValues.marginUnit,
    'padding-bottom': cardValues.paddingBottom + cardValues.marginUnit
  }"
>
  <div class="d-flex flex-column">
    <a
      class="primary-btn"
      #primaryBtn
      (mouseenter)="primaryBtn.setAttribute('data-hovered', 'true')"
      (mouseleave)="primaryBtn.setAttribute('data-hovered', 'false')"
      [ngStyle]="{
        'align-self': cardValues.style_button.alignSelf,
        color:
          primaryBtn.getAttribute('data-hovered') === 'true'
            ? cardValues.style_button.hover_color
            : cardValues.style_button.color,
        background:
          primaryBtn.getAttribute('data-hovered') === 'true'
            ? cardValues.style_button.hover_backgroundColor
            : cardValues.style_button.backgroundColor,
        'font-family': cardValues.style_button.fontFamily,
        'font-size': cardValues.style_button.fontSize + 'px',
        'font-weight': cardValues.style_button.fontWeight,
        'font-style': cardValues.style_button.fontStyle,
        'text-decoration': cardValues.style_button.textDecoration,
        'text-transform': cardValues.style_button.textTransform,
        'text-align': cardValues.style_button.textAlign,
        'padding-left':
          cardValues.style_button.paddingLeft +
          cardValues.style_button.marginUnit,
        'padding-top':
          cardValues.style_button.paddingTop +
          cardValues.style_button.marginUnit,
        'padding-right':
          cardValues.style_button.paddingRight +
          cardValues.style_button.marginUnit,
        'padding-bottom':
          cardValues.style_button.paddingBottom +
          cardValues.style_button.marginUnit,

        'border-style': cardValues.style_button.borderStyle,
        'border-radius': cardValues.style_button.borderRadius + 'px',
        'border-color':
          primaryBtn.getAttribute('data-hovered') === 'true'
            ? cardValues.style_button.hover_borderColor
            : cardValues.style_button.borderColor
      }"
      [href]="cardValues.button_url"
      [ngClass]="{ shadow: cardValues.style_button.hasShadow }"
    >
      {{cardValues.button_text}}
    </a>
  </div>
</div>
