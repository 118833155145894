export const ProductTitle = 'AdBox';
export const ProductSubtitle = 'Ad & Promotionbuilder';

// Ad Status
export const AdStatusNew = 'Neu';
export const AdStatusInProgress = 'In Bearbeitung';
export const AdStatusDone = 'Abgeschlossen';

// Context Menu
export const Edit = 'Bearbeiten';
export const Show = 'Anzeigen';
export const Delete = 'Löschen';
export const Duplicate = 'Duplizieren';
export const Archive = 'Archivieren';
export const Reporting = 'Reporting';

// Ad Preview (Reflection)
export const Logo = 'Logo';
export const BodyText = 'Fließtext';
export const Background = 'Hintergrund';
export const Button = 'Button';
export const AdFileSize = 'Ad Dateigröße';

// Ad Design
export const CreateTemplate = 'Vorlage erstellen';
export const MyTemplates = 'Meine Designvorlagen';
export const FontSize = 'Schriftgröße';
export const FontType = 'Schriftart';
export const InfoText = 'Infotext';
export const ContinuousText = 'Fließtext';
export const Distance = 'Abstand';

// Ad Content
export const NewSlide = 'Neuer Slide';
export const DesignTemplates = 'Designvorlagen';
export const Slides = 'Slides';
export const CtaButton = 'CTA Button';
export const UploadFont = 'Schrift hochladen';
export const ReallyDeleteSlide = 'Soll der Slide wirklich gelöscht werden?';
export const ReallyDeleteOption = 'Soll die Option wirklich gelöscht werden?';
export const Texts = 'Texte';
export const OptionText = 'Option';
export const Options = 'Optionen';
export const Thumbnail = 'Miniaturbild';
export const InfoArea = 'Infobereich';
export const Shape = 'Form';
export const Round = 'Rund';
export const Square = 'Quadratisch';
export const NewOption = 'Neue Option';

// Ad Animation
export const AnimationTypeTitle = 'Überblendungen';
export const AnimationType = 'Stil';
export const AnimationDurationTitle = 'Anzeigedauer';
export const AnimationDuration = 'Anzeigedauer je Slide in ms';
export const AnimationRepetitionsTitle = 'Wiederholungen';
export const AnimationRepetitions =
  'Anzahl Wiederholungen (der gesamten Abspieldauer)';

// Ad Download
export const Remark =
  'Hinweis: Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.';
export const Publisher = 'Publisher';
export const TargetLink = 'Ziellink';
export const ActivateTracking = 'Tracking aktivieren';
export const NoDownloadAfterEdit =
  'Nach dem Download kann das Ad nicht mehr bearbeitet werden.';
export const Preview = 'Preview';
export const Download = 'Download';

// Image Handling
export const UploadImage = 'Bild hochladen';
export const EditImage = 'Bild bearbeiten';
export const ImageQuality = 'Bildqualität';
export const SelectCroppingArea = 'Bildausschnitt wählen';

// Font Handling
export const FontSuccessfullyAdded = 'Font erfolgreich hinzugefügt.';

// Publisher
export const IABStandard = 'IAB Standard';
export const GDN = 'GDN – Google Display Network';

// Image upload
export const ImageTooLarge = `Das Bild überschreitet die Maximalgröße. Maximal erlaubt sind:  `;
export const FontTooLarge = `Der Font überschreitet die Maximalgröße. Maximal erlaubt sind:  `;
export const ImageTypeNotSupported = 'Das Bildformat wird nicht unterstützt.';

// Errors
export const ErrorOccurred = 'Es ist ein Fehler aufgetreten. ';
export const UnknownParsingError = 'Das Bild konnte nicht verarbeitet werden.';
export const FileNotParsable = 'Die Datei konnte nicht verarbeitet werden.';
export const FileTypeNotSupported = 'Der Dateityp wird nicht unterstützt.';
export const UnknownAdId = 'Ungültige Ad Id';

// File Size
export const AdSizeInfo = 'Ad Dateigröße';

// Misc
export const NewAd = 'Neues Ad';
export const NavigateForward = 'Weiter';
export const NavigateBackwards = 'Zurück';
export const Save = 'Speichern';
export const Color = 'Farbe';
export const TextColor = 'Textfarbe';
export const BackgroundColor = 'Hintergrundfarbe';
export const DownloadAd = 'Download Ad';
export const Ok = 'Ok';
export const Create = 'Erstellen';
export const Cancel = 'Abbrechen';
export const Left = 'Links';
export const Right = 'Rechts';
export const Top = 'Oben';
export const Bottom = 'Unten';
export const Center = 'Zentriert';
export const Text = 'Text';
export const Buttons = 'Buttons';
export const Title = 'Titel';
export const Headline = 'Überschrift';
export const Attention = 'Achtung';
export const Type = 'Typ';
export const Direction = 'Ausrichtung';
export const Horizontally = 'Horizontal';
export const Vertically = 'Vertikal';
export const Status = 'Status';
export const Filter = 'Filter';
export const Close = 'Schließen';
export const Reload = 'Neu laden';
