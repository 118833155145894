import {Component, OnDestroy, OnInit} from '@angular/core';
import {RoutingHelperService} from '../../shared/routing/routing-helper.service';
import {AdsService} from '../ads.service';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {SubSink} from 'subsink';
import {AdBaseModel} from '../../shared/models/ad.model';
import {AdTypeService} from '../ad-type.service';

@Component({
  selector: 'app-ad-preview',
  templateUrl: './ad-preview.component.html',
  styleUrls: ['./ad-preview.component.scss']
})
export class AdPreviewComponent implements OnInit, OnDestroy {
  public adModel!: AdBaseModel;
  private subs = new SubSink();

  constructor(public adTypeService: AdTypeService,
              private routingHelperService: RoutingHelperService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private adsService: AdsService) {
  }

  ngOnInit(): void {
    this.subs.sink = this.routingHelperService.fetchAdIdFromRoute(this.activatedRoute.paramMap)
      .pipe(
        switchMap(id => {
          return this.adsService.fetchAd(+id);
        })
      ).subscribe(([model, _]) => {
        this.adModel = model;
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
