import { Component } from '@angular/core';
import { NewPromotionService } from '../../../new-promotion.service';

@Component({
  selector: 'app-spacing-block-expanded-view',
  templateUrl: './spacing-block-expanded-view.component.html',
  styleUrls: ['./spacing-block-expanded-view.component.scss'],
})
export class SpacingBlockExpandedViewComponent {
  constructor(public newPromotionService: NewPromotionService) {}

  closeCard() {
    this.newPromotionService.layoutCardExpanded = false;
  }
}
