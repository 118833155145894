import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-promotion-type-picker',
  templateUrl: './new-promotion-type-picker.component.html',
  styleUrls: ['./new-promotion-type-picker.component.scss']
})
export class NewPromotionTypePickerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
