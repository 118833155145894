import {Component, Input, OnInit} from '@angular/core';
import {CustomerService} from "../../backend-services/customer.service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-customer-logo-display',
  templateUrl: './customer-logo-display.component.html',
  styleUrls: ['./customer-logo-display.component.scss'],
})
export class CustomerLogoDisplayComponent implements OnInit {
  @Input() customerId?: number | null;
  logoImg?: SafeResourceUrl;


  constructor(public sanitizer: DomSanitizer, private customerService: CustomerService) {}

  ngOnInit(): void {
    if(this.customerId) {
      this.customerService.getCustomerById(this.customerId).subscribe((customer) => {
        if(customer) {
          this.logoImg = this.sanitizer.bypassSecurityTrustResourceUrl(`/api/customers/${this.customerId}/logo?type=thumb&file_name=${customer.logo_file}`)
        }
      });

    }

  }
}
