import { Component, Input } from '@angular/core';
import { LayoutCard } from '../../new-promotion.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-columns-block-preview',
  templateUrl: './columns-block-preview.component.html',
  styleUrls: ['./columns-block-preview.component.scss'],
})
export class ColumnsBlockPreviewComponent {
  @Input() card!: LayoutCard;
  constructor(public sanitizer: DomSanitizer) {}

  get cardValues() {
    return this.card.data.value;
  }

  get contents() {
    return this.cardValues.contents;
  }
}
