import { FormBuilder } from '@angular/forms';

export function buildHeadlineStyles(fb: FormBuilder, initialSize: number, data?: any) {
  return {
    fontFamily: fb.control('roboto'),
    fontSize: fb.control(initialSize),
    lineHeight: fb.control(''),
    color: fb.control(data?.color || '#FFFFFF'),
    fontWeight: fb.control('normal'),
    fontStyle: fb.control('normal'),
    textDecoration: fb.control('normal'),
    textTransform: fb.control('normal'),
    textAlign: fb.control('center'),
  };
}

export function buildTextStyles(fb: FormBuilder, data?: any) {
  return {
    fontFamily: fb.control('roboto'),
    fontSize: fb.control(18),
    lineHeight: fb.control(''),
    color: fb.control( data?.color || '#DDDDDD'),
  };
}

export function buildPaddingData(fb: FormBuilder) {
  return {
    marginUnit: fb.control('px'),
    paddingLeft: fb.control(16),
    paddingTop: fb.control(16),
    paddingRight: fb.control(16),
    paddingBottom: fb.control(16),
  }
}

export function buildButtonStyles(fb: FormBuilder, isPrimary: boolean) {
  return {
    alignSelf: fb.control('center'),
    color: fb.control( isPrimary ? '#FFFFFF' : '#24B3A8'),
    hover_color: fb.control('#FFFFFF'),
    backgroundColor: fb.control(isPrimary ? '#24B3A8' : '#FFFFFF'),
    hover_backgroundColor: fb.control(isPrimary ? '#3adfd1' : '#24B3A8'),
    fontFamily: fb.control(''),
    fontSize: fb.control(''),
    textAlign: fb.control(null),
    fontWeight: fb.control('normal'),
    fontStyle: fb.control('normal'),
    textDecoration: fb.control(null),
    textTransform: fb.control(null),
    marginUnit: fb.control('px'),
    paddingLeft: fb.control(24),
    paddingTop: fb.control(12),
    paddingRight: fb.control(24),
    paddingBottom: fb.control(12),
    borderStyle: fb.control('none'),
    borderRadius: fb.control(5),
    borderColor: fb.control('#FFFFFF'),
    hover_borderColor: fb.control('#FFFFFF'),
    hasShadow: fb.control(false),
  }
}

export function getTinyMCEConfig() {
  return {
    plugins: 'lists link code anchor',
    menubar: false,
    branding: false,
    promotion: false,
    license_key: 'gpl',
    toolbar:
      'alignleft aligncenter alignright | bold italic underline | link code strikethrough | bullist numlist outdent indent',
    base_url: '/tinymce',
    suffix: '.min'
  }
}
