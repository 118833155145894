import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AdTypeModel} from '../shared/models/ad-type.model';
import {selectAdType, selectAdTypes} from '../state/ad-types/ad-types.selectors';
import {Store} from '@ngrx/store';
import {Actions, ofType} from '@ngrx/effects';
import * as fromAdTypeActions from '../state/ad-types/ad-types.actions';
import {map} from 'rxjs/operators';
import {SnackBarService, SnackBarType} from '../shared/snack-bar/snack-bar.service';
import {AdBaseModel} from '../shared/models/ad.model';

@Injectable({
  providedIn: 'root'
})
export class AdTypeService {

  constructor(private store: Store,
              private snackBarService: SnackBarService,
              private actions$: Actions) {
    this.initEvents();
  }

  public getAdTypes(): Observable<ReadonlyArray<AdTypeModel>> {
    return this.store.select(selectAdTypes);
  }

  public getAdType(id: number): Observable<AdTypeModel | undefined> {
    return this.store.select(selectAdType(id));
  }

  public getAdTypeName(adModel: AdBaseModel): Observable<string> {
    return this.getAdType(adModel.adTypeId!).pipe(
      map(adType =>{
        return adType?.name!
      })
    );
  }

  private initEvents() {
    this.actions$.pipe(
      ofType(fromAdTypeActions.httpFail),
      map(response => response.payload)
    ).subscribe((message: string) => {
      this.snackBarService.openSnackBar(message, SnackBarType.Error);
    });
  }

}
