export const AdTypesSlug = 'ad-types';
export const AdsSlug = 'ads';
export const SlidesSlug = 'slides';
export const OptionsSlug = 'options';
export const DownloadSlug = 'pack';
export const FontsSlug = 'fonts';
export const FileTypesSlug = 'filetypes';
export const OriginalImageSlidesSlug = 'original-image-slides';
export const OriginalImageOptionsSlug = 'original-image-options';

// Ad Types
export const StorySlug = 'story';
export const PickerSlug = 'picker';
