import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromOptionsActions from '../options/options.actions';
import { setOptionList } from '../options/options.actions';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {
  EndpointService,
  HttpOperation,
} from '../../shared/services/endpoint.service';
import { HttpErrorService } from '../../shared/services/http-error.service';
import { AdTypeService } from '../../ads/ad-type.service';
import { OptionModel } from '../../shared/models/option.model';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { combineLatest, concatMap, of } from 'rxjs';
import { OptionsService } from '../../ads/ad-template-types/picker-ad/options.service';

@Injectable()
export class OptionsEffects {
  fetchOptions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromOptionsActions.fetchOptionList),
      switchMap((action) => {
        return combineLatest([
          this.optionsService.executeFetchOptionsRequest(
            action.optionableId,
            this.endpointService,
            this.http,
            this.httpErrorService
          ),
          of(action.operation),
        ]);
      }),
      map(([json, operation]: [any[], HttpOperation]) => {
        let options = plainToInstance(OptionModel, json);
        // Attach an ordinal number
        options = options.map((option, index) => {
          return {
            ...option,
            orderNumber: index,
          };
        });
        return setOptionList({ payload: [options, operation] }) || [];
      })
    );
  });

  addOptions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromOptionsActions.addOptions),
      map((action) => action.payload),
      mergeMap(([options, _, optionableId]) => {
        const optionsHttp = instanceToPlain(options);
        return this.optionsService.executePostOptionsRequest(
          optionsHttp,
          optionableId,
          this.endpointService,
          this.http,
          this.httpErrorService
        );
      })
    );
  });

  duplicateOptions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromOptionsActions.duplicateOptions),
      map((action) => action.payload),
      switchMap(([originalAd, duplicatedAd]) => {
        const fetchOptions$ = this.optionsService.executeFetchOptionsRequest(
          originalAd.baseable!.id,
          this.endpointService,
          this.http,
          this.httpErrorService
        );
        return combineLatest([fetchOptions$, of(originalAd), of(duplicatedAd)]);
      }),
      concatMap(([optionsHttp, originalAd, newAd]) => {
        const updatedOptions = optionsHttp.map((optionHttp: any) => {
          const option = plainToInstance(OptionModel, optionHttp);
          this.optionsService.deleteIds(option);
          const optionPlain = instanceToPlain(option);
          return optionPlain;
        });
        return this.optionsService.executePostOptionsRequest(
          updatedOptions,
          newAd.baseable!.id,
          this.endpointService,
          this.http,
          this.httpErrorService
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private endpointService: EndpointService,
    private adTypeService: AdTypeService,
    private optionsService: OptionsService,
    private httpErrorService: HttpErrorService
  ) {}
}
