<div
  class="w-100 d-flex flex-column align-items-start justify-content-center gap-1"
  [formGroup]="rootFormGroup"
>
  <label style="font-size: 14px; color: #332e45" class="mb-3"
    >Hintergrund</label
  >
  <select
    class="form-select"
    id="hintergrund"
    aria-label="select hintergrund"
    formControlName="background_type"
  >
    <option value="color">Farbe</option>
  </select>
  <span class="form-control d-flex align-items-center justify-content-between">
    <label style="font-size: 14px">{{
      rootFormGroup.get("background_color")?.value
    }}</label>
    <input
      type="color"
      class="color-picker"
      id="hintergrund-color"
      formControlName="background_color"
      title="Choose your color"
    />
  </span>
  <div
    class="mt-4 d-flex w-100 flex-row justify-content-between align-items-center mt-1"
  >
    <label for="breite" style="font-size: 14px; color: #332e45">Breite</label>
    <label for="breite" style="font-size: 14px; color: #332e45"
      >{{ rootFormGroup.get("width")?.value }}%</label
    >
  </div>
  <input type="range" class="form-range" id="breite" formControlName="width" />
  <label class="mt-3" for="breite" style="font-size: 14px; color: #332e45"
    >Abstand</label
  >
  <div
    class="w-100 d-flex flex-row justify-content-start align-items-center gap-4 mt-2 mb-2"
  >
    <div class="d-flex gap-2 align-items-center">
      <input
        class="form-check-input radio-border"
        type="radio"
        formControlName="marginUnit"
        value="px"
        id="px"
        checked
      />
      <label class="form-check-label" for="px" style="font-size: 14px">
        px
      </label>
    </div>
    <div class="d-flex gap-2 align-items-center">
      <input
        class="form-check-input radio-border"
        type="radio"
        formControlName="marginUnit"
        id="percentage"
        value="%"
      />
      <label class="form-check-label" for="percentage" style="font-size: 14px">
        %
      </label>
    </div>
  </div>
  <div class="d-flex justify-content-between align-items-center gap-1">
    <div class="form-group">
      <label for="links">Links</label>
      <input
        type="text"
        class="form-control text-center"
        formControlName="paddingLeft"
        id="links"
      />
    </div>
    <div class="form-group">
      <label for="oben">Oben</label>
      <input
        type="text"
        class="form-control text-center"
        id="oben"
        formControlName="paddingTop"
      />
    </div>
    <div class="form-group">
      <label for="unten">Unten</label>
      <input
        type="text"
        class="form-control text-center"
        id="unten"
        formControlName="paddingBottom"
      />
    </div>
    <div class="form-group">
      <label for="rechts">Rechts</label>
      <input
        type="text"
        class="form-control text-center"
        id="rechts"
        formControlName="paddingRight"
      />
    </div>
  </div>
</div>
