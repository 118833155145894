import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { EditAdNavigationService } from '../../edit-ad/edit-ad-navigation.service';
import { SubSink } from 'subsink';
import { AdBaseModel, StoryAdModel } from '../../../shared/models/ad.model';
import { SlidesService } from './slides.service';
import { SlideModel } from '../../../shared/models/slide.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Background } from '../../../shared/data-repository/global-text-snippets';
import { debounceTime } from 'rxjs/operators';
import { StorageService } from '../../../shared/services/storage.service';
import { ActivatedRoute } from '@angular/router';
import { map, merge, take } from 'rxjs';
import StoryAd from '../../../../assets/js/story-ad.js';
import {
  AdStorageKey,
  SlidesStorageKey,
} from '../../../shared/data-repository/global-constants';
import { FontsService } from '../../../shared/services/fonts.service';
import { CommonEditAdDesignService } from '../../edit-ad/common-services/common-edit-ad-design.service';
import { CommonAdTemplateService } from '../common-ad-templates/common-ad-template.service';

@Component({
  selector: 'app-story-ad',
  templateUrl: './story-ad.component.html',
  styleUrls: ['./style.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoryAdComponent implements OnInit, OnDestroy {
  @Input() adModel!: AdBaseModel;
  public storyAdModel!: StoryAdModel;
  public slides!: ReadonlyArray<SlideModel>;
  public backgroundColor = '';
  public Background = Background;
  public sanitizedLogo!: SafeResourceUrl;

  private initialized = false;
  private AdStorageKey = AdStorageKey;
  private SlidesStorageKey = SlidesStorageKey;
  private subs = new SubSink();
  private storyAdObj = new StoryAd();

  constructor(
    public sanitizer: DomSanitizer,
    public commonAdTemplateService: CommonAdTemplateService,
    private editAdNavigationService: EditAdNavigationService,
    private cd: ChangeDetectorRef,
    private commonEditAdDesignService: CommonEditAdDesignService,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private fontsService: FontsService,
    private slidesService: SlidesService
  ) {}

  ngOnInit(): void {
    this.subs.sink = this.activatedRoute.queryParams.subscribe((params) => {
      if (params && params.v) {
        // Show preview
        this.initLivePreview();
      } else {
        if (!this.adModel) {
          throw new Error('Ad model not found.');
        }
        this.storageService.setData(AdStorageKey, this.adModel);
        this.storyAdModel = this.adModel.baseable as StoryAdModel;
        this.initEvents();
      }
      this.sanitizeLogo();
      this.fontsService.fetchFonts();
      this.initStoryAdObj();
    });

    this.initializeDownload();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private initEvents(): void {
    this.subs.sink = this.editAdNavigationService.adModelChanged$.subscribe(
      (adModel) => {
        this.adModel = adModel;
        this.storyAdModel = adModel.baseable as StoryAdModel;
        this.sanitizeLogo();
        this.initStoryAdObj();
        this.save(this.AdStorageKey, this.adModel);
      }
    );

    const slidesChangedObs$ = this.slidesService.slideModelsChanged$.pipe(
      debounceTime(200),
      map((values) => [values, true])
    );
    const fetchSlidesObs$ = this.slidesService
      .fetchSlides(this.adModel.baseable!.id!)
      .pipe(
        take(1),
        map((values) => [values, true])
      );

    this.subs.sink = merge(slidesChangedObs$, fetchSlidesObs$)
      .pipe(debounceTime(600))
      .subscribe(([value, calcFileSize]) => {
        if (Array.isArray(value)) {
          this.slides = value;
        }
        if (value instanceof Map) {
          this.slides = Array.from(value, ([_, value]) => value);
        }

        const slidesForStorage = this.slides.map((slide) => {
          if (typeof slide.imageOriginal !== 'undefined') {
            const updatedSlide = new SlideModel();
            Object.assign(updatedSlide, slide);
            delete updatedSlide.imageOriginal;
            return updatedSlide;
          } else {
            return slide;
          }
        });

        this.save(this.SlidesStorageKey, slidesForStorage);
        this.rerender(calcFileSize as boolean);
      });

    this.subs.sink = this.commonAdTemplateService
      .renderPreview()
      .subscribe(() => {
        if (this.initialized) {
          this.rerender(true);
        }
        this.initialized = true;
      });

    this.subs.sink = this.editAdNavigationService.adModelSaved$.subscribe(
      ([ad, generateThumbnail]) => {
        if (generateThumbnail) {
          this.editAdNavigationService.thumbnailGenerationInitiated$.next(ad);
        } else {
          this.editAdNavigationService.thumbnailGenerated$.next(true);
        }
      }
    );
  }

  private save(
    key: string,
    values: ReadonlyArray<SlideModel> | AdBaseModel
  ): void {
    this.storageService.setData(key, values);
  }

  private rerender(calcFileSize: boolean): void {
    this.cd.detectChanges();
    this.storyAdObj.resetAndStart();
    if (calcFileSize) {
      this.commonAdTemplateService.updatePreviewAndFileSize.next([
        false,
        [...this.slides],
      ]);
    }
  }

  private initLivePreview(): void {
    const storedAdModel = this.storageService.getData(this.AdStorageKey);
    const slides = this.storageService.getData(this.SlidesStorageKey);
    if (storedAdModel === null || slides === null) {
      throw new Error('No ad data in local storage found.');
    }
    this.adModel = storedAdModel;
    this.slides = slides;
    this.storyAdModel = this.adModel.baseable as StoryAdModel;
    this.sanitizeLogo();

    this.cd.detectChanges();
    this.storyAdObj.resetAndStart();
  }

  private initStoryAdObj() {
    this.storyAdObj.maxRepetitionCount = this.storyAdModel.repetitionCount;
    this.storyAdObj.animationDuration = this.storyAdModel.animationDuration;
  }

  private sanitizeLogo() {
    if (this.adModel.logo) {
      this.sanitizedLogo = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.adModel.logo!
      );
    }
  }

  private initializeDownload() {
    this.subs.sink = this.editAdNavigationService.downloadInitiated$.subscribe(
      () => {
        this.commonAdTemplateService.updatePreviewAndFileSize.next([
          true,
          [...this.slides],
        ]);
      }
    );
  }
}
