<div
  class="w-100 d-flex flex-column align-items-start justify-content-center gap-1"
  [formGroup]="rootFormGroup"
>
  <button
    class="mb-3 w-100 border-0 rounded-2 py-2 gap-1 d-flex justify-content-center align-items-center"
    style="color: #ffffff; background: #6e72eb"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M16.6665 5.00004H9.99984L8.33317 3.33337H3.33317C2.4165 3.33337 1.6665 4.08337 1.6665 5.00004V15C1.6665 15.9167 2.4165 16.6667 3.33317 16.6667H16.6665C17.5832 16.6667 18.3332 15.9167 18.3332 15V6.66671C18.3332 5.75004 17.5832 5.00004 16.6665 5.00004ZM14.9498 14.1667L12.4998 12.7334L10.0498 14.1667L10.6998 11.3917L8.5415 9.52504L11.3832 9.28337L12.4998 6.66671L13.6165 9.28337L16.4582 9.52504L14.2998 11.3917L14.9498 14.1667Z"
        fill="white"
      />
    </svg>
    <span
      class="text-uppercase"
      style="
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.5px;
      "
      >branding Stil laden</span
    >
  </button>
  <div class="row row-cols-2 w-100 row-gap-1">
    <div class="col">
      <select
        class="form-select"
        aria-label="select Schriftart"
        formControlName="fontFamily"
      >
        <option value="" disabled>Schriftart</option>
        <option
          [value]="fontFamily.value"
          *ngFor="let fontFamily of newPromotionService.availableFontFamilies"
        >
          {{ fontFamily.name }}
        </option>
      </select>
    </div>
    <div class="col">
      <select
        class="form-select"
        aria-label="select Schriftart"
        formControlName="fontSize"
      >
        <option value="" disabled>Schriftgröße</option>
        <option
          [value]="fontSize"
          *ngFor="let fontSize of newPromotionService.availableFontSizes"
        >
          {{ fontSize }}
        </option>
      </select>
    </div>
    <div class="col">
      <select
        class="form-select"
        aria-label="select Schriftart"
        formControlName="lineHeight"
      >
        <option value="" disabled>Zeilenhöhe</option>
        <option
          [value]="lineHeight.value"
          *ngFor="let lineHeight of newPromotionService.availableLineHeights"
        >
          {{ lineHeight.name }}
        </option>
      </select>
    </div>
    <div class="col">
      <span
        class="form-control d-flex align-items-center justify-content-between"
      >
        <label style="font-size: 14px; color: #332e45">Farbe</label>
        <input
          type="color"
          class="color-picker"
          formControlName="color"
          title="Choose your color"
        />
      </span>
    </div>
  </div>
</div>
