export class AdboxRoutes {
  public static readonly Dashboard = 'dashboard';
  public static readonly Promotions = 'promotions';
  public static readonly AllPromotions = 'all-promotions';
  public static readonly NewPromotion = 'new-promotion';
  public static readonly Ads = 'ads';
  public static readonly NewAd = 'new-ad';
  public static readonly MyAds = 'my-ads';
  public static readonly MyDesignTemplates = 'my-design-templates';
  public static readonly AnalysisAndTracking = 'analysis-and-tracking';
  public static readonly Archive = 'archive';
  public static readonly Design = 'design';
  public static readonly Content = 'content';
  public static readonly Animation = 'animation';
  public static readonly Download = 'download';
  public static readonly AdPreview = 'ad-preview';
  public static readonly Edit = 'edit';
  public static readonly TemplateGallery = 'template-gallery';
  public static readonly Account = 'account';
  public static readonly MyProfile = 'profile';
  public static readonly UserManagement = 'user-management';
  public static readonly ManageSingleUser = 'user';
  public static readonly ManageSingleCustomer = 'customer';
}
