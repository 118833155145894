import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromAdsActions from '../ads/ads.actions';
import { setAdsList } from '../ads/ads.actions';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  EndpointService,
  HttpOperation,
} from '../../shared/services/endpoint.service';
import { AdBaseModel } from '../../shared/models/ad.model';
import { HttpErrorService } from '../../shared/services/http-error.service';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { combineLatest, debounceTime, Observable, of } from 'rxjs';
import { AdTypeService } from '../../ads/ad-type.service';
import { HTMLAdComposite } from '../../ads/edit-ad/ad-reflection/ad-generate-html.directive';
import * as fromSlidesActions from '../slides/slides.actions';
import { FilterMenuService } from '../../shared/menus/filter-menu/filter-menu.service';
import { MapHelper } from '../../shared/helper/map-helper';
import { ItemsPerPage } from '../../shared/data-repository/global-constants';
import { SlidesService } from '../../ads/ad-template-types/story-ad/slides.service';
import { AdStatus } from '../../shared/data-structures/ad-data-structures';
import { AdStatusMap, BaseableTypeMap } from '../../shared/data-structures/map';

@Injectable()
export class AdsEffects {
  fetchAds$ = createEffect(() => {
    const fetchAdList$ = this.actions$.pipe(
      ofType(fromAdsActions.fetchAdsList),
      debounceTime(200),
      map((params) => params.payload),
      switchMap(([adId, httpOperation, sortAndFilter, pagination]) => {
        let endpoint;
        if (adId !== null) {
          endpoint = this.endpointService.getAdsSlug(HttpOperation.Get, adId);
        } else {
          endpoint = this.endpointService.getAdsSlug(HttpOperation.Get);
        }

        let params = new HttpParams();
        sortAndFilter?.sort.forEach((sortCriteria, index) => {
          const key = `sort[${index}]`;
          params = params.append(
            key,
            sortCriteria.active + ',' + sortCriteria.direction
          );
        });

        if (sortAndFilter && sortAndFilter.filter) {
          sortAndFilter.filter.forEach((filterAttr) => {
            const key = MapHelper.getByValue(sortAndFilter.filter, filterAttr);
            params = this.appendFilterQueryParams(key, filterAttr, params);
          });
        }

        if (!pagination) {
          params = params.append('per_page', ItemsPerPage);
          params = params.append('page', 1);
        } else {
          params = params.append('per_page', pagination.perPage);
          params = params.append('page', pagination.currentPage);
        }

        return this.http.get<any>(endpoint, { params }).pipe(
          map((result) => [result, httpOperation]),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Ads',
              fromSlidesActions,
              error
            );
          })
        );
      })
    );
    const fetchAdTypes$ = this.adTypeService.getAdTypes();

    return combineLatest([fetchAdList$, fetchAdTypes$]).pipe(
      map(([fetchedAdsResponse, adTypes]) => {
        let response = fetchedAdsResponse as any[];
        let json = response[0].data;
        let httpOperation = response[1];
        let enhancedEntries = new Array<AdBaseModel>();
        const total = response[0].total;

        if (Array.isArray(json)) {
          enhancedEntries = json.map((entry: any) => {
            const enhancedEntry = {
              ...entry,
              baseable: {
                ...entry.baseable,
                __type: adTypes.find((type) => entry.ad_type_id === type.id)!
                  .name,
              },
            };
            return enhancedEntry;
          });
        } else {
          const adType = adTypes.find((type) => json.ad_type_id === type.id);
          if (!adType) {
            throw new Error('Ad type is not defined.');
          }

          const adModel = {
            ...json,
            baseable: {
              ...json.baseable,
              __type: adType.name,
            },
          };
          enhancedEntries.push(adModel);
        }

        const ads = plainToInstance(AdBaseModel, enhancedEntries);
        return setAdsList({ payload: [ads, httpOperation, total] }) || [];
      }),
      catchError((error) => {
        return this.httpErrorService.handleError('Ad', fromAdsActions, error);
      })
    );
  });

  fetchSingleAd$ = createEffect(() => {
    const fetchAd$ = this.actions$.pipe(
      ofType(fromAdsActions.fetchSingleAd),
      map((action) => action.payload),
      switchMap((id) => {
        const endpoint = this.endpointService.getAdsSlug(HttpOperation.Get, id);
        return this.http.get<any>(endpoint).pipe(
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Ad',
              fromAdsActions,
              error
            );
          })
        );
      })
    );

    const fetchAdTypes$ = this.adTypeService.getAdTypes();
    return combineLatest([fetchAd$, fetchAdTypes$]).pipe(
      map(([json, adTypes]) => {
        const adType = adTypes.find((type) => json.ad_type_id === type.id);
        if (!adType) {
          throw new Error('Ad type is not defined.');
        }

        const enhancedEntry: AdBaseModel = {
          ...json,
          baseable: {
            ...json.baseable,
            __type: adType.name,
          },
        };

        const ads = plainToInstance(AdBaseModel, [enhancedEntry]);
        return setAdsList({ payload: [ads, HttpOperation.Get, 1] }) || [];
      })
    );
  });

  addAd$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAdsActions.addAd),
      map((action) => action.payload),
      mergeMap((ad) => {
        const endpoint = this.endpointService.getAdsSlug(HttpOperation.Post);
        return this.http
          .post<any>(endpoint, {
            title: ad.title,
            ad_type_id: ad.adTypeId,
            status: AdStatus.AdStatusNew,
          })
          .pipe(
            map((_) => {
              return fromAdsActions.fetchAdsList({
                payload: [
                  null,
                  HttpOperation.Post,
                  this.filterMenuService.createDefaultSortAndFilter(),
                ],
              });
            }),
            catchError((error) => {
              return this.httpErrorService.handleError(
                'Ad',
                fromAdsActions,
                error
              );
            })
          );
      })
    );
  });

  duplicateAd = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAdsActions.duplicateAd),
      map((action) => action.payload),
      mergeMap((baseAd) => {
        const adsEndpoint = this.endpointService.getAdsSlug(HttpOperation.Post);
        const duplicatedBaseAdHttp = instanceToPlain(baseAd);
        const duplicatedBaseableHttp = instanceToPlain(baseAd.baseable);
        const fetchAdTypes$ = this.adTypeService.getAdTypes();

        let params = new HttpParams();
        params = params.append('duplicate_id', baseAd.id!.toString());

        return this.http
          .post<any>(
            adsEndpoint,
            {
              title: duplicatedBaseAdHttp.title,
              ad_type_id: duplicatedBaseAdHttp.ad_type_id,
              status: AdStatus.AdStatusNew,
            },
            { params }
          )
          .pipe(
            switchMap((newAd) => {
              delete duplicatedBaseAdHttp.status;
              const updateAdObs$ = this.updateAd(
                newAd.id,
                duplicatedBaseAdHttp,
                duplicatedBaseableHttp
              );
              return combineLatest([updateAdObs$, fetchAdTypes$, of(newAd)]);
            }),
            map(([_, adTypes, newAd]) => {
              const baseable = {
                ...newAd.baseable,
                __type: adTypes.find((type) => newAd.ad_type_id === type.id)!
                  .name,
              };

              newAd.baseable = baseable;
              const duplicatedAd = plainToInstance(AdBaseModel, newAd);
              return fromAdsActions.setDuplicatedAd({
                payload: [baseAd, duplicatedAd],
              });
            }),
            catchError((error) => {
              return this.httpErrorService.handleError(
                'Ad',
                fromAdsActions,
                error
              );
            })
          );
      })
    );
  });

  setDuplicatedAd$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAdsActions.setDuplicatedAd),
      map(() => {
        return fromAdsActions.fetchAdsList({
          payload: [
            null,
            HttpOperation.Post,
            this.filterMenuService.createDefaultSortAndFilter(),
          ],
        });
      })
    );
  });

  updateAd$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAdsActions.updateAd),
      map((action) => action.payload),
      mergeMap(([adModel, adBaseable, _, reloadAds]) => {
        const adModelHttp = instanceToPlain(adModel);
        const adBaseableModelHttp = instanceToPlain(adBaseable);
        return combineLatest([
          this.updateAd(adModelHttp.id, adModelHttp, adBaseableModelHttp),
          of(reloadAds),
        ]);
      }),
      map(([_, reload]) => {
        if (reload) {
          return fromAdsActions.fetchAdsList({
            payload: [
              null,
              HttpOperation.Put,
              this.filterMenuService.createDefaultSortAndFilter(),
            ],
          });
        } else {
          return fromAdsActions.NoOp({
            payload: HttpOperation.Put,
          });
        }
      })
    );
  });

  deleteAd$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAdsActions.deleteAd),
      map((action) => action.payload),
      mergeMap((adId) => {
        const endpoint = this.endpointService.getAdsSlug(
          HttpOperation.Delete,
          adId
        );
        return this.http.delete<any>(endpoint).pipe(
          map(() => {
            return fromAdsActions.fetchAdsList({
              payload: [
                null,
                HttpOperation.Delete,
                this.filterMenuService.createDefaultSortAndFilter(),
              ],
            });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Ad',
              fromAdsActions,
              error
            );
          })
        );
      })
    );
  });

  initializeDownload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAdsActions.initializeAdDownload),
      map((action) => action.payload),
      switchMap(([adId, htmlComposite]: [number, HTMLAdComposite]) => {
        const endpoint = this.endpointService.getAdsDownloadSlug(adId);
        return this.http
          .post(endpoint, htmlComposite, {
            responseType: 'blob',
          })
          .pipe(
            map((response) => {
              return fromAdsActions.setAdDownload({ payload: response });
            }),
            catchError((error) => {
              return this.httpErrorService.handleError(
                'Ad',
                fromAdsActions,
                error
              );
            })
          );
      })
    );
  });

  initializeFileSizeCalculation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAdsActions.calculateAdFileSize),
      map((action) => action.payload),
      switchMap(([adId, htmlComposite]: [number, HTMLAdComposite]) => {
        const endpoint = this.endpointService.getAdsFileSizeSlug(adId);
        let params = new HttpParams();
        params = params.append('calc_filesize', true);

        return this.http.post(endpoint, htmlComposite, { params }).pipe(
          map((response) => {
            return fromAdsActions.setAdFileSize({ payload: +response });
          }),
          catchError((error) => {
            return this.httpErrorService.handleError(
              'Ad',
              fromAdsActions,
              error
            );
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private endpointService: EndpointService,
    private adTypeService: AdTypeService,
    private slidesService: SlidesService,
    private httpErrorService: HttpErrorService,
    private filterMenuService: FilterMenuService
  ) {}

  private appendFilterQueryParams(
    key: string | null,
    filterAttr: string | Map<string, string>,
    params: HttpParams
  ): HttpParams {
    if (!key) {
      return params;
    }
    if (filterAttr instanceof Map) {
      let index = 0;
      filterAttr.forEach((entry) => {
        let key = this.getMapKey(filterAttr, index);
        params = params.append(key, entry);
        index++;
      });
    } else {
      params = params.append(key, filterAttr);
    }
    return params;
  }

  private updateAd(
    adBaseId: number,
    adBaseHttp: any,
    adBaseableHttp?: any
  ): Observable<any> {
    const endpoint = this.endpointService.getAdsSlug(
      HttpOperation.Put,
      adBaseId
    );
    let compoundModel = {};
    if (adBaseableHttp) {
      delete adBaseableHttp.id; // Remove id since we don't need it when updating the ad
      compoundModel = {
        baseable: adBaseableHttp,
      };
    }

    delete adBaseHttp.id; // Remove id since we don't need it when updating the ad
    delete adBaseHttp.baseable_id; // Remove id since we don't need it when updating the ad
    compoundModel = {
      ...compoundModel,
      base: adBaseHttp,
    };

    return this.http.put<any>(endpoint, compoundModel).pipe(
      map(() => {
        return fromAdsActions.NoOp({
          payload: HttpOperation.Put,
        });
      }),
      catchError((error) => {
        return this.httpErrorService.handleError('Ads', fromAdsActions, error);
      })
    );
  }

  private getMapKey(filterAttr: Map<string, string>, index: number) {
    let key = '';
    if (filterAttr instanceof BaseableTypeMap) {
      key = `baseable_type[${index}]`;
    } else if (filterAttr instanceof AdStatusMap) {
      key = `status[${index}]`;
    }
    return key;
  }
}
