<form [formGroup]="newPromotionService.settingsFormData" class="w-100 h-100 d-flex flex-column">
  <ul class="list-unstyled">
    <li>
      <div class="list-item-dropdown-title">
        <a
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#allgemeines-collapse"
          aria-expanded="false"
        >
              <span class="d-flex justify-content-between align-items-center">
                <span style="color: #332e45; font-weight: bold"
                >Allgemeines</span
                >
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="expand_less">
                      <path
                        id="Vector"
                        d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                        fill="#332E45"
                      />
                    </g>
                  </svg>
                </span>
              </span>
        </a>
      </div>
      <div class="collapse show" id="allgemeines-collapse">
        <div
          class="list-item-dropdown-body d-flex flex-column justify-content-center row-gap-2 align-items-center bg-white"
        >
          <input
            formControlName="title"
            class="form-control"
            type="text"
            placeholder="Promotion Titel"
            aria-label="Promotion Titel"
          />
          <div class="d-flex w-100 column-gap-2">
            <input
              formControlName="starts_at"
              class="form-control"
              type="date"
              placeholder="Laufzeit von"
              aria-label="Laufzeit von"
            />
            <input
              formControlName="ends_at"
              class="form-control"
              type="date"
              placeholder="Laufzeit bis"
              aria-label="Laufzeit bis"
            />
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="list-item-dropdown-title">
        <a
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#layout-collapse"
          aria-expanded="false"
        >
              <span class="d-flex justify-content-between align-items-center">
                <span style="color: #332e45; font-weight: bold">Layout</span>
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="expand_less">
                      <path
                        id="Vector"
                        d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                        fill="#332E45"
                      />
                    </g>
                  </svg>
                </span>
              </span>
        </a>
      </div>
      <div class="collapse" id="layout-collapse">
        <div
          class="list-item-dropdown-body d-flex flex-column justify-content-center row-gap-2 align-items-center bg-white"
        >
              <span
                class="form-control d-flex align-items-center justify-content-between py-2"
              >
                <label style="font-size: 14px">Hintergrundfarbe</label>
                <input
                  formControlName="background_color"
                  type="color"
                  class="color-picker"
                  id="exampleColorInput"
                  value="#FFFFFF"
                  title="Choose your color"
                />
              </span>
        </div>
      </div>
    </li>
    <li>
      <div class="list-item-dropdown-title">
        <a
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#meta-daten-collapse"
          aria-expanded="false"
        >
              <span class="d-flex justify-content-between align-items-center">
                <span style="color: #332e45; font-weight: bold"
                >Meta Daten</span
                >
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="expand_less">
                      <path
                        id="Vector"
                        d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                        fill="#332E45"
                      />
                    </g>
                  </svg>
                </span>
              </span>
        </a>
      </div>
      <div class="collapse" id="meta-daten-collapse">
        <div
          class="list-item-dropdown-body d-flex flex-column justify-content-center row-gap-2 align-items-center bg-white"
        >
          <input
            formControlName="meta_title"
            class="form-control"
            type="text"
            placeholder="Seitentitel"
            aria-label="Seitentitel"
          />
          <textarea
            formControlName="meta_description"
            class="form-control"
            placeholder="Text"
            aria-label="text"
            id="floatingTextarea2"
            style="height: 152px"
          ></textarea>
          <div *ngIf="metaImgPreviewUrl" class="d-flex justify-content-start">
            <img class="preview-img" [src]="sanitizer.bypassSecurityTrustResourceUrl(metaImgPreviewUrl)" alt="" />
          </div>
          <label
            role="button"
            for="meta_image_file"
            class="bg-transparent rounded-2 w-100 px-2 py-1 d-flex align-items-center justify-content-center cursor-pointer"
            style="border: 2px solid #6e72eb"
          >
            <i>
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="icon">
                  <path
                    id="Vector"
                    d="M4.66663 17.0833H16.3333V15.4166H4.66663V17.0833ZM4.66663 8.74996H7.99996V13.75H13V8.74996H16.3333L10.5 2.91663L4.66663 8.74996Z"
                    fill="#6E72EB"
                  />
                </g>
              </svg>
            </i>
            <div
              class="text-uppercase ms-1"
              style="
                      color: #6e72eb;
                      font-weight: 500;
                      letter-spacing: 0.5px;
                    "
            >share-BILD HOCHLADEN</div
            >
          </label>
          <input id="meta_image_file" formControlName="meta_image_file" type="file" class="d-none" (change)="handleFileChange($event)" />
        </div>
      </div>
    </li>
    <li>
      <div class="list-item-dropdown-title">
        <a
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#teilnehmerdaten-collapse"
          aria-expanded="false"
        >
              <span class="d-flex justify-content-between align-items-center">
                <span style="color: #332e45; font-weight: bold"
                >Teilnehmerdaten</span
                >
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="expand_less">
                      <path
                        id="Vector"
                        d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                        fill="#332E45"
                      />
                    </g>
                  </svg>
                </span>
              </span>
        </a>
      </div>
      <div class="collapse" id="teilnehmerdaten-collapse">
        <div
          class="list-item-dropdown-body d-flex flex-column justify-content-center align-items-start bg-white"
        >
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              [value]="false"
              name="should_delete_data_on_exp"
              id="nicht-löschen"
              formControlName="should_delete_data_on_exp"
            />
            <label
              class="form-check-label"
              for="nicht-löschen"
              style="font-size: 14px"
            >
              nicht löschen
            </label>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <div class="form-check w-75">
              <input
                class="form-check-input"
                type="radio"
                name="should_delete_data_on_exp"
                id="löschen-am"
                [value]="true"
                formControlName="should_delete_data_on_exp"
              />
              <label
                class="form-check-label"
                for="löschen-am"
                style="font-size: 14px"
              >
                löschen am
              </label>
            </div>
            <input
              class="form-control ms-1"
              type="date"
              formControlName="keep_data_until"
              placeholder="Datum"
              aria-label="Datum"
            />
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="list-item-dropdown-title">
        <a
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#impressum-collapse"
          aria-expanded="false"
        >
              <span class="d-flex justify-content-between align-items-center">
                <span style="color: #332e45; font-weight: bold">Impressum</span>
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="expand_less">
                      <path
                        id="Vector"
                        d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                        fill="#332E45"
                      />
                    </g>
                  </svg>
                </span>
              </span>
        </a>
      </div>
      <div class="collapse" id="impressum-collapse">
        <div
          class="list-item-dropdown-body d-flex flex-column justify-content-center row-gap-2 align-items-start bg-white"
        >
          <select
            class="form-select"
            id="impressum"
            aria-label="select impressum"
            formControlName="imprint_type"
          >
            <option value="0" selected>URL</option>
          </select>
          <input
            class="form-control"
            type="url"
            placeholder="https://"
            aria-label="url"
            formControlName="imprint_content"
          />
        </div>
      </div>
    </li>
    <li>
      <div class="list-item-dropdown-title">
        <a
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#datenschutz-collapse"
          aria-expanded="false"
        >
              <span class="d-flex justify-content-between align-items-center">
                <span style="color: #332e45; font-weight: bold"
                >Datenschutz</span
                >
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="expand_less">
                      <path
                        id="Vector"
                        d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                        fill="#332E45"
                      />
                    </g>
                  </svg>
                </span>
              </span>
        </a>
      </div>
      <div class="collapse" id="datenschutz-collapse">
        <div
          class="list-item-dropdown-body d-flex flex-column justify-content-center row-gap-2 align-items-start bg-white"
        >
          <select
            class="form-select"
            id="datenschutz"
            aria-label="select impressum"
            formControlName="privacy_policy_type"
          >
            <option value="0" selected>URL</option>
          </select>
          <input
            class="form-control"
            type="url"
            placeholder="https://"
            aria-label="url"
            formControlName="privacy_policy_content"
          />
        </div>
      </div>
    </li>
    <li>
      <div class="list-item-dropdown-title">
        <a
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#teilnahmebedingungen-collapse"
          aria-expanded="false"
        >
              <span class="d-flex justify-content-between align-items-center">
                <span style="color: #332e45; font-weight: bold"
                >Teilnahmebedingungen</span
                >
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="expand_less">
                      <path
                        id="Vector"
                        d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                        fill="#332E45"
                      />
                    </g>
                  </svg>
                </span>
              </span>
        </a>
      </div>
      <div class="collapse" id="teilnahmebedingungen-collapse">
        <div
          class="list-item-dropdown-body d-flex flex-column justify-content-center row-gap-2 align-items-start bg-white"
        >
          <select
            class="form-select"
            id="teilnahmebedingungen"
            aria-label="select impressum"
            formControlName="terms_type"
          >
            <option value="0" selected>URL</option>
          </select>
          <input
            class="form-control"
            type="url"
            placeholder="https://"
            aria-label="url"
            formControlName="terms_content"
          />
        </div>
      </div>
    </li>
    <li>
      <div class="list-item-dropdown-title">
        <a
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#direktlink-collapse"
          aria-expanded="false"
        >
              <span class="d-flex justify-content-between align-items-center">
                <span style="color: #332e45; font-weight: bold"
                >Direktlink</span
                >
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="expand_less">
                      <path
                        id="Vector"
                        d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                        fill="#332E45"
                      />
                    </g>
                  </svg>
                </span>
              </span>
        </a>
      </div>
      <div class="collapse" id="direktlink-collapse">
        <div
          class="list-item-dropdown-body d-flex flex-column justify-content-center row-gap-2 align-items-start bg-white"
        >
          <input
            class="form-control"
            type="url"
            placeholder="https://adbox.promotion.com/id=2345324324"
            aria-label="url"
          />
        </div>
      </div>
    </li>
    <li>
      <div class="list-item-dropdown-title">
        <a
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#einbindung-collapse"
          aria-expanded="false"
        >
              <span class="d-flex justify-content-between align-items-center">
                <span style="color: #332e45; font-weight: bold"
                >Einbindung</span
                >
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="expand_less">
                      <path
                        id="Vector"
                        d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                        fill="#332E45"
                      />
                    </g>
                  </svg>
                </span>
              </span>
        </a>
      </div>
      <div class="collapse" id="einbindung-collapse">
        <div
          class="list-item-dropdown-body d-flex flex-column justify-content-center row-gap-2 align-items-start bg-white"
        >
          <label
            for="snippet-before"
            style="
                  font-size: 14px;
                  color: #332e45;
                  font-weight: 400;
                  line-height: 18px;
                "
          >
            Paste this snippet before the closing &lt;⁄head&gt; tag of your
            website:
          </label>
          <textarea
            class="form-control"
            placeholder="<script src='https://adbox.com/cms/embed.php' type='text/javascript' async></script>"
            aria-label="text"
            id="snippet-before"
            style="height: 60px"
          ></textarea>
          <label
            for="snippet-inside"
            style="
                  font-size: 14px;
                  color: #332e45;
                  font-weight: 400;
                  line-height: 18px;
                "
          >
            Paste this snippet inside your &lt;body&gt; tag, where you want
            the promotion to appear
          </label>
          <textarea
            class="form-control"
            placeholder="<script src='https://adbox.com/cms/embed.php' type='text/javascript' async></script>"
            aria-label="text"
            id="snippet-inside"
            style="height: 60px"
          ></textarea>
        </div>
      </div>
    </li>
    <li>
      <div class="list-item-dropdown-title">
        <a
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#custom-css-collapse"
          aria-expanded="false"
        >
              <span class="d-flex justify-content-between align-items-center">
                <span style="color: #332e45; font-weight: bold"
                >Custom CSS</span
                >
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="expand_less">
                      <path
                        id="Vector"
                        d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                        fill="#332E45"
                      />
                    </g>
                  </svg>
                </span>
              </span>
        </a>
      </div>
      <div class="collapse" id="custom-css-collapse">
        <div
          class="list-item-dropdown-body d-flex flex-column justify-content-center row-gap-2 align-items-center bg-white"
        >
              <textarea
                class="form-control"
                placeholder="Text"
                aria-label="text"
                id="custom-css"
                style="height: 152px"
                formControlName="custom_css"
              ></textarea>
        </div>
      </div>
    </li>
  </ul>
</form>
