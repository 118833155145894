<div class="container-fluid">
  <!-- Cards -->
  <section class="row">
    <div class="template-wrapper col-xxl-2 col-xl-3 col-sm-4 mb-5">
      <div class="template-preview-img position-relative">
        <div class="img-wrapper d-flex justify-content-center align-items-center"
             style="background: transparent url('../../../assets/imgs/template-gallery/story.jpg') no-repeat center top">
          <div class="hoverable-icons position-absolute">
            <a class="icon material-icons me-2 text-decoration-none mat-elevation-z0 bg-white"
               [routerLink]="['/', AdsRoute, AdPreviewRoute]"
               target="_blank">remove_red_eye</a>
            <a class="icon material-icons ms-2 text-decoration-none mat-elevation-z0 bg-white"
               (click)="openDialog()">add</a>
          </div>
        </div>
      </div>
      <div class="data-wrapper">
        <div class="type text-uppercase mt-3 mb-2">
          Story Ad
        </div>
        <div class="template-name">
          <h4 class="mb-0">Tourismus Template</h4>
        </div>
        <div class="dimension">
          300x600
        </div>
      </div>
    </div>

  </section>
</div>
