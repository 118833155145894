import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './footer/footer.component';
import {AppBarComponent} from './app-bar/app-bar.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {RouterModule} from '@angular/router';
import {MenusModule} from '../../../shared/menus/menus.module';
import {BreadcrumbBarComponent} from './app-bar/breadcrumb-bar/breadcrumb-bar.component';
import {FormsModule} from '@angular/forms';
import {AuthModule} from "../../../auth/auth.module";

@NgModule({
  declarations: [
    FooterComponent,
    AppBarComponent,
    SidebarComponent,
    BreadcrumbBarComponent
  ],
  exports: [
    SidebarComponent,
    AppBarComponent,
    FooterComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        MenusModule,
        FormsModule,
        AuthModule
    ]
})
export class ComponentsModule {
}
