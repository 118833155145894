import { DropdownOption } from '../data-structures/dropdown-option';
import {
  Bottom,
  Center,
  GDN,
  IABStandard,
  Left,
  Right,
  Top,
} from './global-text-snippets';

export const HorizontalLogoAlignmentOptions: DropdownOption[] = [
  { key: 'left', value: Left },
  { key: 'right', value: Right },
  { key: 'center', value: Center },
];

export const VerticalLogoAlignmentOptions: DropdownOption[] = [
  { key: 'top', value: Top },
  { key: 'bottom', value: Bottom },
  { key: 'center', value: Center },
];

export const FontSizeOptions: DropdownOption[] = [
  { key: '6', value: '6' },
  { key: '8', value: '8' },
  { key: '10', value: '10' },
  { key: '12', value: '12' },
  { key: '14', value: '14' },
  { key: '16', value: '16' },
  { key: '18', value: '18' },
  { key: '20', value: '20' },
  { key: '22', value: '22' },
  { key: '24', value: '24' },
  { key: '26', value: '26' },
  { key: '28', value: '28' },
  { key: '30', value: '30' },
  { key: '32', value: '32' },
  { key: '34', value: '34' },
  { key: '36', value: '36' },
  { key: '38', value: '38' },
  { key: '40', value: '40' },
  { key: '42', value: '42' },
];

export const AnimationTypeStoryOptions: DropdownOption[] = [
  { key: 'fadeIn', value: 'Fade In' },
  { key: 'slideIn', value: 'Slide In' },
];

export const AnimationTypePickerOptions: DropdownOption[] = [
  { key: 'fadeIn', value: 'Fade In' },
  { key: 'fadeInUp', value: 'Fade In Up' },
  { key: 'slideInLeft', value: 'Slide In Left' },
];

export const PublisherOptions: DropdownOption[] = [
  { key: 'iab-standard', value: IABStandard },
  { key: 'gdn-google-display-network', value: GDN },
];
