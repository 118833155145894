import {createReducer, on} from '@ngrx/store';
import {SlideModel} from '../../shared/models/slide.model';
import {addSlides, setSlideList} from './slides.actions';
import {ArrayHelper} from '../../shared/helper/array-helper';


export const initialState: ReadonlyArray<SlideModel> = [];

export const slidesReducer = createReducer(
  initialState,
  on(setSlideList, (state, {payload: [slides, _]}): ReadonlyArray<SlideModel> => {
    const mergedArr = [...state, ...slides];
    const condition = (first: SlideModel, second: SlideModel) => {
      return first.id === second.id
    };
    const filteredArr = ArrayHelper.filterDuplicates(mergedArr, condition);
    return [...filteredArr];
  }),
  on(addSlides, (state, {payload: [slides, _]}): ReadonlyArray<SlideModel> => {
    const newSlides = slides.filter(slide => typeof slide.id !== 'undefined');
    const merged = [...initialState, ...newSlides];
    const uniqueSlides = merged.filter(function (item, pos) {
      return merged.indexOf(item) == pos;
    })
    return [...uniqueSlides];
  })
);
