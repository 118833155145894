import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdboxRoutes } from '../../data-repository/routes';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdTypeModel } from '../../models/ad-type.model';
import {
  Cancel,
  Create,
  NewAd,
} from 'src/app/shared/data-repository/global-text-snippets';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import { AdTypeService } from '../../../ads/ad-type.service';
import { AdsService } from '../../../ads/ads.service';
import { Operation } from '../../data-structures/interaction-data-structures';

@Component({
  selector: 'app-create-ad-dialog',
  templateUrl: './create-ad-dialog.component.html',
  styleUrls: ['./create-ad-dialog.component.scss'],
})
export class CreateAdDialogComponent implements OnInit {
  public Create = Create;
  public Cancel = Cancel;
  public NewAd = NewAd;
  public selectedAdType!: AdTypeModel;
  public adTypes: ReadonlyArray<AdTypeModel> = [];
  public disabled = true;
  public newAdForm!: FormGroup;

  constructor(
    private router: Router,
    private adTypeService: AdTypeService,
    private adsService: AdsService,
    private formBuilder: FormBuilder,
    private actions$: Actions,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.adTypeService.getAdTypes().subscribe((adTypes) => {
      this.adTypes = adTypes;
      if (data && data.adType !== undefined) {
        this.selectedAdType = data.adType;
        this.disabled = true;
      } else {
        this.disabled = false;
        this.selectedAdType = this.adTypes[0];
      }
    });
  }

  ngOnInit(): void {
    this.initForm();
  }

  onSubmit() {
    if (!this.newAdForm.valid) {
      return;
    }

    const title = this.newAdForm.controls.titleCtrl.value;
    const id = +this.newAdForm.controls.adTypeCtrl.value.id;

    this.adsService.createAd(title, id);
    this.router.navigate([AdboxRoutes.Ads, AdboxRoutes.MyAds], {
      queryParams: { op: Operation.Create },
    });
  }

  private initForm() {
    this.newAdForm = this.formBuilder.group({
      titleCtrl: ['', Validators.required],
      adTypeCtrl: [
        {
          value: this.selectedAdType,
          disabled: this.disabled,
        },
        Validators.required,
      ],
    });
  }
}
