export class ArrayHelper {
  public static filterDuplicates<T>(arr: Array<T>, condition: (first: T, second: T) => boolean): Array<T> {
    const filteredArr = arr.filter((value, index, self) =>
      index === self.findIndex((other) => (
        condition(value, other)
      ))
    );
    return filteredArr;
  }
}
