import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AdBaseModel } from '../../../shared/models/ad.model';
import { ContextMenuItem } from '../../../shared/menus/context-menu/context-menu-item';
import { AdboxRoutes } from '../../../shared/data-repository/routes';
import { AdTypeService } from '../../ad-type.service';
import { AdStatus } from '../../../shared/data-structures/ad-data-structures';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ItemsPerPage,
  UrlSegmentDelimiter,
} from '../../../shared/data-repository/global-constants';
import { SubSink } from 'subsink';
import { AdminLayoutService } from '../../../layouts/admin-layout/admin-layout.service';
import { AdsService } from '../../ads.service';
import { FilterMenuService } from '../../../shared/menus/filter-menu/filter-menu.service';
import { debounceTime, exhaustMap, filter, map, Observable, take } from 'rxjs';
import { PaginationComposite } from '../../../shared/data-structures/pagination';
import { Actions, ofType } from '@ngrx/effects';
import * as fromAdsActions from '../../../state/ads/ads.actions';
import { HttpOperation } from '../../../shared/services/endpoint.service';
import { SortAndFilterComposite } from '../../../shared/data-structures/sort-and-filter';

@Component({
  selector: 'app-my-ads-cards-view',
  templateUrl: './my-ads-cards-view.component.html',
  styleUrls: ['./my-ads-cards-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyAdsCardsViewComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() public contextMenuItems!: ContextMenuItem[];
  @Input() public total!: number;
  @Output() public pageChanged = new EventEmitter<PaginationComposite>();
  public ads!: ReadonlyArray<AdBaseModel>;
  public AdsRoute = AdboxRoutes.Ads;
  public AdPreviewRoute = AdboxRoutes.AdPreview;
  public EditRoute = AdboxRoutes.Edit;
  public EditDesignRoute = AdboxRoutes.Design;
  public AdStatus = AdStatus;
  public UrlSegmentDelimiter = UrlSegmentDelimiter;

  private sortAndFilter = this.filterMenuService.createDefaultSortAndFilter();
  private viewportHeight = 0;
  private subs = new SubSink();
  private currentPage = 1;
  private initialized = false;
  private readonly scrollOffset = 50; // offset to load ads before user scrolled to the very bottom. Makes lazy loading smoother.

  constructor(
    public adTypeService: AdTypeService,
    public sanitizer: DomSanitizer,
    private actions$: Actions,
    private cd: ChangeDetectorRef,
    private adsService: AdsService,
    private filterMenuService: FilterMenuService,
    private adminLayoutService: AdminLayoutService
  ) {}

  ngOnInit(): void {
    this.lazyLoad();
    this.initEvents();
  }

  ngAfterViewInit(): void {
    this.setViewportHeight();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private lazyLoad(): void {
    // If the cards overflow, check if user scrolled to the bottom and load ads accordingly.
    this.subs.sink = this.adminLayoutService.windowScrolled
      .pipe(
        debounceTime(50),
        filter(([scrollHeight, scrollTop]) => {
          return (
            this.ads &&
            scrollHeight <=
              this.viewportHeight + scrollTop + this.scrollOffset &&
            this.ads.length < this.total
          );
        }),
        exhaustMap(() => {
          return this.fetchNextPage(++this.currentPage);
        })
      )
      .subscribe(([ads, _, _1]) => {
        this.ads = [...this.ads, ...ads];
        this.cd.detectChanges();
      });

    // If we have no overflow because the ads fit on one page without overflowing it, use the mouse wheel event.
    this.subs.sink = this.adminLayoutService.mouseWheelTriggered
      .pipe(
        debounceTime(50),
        filter((_) => {
          const panelWrapper = document.querySelector(
            '#panel-wrapper'
          ) as HTMLDivElement;
          return panelWrapper.scrollHeight - panelWrapper.clientHeight === 0;
        }),
        exhaustMap(() => {
          return this.fetchNextPage(1);
        })
      )
      .subscribe(([ads, _, _1]) => {
        this.ads = [...this.ads, ...ads];
      });
  }

  private fetchAds(sortAndFilter: SortAndFilterComposite | undefined): void {
    this.subs.sink = this.adsService
      .fetchAds(sortAndFilter)
      .subscribe((response) => {
        if (response[1] === HttpOperation.Post) {
          this.ads = response[0];
        } else if (response[1] === HttpOperation.Get && !this.initialized) {
          this.ads = response[0];
          this.currentPage = 1;
          this.initialized = true;
        }
        this.total = response[2];
        this.cd.detectChanges();
      });
  }

  private initEvents() {
    this.subs.sink = this.filterMenuService.sortAndFilterChanged$.subscribe(
      (sortAndFilter) => {
        if (!sortAndFilter) {
          this.fetchAds(undefined);
          return;
        }

        this.sortAndFilter = {
          ...this.sortAndFilter,
          filter: sortAndFilter.filter,
        };
        this.fetchAds(this.sortAndFilter);
        this.initialized = false;
      }
    );

    this.subs.sink = this.actions$
      .pipe(
        ofType(fromAdsActions.deleteAd),
        map((response) => response.payload)
      )
      .subscribe((deletedAdId) => {
        this.ads = this.ads.filter((ad) => ad.id !== deletedAdId);
      });

    this.subs.sink = this.actions$
      .pipe(
        ofType(fromAdsActions.setAdsList),
        map((response) => response.payload)
      )
      .subscribe((payload) => {
        if (payload[1] === HttpOperation.Post) {
          this.currentPage = 1;
        }
      });

    this.subs.sink = this.adminLayoutService.viewportResized.subscribe(() =>
      this.setViewportHeight()
    );
  }

  private fetchNextPage(
    currentPage: number
  ): Observable<[readonly AdBaseModel[], HttpOperation, number]> {
    const pagination: PaginationComposite = {
      perPage: ItemsPerPage,
      currentPage: currentPage,
    };
    this.pageChanged.next(pagination);
    return this.adsService
      .fetchAds(this.sortAndFilter, pagination)
      .pipe(take(1));
  }

  private setViewportHeight(): void {
    const viewport = document.querySelector('body');
    this.viewportHeight = viewport!.clientHeight;
  }
}
