import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {SortAndFilterComposite} from '../../data-structures/sort-and-filter';
import {FilterMap} from '../../data-structures/filter';

@Injectable({
  providedIn: 'root'
})
export class FilterMenuService {
  public openFilter$ = new Subject();
  public filterReset$ = new Subject<boolean>();
  public sortAndFilterChanged$ = new BehaviorSubject<SortAndFilterComposite | null>(null);

  public createDefaultSortAndFilter(): SortAndFilterComposite {
    return {
      filter: new Map<string, string>(),
      sort: [
        {
          direction: 'desc',
          active: 'created_at'
        },
        {
          direction: 'asc',
          active: 'title'
        }
      ]
    }
  }

  public flattenFilter(filterMap: FilterMap): Array<[string, string]> {
    let flattenedFilterArr = new Array<[string, string]>();
    filterMap.forEach((value, key) => {
      if (value instanceof Map) {
        if (value.size > 0) {
          value.forEach((_, entryKey) => {
            flattenedFilterArr.push([key, entryKey]);
          });
        }
      } else {
        flattenedFilterArr.push([key, value]);
      }
    });
    return flattenedFilterArr;
  }

  public purgeFilter(sortAndFilter: SortAndFilterComposite): FilterMap {
    let updatedFilter = new Map(sortAndFilter.filter);
    sortAndFilter.filter.forEach((entry, key) => {
      if (entry instanceof Map && entry.size === 0) {
        updatedFilter.delete(key);
      }
    });

    return updatedFilter;
  }
}
