import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(base64: string | null): string | null {
    if (base64) {
      const sanitzesBase64 = this.sanitizer.sanitize(SecurityContext.STYLE, 'url(' + base64 + ')');
      return sanitzesBase64;
    }
    return null;
  }

}
