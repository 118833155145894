import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SlideModel} from '../../shared/models/slide.model';

export const selectSlides = createSelector(
  createFeatureSelector('slides'),
  (slides: SlideModel[]) => {
    return slides;
  }
);

export const selectFilteredSlides = (slideableId: number) => createSelector(selectSlides, (slides) => {
  return slides
    .filter(slide => slide.slideableId === slideableId)
    .sort((slideOne, slideTwo) => slideOne.orderNumber - slideTwo.orderNumber);
});
