import { Component } from '@angular/core';
import { NewPromotionService } from '../../../new-promotion.service';
import { AbstractControl, FormArray, FormBuilder } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UploadService } from '../../../../../backend-services/upload.service';
import { DomSanitizer } from '@angular/platform-browser';
import {getTinyMCEConfig} from "../../../../../utils/default-form-data-builder";

@Component({
  selector: 'app-quiz-block-expanded-view',
  templateUrl: './quiz-block-expanded-view.component.html',
  styleUrls: ['./quiz-block-expanded-view.component.scss'],
})
export class QuizBlockExpandedViewComponent {
  public readonly tineMCEConfig = getTinyMCEConfig();

  constructor(
    public newPromotionService: NewPromotionService,
    private fb: FormBuilder,
    private uploadService: UploadService,
    public sanitizer: DomSanitizer
  ) {}

  closeCard() {
    this.newPromotionService.layoutCardExpanded = false;
  }

  toggleStyle(
    control: AbstractControl | null | undefined,
    values: { activeValue: any; defaultValue: any }
  ) {
    if (control?.value === values.activeValue) {
      control?.setValue(values.defaultValue);
    } else {
      control?.setValue(values.activeValue);
    }
  }

  get formFields() {
    return (
      this.newPromotionService.selectedLayoutCard!.data.get(
        'form_fields'
      ) as FormArray
    ).controls;
  }

  get questions() {
    return this.newPromotionService.selectedLayoutCard!.data.get(
      'questions'
    ) as FormArray;
  }

  getAnswers(questionIndex: any) {
    return this.questions.at(questionIndex)?.get('answers') as FormArray;
  }

  createNewFormField() {
    this.formFields.push(
      this.fb.group({
        name: this.fb.control('form_field'),
        label: this.fb.control('Form Field'),
        isRequired: this.fb.control(true),
        type: this.fb.control('text'),
      })
    );
  }

  drop(event: CdkDragDrop<string[]>, data: any) {
    moveItemInArray(this.formFields, event.previousIndex, event.currentIndex);
  }

  deleteFormField(index: number) {
    (
      this.newPromotionService.selectedLayoutCard!.data.get(
        'form_fields'
      ) as FormArray
    ).removeAt(index);
  }

  duplicateFormField(value: any) {
    this.formFields.push(
      this.fb.group({
        name: this.fb.control(value.name),
        label: this.fb.control(value.label),
        isRequired: this.fb.control(value.isRequired),
        type: this.fb.control(value.type),
      })
    );
  }

  createNewAnswer(questionIndex: number) {
    this.getAnswers(questionIndex).push(
      this.fb.group({
        answer: this.fb.control(''),
        image_file: this.fb.control(''),
        correct: this.fb.control(false),
        enabled: this.fb.control(true),
      })
    );
  }

  deleteAnswer(questionIndex: number, answerIndex: number) {
    this.getAnswers(questionIndex).removeAt(answerIndex);
  }

  duplicateAnswer(questionIndex: number, answerIndex: number) {
    const data = this.getAnswers(questionIndex).at(answerIndex).value;

    this.getAnswers(questionIndex).push(
      this.fb.group({
        answer: this.fb.control(data.answer),
        image_file: this.fb.control(data.image_file),
        image_thumb_file: this.fb.control(data.image_thumb_file),
        correct: this.fb.control(data.correct),
        enabled: this.fb.control(data.enabled),
      })
    );
  }

  createNewQuestion() {
    this.questions.push(
      this.fb.group({
        enabled: this.fb.control(true),
        question: this.fb.control(''),
        answers: this.fb.array([
          this.fb.group({
            answer: this.fb.control(''),
            image_file: this.fb.control(''),
            image_thumb_file: this.fb.control(''),
            correct: this.fb.control(true),
            enabled: this.fb.control(true),
          }),
        ]),
      })
    );
  }

  deleteQuestion(questionIndex: number) {
    this.questions.removeAt(questionIndex);
  }

  duplicateQuestion(questionIndex: number) {
    const data = this.questions.at(questionIndex).value;

    const answers = data.answers.map((a: any) => {
      return this.fb.group({
        answer: this.fb.control(a.answer),
        image_file: this.fb.control(a.image_file),
        image_thumb_file: this.fb.control(a.image_thumb_file),
        correct: this.fb.control(a.correct),
        enabled: this.fb.control(a.enabled),
      });
    });

    this.questions.push(
      this.fb.group({
        enabled: this.fb.control(data.enabled),
        question: this.fb.control(data.question),
        answers: this.fb.array(answers),
      })
    );
  }

  handleQuestionImageChange(
    event: any,
    questionIndex: number,
    answerIndex: number
  ) {
    const file = event.target.files[0];
    if (file) {
      this.uploadService.uploadImage(file).subscribe({
        next: (res) => {
          if (res.success && res.data?.file) {
            this.getAnswers(questionIndex)
              .at(answerIndex)
              .get('image_file')
              ?.setValue(res.data.file);

            this.getAnswers(questionIndex)
              .at(answerIndex)
              .get('image_thumb_file')
              ?.setValue(res.data.thumb);
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
    }
  }

  deleteImageFile(questionIndex: number, answerIndex: number) {
    const file = this.getAnswers(questionIndex)
      .at(answerIndex)
      .get('image_file')?.value;
    if (file) {
      this.uploadService.deleteImage(file).subscribe({ error: console.log });
    }

    this.getAnswers(questionIndex)
      .at(answerIndex)
      .get('image_file')
      ?.setValue('');
    this.getAnswers(questionIndex)
      .at(answerIndex)
      .get('image_thumb_file')
      ?.setValue('');
  }
}
