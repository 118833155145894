import {createFeatureSelector, createSelector} from '@ngrx/store';
import {OptionModel} from '../../shared/models/option.model';

export const selectOptions = createSelector(
  createFeatureSelector('options'),
  (options: OptionModel[]) => {
    return options;
  }
);

export const selectFilteredOptions = (optionableId: number) => createSelector(selectOptions, (options) => {
  return options
    .filter(option => option.optionableId === optionableId)
    .sort((optionOne, optionTwo) => optionOne.orderNumber - optionTwo.orderNumber);
});
