import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as fromAdTypeActions from './ad-types.actions'
import {setAdTypeList} from './ad-types.actions'
import * as fromAppActions from '../app/app.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {EndpointService} from '../../shared/services/endpoint.service';
import {AdTypeModel} from '../../shared/models/ad-type.model';
import {plainToInstance} from 'class-transformer';
import {HttpErrorService} from '../../shared/services/http-error.service';

@Injectable()
export class AdTypesEffects {
  fetchAdTypes$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromAppActions.bootstrapApp),
        switchMap(_ => {
          const endpoint = this.endpointService.getAdTypesSlug();
          return this.http.get<any>(endpoint).pipe(
            catchError(error => {
              return this.httpErrorService.handleError('Ad Typen', fromAdTypeActions, error);
            })
          )
        }),
        map((json: any[]) => {
          const adTypes = plainToInstance(AdTypeModel, json);
          return setAdTypeList({payload: adTypes}) || []
        })
      )
    }
  );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private endpointService: EndpointService,
    private httpErrorService: HttpErrorService
  ) {
  }
}
