import {AfterViewInit, Directive, ElementRef, HostListener} from '@angular/core';
import {AdsService} from '../ads.service';

@Directive({
  selector: '[appEditAd]'
})
export class EditAdDirective implements AfterViewInit {
  constructor(private myElement: ElementRef,
              private adsService: AdsService) {

  }

  ngAfterViewInit(): void {
    this.adsService.configurationItemWidthUpdated.next(this.myElement.nativeElement.clientWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(_: any) {
    this.adsService.configurationItemWidthUpdated.next(this.myElement.nativeElement.clientWidth);
  }

}
