import { Component, OnInit } from '@angular/core';
import { NewPromotionService } from '../new-promotion.service';

@Component({
  selector: 'app-new-promotion-settings',
  templateUrl: './new-promotion-settings.component.html',
  styleUrls: ['./new-promotion-settings.component.scss'],
})
export class NewPromotionSettingsComponent implements OnInit {

  constructor(public newPromotionService: NewPromotionService) {}

  ngOnInit(): void {}


}
