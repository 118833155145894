import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AdBaseModel} from '../../shared/models/ad.model';

export const selectAds = createSelector(
  createFeatureSelector('ads'),
  (ads: AdBaseModel[]) => {
    return ads;
  }
);

export const selectAd = (adId: number) => createSelector(selectAds, (ads) => {
  return ads.find(adModel => adModel.id === adId);
});
