<div id="container-fluid">
  <div class="row">

    <div class="col">
      <table mat-table [dataSource]="ads"
             matSort
             (matSortChange)="orderData($event)"
             class="mat-elevation-z8 w-100">

        <ng-container matColumnDef="title">
          <th mat-header-cell
              mat-sort-header="title"
              *matHeaderCellDef>Titel
          </th>
          <td mat-cell
              *matCellDef="let element"
              style="font-weight:500"
              [ngClass]="element.status"> {{element.title}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell
              mat-sort-header="baseable_type"
              *matHeaderCellDef>Typ
          </th>
          <td mat-cell
              *matCellDef="let element"
              [ngClass]="element.status"> {{(adTypeService.getAdType(element.adTypeId) | async)?.title}} </td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell
              mat-sort-header="created_at"
              *matHeaderCellDef>Erstellungsdatum
          </th>
          <td mat-cell
              *matCellDef="let element"
              [ngClass]="element.status"> {{element.created | date:'dd.MM.yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell
              mat-sort-header="status"
              *matHeaderCellDef>Status
          </th>
          <td mat-cell
              *matCellDef="let element"
              [ngClass]="element.status">
            <button mat-button class="pe-none">{{element.status | adStatus}}</button>
          </td>
        </ng-container>

        <ng-container matColumnDef="context">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell
              class="text-end"
              *matCellDef="let element"
              [ngClass]="element.status"><a
            (click)="contextMenu.onContextMenu($event, contextMenuItems, element)"
            class="material-icons context text-decoration-none">more_horiz</a></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="clickRow(row, $event)">
      </table>
      <mat-paginator
        #paginator
        [length]="total"
        [pageSize]="ItemsPerPage"
        [hidePageSize]="true"
        [showFirstLastButtons]="true"
        [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="switchedPage($event)"
        aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
</div>

<app-context-menu #contextMenu="context-menu"></app-context-menu>
