import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  AdsSlug,
  AdTypesSlug,
  DownloadSlug,
  FileTypesSlug,
  FontsSlug,
  OptionsSlug,
  OriginalImageOptionsSlug,
  OriginalImageSlidesSlug,
  PickerSlug,
  SlidesSlug,
  StorySlug,
} from '../data-repository/api-slugs';
import { UrlSegmentDelimiter } from '../data-repository/global-constants';

@Injectable({
  providedIn: 'root',
})
export class EndpointService {
  private readonly PREFIX = environment.apiPath + UrlSegmentDelimiter;

  constructor() {}

  // ////////////############ Ad Types ############////////////
  public getAdTypesSlug(): string {
    return this.PREFIX + AdTypesSlug;
  }

  // ////////////############ Ads ############////////////
  public getAdsSlug(operation: HttpOperation, id?: number): string {
    if (
      operation === HttpOperation.Post ||
      (operation === HttpOperation.Get && !id)
    ) {
      return this.PREFIX + AdsSlug;
    } else {
      return this.buildUrlWithId(AdsSlug, operation, id);
    }
  }

  public getAdsDownloadSlug(id: number): string {
    return (
      this.buildUrlWithId(AdsSlug, HttpOperation.Post, id) +
      UrlSegmentDelimiter +
      DownloadSlug
    );
  }

  public getAdsFileSizeSlug(id: number): string {
    return (
      this.buildUrlWithId(AdsSlug, HttpOperation.Post, id) +
      UrlSegmentDelimiter +
      DownloadSlug
    );
  }

  // ////////////############ Slides ############////////////
  public getSlidesSlug(operation: HttpOperation, id?: number): string {
    if (
      operation === HttpOperation.Post ||
      (operation == HttpOperation.Get && !id)
    ) {
      return this.PREFIX + SlidesSlug;
    } else {
      return this.buildUrlWithId(SlidesSlug, operation, id);
    }
  }

  // ////////////############ Options ############////////////
  public getOptionsSlug(operation: HttpOperation, id?: number): string {
    if (
      operation === HttpOperation.Post ||
      (operation == HttpOperation.Get && !id)
    ) {
      return this.PREFIX + OptionsSlug;
    } else {
      return this.buildUrlWithId(OptionsSlug, operation, id);
    }
  }

  // ////////////############ Fonts ############////////////
  public getFontsSlug(operation: HttpOperation, id?: number): string {
    if (
      operation === HttpOperation.Post ||
      (operation == HttpOperation.Get && !id)
    ) {
      return this.PREFIX + FontsSlug;
    } else {
      return this.buildUrlWithId(FontsSlug, operation, id);
    }
  }

  // ////////////############ File Types ############////////////
  public getFileTypesSlug(): string {
    return this.PREFIX + FileTypesSlug;
  }

  // ////////////############ Images ############////////////
  public getOriginalImageSlug(
    operation: HttpOperation,
    adTypeName: string,
    imageWrapperId: number
  ): string {
    let adSlug = '';
    switch (adTypeName) {
      case StorySlug:
        adSlug = OriginalImageSlidesSlug;
        break;
      case PickerSlug:
        adSlug = OriginalImageOptionsSlug;
        break;
      default:
        throw new Error('Ad type is not supported.');
    }
    return this.PREFIX + adSlug + UrlSegmentDelimiter + imageWrapperId;
  }

  private buildUrlWithId(
    slug: string,
    httpOperation: HttpOperation,
    id?: number
  ): string | never {
    if (
      httpOperation !== HttpOperation.Get &&
      (id === null || typeof id === 'undefined')
    ) {
      throw new Error('Id not specified.');
    }
    return this.PREFIX + slug + UrlSegmentDelimiter + id;
  }
}

export enum HttpOperation {
  Post,
  Get,
  Put,
  Delete,
  None,
}
