<div class="card-wrapper accordion"
     [class.has-sub-items]="item.hasSubItems"
     id="{{item.id}}">
  <div class="card text-start accordion-item">
    <div class="card-header accented accordion-header d-flex align-items-center"
         [id]="item.id + '-heading'">
         <span class="controls d-flex" *ngIf="item.sortable || item.cloneable || item.removable">
            <a *ngIf="item.sortable"
               class="drag-handle icon material-icons-sharp text-decoration-none">drag_handle</a>
            <a class="icon material-icons-sharp text-decoration-none"
               *ngIf="item.cloneable"
               (click)="execCopyCallback()">copy</a>
            <a class="icon material-icons-sharp text-decoration-none"
               *ngIf="item.removable"
               (click)="execRemoveCallback()">delete</a>
         </span>
      <button class="accordion-button justify-content-between"
              type="button"
              data-bs-toggle="collapse"
              [class.collapsed]="item.collapsed ? 'collapsed' : ''"
              [attr.data-bs-target]="'#' + item.id + '-collapse'"
              [attr.aria-controls]="item.id + '-collapse'"
              [attr.aria-expanded]="item.collapsed ? 'false' : 'true'">
        <span class="title ps-0 pe-2">
          {{item.title}}
        </span>
      </button>
      <div class="status-wrapper ms-auto d-flex position-absolute">
          <span class="done icon material-icons"
                [hidden]="!item.altered">check</span>
        <a class="adbox-tooltip icon material-icons-sharp ms-2 text-decoration-none"
           [matTooltip]="'Erlaubte Formate: .gif, .jpg, .png\n'+
'Maximale Breite: 300px\n'+
'Maximale Höhe: 70px'"
           (click)="showTooltip()">info</a>
      </div>
    </div>
    <div [id]="item.id + '-collapse'"
         class="card-body accordion-collapse collapse"
         [class.show]="item.collapsed ? '' : 'show'"
         [attr.aria-labbelledby]="item.id + '-heading'">
      <div class="row accordion-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
