<li>
  <div class="list-item-dropdown-title">
    <a
      style="letter-spacing: 0.7px; line-height: 16px"
      class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
      href="#"
      role="button"
      data-bs-toggle="collapse"
      [attr.data-bs-target]="'#section-collapse-' + encodedTitle"
      aria-expanded="false"
    >
      <span class="d-flex justify-content-between align-items-center">
        <span style="color: #332e45" class="fw-bold text-uppercase">{{ title }}</span>
        <span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="expand_less">
              <path
                id="Vector"
                d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                fill="#332E45"
              />
            </g>
          </svg>
        </span>
      </span>
    </a>
  </div>
  <div class="collapse w-100" [id]="'section-collapse-' + encodedTitle">
    <div
      class="w-100 list-item-dropdown-body d-flex flex-column justify-content-center row-gap-2 align-items-center bg-white"
    >
      <ng-content></ng-content>
    </div>
  </div>
</li>
