import {
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import html2canvas from 'html2canvas';
import { AdBaseModel } from '../../../shared/models/ad.model';
import { SubSink } from 'subsink';
import { EditAdNavigationService } from '../edit-ad-navigation.service';
import { AdsService } from '../../ads.service';
import { combineLatest } from 'rxjs';
import { AdTypeService } from '../../ad-type.service';
import { Actions } from '@ngrx/effects';
import { StorySlug } from '../../../shared/data-repository/api-slugs';
import { EditAdService } from '../edit-ad.service';

@Directive({
  selector: '[appGeneratePreviewThumb]',
})
export class GeneratePreviewThumbDirective implements OnInit, OnDestroy {
  private subs = new SubSink();

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private adsService: AdsService,
    private editAdService: EditAdService,
    private adTypeService: AdTypeService,
    private renderer: Renderer2,
    private actions: Actions,
    private editAdNavigationService: EditAdNavigationService
  ) {}

  ngOnInit(): void {
    this.createThumb();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private generateThumb(
    shadowContainer: any,
    clonedViewContainer: any,
    adModel: AdBaseModel
  ): void {
    html2canvas(clonedViewContainer, {
      width: 90,
      height: 180,
    }).then((canvas: HTMLCanvasElement) => {
      const base64 = canvas.toDataURL('image/jpeg', 1);
      const updatedAd = new AdBaseModel();
      Object.assign(updatedAd, adModel);
      updatedAd.thumbnail = base64;
      this.adsService.updateAd(
        updatedAd,
        updatedAd.baseable!,
        updatedAd.id,
        false
      );
      this.renderer.removeChild(shadowContainer, clonedViewContainer);
      this.editAdNavigationService.thumbnailGenerated$.next(true);
    });
  }

  private createThumb() {
    const adTypesObs$ = this.adTypeService.getAdTypes();
    const thumbnailGenerationInitiatedObs$ =
      this.editAdNavigationService.thumbnailGenerationInitiated$;

    this.subs.sink = combineLatest([
      adTypesObs$,
      thumbnailGenerationInitiatedObs$,
    ]).subscribe(([adTypes, adModel]) => {
      if (!adModel) {
        return;
      }
      const adTypeName = adTypes.find(
        (type) => type.id === adModel.adTypeId
      )!.name;
      const clonedViewContainer = this.elementRef.nativeElement.cloneNode(
        true
      ) as HTMLElement;
      const shadowContainer = document.querySelector(
        '#ad-reflection-wrapper #view-container-shadow'
      ) as HTMLElement;
      const fab = new ViewContainerShadowFactory(this.renderer);

      this.unwrapClonedViewcontainer(clonedViewContainer);

      if (adTypeName === StorySlug) {
        fab.buildStoryline(clonedViewContainer);
      }

      this.renderer.setStyle(clonedViewContainer, 'transform', 'scale(0.3)');
      this.renderer.setStyle(clonedViewContainer, 'display', 'block');
      this.renderer.appendChild(shadowContainer, clonedViewContainer);
      window.setTimeout(() => {
        this.generateThumb(shadowContainer, clonedViewContainer, adModel);
      }, 0);
    });
  }

  /**
   * Unwrap the cloned view container since we don't want to select the elements in the html2canvas iframe
   */
  private unwrapClonedViewcontainer(clonedViewContainer: HTMLElement) {
    const renderable = clonedViewContainer.querySelector('#renderable')!;
    const parent = renderable.parentNode!;
    while (renderable.firstChild) {
      parent.insertBefore(renderable.firstChild, renderable);
    }
    parent.removeChild(renderable);
  }
}

class ViewContainerShadowFactory {
  constructor(private renderer: Renderer2) {}

  public buildStoryline(shadowContainer: HTMLElement): void {
    const cardHolder = shadowContainer.querySelector('#story-preview-body');
    shadowContainer.querySelectorAll('.carditem').forEach((card) => {
      if (!card.classList.contains('first')) {
        this.renderer.removeChild(cardHolder, card);
      } else {
        card.classList.add('visible');
      }
    });
  }
}
