import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { PromotionService } from '../../backend-services/promotion.service';
import { ToastrService } from 'ngx-toastr';
import {
  buildButtonStyles,
  buildHeadlineStyles,
  buildPaddingData,
  buildTextStyles,
} from '../../utils/default-form-data-builder';

export type LayoutCard = {
  id: string;
  type: string;
  block_id: number;
  data: FormGroup;
};

@Injectable({
  providedIn: 'root',
})
export class NewPromotionService {
  sidebarView: 'Settings' | 'Layout' = 'Settings';
  previewType: 'Desktop' | 'Tab' | 'Mobile' = 'Desktop';

  settingsFormData = new FormGroup({
    title: new FormControl('', [Validators.required]),
    status: new FormControl('Status Value', [Validators.required]),
    starts_at: new FormControl(''),
    ends_at: new FormControl(''),
    background_color: new FormControl('#FFFFFF'),
    meta_title: new FormControl(),
    meta_description: new FormControl(),
    meta_image_file: new FormControl(),
    should_delete_data_on_exp: new FormControl(false),
    keep_data_until: new FormControl(''),
    imprint_type: new FormControl(0),
    imprint_content: new FormControl(),
    privacy_policy_type: new FormControl(0),
    privacy_policy_content: new FormControl(),
    terms_type: new FormControl(0),
    terms_content: new FormControl(),
    custom_css: new FormControl(),
  });

  layoutCards: LayoutCard[] = [
    {
      id: uuidv4(),
      type: 'Quiz',
      block_id: 14,
      data: this.buildLayoutCardData('Quiz'),
    },
  ];
  selectedLayoutCard: LayoutCard | undefined = undefined;
  layoutCardExpanded = false;

  availableFontFamilies = [
    { name: 'Roboto', value: 'roboto' },
    { name: 'Sans Serif', value: 'sans-serif' },
    { name: 'Serif', value: 'serif' },
  ];
  availableFontSizes = [
    8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 48, 56, 64, 72, 80, 96,
  ];
  availableLineHeights = [
    {
      name: '1',
      value: 1,
    },
    {
      name: '1.25',
      value: 1.25,
    },
    {
      name: '1.375',
      value: 1.375,
    },
    {
      name: '1.5',
      value: 1.5,
    },
    {
      name: '2',
      value: 2,
    },
  ];

  availableBorderStyles = [
    { name: 'None', value: 'none' },
    { name: 'Solid', value: 'solid' },
    { name: 'Dotted', value: 'dotted' },
    { name: 'Dashed', value: 'dashed' },
    { name: 'Double', value: 'double' },
  ];

  constructor(
    private fb: FormBuilder,
    private promotionService: PromotionService,
    private toastrService: ToastrService
  ) {
    this.shouldDeleteDataOnExpControl.valueChanges.subscribe((v) => {
      if (!v) {
        this.keepDataUntilControl.reset();
      }
    });
  }

  get titleControl() {
    return this.settingsFormData.get('title') as FormControl;
  }
  get shouldDeleteDataOnExpControl() {
    return this.settingsFormData.get(
      'should_delete_data_on_exp'
    ) as FormControl;
  }

  get keepDataUntilControl() {
    return this.settingsFormData.get('keep_data_until') as FormControl;
  }

  toggleSelectedLayoutCard(card: LayoutCard) {
    if (this.selectedLayoutCard && this.selectedLayoutCard.id === card.id) {
      this.selectedLayoutCard = undefined;
    } else {
      this.selectedLayoutCard = card;
    }
  }

  setLayoutCardExpanded(card: LayoutCard, newState: boolean) {
    this.selectedLayoutCard = card;
    this.layoutCardExpanded = newState;
  }

  addNewBlock({
    type,
    index,
    block_id,
  }: {
    type: string;
    index?: number;
    block_id: number;
  }) {
    this.layoutCards.splice(index || this.layoutCards.length, 0, {
      id: uuidv4(),
      block_id,
      type,
      data: this.buildLayoutCardData(type),
    });
  }

  duplicateCard({ card, index }: { card: LayoutCard; index?: number }) {
    this.layoutCards.splice(index || this.layoutCards.length, 0, {
      id: uuidv4(),
      block_id: card.block_id,
      type: card.type,
      data: card.data,
    });
  }

  deleteBlock(id: string) {
    const cardIndex = this.layoutCards.findIndex((c) => c.id == id);
    if (cardIndex >= 0) {
      this.layoutCards.splice(cardIndex, 1);
    }
  }

  buildLayoutCardData(type: string) {
    switch (type) {
      case 'Text':
        return this.fb.group({
          background_type: this.fb.control('color'),
          background_color: this.fb.control('#6E72EB'),
          width: this.fb.control(100),
          ...buildPaddingData(this.fb),
          headline_text: this.fb.control('Headline Text'),
          style_headline: this.fb.group(
            buildHeadlineStyles(this.fb, 48, { color: '#000000' })
          ),
          content: this.fb.control(
            'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet architecto consectetur cum eos id inventore reprehenderit tempora tempore. A aspernatur at blanditiis exercitationem fugit id illum iure molestias nulla sunt.'
          ),
          style_text: this.fb.group(
            buildTextStyles(this.fb, { color: '#303030' })
          ),
        });
      case 'Bild':
        return this.fb.group({
          background_type: this.fb.control('color'),
          background_color: this.fb.control('#6E72EB'),
          width: this.fb.control(100),
          ...buildPaddingData(this.fb),
          image_file: this.fb.control(''),
          image_thumb_file: this.fb.control(''),
          image_title: this.fb.control(''),
          style_image: this.fb.group({
            alignSelf: this.fb.control('auto'),
            width: this.fb.control(100),
          }),
        });
      case 'Bild/Text':
        return this.fb.group({
          background_type: this.fb.control('color'),
          background_color: this.fb.control('#6E72EB'),
          width: this.fb.control(100),
          ...buildPaddingData(this.fb),
          content: this.fb.control(
            'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet architecto consectetur cum eos id inventore reprehenderit tempora tempore. A aspernatur at blanditiis exercitationem fugit id illum iure molestias nulla sunt.'
          ),
          style_text: this.fb.group(
            buildTextStyles(this.fb, { color: '#303030' })
          ),
          image_file: this.fb.control(''),
          image_thumb_file: this.fb.control(''),
          image_title: this.fb.control(''),
          style_image: this.fb.group({
            alignSelf: this.fb.control('auto'),
            width: this.fb.control(100),
          }),
          style_column: this.fb.group({
            marginUnit: this.fb.control('%'),
            width: this.fb.control(100),
          }),
        });
      case 'Galerie':
        return this.fb.group({
          background_type: this.fb.control('color'),
          background_color: this.fb.control('#6E72EB'),
          width: this.fb.control(100),
          ...buildPaddingData(this.fb),
          images: this.fb.array([
            this.fb.group({
              image_file: this.fb.control(''),
              image_thumb_file: this.fb.control(''),
              image_title: this.fb.control(''),
              style_image: this.fb.group({
                alignSelf: this.fb.control('auto'),
                width: this.fb.control(100),
              }),
            }),
          ]),
        });
      case 'Bildbanner':
        return this.fb.group({
          background_type: this.fb.control('color'),
          background_color: this.fb.control('#6E72EB'),
          width: this.fb.control(100),
          ...buildPaddingData(this.fb),
          image_file: this.fb.control(''),
          image_thumb_file: this.fb.control(''),
          image_title: this.fb.control(''),
          style_image: this.fb.group({
            alignSelf: this.fb.control('auto'),
            width: this.fb.control(100),
          }),
          style_headline: this.fb.group(buildHeadlineStyles(this.fb, 48)),
          headline_text: this.fb.control('Headline 1'),
          style_text: this.fb.group(buildTextStyles(this.fb)),
          content: this.fb.control('Text'),
          style_button: this.fb.group(buildButtonStyles(this.fb, true)),
          button_text: this.fb.control('Button'),
          button_type: this.fb.control(0),
          button_url: this.fb.control('https://'),
        });
      case 'Button':
        return this.fb.group({
          background_type: this.fb.control('color'),
          background_color: this.fb.control('#6E72EB'),
          width: this.fb.control(100),
          ...buildPaddingData(this.fb),
          style_button: this.fb.group(buildButtonStyles(this.fb, true)),
          button_text: this.fb.control('Button'),
          button_type: this.fb.control(0),
          button_url: this.fb.control('https://'),
        });
      case 'Spalten':
        return this.fb.group({
          background_type: this.fb.control('color'),
          background_color: this.fb.control('#6E72EB'),
          width: this.fb.control(100),
          ...buildPaddingData(this.fb),
          style_column: this.fb.group({
            marginUnit: this.fb.control('%'),
            width: this.fb.control(100),
          }),
          contents: this.fb.array([
            this.fb.group({
              content: this.fb.control(
                'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'
              ),
              style_text: this.fb.group(buildTextStyles(this.fb)),
            }),
          ]),
        });
      case 'Abstand':
        return this.fb.group({
          height: this.fb.control(24),
        });
      case 'Video':
        return this.fb.group({
          background_type: this.fb.control('color'),
          background_color: this.fb.control('#6E72EB'),
          width: this.fb.control(100),
          ...buildPaddingData(this.fb),
          video_type: this.fb.control(0),
          video_file: this.fb.control(''),
          autoplay: this.fb.control(false),
          style_video: this.fb.group({
            alignSelf: this.fb.control('auto'),
            width: this.fb.control(100),
          }),
        });
      case 'Navbar':
        return this.fb.group({
          background_type: this.fb.control('color'),
          background_color: this.fb.control('#6E72EB'),
          width: this.fb.control(100),
          ...buildPaddingData(this.fb),
          show_logo: this.fb.control(true),
          styles_menu: this.fb.group({
            ...buildHeadlineStyles(this.fb, 36),
            alignSelf: this.fb.control('auto'),
            hover_color: this.fb.control('#1656dd'),
          }),
          logo: this.fb.group({
            image_file: this.fb.control(''),
            image_thumb_file: this.fb.control(''),
            image_title: this.fb.control(''),
            image_link: this.fb.control('https://'),
            style_image: this.fb.group({
              alignSelf: this.fb.control('auto'),
              width: this.fb.control(200),
            }),
          }),
          menu_elements: this.fb.array([
            this.fb.group({
              content: this.fb.control('Text'),
              heading_type: this.fb.control('Abschnitt'),
              url: this.fb.control('https://'),
            }),
          ]),
        });
      case 'Footer':
        return this.fb.group({
          background_type: this.fb.control('color'),
          background_color: this.fb.control('#6E72EB'),
          width: this.fb.control(100),
          ...buildPaddingData(this.fb),
          styles_links: this.fb.group({
            ...buildHeadlineStyles(this.fb, 36),
            alignSelf: this.fb.control('auto'),
            hover_color: this.fb.control('#1656dd'),
          }),
          show_imprint: this.fb.control(true),
          show_data_protection: this.fb.control(true),
          show_conditions: this.fb.control(true),
        });
      case 'Quiz':
        return this.fb.group({
          background_type: this.fb.control('color'),
          background_color: this.fb.control('#6E72EB'),
          background_image_file: this.fb.control(null),
          width: this.fb.control(100),
          ...buildPaddingData(this.fb),
          style_headline_1: this.fb.group(buildHeadlineStyles(this.fb, 48)),
          style_headline_2: this.fb.group(buildHeadlineStyles(this.fb, 36)),
          style_text: this.fb.group(buildTextStyles(this.fb)),
          style_button_primary: this.fb.group(buildButtonStyles(this.fb, true)),
          style_button_secondary: this.fb.group(
            buildButtonStyles(this.fb, false)
          ),
          skip_start_page: this.fb.control(false),
          start_headline_1: this.fb.control(
            'Zeige dein Wissen und nimm am Gewinnspiel teil!'
          ),
          start_headline_2: this.fb.control('Also, worauf wartest du noch?'),
          start_text: this.fb.control(
            'Mit etwas Glück kannst du einen der Hauptpreise gewinnen und dich über eine besondere Belohnung freuen.'
          ),
          start_button_text: this.fb.control('Quiz starten'),
          form_headline: this.fb.control('Formular ausfüllen'),
          form_text: this.fb.control(
            'Fülle das Formular aus, um am Gewinnspiel teilzunehmen:'
          ),
          form_button_text: this.fb.control('Absenden'),
          form_fields: this.fb.array([
            this.fb.group({
              name: this.fb.control('first_name'),
              label: this.fb.control('Vorname'),
              isRequired: this.fb.control(true),
              type: this.fb.control('text'),
            }),
            this.fb.group({
              name: this.fb.control('last_name'),
              label: this.fb.control('Nachname'),
              isRequired: this.fb.control(true),
              type: this.fb.control('text'),
            }),
            this.fb.group({
              name: this.fb.control('email'),
              label: this.fb.control('E-Mail'),
              isRequired: this.fb.control(true),
              type: this.fb.control('email'),
            }),
          ]),
          thanks_headline: this.fb.control('Vielen Dank für die Teilnahme!'),
          thanks_text: this.fb.control(
            'Die Gewinner werden nach Ablauf des Gewinnspiels per E-Mail verständigt.'
          ),
          thanks_button_text: this.fb.control('Nocheinmal teilnehmen'),
          end_headline: this.fb.control('Das Gewinnspiel ist beendet!'),
          end_text: this.fb.control(
            'Die Gewinner werden in Kürze per E-Mail verständigt.'
          ),
          questions_type: this.fb.control(0),
          show_answer_images: this.fb.control(true),
          questions: this.fb.array([
            this.fb.group({
              enabled: this.fb.control(true),
              question: this.fb.control(
                'Welcher dieser Schauspieler verkörpert den Charakter Iron Man im Marvel Cinematic Universe?'
              ),
              answers: this.fb.array([
                this.fb.group({
                  answer: this.fb.control('Tony Stark'),
                  image_file: this.fb.control(''),
                  image_thumb_file: this.fb.control(''),
                  correct: this.fb.control(true),
                  enabled: this.fb.control(true),
                }),
              ]),
            }),
          ]),
        });
    }

    return this.fb.group({});
  }

  handleSubmit() {
    if (this.settingsFormData.invalid) {
      this.toastrService.error('Settings validation failed');
      return;
    }

    // create promotion
    this.promotionService
      .createPromotionWithCards(this.settingsFormData.value, this.layoutCards)
      .subscribe({
        next: (res) => {
          this.toastrService.success('Promotion Created');
        },
        error: console.log,
      });
  }

  toggleStyle(
    control: AbstractControl | null | undefined,
    values: { activeValue: any; defaultValue: any }
  ) {
    if (control?.value === values.activeValue) {
      control?.setValue(values.defaultValue);
    } else {
      control?.setValue(values.activeValue);
    }
  }
}
