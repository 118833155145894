import { Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../../backend-services/auth.service';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  personalDataForm = new FormGroup({
    first_name: new FormControl(''),
    last_name: new FormControl(''),
    email: new FormControl('', [Validators.email]),
  });
  personalDataFormError?: string = undefined;
  personalDataFormIsSubmitting = false;

  passwordDataForm = new FormGroup({
    password: new FormControl(''),
    new_password: new FormControl('', [
      Validators.pattern(/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/),
    ]),
    confirm_password: new FormControl(
      '',
      this.confirmPasswordValidator()
    ),
  });
  passwordDataFormIsSubmitting = false;

  @ViewChild('passwordElement') passwordElement: ElementRef | undefined;
  @ViewChild('newPasswordElement') newPasswordElement: ElementRef | undefined;
  @ViewChild('confirmPasswordElement') confirmPasswordElement: ElementRef | undefined;

  @ViewChild('passwordChangeModalBackdrop') passwordChangeModalBackdrop: ElementRef | undefined;

  get passwordControl() {
    return this.passwordDataForm.get('password') as AbstractControl;
  }
  get newPasswordControl() {
    return this.passwordDataForm.get('new_password') as AbstractControl;
  }
  get confirmPasswordControl() {
    return this.passwordDataForm.get('confirm_password') as AbstractControl;
  }

  constructor(private authService: AuthService, private toastr: ToastrService) {
    authService.profile.subscribe((profile) => {
      this.personalDataForm.get('first_name')?.setValue(profile?.first_name);
      this.personalDataForm.get('last_name')?.setValue(profile?.last_name);
      this.personalDataForm.get('email')?.setValue(profile?.email);
    });

    this.newPasswordControl.valueChanges.subscribe(() => {
      this.onPasswordChange();
    });
  }

  confirmPasswordValidator(): ValidatorFn {
    return (confirmPasswordControl: AbstractControl): { [key: string]: any } | null => {
      const passwordControl = this.passwordDataForm?.get('new_password');


      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        this.confirmPasswordElement?.nativeElement.setCustomValidity('invalid');
        confirmPasswordControl.setErrors({ passwordMismatch: true });
        return { passwordMismatch: true };
      } else {
        this.confirmPasswordElement?.nativeElement.setCustomValidity('');
        confirmPasswordControl.setErrors(null);
        return null;
      }
    };
  }

  onPasswordChange() {
    if (this.newPasswordControl.valid) {
      this.newPasswordElement?.nativeElement.setCustomValidity('');
    } else {
      this.newPasswordElement?.nativeElement.setCustomValidity('invalid');
    }

    // validate confirm password
    const confirmPasswordValidatorFn = this.confirmPasswordControl.validator;
    if (confirmPasswordValidatorFn) {
      confirmPasswordValidatorFn(this.confirmPasswordControl)
    }

  }

  saveProfileData() {
    this.personalDataForm.markAllAsTouched();
    this.personalDataFormError = undefined;

    if (this.personalDataForm.valid) {
      this.personalDataFormIsSubmitting = true;
      this.personalDataForm.disable();

      this.authService.updateProfile(this.personalDataForm.value).subscribe({
        next: () => {
          this.personalDataFormIsSubmitting = false;
          this.personalDataForm.enable();
          this.toastr.success("Ihr Profil wurde gespeichert");
        },
        error: (res) => {
          this.personalDataFormError = res.error?.message;

          this.personalDataFormIsSubmitting = false;
          this.personalDataForm.enable();
        },
      });
    }
  }

  savePassword() {
    this.passwordDataForm.markAllAsTouched();

    if (this.passwordDataForm.valid) {
      this.passwordDataFormIsSubmitting = true;
      this.passwordElement?.nativeElement.setCustomValidity('')
      this.passwordDataForm.disable({emitEvent: true});

      this.authService.changePassword({
        current_password: this.passwordDataForm.get('password')!.value,
        password: this.passwordDataForm.get('new_password')!.value,
      }).subscribe({
        next: () => {
          this.passwordDataFormIsSubmitting = false;
          this.passwordDataForm.enable({emitEvent: true});

          this.toastr.success('Passwort wurde gespeichert');
          this.passwordChangeModalBackdrop?.nativeElement?.click();

          // reset the password form
          this.passwordDataForm.reset();
        },
        error: (res) => {
          if (res.error?.error === 'INVALID_CURRENT_PASSWORD') {
            this.passwordElement?.nativeElement.setCustomValidity('invalid')
          }else {
            this.toastr.error(res.error?.error || 'Something went wrong');
          }

          this.passwordDataFormIsSubmitting = false;
          this.passwordDataForm.enable({emitEvent: true});
        },
      })
    }

  }
}
