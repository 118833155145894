import { Component, HostBinding, Input } from '@angular/core';
import { NewPromotionService } from '../../../../new-promotion.service';

@Component({
  selector: 'app-menu-item-styles-view',
  templateUrl: './menu-item-styles-view.component.html',
  styleUrls: ['./menu-item-styles-view.component.scss'],
})
export class MenuItemStylesViewComponent {
  @HostBinding('class.w-100')
  @Input()
  rootFormGroup!: any;

  constructor(public newPromotionService: NewPromotionService) {}
}
