import { Injectable } from '@angular/core';
import {
  Background,
  Buttons,
  DesignTemplates,
  ErrorOccurred,
  Logo,
  Text,
} from '../../../shared/data-repository/global-text-snippets';
import {
  AdBaseModel,
  PickerAdModel,
  StoryAdModel,
} from '../../../shared/models/ad.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  SnackBarService,
  SnackBarType,
} from '../../../shared/snack-bar/snack-bar.service';
import { AdConfigurationItemModel } from '../../../shared/models/ad-configuration-item.model';
import { EditAdNavigationService } from '../edit-ad-navigation.service';
import { EditAdService } from '../edit-ad.service';
import { FontsService } from '../../../shared/services/fonts.service';
import { DropdownOption } from '../../../shared/data-structures/dropdown-option';
import { FileTypesService } from '../../../shared/files/file-types.service';
import { FileService } from '../../../shared/files/file.service';
import { OverlayService } from '../../../shared/services/overlay.service';
import { AdTypeService } from '../../ad-type.service';
import { take } from 'rxjs';
import { StorySlug } from '../../../shared/data-repository/api-slugs';
import { CommonEditAdBasicService } from './common-edit-ad-basic.service';

@Injectable({
  providedIn: 'root',
})
export class CommonEditAdDesignService {
  public fontTypeOptions!: DropdownOption[];
  public designTemplateForm!: FormGroup | undefined;
  public fontFileName = '';
  public logoBase64? = '';

  constructor(
    public fontsService: FontsService,
    public editAdService: EditAdService,
    private editAdNavigationService: EditAdNavigationService,
    private formBuilder: FormBuilder,
    private fileTypeService: FileTypesService,
    private commonEditAdDesignService: CommonEditAdBasicService,
    private snackBarService: SnackBarService,
    private overlayService: OverlayService,
    private adTypeService: AdTypeService,
    private fileService: FileService
  ) {}

  public initConfigurationItems(
    designTemplateConfigurationItem: AdConfigurationItemModel,
    logoConfigurationItem: AdConfigurationItemModel,
    backgroundConfigurationItem: AdConfigurationItemModel,
    textConfigurationItem: AdConfigurationItemModel,
    buttonConfigurationItem: AdConfigurationItemModel
  ): void {
    designTemplateConfigurationItem.id = 'design-templates';
    designTemplateConfigurationItem.title = DesignTemplates;
    designTemplateConfigurationItem.collapsed = false;

    logoConfigurationItem.id = 'logo';
    logoConfigurationItem.title = Logo;

    backgroundConfigurationItem.id = 'background';
    backgroundConfigurationItem.title = Background;

    textConfigurationItem.id = 'text';
    textConfigurationItem.title = Text;

    buttonConfigurationItem.id = 'buttons';
    buttonConfigurationItem.title = Buttons;
  }

  public initDesignForm(adModel: AdBaseModel): void {
    this.adTypeService
      .getAdType(adModel.adTypeId!)
      .pipe(take(1))
      .subscribe((adType) => {
        const model = adModel;
        let baseable;
        if (adType?.name === StorySlug) {
          baseable = model.baseable as StoryAdModel;
        } else {
          // Picker
          baseable = model.baseable as PickerAdModel;
        }

        this.logoBase64 = adModel.logo;
        this.designTemplateForm = this.formBuilder.group({
          designTemplate: new FormControl(),
          logo: new FormControl(model.logo),
          logoImageName: new FormControl(model.logoImageName),
          logoLeft: new FormControl(model.logoPositionLeft),
          logoRight: new FormControl(model.logoPositionRight),
          logoTop: new FormControl(model.logoPositionTop),
          logoBottom: new FormControl(model.logoPositionBottom),
          horizontalLogoAlignment: new FormControl(
            model.horizontalLogoAlignment
          ),
          verticalLogoAlignment: new FormControl(model.verticalLogoAlignment),
          headlineFontType: new FormControl(baseable.headlineFontType),
          headlineFontSize: new FormControl(
            baseable.headlineFontSize.toString()
          ),
          ctaButtonTextFontType: new FormControl(model.ctaButtonTextFontType),
          ctaButtonTextSize: new FormControl(
            model.ctaButtonTextSize!.toString()
          ),
          backgroundColorCtrl: new FormControl(model.backgroundColor),
          ctaButtonTextColorCtrl: new FormControl(model.ctaButtonTextColor),
          ctaButtonBackgroundColorCtrl: new FormControl(
            model.ctaButtonBackgroundColor
          ),
        });

        if (adType?.name === StorySlug) {
          this.attachStorylineAdControls(baseable as StoryAdModel);
        } else {
          // Picker
          this.attachPickerAdControls(baseable as PickerAdModel);
        }

        this.initDesignFormCtrlChanges();
      });
  }

  public logoChanged($event: Event): void {
    const success = (result: string, fileName: string) => {
      this.designTemplateForm!.patchValue({
        logo: result,
        logoImageName: fileName,
      });
      this.logoBase64 = result;
    };
    const error = (message: string) => {
      this.snackBarService.openSnackBar(
        ErrorOccurred + message,
        SnackBarType.Error
      );
    };

    this.fileTypeService
      .getImageTypes()
      .subscribe((imageTypes) =>
        this.fileService.toBase64($event, success, error, imageTypes)
      );
  }

  public resetLogo(): void {
    this.logoBase64 = '';
    this.designTemplateForm!.controls.logo.patchValue('');
  }

  public uploadFont($event: Event): void {
    this.fontFileName = '';
    const file = ($event.target as HTMLInputElement).files![0];
    this.fontsService.addFont(file).subscribe(() => {
      this.fontFileName = file.name;
    });
  }

  public formValueChanges(formValues: any, adTypeId: number) {
    this.adTypeService
      .getAdType(adTypeId)
      .pipe(take(1))
      .subscribe((adType) => {
        if (!adType) {
          throw new Error('Ad type is undefined.');
        }

        const curIndex = this.editAdNavigationService.getPageIndex();
        this.editAdNavigationService.editAdPagesSaved[curIndex] = false;

        const updatedAdBaseModel = new AdBaseModel();
        Object.assign(
          updatedAdBaseModel,
          this.commonEditAdDesignService.AdModel
        );
        updatedAdBaseModel.ctaButtonTextColor =
          formValues.ctaButtonTextColorCtrl.hex;
        updatedAdBaseModel.ctaButtonTextFontType =
          formValues.ctaButtonTextFontType;
        updatedAdBaseModel.ctaButtonTextSize = formValues.ctaButtonTextSize;
        updatedAdBaseModel.ctaButtonBackgroundColor =
          formValues.ctaButtonBackgroundColorCtrl.hex;
        updatedAdBaseModel.backgroundColor = formValues.backgroundColorCtrl.hex;
        updatedAdBaseModel.logo = formValues.logo;
        updatedAdBaseModel.horizontalLogoAlignment =
          formValues.horizontalLogoAlignment;
        updatedAdBaseModel.verticalLogoAlignment =
          formValues.verticalLogoAlignment;
        updatedAdBaseModel.logoPositionLeft = formValues.logoLeft;
        updatedAdBaseModel.logoPositionRight = formValues.logoRight;
        updatedAdBaseModel.logoPositionBottom = formValues.logoBottom;
        updatedAdBaseModel.logoPositionTop = formValues.logoTop;
        updatedAdBaseModel.logoImageName = formValues.logoImageName;

        let updatedAdBaseableModel;
        if (adType.name === StorySlug) {
          updatedAdBaseableModel = new StoryAdModel();
        } else {
          // Picker
          updatedAdBaseableModel = new PickerAdModel();
        }

        Object.assign(
          updatedAdBaseableModel,
          this.commonEditAdDesignService.AdModel.baseable
        );
        updatedAdBaseableModel.headlineFontSize = formValues.headlineFontSize;
        updatedAdBaseableModel.headlineFontType = formValues.headlineFontType;

        if (adType.name === StorySlug) {
          this.updateStoryLineModel(
            updatedAdBaseableModel as StoryAdModel,
            formValues
          );
        } else {
          // Picker Ad
          this.updatePickerModel(
            updatedAdBaseableModel as PickerAdModel,
            formValues
          );
        }

        updatedAdBaseModel.baseable = updatedAdBaseableModel;
        this.editAdService.adModelUserChange = true;
        this.commonEditAdDesignService.AdModel = updatedAdBaseModel;
        this.editAdNavigationService.adModelChanged$.next(updatedAdBaseModel);
      });
  }

  private initDesignFormCtrlChanges(): void | never {
    if (!this.designTemplateForm) {
      throw new Error('Design template form is invalid.');
    }
    this.designTemplateForm.valueChanges.subscribe((formValues) => {
      this.formValueChanges(
        formValues,
        this.commonEditAdDesignService.AdModel.adTypeId!
      );
    });
  }

  private attachStorylineAdControls(baseable: StoryAdModel): void {
    this.designTemplateForm?.addControl(
      'textFontType',
      new FormControl(baseable.textFontType)
    );
    this.designTemplateForm?.addControl(
      'textFontSize',
      new FormControl(baseable.textFontSize)
    );
  }

  private attachPickerAdControls(baseable: PickerAdModel): void {
    this.designTemplateForm?.addControl(
      'headlineFontColor',
      new FormControl(baseable.headlineFontColor)
    );

    this.designTemplateForm?.addControl(
      'continuousTextFontSize',
      new FormControl(baseable.continuousTextFontSize.toString())
    );
    this.designTemplateForm?.addControl(
      'continuousTextFontType',
      new FormControl(baseable.continuousTextFontType)
    );
    this.designTemplateForm?.addControl(
      'continuousTextFontColor',
      new FormControl(baseable.continuousTextFontColor)
    );

    this.designTemplateForm?.addControl(
      'infoTextFontSize',
      new FormControl(baseable.infoTextFontSize.toString())
    );
    this.designTemplateForm?.addControl(
      'infoTextFontType',
      new FormControl(baseable.infoTextFontType)
    );
  }

  private updateStoryLineModel(
    updatedAdBaseableModel: StoryAdModel,
    formValues: any
  ): void {
    (updatedAdBaseableModel as StoryAdModel).textFontSize =
      formValues.textFontSize;
    (updatedAdBaseableModel as StoryAdModel).textFontType =
      formValues.textFontType;
  }

  private updatePickerModel(
    updatedAdBaseableModel: PickerAdModel,
    formValues: any
  ): void {
    (updatedAdBaseableModel as PickerAdModel).headlineFontColor =
      formValues.headlineFontColor.hex;
    (updatedAdBaseableModel as PickerAdModel).continuousTextFontSize =
      formValues.continuousTextFontSize;
    (updatedAdBaseableModel as PickerAdModel).continuousTextFontType =
      formValues.continuousTextFontType;
    (updatedAdBaseableModel as PickerAdModel).continuousTextFontColor =
      formValues.continuousTextFontColor.hex;

    (updatedAdBaseableModel as PickerAdModel).infoTextFontSize =
      formValues.infoTextFontSize;
    (updatedAdBaseableModel as PickerAdModel).infoTextFontType =
      formValues.infoTextFontType;
  }
}

export enum AdDesignFormControls {
  Background,
  headlineTextColor,
  continuousTextColor,
  CtaBtnTextColor,
  CtaBtnBackgroundColor,
}

export enum AdContentFormControls {
  HeadlineColor,
  TextColor,
  BackgroundColor,
}
