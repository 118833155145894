import {Injectable, TemplateRef, ViewContainerRef} from '@angular/core';
import {TemplatePortal} from '@angular/cdk/portal';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {
  private overlayRef!: OverlayRef | null;

  constructor(private overlay: Overlay) {
  }

  public showOverlay(overlayTemplate: TemplateRef<any>, viewContainerRef: ViewContainerRef): void {
    const configs = new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['disable-user-input-panel', 'is-active'],
      backdropClass: 'disable-user-input-backdrop'
    });

    this.overlayRef = this.overlay.create(configs);
    this.overlayRef.attach(new TemplatePortal(overlayTemplate, viewContainerRef));
  }

  public hideOverlay(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = null;
    }
  }

}
