<form
  [formGroup]="newPromotionService.selectedLayoutCard!.data"
  class="w-100 d-flex flex-column"
>
  <ul class="list-unstyled">
    <li>
      <div class="list-item-dropdown-title" style="background: #6e72eb">
        <div
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
        >
          <div
            class="d-flex justify-content-between align-items-center text-white"
          >
            <div style="font-weight: bold">Abstand</div>
            <button class="transparent-btn" (click)="closeCard()">
              <i class="material-icons">close</i>
            </button>
          </div>
        </div>
      </div>
    </li>
    <app-block-section title="Abstand">
      <div class="w-100">
        <div
          class="d-flex w-100 flex-row justify-content-between align-items-center mt-1"
        >
          <label for="breite" style="font-size: 14px; color: #332e45"
            >Breite</label
          >
          <label for="breite" style="font-size: 14px; color: #332e45"
            >{{
              newPromotionService.selectedLayoutCard?.data?.get("height")?.value
            }}px</label
          >
        </div>
        <input
          type="range"
          class="form-range"
          id="breite"
          formControlName="height"
          min="0"
          max="256"
        />
      </div>
    </app-block-section>
  </ul>
</form>
