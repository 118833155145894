<form class="form configuration-items-wrapper"
      [formGroup]="animationTemplateForm"
      [ngClass]="{ 'displayErroneousInputs': displayErroneousInputs }">

  <!-- ############ Überblendung -->
  <app-ad-configuration-item  [item]="animationFunctionConfigurationItem">
    <div class="col-6 d-flex align-items-center">
      <mat-form-field appearance="fill">
        <mat-label>{{AnimationType}}</mat-label>
        <mat-select
          formControlName="animationType">
          <mat-option
            [value]="option.key"
            *ngFor="let option of AnimationTypeOptions">
            {{option.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </app-ad-configuration-item>

  <!-- ############ Anzeigedauer -->
  <app-ad-configuration-item [item]="animationDurationConfigurationItem">
    <div class="col-12 mt-3"><h6 class="mb-0">{{AnimationDuration}}</h6></div>
    <div class="col-6 d-flex align-items-center">
      <mat-form-field appearance="fill">
        <mat-label>{{AnimationDuration}}</mat-label>
        <input matInput
               formControlName="animationDuration">
      </mat-form-field>
    </div>
  </app-ad-configuration-item>

  <!-- ############ Wiederholungen -->
  <app-ad-configuration-item [item]="animationRepetitionConfigurationItem">
    <div class="col-12 mt-3"><h6 class="mb-0">{{AnimationRepetitions}}</h6></div>
    <div class="col-6 d-flex align-items-center">
      <mat-form-field appearance="fill">
        <mat-label>{{AnimationRepetitions}}</mat-label>
        <input matInput
               formControlName="animationRepetitions">
      </mat-form-field>
    </div>
  </app-ad-configuration-item>
</form>

<app-edit-ad-navigation></app-edit-ad-navigation>
