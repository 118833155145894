import {Routes} from '@angular/router';
import {PageNotFoundComponent} from '../../page-not-found/page-not-found.component';
import {AdboxRoutes} from '../../shared/data-repository/routes';
import {NewPromotionTypePickerComponent} from './new-promotion-type-picker/new-promotion-type-picker.component';
import {NewPromotionSettingsComponent} from './new-promotion-settings/new-promotion-settings.component';

export const NewPromotionRoutes: Routes = [
  {
    path: 'settings',
    component: NewPromotionSettingsComponent,
  },
];
