import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {SnackBarType} from './snack-bar.service';
import {Ok} from '../data-repository/global-text-snippets';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {
  public Ok = Ok;
  public message = '';
  public type = SnackBarType.Info;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    if (typeof data !== 'undefined') {
      this.message = data.message;
      this.type = data.type;
    }
  }

  public dismiss(): void {
    this.data.preClose();
  }
}
