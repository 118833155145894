import { lyl, ThemeRef, ThemeVariables } from '@alyle/ui';
import { STYLES as CROPPER_STYLES } from '@alyle/ui/image-cropper';

export const STYLES = (_: ThemeVariables, ref: ThemeRef) => {
  ref.renderStyleSheet(CROPPER_STYLES);
  const cropper = ref.selectorsOf(CROPPER_STYLES);

  return {
    root: lyl`{
      ${cropper.root} {
        height: 530px
        width: 500px
      },
    }`,
  };
};
