import { Injectable } from '@angular/core';
import Compressor from 'compressorjs';
import { ImageType } from './image.service';

@Injectable({
  providedIn: 'root',
})
export class ImageCompressorService {
  public compress(
    blob: Blob,
    quality: number,
    type: ImageType,
    callback: (blob: Blob) => void
  ): void {
    new Compressor(blob, {
      quality: quality / 100,
      convertTypes: 'image/' + type,
      success: (result: any) => {
        callback(result);
      },
      error: (err: any) => {
        throw new Error(err.message);
      },
    });
  }
}
