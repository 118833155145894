import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, switchMap} from 'rxjs/operators';
import {EndpointService} from '../../shared/services/endpoint.service';
import * as fromFileTypesActions from './file-types.actions';
import * as fromAppActions from '../app/app.actions';
import {map} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpErrorService} from '../../shared/services/http-error.service';
import {plainToInstance} from 'class-transformer';
import {FileTypeModel} from '../../shared/models/file-type.model';

@Injectable()
export class FileTypesEffects {
  fetchFileTypes$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromAppActions.bootstrapApp),
        switchMap(() => {
          const endpoint = this.endpointService.getFileTypesSlug();
          return this.http.get<any>(endpoint)
            .pipe(
              catchError(error => {
                return this.httpErrorService.handleError('File Types', fromFileTypesActions, error);
              })
            )
        }),
        map((json: any) => {
          const fileTypeModel = plainToInstance(FileTypeModel, json);
          return fromFileTypesActions.setFileTypesList({payload: fileTypeModel}) || []
        })
      )
    }
  );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private endpointService: EndpointService,
    private httpErrorService: HttpErrorService
  ) {
  }
}
