<div
  *ngIf="!newPromotionService.layoutCardExpanded"
  cdkDropList
  class="my-1 list-container"
  (cdkDropListDropped)="drop($event)"
>
  <ng-container
    *ngFor="let card of newPromotionService.layoutCards; index as i"
  >
    <app-layout-block-card [card]="card" [index]="i"></app-layout-block-card>
  </ng-container>
  <button
    class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 w-100 border-0 bg-white rounded-3"
    role="button"
    data-bs-toggle="modal"
    data-bs-target="#addNewBlockModal"
  >
    <div
      class="d-flex justify-content-center align-items-center gap-2 layout-view-title-button-outer"
      style="border: #332e45 dashed 1px"
    >
      <div class="d-flex gap-2 align-items-center justify-content-center">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="add">
            <path
              id="Vector"
              d="M12.6666 8.66668H8.66659V12.6667H7.33325V8.66668H3.33325V7.33334H7.33325V3.33334H8.66659V7.33334H12.6666V8.66668Z"
              fill="#332E45"
            />
          </g>
        </svg>
        <span>Neuer Block</span>
      </div>
    </div>
  </button>
</div>
<div *ngIf="newPromotionService.layoutCardExpanded">
  <app-quiz-block-expanded-view
    *ngIf="newPromotionService.selectedLayoutCard?.type === 'Quiz'"
  ></app-quiz-block-expanded-view>
  <app-text-block-expanded-view
    *ngIf="newPromotionService.selectedLayoutCard?.type === 'Text'"
  ></app-text-block-expanded-view>
  <app-image-block-expanded-view
    *ngIf="newPromotionService.selectedLayoutCard?.type === 'Bild'"
  ></app-image-block-expanded-view>
  <app-video-block-expanded-view
    *ngIf="newPromotionService.selectedLayoutCard?.type === 'Video'"
  ></app-video-block-expanded-view>
  <app-image-text-block-expanded-view
    *ngIf="newPromotionService.selectedLayoutCard?.type === 'Bild/Text'"
  ></app-image-text-block-expanded-view>
  <app-gallery-block-expanded-view
    *ngIf="newPromotionService.selectedLayoutCard?.type === 'Galerie'"
  ></app-gallery-block-expanded-view>
  <app-image-banner-block-expanded-view
    *ngIf="newPromotionService.selectedLayoutCard?.type === 'Bildbanner'"
  ></app-image-banner-block-expanded-view>
  <app-columns-block-expanded-view
    *ngIf="newPromotionService.selectedLayoutCard?.type === 'Spalten'"
  ></app-columns-block-expanded-view>
  <app-spacing-block-expanded-view
    *ngIf="newPromotionService.selectedLayoutCard?.type === 'Abstand'"
  ></app-spacing-block-expanded-view>
  <app-navbar-block-expanded-view
    *ngIf="newPromotionService.selectedLayoutCard?.type === 'Navbar'"
  ></app-navbar-block-expanded-view>
  <app-footer-block-expanded-view
    *ngIf="newPromotionService.selectedLayoutCard?.type === 'Footer'"
  ></app-footer-block-expanded-view>
</div>
