import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {HttpClient, HttpParams} from '@angular/common/http';
import * as fromImagesActions from '../../state/images/images.actions';
import {catchError, switchMap} from 'rxjs/operators';
import {EndpointService, HttpOperation} from '../../shared/services/endpoint.service';
import {map} from 'rxjs';
import {plainToInstance} from 'class-transformer';
import {HttpErrorService} from '../../shared/services/http-error.service';
import {ImageModel} from '../../shared/models/image.model';
import {OriginalOptionImageType} from '../../ads/edit-ad/edit-ad.service';

@Injectable()
export class ImagesEffects {
  fetchImages$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromImagesActions.fetchOriginalImage),
        map(data => data.payload),
        switchMap(([imageWrapperId, adTypeName, types]) => {
          const endpoint = this.endpointService.getOriginalImageSlug(HttpOperation.Get, adTypeName, imageWrapperId);
          let params = new HttpParams();
          if (types) {
            types.forEach(type => {
              const key = type === OriginalOptionImageType.Thumb ? OriginalOptionImageType.Thumb : OriginalOptionImageType.Background;
              params = params.append(key, 'true');
            });
          }

          return this.http.get<any>(endpoint, {params})
            .pipe(
              catchError(error => {
                return this.httpErrorService.handleError('Original Image', fromImagesActions, error);
              })
            )
        }),
        map((json: any) => {
          const image = plainToInstance(ImageModel, json);
          return fromImagesActions.setOriginalImage({payload: image}) || []
        })
      )
    }
  )

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private endpointService: EndpointService,
    private httpErrorService: HttpErrorService
  ) {
  }
}
