import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient) {}

  uploadImage(file: File) {
    const formData = new FormData();
    formData.set('image', file);
    return this.http.post<{success: true, data: {file: string, thumb: string}}>(`/api/image`, formData);
  }

  uploadFile(file: File) {
    const formData = new FormData();
    formData.set('file', file);
    return this.http.post<{success: true, data: {file: string, thumb: string}}>(`/api/image`, formData);
  }


  deleteImage(id: number) {
    return this.http.delete<{success: true}>(`/api/image/${id}`);
  }
}
