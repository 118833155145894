import { Injectable } from '@angular/core';
import { merge, Observable, startWith, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EditAdNavigationService } from '../../edit-ad/edit-ad-navigation.service';
import { AdBaseModel } from '../../../shared/models/ad.model';
import { AdAsset } from '../../../shared/interfaces/ad-asset';

@Injectable({
  providedIn: 'root',
})
export class CommonAdTemplateService {
  // boolean: If zip should be downloaded
  // AdAsset[]: All slides, options used for file size calculation
  public updatePreviewAndFileSize = new Subject<[boolean, AdAsset[]]>();

  constructor(private editAdNavigationService: EditAdNavigationService) {}

  public renderPreview(): Observable<any> {
    const adModelChangedObs$ =
      this.editAdNavigationService.adModelChanged$.pipe(startWith(null));
    const refreshPreviewClickedObs$ =
      this.editAdNavigationService.refreshPreviewClicked$.pipe(startWith(null));
    const adModelSavedObs$ = this.editAdNavigationService.adModelSaved$;

    return merge(
      adModelChangedObs$,
      refreshPreviewClickedObs$,
      adModelSavedObs$
    ).pipe(debounceTime(600));
  }

  public alignLogo(adModel: AdBaseModel): any {
    return {
      'justify-content':
        adModel.horizontalLogoAlignment === 'left'
          ? 'start'
          : adModel.horizontalLogoAlignment === 'right'
          ? 'end'
          : 'center',
      'align-items':
        adModel.verticalLogoAlignment === 'top'
          ? 'start'
          : adModel.verticalLogoAlignment === 'bottom'
          ? 'end'
          : 'center',
      'padding-top': adModel.logoPositionTop,
      'padding-bottom': adModel.logoPositionBottom,
      'padding-left': adModel.logoPositionLeft,
      'padding-right': adModel.logoPositionRight,
    };
  }
}
