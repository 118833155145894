import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AnimationTypePickerOptions } from 'src/app/shared/data-repository/dropdown-options';
import {
  AnimationType,
  AnimationTypeTitle,
} from 'src/app/shared/data-repository/global-text-snippets';
import { AdConfigurationItemModel } from '../../../../shared/models/ad-configuration-item.model';
import { EditAdNavigationService } from '../../edit-ad-navigation.service';
import { EditAdService } from '../../edit-ad.service';
import { ActivatedRoute } from '@angular/router';
import { AdsService } from '../../../ads.service';
import { CommonEditAdBasicService } from '../../common-services/common-edit-ad-basic.service';
import { SubSink } from 'subsink';
import { Animations } from '../../../../shared/data-repository/page-mapping';
import { AdBaseModel, PickerAdModel } from '../../../../shared/models/ad.model';

@Component({
  selector: 'app-picker-ad-animation',
  templateUrl: './picker-ad-animation.component.html',
  styleUrls: ['./picker-ad-animation.component.scss'],
})
export class PickerAdAnimationComponent implements OnInit, OnDestroy {
  // Constants
  public AnimationType = AnimationType;
  public AnimationTypeOptions = AnimationTypePickerOptions;
  public animationTemplateForm!: FormGroup;
  public displayErroneousInputs = false;
  public animationFunctionConfigurationItem: AdConfigurationItemModel =
    new AdConfigurationItemModel();

  private subs = new SubSink();

  constructor(
    private formBuilder: FormBuilder,
    private editAdNavigationService: EditAdNavigationService,
    private editAdService: EditAdService,
    private activatedRoute: ActivatedRoute,
    private adsService: AdsService,
    private commonEditAdBasicService: CommonEditAdBasicService
  ) {}

  ngOnInit(): void {
    this.initConfigurationItems();
    this.initEvents();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private initForm(adModel: AdBaseModel): void {
    const model = adModel;
    const baseable = model.baseable as PickerAdModel;

    this.animationTemplateForm = this.formBuilder.group({
      animationType: new FormControl(baseable.animationType),
    });
    this.initFormCtrlChanges();
  }

  private initFormCtrlChanges(): void {
    this.animationTemplateForm.valueChanges.subscribe((formValues) => {
      if (!this.animationTemplateForm.valid) {
        this.editAdNavigationService.saveOrDownloadBtnDisabled$.next(true);
        return;
      }

      const curIndex = this.editAdNavigationService.getPageIndex();
      this.editAdNavigationService.editAdPagesSaved[curIndex] = false;

      const currentAdModel = this.commonEditAdBasicService.AdModel;
      const updatedModel = new AdBaseModel();
      Object.assign(updatedModel, currentAdModel);

      const baseable = new PickerAdModel();
      Object.assign(baseable, currentAdModel?.baseable);
      baseable.animationType = formValues.animationType;

      updatedModel.baseable = baseable;
      this.editAdNavigationService.adModelChanged$.next(updatedModel);
      this.commonEditAdBasicService.AdModel = updatedModel;
      this.editAdNavigationService.saveOrDownloadBtnDisabled$.next(false);
      this.editAdService.adModelUserChange = true;
    });
  }

  private initConfigurationItems() {
    this.animationFunctionConfigurationItem.id = 'animation-function';
    this.animationFunctionConfigurationItem.title = AnimationTypeTitle;
    this.animationFunctionConfigurationItem.collapsed = false;
  }

  private initEvents() {
    // Init form
    this.subs.sink = this.editAdService.adModelUpdated$.subscribe((adModel) => {
      if (!adModel) {
        throw new Error('Ad model is not defined.');
      }

      if (!this.animationTemplateForm) {
        this.initForm(adModel);
      }
    });

    this.subs.sink = this.editAdNavigationService.savedAdTriggered$.subscribe(
      () => {
        this.commonEditAdBasicService.adSaved(Animations, false);
      }
    );
  }
}
