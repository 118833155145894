import {AfterViewInit, Component, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AdBaseModel} from '../../../shared/models/ad.model';
import {ContextMenuItem} from '../../../shared/menus/context-menu/context-menu-item';
import {AdTypeService} from '../../ad-type.service';
import {MatSort, Sort} from '@angular/material/sort';
import {HttpOperation} from '../../../shared/services/endpoint.service';
import {SubSink} from 'subsink';
import {AdsService} from '../../ads.service';
import {FilterMenuService} from '../../../shared/menus/filter-menu/filter-menu.service';
import {ItemsPerPage} from '../../../shared/data-repository/global-constants';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {PaginationComposite} from '../../../shared/data-structures/pagination';
import {filter, map, take} from 'rxjs';
import {Actions} from '@ngrx/effects';
import {Router} from '@angular/router';
import {AdStatus} from '../../../shared/data-structures/ad-data-structures';
import {AdboxRoutes} from '../../../shared/data-repository/routes';
import {SortAndFilterComposite} from '../../../shared/data-structures/sort-and-filter';

@Component({
  selector: 'app-my-ads-table-view',
  templateUrl: './my-ads-table-view.component.html',
  styleUrls: ['./my-ads-table-view.component.scss']
})
export class MyAdsTableViewComponent implements OnInit, AfterViewInit {
  @Input() public contextMenuItems!: ContextMenuItem[];
  @Input() public total!: number;
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) public sort!: MatSort;
  public ads!: ReadonlyArray<AdBaseModel>;
  public ItemsPerPage = ItemsPerPage;
  public displayedColumns: string[] = ['title', 'type', 'created', 'status', 'context'];

  private filter = new Map<string, string>();
  private subs = new SubSink();
  private sortAndFilter = this.filterMenuService.createDefaultSortAndFilter();
  private interaction = TableInteraction.None;
  private AdsRoute = AdboxRoutes.Ads;
  private EditDesignRoute = AdboxRoutes.Design;
  private EditRoute = AdboxRoutes.Edit;
  private AdPreviewRoute = AdboxRoutes.AdPreview;

  constructor(public adTypeService: AdTypeService,
              private filterMenuService: FilterMenuService,
              private renderer: Renderer2,
              private actions$: Actions,
              private router: Router,
              private adsService: AdsService) {
  }

  ngOnInit(): void {
    this.initEvents();
  }

  ngAfterViewInit(): void {
    const parent = document.querySelector('.mat-paginator-range-actions');
    const child = document.querySelector('.mat-paginator-range-label');
    const refChild = document.querySelector('.mat-paginator-navigation-next');
    this.renderer.insertBefore(parent, child, refChild);
  }

  public orderData($event: Sort): void {
    let newSort = [$event];
    this.interaction = TableInteraction.OrderColumn;
    if ($event.direction === '') {
      newSort = this.filterMenuService.createDefaultSortAndFilter().sort;
    }
    this.sortAndFilter = {
      ...this.sortAndFilter,
      sort: newSort
    };
    this.filterMenuService.sortAndFilterChanged$.next(this.sortAndFilter);
    this.subs.sink = this.adsService.fetchAds(this.sortAndFilter)
      .pipe(
        filter(response => response[2] !== HttpOperation.Put && this.interaction === TableInteraction.OrderColumn),
        take(1)
      )
      .subscribe(([ads, operation, _]) => {
        if (operation !== HttpOperation.Put && this.interaction === TableInteraction.OrderColumn) {
          this.ads = ads;
          this.paginator.firstPage();
        }
      });
  }

  public switchedPage($event: PageEvent): void {
    this.interaction = TableInteraction.SwitchPage;
    const pagination: PaginationComposite = {
      perPage: $event.pageSize,
      currentPage: $event.pageIndex + 1
    }
    this.subs.sink = this.adsService.fetchAds(this.sortAndFilter, pagination)
      .pipe(
        filter(_ => this.interaction === TableInteraction.SwitchPage),
        map(response => response[0]),
        take(1)
      )
      .subscribe(ads => {
        this.ads = ads;
      });
  }

  public clickRow(adBaseModel: AdBaseModel, $event: MouseEvent): void {
    const cell = $event.target as HTMLTableCellElement;
    if (cell.classList.contains('context')) {
      return;
    }

    if (!adBaseModel) {
      throw new Error('Adbase model not defined.');
    }

    this.subs.sink = this.adTypeService.getAdTypeName(adBaseModel).subscribe(name => {
      const adId = adBaseModel.id;
      if (adBaseModel.status === AdStatus.AdStatusDone) {
        // Preview
        const url = this.router.serializeUrl(
          this.router.createUrlTree([this.AdsRoute,
            this.AdPreviewRoute,
            adId])
        );

        window.open(url, '_blank');
      } else {
        // Edit mode
        this.router.navigate([this.AdsRoute,
          this.EditRoute,
          name,
          adId,
          this.EditDesignRoute], {state: {accessible: true}});
      }
    });
  }

  private fetchAds(sortAndFilter: SortAndFilterComposite | undefined): void {
    this.subs.sink = this.adsService.fetchAds(sortAndFilter)
      .subscribe(response => {
        if (response[1] === HttpOperation.Post || response[1] === HttpOperation.Delete) {
          this.ads = response[0];
          this.paginator.firstPage();
        } else if (response[1] === HttpOperation.Get) {
          this.ads = response[0];
        }
        this.total = response[2];
      });
  }

  private initEvents() {
    this.subs.sink = this.filterMenuService.sortAndFilterChanged$
      .subscribe(sortAndFilter => {
        if (!sortAndFilter) {
          this.fetchAds(undefined);
          return;
        }

        this.sortAndFilter = {
          ...this.sortAndFilter,
          filter: sortAndFilter.filter
        };
        this.fetchAds(this.sortAndFilter);
      });
  }
}

enum TableInteraction {
  None,
  SwitchPage,
  OrderColumn
}
