import {Component, Input} from '@angular/core';
import {InfoBoxModel} from '../../models/info-box.model';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent {
  @Input() infoBox!: InfoBoxModel;
}
