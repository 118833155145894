<form
  [formGroup]="newPromotionService.selectedLayoutCard!.data"
  class="w-100 d-flex flex-column"
>
  <ul class="list-unstyled">
    <li>
      <div class="list-item-dropdown-title" style="background: #6e72eb">
        <div
          style="letter-spacing: 0.7px; line-height: 16px"
          class="text-uppercase ink-offset-2 link-underline link-underline-opacity-0 link-icon-svg-black"
          href="#"
        >
          <div
            class="d-flex justify-content-between align-items-center text-white"
          >
            <div style="font-weight: bold">SPALTEN</div>
            <button class="transparent-btn" (click)="closeCard()">
              <i class="material-icons">close</i>
            </button>
          </div>
        </div>
      </div>
    </li>
    <app-block-section title="BLOCK">
      <app-block-styles-view
        [rootFormGroup]="newPromotionService.selectedLayoutCard!.data"
      ></app-block-styles-view>
    </app-block-section>
    <app-block-section title="SPALTEN">
      <app-columns-styles-view
        [rootFormGroup]="
          newPromotionService.selectedLayoutCard!.data.get('style_column')
        "
      ></app-columns-styles-view>
    </app-block-section>
    <app-block-section title="Texte">
      <ul
        class="w-100 ps-2"
        formArrayName="contents"
        cdkDropList
        (cdkDropListDropped)="drop($event)"
      >
        <li
          class="w-100 mb-3"
          *ngFor="let currentContent of contents.controls; index as i"
          [formGroupName]="i"
        >
          <div class="w-100 list-item-dropdown-title" cdkDrag>
            <div
              style="letter-spacing: 0.7px; line-height: 16px"
              class="w-100 text-uppercase"
              role="button"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#content-collapse-' + i"
              aria-expanded="false"
            >
              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="d-flex justify-content-start align-items-center gap-1 w-25"
                >
                  <button class="transparent-btn">
                    <i class="material-icons">drag_handle</i>
                  </button>
                  <button class="transparent-btn" (click)="duplicateContent(i)">
                    <i class="material-icons">content_copy</i>
                  </button>
                  <button class="transparent-btn" (click)="deleteContent(i)">
                    <i class="material-icons">delete</i>
                  </button>
                </span>
                <span class="w-75" style="color: #332e45; font-weight: bold"
                  >TEXT {{ i + 1 }}</span
                >
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="expand_less">
                      <path
                        id="Vector"
                        d="M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z"
                        fill="#332E45"
                      />
                    </g>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div class="collapse mb-3" [id]="'content-collapse-' + i">
            <app-text-styles-view
              [rootFormGroup]="currentContent.get('style_text')"
            ></app-text-styles-view>
            <div class="w-100">
              <editor [init]="tineMCEConfig" formControlName="content"></editor>
            </div>
          </div>
        </li>
      </ul>
      <button
        class="w-100 bg-white mt-2 mb-3 dashed-btn"
        (click)="addNewContent()"
      >
        <i class="material-icons">add</i>
        <span class="ms-2 text-uppercase">NEUER TEXT</span>
      </button>
    </app-block-section>
  </ul>
</form>
