<div
  class="text-container"
  [ngStyle]="{
    background:
      cardValues.background_type === 'color'
        ? cardValues.background_color
        : 'url(\'' + cardValues.background_image_file + '\')',
    width: cardValues.width + '%',
    'padding-left': cardValues.paddingLeft + cardValues.marginUnit,
    'padding-top': cardValues.paddingTop + cardValues.marginUnit,
    'padding-right': cardValues.paddingRight + cardValues.marginUnit,
    'padding-bottom': cardValues.paddingBottom + cardValues.marginUnit
  }"
>
  <div class="position-relative banner-container">
    <img
      *ngIf="cardValues.image_file"
      [src]="
        sanitizer.bypassSecurityTrustResourceUrl(
          '/api/image/' + cardValues.image_file
        )
      "
      [ngStyle]="{
        width: cardValues.style_image.width + '%'
      }"
      [alt]="cardValues.image_title"
      [title]="cardValues.image_title"
    />
    <div class="banner-content">
      <div>
        <h2
          class="my-3"
          [ngStyle]="{
            'font-family': cardValues.style_headline.fontFamily,
            'font-size': cardValues.style_headline.fontSize + 'px',
            'line-height': cardValues.style_headline.lineHeight
              ? cardValues.style_headline.lineHeight
              : 'normal',
            color: cardValues.style_headline.color,
            'font-weight': cardValues.style_headline.fontWeight,
            'font-style': cardValues.style_headline.fontStyle,
            'text-decoration': cardValues.style_headline.textDecoration,
            'text-transform': cardValues.style_headline.textTransform,
            'text-align': cardValues.style_headline.textAlign
          }"
        >
          {{ cardValues.headline_text }}
        </h2>
      </div>
      <p
        class="my-3"
        [ngStyle]="{
          'font-family': cardValues.style_text.fontFamily,
          'font-size': cardValues.style_text.fontSize + 'px',
          'line-height': cardValues.style_text.lineHeight
            ? cardValues.style_text.lineHeight
            : 'normal',
          color: cardValues.style_text.color
        }"
        [innerHTML]="sanitizer.bypassSecurityTrustHtml(cardValues.content)"
      ></p>
      <a
        class="primary-btn"
        #primaryBtn
        (mouseenter)="primaryBtn.setAttribute('data-hovered', 'true')"
        (mouseleave)="primaryBtn.setAttribute('data-hovered', 'false')"
        [ngStyle]="{
          'align-self': cardValues.style_button.alignSelf,
          color:
            primaryBtn.getAttribute('data-hovered') === 'true'
              ? cardValues.style_button.hover_color
              : cardValues.style_button.color,
          background:
            primaryBtn.getAttribute('data-hovered') === 'true'
              ? cardValues.style_button.hover_backgroundColor
              : cardValues.style_button.backgroundColor,
          'font-family': cardValues.style_button.fontFamily,
          'font-size': cardValues.style_button.fontSize + 'px',
          'font-weight': cardValues.style_button.fontWeight,
          'font-style': cardValues.style_button.fontStyle,
          'text-decoration': cardValues.style_button.textDecoration,
          'text-transform': cardValues.style_button.textTransform,
          'text-align': cardValues.style_button.textAlign,
          'padding-left':
            cardValues.style_button.paddingLeft +
            cardValues.style_button.marginUnit,
          'padding-top':
            cardValues.style_button.paddingTop +
            cardValues.style_button.marginUnit,
          'padding-right':
            cardValues.style_button.paddingRight +
            cardValues.style_button.marginUnit,
          'padding-bottom':
            cardValues.style_button.paddingBottom +
            cardValues.style_button.marginUnit,

          'border-style': cardValues.style_button.borderStyle,
          'border-radius': cardValues.style_button.borderRadius + 'px',
          'border-color':
            primaryBtn.getAttribute('data-hovered') === 'true'
              ? cardValues.style_button.hover_borderColor
              : cardValues.style_button.borderColor
        }"
        [href]="cardValues.button_url"
        [ngClass]="{ shadow: cardValues.style_button.hasShadow }"
      >
        {{ cardValues.button_text }}
      </a>
    </div>
  </div>
</div>
