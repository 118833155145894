import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {ViewMode} from '../../../../shared/data-structures/view-mode';
import {PageKey} from '../../../../shared/data-structures/navigation-data';
import {BreadcrumbState} from './breadcrumb-bar/breadcrumb-bar.component';
import {EditAdNavigationService} from '../../../../ads/edit-ad/edit-ad-navigation.service';
import {AdBaseModel} from '../../../../shared/models/ad.model';
import {AdTypeService} from '../../../../ads/ad-type.service';
import {SubSink} from 'subsink';

@Injectable({
  providedIn: 'root'
})
export class AppBarService implements OnDestroy {
  public viewModeChanged = new Subject<[PageKey, ViewMode]>();
  public breadcrumbItemStates = new Array<BreadcrumbState>();
  public breadcrumbStateChanged = new BehaviorSubject<BreadcrumbState[]>([]);

  private subs = new SubSink();

  constructor(private editAdNavigationService: EditAdNavigationService,
              private adTypeService: AdTypeService) {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public initializeBreadcrumbStates(adModel: AdBaseModel): void {
    this.subs.sink = this.adTypeService.getAdType(adModel.adTypeId!)
      .subscribe(adType => {
        if (!adType) {
          throw new Error('Ad type is undefined.');
        }

        const pages = this.editAdNavigationService.getEditViewPages(adType.name);
        pages.forEach((_, i) => {
          if (i === 0) {
            this.breadcrumbItemStates.push(BreadcrumbState.Current);
          } else {
            this.breadcrumbItemStates.push(BreadcrumbState.Future);
          }
        });
        this.breadcrumbStateChanged.next(this.breadcrumbItemStates)
      });
  }

  public updateBreadcrumbState(pIndex: number, state: BreadcrumbState): void {
    this.breadcrumbItemStates[pIndex] = state;
    this.breadcrumbStateChanged.next(this.breadcrumbItemStates);
  }

  public resetBreadcrumbBar(): void {
    this.breadcrumbItemStates = [];
    this.breadcrumbStateChanged.next(this.breadcrumbItemStates);
  }
}

