export type MaxFileUpload = {
  fonts: number;
  images: number;
};

export enum FileSizeInfo {
  OkForAll,
  OkForSome,
  OkForAny,
}
