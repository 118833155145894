<ng-container *ngIf="adModel !== null">
  <div id="renderable"> <!-- Needed because this whole template is duplicated by html2canvas library -->
    <div id="story-preview" class="ad-container">
      <div id="story-line-ad-wrapper" style="height: 600px; width: 300px;">

        <section id="story-preview-header">
          <div class="story-logo d-flex"
               [ngStyle]="commonAdTemplateService.alignLogo(adModel)">
            <img *ngIf="adModel.logo !== undefined && adModel.logo !== ''"
                 class="replace-base64 logo"
                 [attr.file-name]="adModel.logoImageName"
                 [src]="sanitizedLogo">
          </div>
        </section>

        <section id="story-preview-body"
                 class="position-relative h-100"
                 [style.background]="'#' + adModel.backgroundColor">

          <div id="slide-indicator" class="d-flex flex-direction-row" data-html2canvas-ignore="true">
            <div class="progress d-flex mx-1 flex-grow-1"
                 *ngFor="let slide of slides; let i = index;"
                 [style.animation-duration.ms]="storyAdModel.animationDuration"
                 [class.mx-1]="i === 0"></div>
          </div>

          <ng-container *ngFor="let slide of slides; let i = index;">
            <div class="carditem slide-{{i+1}} animate__animated replace-base64"
                 style="background-repeat: no-repeat; background-position: center"
                 [attr.data-html2canvas-ignore]="i === 0 ? null : 'true'"
                 [attr.file-name]="slide.imageName"
                 [ngClass]="storyAdModel.animationType"
                 [style.background-image]="slide.imageCropped | safeHtml"
                 [style.background-color]="'#' + slide.backgroundColor"
                 [class.first]="i === 0"
                 [class.show]="i === 0">
              <div class="content-wrapper">
                <div class="text-wrapper position-absolute"
                     [style.color]="'#' + slide.textColor">
                  <div class="story-preview-headline mb-2 text-uppercase"
                       [style.color]="'#' + slide.headlineColor"
                       [style.font-size.px]="storyAdModel.headlineFontSize"
                       [style.font-family]="storyAdModel.headlineFontType">
                    {{slide.headline}}
                  </div>
                  <div class="story-preview-text"
                       [style.color]="'#' + slide.textColor"
                       [style.font-size.px]="storyAdModel.textFontSize"
                       [style.font-family]="storyAdModel.textFontType">{{slide.text}}</div>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="button-wrapper position-absolute m-auto text-center">
            <button class="btn tertiary text-uppercase"
                    [style.font-size.px]="this.adModel.ctaButtonTextSize"
                    [style.font-family]="this.adModel.ctaButtonTextFontType"
                    [style.background]="'#' + this.adModel.ctaButtonBackgroundColor"
                    [style.color]="'#' + this.adModel.ctaButtonTextColor">
              {{adModel.ctaButtonText}}
            </button>
          </div>

          <div id="story-preview-background-label"
               *ngIf="slides === undefined || slides.length === 0"
               class="d-flex justify-content-center align-items-center position-absolute w-100 h-100 top-0">{{Background}}
          </div>
          <a id="nav-back"
             class="position-absolute h-100 start-0 top-0 text-decoration-none"></a>
          <a id="nav-next"
             class="position-absolute h-100 end-0 top-0 text-decoration-none"></a>
        </section>
      </div>
    </div>
  </div>
</ng-container>
